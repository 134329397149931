import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Dialog, DialogTitle, DialogContent, DialogContentText, Divider, Fab, Grid, Button, CardMedia, useMediaQuery, Avatar, CircularProgress } from '@mui/material';
import { CalendarMonth, Close, Edit } from '@mui/icons-material';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { gql, useQuery } from '@apollo/client';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { selectSession } from '../../reducers/sessionSlice';
import ScheduleTournamentPlayoffGameModal from './ScheduleTournamentPlayoffGameModal';
import { FIELD_TYPES, TOURNAMENT_PLAYOFF_STAGES } from '../../utilities/utilities';
import { FivezerWhiteIcon, LocationIcon, ScoreBoardIcon, ShareIcon, VersusIcon } from '../../img/icons/Icons';
import ShareURLButton from '../common/ShareURLButton';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import _ from 'lodash';
import Moment from 'react-moment';
import ViewTeamLineupModal from '../team/ViewTeamLineupModal';
import ViewScoreModal from '../challenge/ViewScoreModal';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import EditTournamentPlayoffGameModal from './EditTournamentPlayoffGameModal';
import ManageScoreByAdminsModal from './ManageScoreByAdminsModal';
import LoadingComponent from '../common/LoadingComponent';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function ViewTournamentPlayoffGameModal(props: { game_id: string; handleClose: any; next_game: any; current_game: any; open: any }) {
  const FIND_TOURNAMENT_STAGE_BY_ID_QUERY = gql`
    query findTournamentStageByID($id: ID!) {
      findTournamentStageByID(id: $id) {
        id
        stage_name
        position
        game {
          id
          short_id
          title
          status
          type
          start_date
          end_date
          home_team {
            id
            name
            team {
              ... on FootballTeam {
                __typename
                id
                short_id
                name
                shortname
                avatar {
                  path
                }
              }
            }
          }
          away_team {
            id
            name
            team {
              ... on FootballTeam {
                __typename
                id
                short_id
                name
                shortname
                avatar {
                  path
                }
              }
            }
          }
          complex {
            id
            name
            location {
              id
              title
              coordinates
              country_code
              street_name
              secondary_street_name
              status
              postcode {
                name
              }
              city {
                name
              }
              county {
                name
              }
              state {
                name
              }
              country {
                name
              }
              continent {
                name
              }
            }
          }
          arena {
            id
            name
            type
            size
            rank
            pictures {
              path
            }
            feedbacks {
              id
            }
            location {
              id
              title
              coordinates
              country_code
              street_name
              secondary_street_name
              status
              postcode {
                name
              }
              city {
                name
              }
              county {
                name
              }
              state {
                name
              }
              country {
                name
              }
              continent {
                name
              }
            }
          }
          results {
            id
            status
            home_team {
              id
              name
            }
            away_team {
              id
              name
            }
            captains_confirmations {
              ... on Footballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                }
              }
            }
            men_of_the_match {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
            statistics {
              id
              status
              nb_goals
              given_assists {
                id
                nb_assists
                giver {
                  ... on Footballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                  }
                  ... on Basketballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                  }
                }
                receiver {
                  ... on Footballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                  }
                  ... on Basketballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                  }
                }
              }
              pace
              shooting
              passing
              dribbling
              defending
              physical
              goal_keeping
              team_player {
                id
                team_player {
                  ... on FootballTeam {
                    __typename
                    id
                    name
                  }
                }
              }
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
              }
            }
          }
        }
        tournament {
          id
          status
          admins {
            id
            username
            avatar {
              filename
              path
            }
          }
          selected_teams {
            id
            name
            players {
              id
              status
              team_number
              team_player {
                ... on FootballTeam {
                  __typename
                  id
                  name
                }
              }
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  avatar {
                    path
                  }
                  player {
                    id
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  avatar {
                    path
                  }
                  player {
                    id
                  }
                }
              }
            }
            captains {
              id
              status
              team_number
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
              }
            }
            team {
              ... on FootballTeam {
                __typename
                id
                short_id
                name
                shortname
                avatar {
                  path
                }
              }
            }
          }
        }
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const intl = useIntl();

  const [tournamentGame, setTournamentGame] = React.useState(UNDEFINED_AS_ANY);
  const [openScheduleTournamentGame, setOpenScheduleTournamentGame] = React.useState(false);
  const [openEditTournamentGame, setOpenEditTournamentGame] = React.useState(false);
  const [openTeamLineup, setOpenTeamLineup] = React.useState(false);
  const [openManageScore, setOpenManageScore] = React.useState(false);
  const [openViewScore, setOpenViewScore] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const isSmallMobile = useMediaQuery('(max-width:600px)');

  const { watch, setValue } = useForm({
    defaultValues: {
      selected_team_lineup: '',
    },
  });
  const inputFields = watch();

  const loadedTournamentGame = useQuery(FIND_TOURNAMENT_STAGE_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      id: props.game_id,
    },
  });

  const ALL_ADMINS = tournamentGame?.tournament?.admins;
  const HOME_TEAM = tournamentGame?.game?.home_team;
  const AWAY_TEAM = tournamentGame?.game?.away_team;
  const HOME_TEAM_SCORE = _.sum(tournamentGame?.game?.results?.statistics?.filter((s: any) => s?.team_player?.team_player?.id === HOME_TEAM?.team?.id)?.map((s: any) => s?.nb_goals));
  const AWAY_TEAM_SCORE = _.sum(tournamentGame?.game?.results?.statistics?.filter((s: any) => s?.team_player?.team_player?.id === AWAY_TEAM?.team?.id)?.map((s: any) => s?.nb_goals));

  const handleClose = () => {
    props.handleClose();
  };

  const handleCloseTeamLineup = () => {
    setValue('selected_team_lineup', '');
    setOpenTeamLineup(false);
  };

  const handleOpenTeamLineup = (team_lineup_id: string) => {
    setValue('selected_team_lineup', team_lineup_id);
    setOpenTeamLineup(true);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const handleCloseManageScore = async () => {
    try {
      await handleRefetch();
      setOpenManageScore(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handleRefetch = async () => {
    try {
      const result = await loadedTournamentGame.refetch({
        id: props.game_id,
      });

      if (result) {
        setTournamentGame(result.data?.findTournamentStageByID);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpenScheduleTournamentGame = () => {
    setOpenScheduleTournamentGame(true);
  };

  const handleOpenEditTournamentGame = () => {
    setOpenEditTournamentGame(true);
  };

  const handleCloseEditTournamentGame = async () => {
    try {
      await handleRefetch();
      setOpenScheduleTournamentGame(false);
      setOpenEditTournamentGame(false);
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    if (props.open && tournamentGame === undefined && loadedTournamentGame.called && loadedTournamentGame.data?.findTournamentStageByID) {
      setTournamentGame(loadedTournamentGame.data?.findTournamentStageByID);
    }

    if (!props.open && tournamentGame) {
      setTournamentGame(UNDEFINED_AS_ANY);
    }
  }, [tournamentGame, loadedTournamentGame, setTournamentGame, props]);

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: { minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' }, p: '20px 0px 0' },
      }}
    >
      <Grid container>
        <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'left' }}>
          <Fab
            size="small"
            onClick={handleClose}
            className={'button-cancel'}
            sx={{
              boxShadow: 'none',
              bgcolor: 'transparent',
              ml: '2%',
            }}
          >
            <Close />
          </Fab>
        </Grid>
        <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'right' }}>
          {tournamentGame?.tournament?.status === 'SCHEDULING_GAMES' && tournamentGame?.tournament?.admins?.find((a: any) => a?.id === loggedPlayer?.id) ? (
            !tournamentGame?.game ? (
              <Fab
                size="small"
                variant="extended"
                onClick={handleOpenScheduleTournamentGame}
                className={'button-green-reverse'}
                sx={{
                  boxShadow: 'none',
                  bgcolor: 'transparent',
                  mr: '2%',
                }}
              >
                <CalendarMonth />
                &nbsp;Schedule game
              </Fab>
            ) : tournamentGame?.game?.start_date <= Date.now() ? (
              ''
            ) : (
              <Fab
                size="small"
                variant="extended"
                onClick={handleOpenEditTournamentGame}
                className={'button-green-reverse'}
                sx={{
                  boxShadow: 'none',
                  bgcolor: 'transparent',
                  mr: '2%',
                }}
              >
                <Edit />
                &nbsp;Edit game
              </Fab>
            )
          ) : (
            ''
          )}
        </Grid>
      </Grid>

      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>
          {tournamentGame?.stage_name ? intl.formatMessage({ id: TOURNAMENT_PLAYOFF_STAGES?.find((s: any) => s?.value === tournamentGame?.stage_name)?.label }) : ''} #{tournamentGame?.position}
        </Typography>
      </DialogTitle>
      <Divider variant="middle" sx={{ width: '80%', m: 'auto' }} />
      <DialogContent sx={{ pl: '0', pr: '0', pb: '0' }}>
        <DialogContentText id="alert-dialog-description" component="span">
          {!tournamentGame?.game || loadedTournamentGame.loading ? (
            <Grid item xl={12} xs={12} sm={12} sx={{ p: '0 10%' }}>
              {!tournamentGame === undefined && loadedTournamentGame.called && !loadedTournamentGame.loading ? (
                <Typography
                  variant="h3"
                  sx={{
                    p: '30px 20px',
                    fontSize: '1.3em',
                    textAlign: 'center',
                    width: '100%',
                    color: 'rgba(247, 251, 250, 0.3) !important',
                  }}
                >
                  No game scheduled
                </Typography>
              ) : (
                ''
              )}

              {loadedTournamentGame.loading && tournamentGame === undefined ? <LoadingComponent amount={1} text={<Typography sx={{ mb: '20px' }}>Loading game, please wait...</Typography>} loader={<CircularProgress className="orange-loader" />} /> : ''}
            </Grid>
          ) : (
            <>
              <AutoPlaySwipeableViews axis={'x-reverse'} index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents>
                <CardMedia
                  sx={{
                    height: '25vh',
                    backgroundImage: `linear-gradient(to bottom, rgba(15, 15, 15,0) 0%,rgba(15, 15, 15,1) 70%), url(${tournamentGame?.game?.arena?.pictures[activeStep]?.path})`,
                  }}
                ></CardMedia>
              </AutoPlaySwipeableViews>
              {tournamentGame?.game?.is_private && !ALL_ADMINS?.find((p: any) => p?.id === loggedPlayer?.id) ? (
                <Grid container sx={{ height: '60vh', justifyContent: 'center', alignItems: 'center', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', position: 'relative', mt: '-30px', bgcolor: 'rgba(15, 15, 15, 1)' }}>
                  <Typography sx={{ fontSize: '1.3em', textAlign: 'center', position: 'relative' }}>This is a private event</Typography>
                </Grid>
              ) : (
                <Grid item xs={12} md={12} lg={12} sx={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px', position: 'relative', mt: '-60px', bgcolor: 'rgba(15, 15, 15, 1)' }}>
                  <Grid container sx={{ p: '10px 10% 30px', textAlign: 'center' }}>
                    <Grid item xs={5.5} sm={5.5} md={5.5} lg={5.5} sx={{ 'display': { xs: 'inline-block', sm: 'inline-flex' }, 'textAlign': 'center', 'alignItems': 'center', 'justifyContent': 'center', '&:hover': { '& .MuiAvatar-root': { border: tournamentGame?.game?.home_team?.id ? 'solid 3px rgba(199, 240, 11, 1)' : '' } } }} onClick={tournamentGame?.game?.home_team?.id ? () => handleOpenTeamLineup(tournamentGame?.game?.home_team?.id) : undefined}>
                      <Grid sx={{ textAlign: 'center' }}>
                        <Typography
                          sx={{
                            lineHeight: '15px',
                            fontWeight: '800',
                            textTransform: 'uppercase',
                            height: '58px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'rgba(247, 251, 250, 1)',
                          }}
                        >
                          {tournamentGame?.game?.home_team?.name}
                        </Typography>
                        {tournamentGame?.game?.home_team?.team?.avatar?.path ? <Avatar src={tournamentGame?.game?.home_team?.team?.avatar?.path} alt={tournamentGame?.game?.home_team?.name} sx={{ m: 'auto', width: { xs: '60px', sm: '80px', md: '100px' }, height: { xs: '60px', sm: '80px', md: '100px' } }} /> : <FivezerWhiteIcon sx={{ m: 'auto', opacity: '.05', width: { xs: '40px', sm: '60px', md: '80px' }, height: { xs: '40px', sm: '60px', md: '80px' } }} />}
                      </Grid>
                      {tournamentGame?.game?.results?.status === 'CONFIRMED' ? <Typography sx={{ color: HOME_TEAM_SCORE > AWAY_TEAM_SCORE ? 'rgba(168, 202, 65, 1) !important' : 'rgba(247, 251, 250, 1) !important', fontWeight: 'bold', fontSize: { xs: '4em', sm: '5em' }, width: { xs: '100%', sm: 'auto' } }}>{HOME_TEAM_SCORE}</Typography> : ''}
                    </Grid>
                    <Grid className="separator" item xs={1} sm={1} md={1} lg={1} xl={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <VersusIcon sx={{ m: '0', color: 'rgba(238, 44, 32, 1) !important', fontSize: { xs: '50px', sm: '50px' } }} />
                    </Grid>
                    <Grid item xs={5.5} sm={5.5} md={5.5} lg={5.5} sx={{ 'display': { xs: 'inline-block', sm: 'inline-flex' }, 'textAlign': 'center', 'alignItems': 'center', 'justifyContent': 'center', '&:hover': { '& .MuiAvatar-root': { border: tournamentGame?.game?.away_team?.id ? 'solid 3px rgba(199, 240, 11, 1)' : '' } } }} onClick={tournamentGame?.game?.away_team?.id ? () => handleOpenTeamLineup(tournamentGame?.game?.away_team?.id) : undefined}>
                      {tournamentGame?.game?.results?.status === 'CONFIRMED' ? <>{isSmallMobile ? '' : <Typography sx={{ color: HOME_TEAM_SCORE < AWAY_TEAM_SCORE ? 'rgba(168, 202, 65, 1) !important' : 'rgba(247, 251, 250, 1) !important', fontWeight: 'bold', fontSize: { xs: '4em', sm: '5em' }, width: { xs: '100%', sm: 'auto' } }}>{AWAY_TEAM_SCORE}</Typography>}</> : ''}
                      <Grid sx={{ textAlign: 'center' }}>
                        <Typography
                          sx={{
                            lineHeight: '15px',
                            fontWeight: '800',
                            textTransform: 'uppercase',
                            height: '58px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'rgba(247, 251, 250, 1)',
                          }}
                        >
                          {tournamentGame?.game?.away_team?.name}
                        </Typography>
                        {tournamentGame?.game?.away_team?.team?.avatar?.path ? <Avatar src={tournamentGame?.game?.away_team?.team?.avatar?.path} alt={tournamentGame?.game?.away_team?.name} sx={{ m: 'auto', width: { xs: '60px', sm: '80px', md: '100px' }, height: { xs: '60px', sm: '80px', md: '100px' } }} /> : <FivezerWhiteIcon sx={{ m: 'auto', opacity: '.05', width: { xs: '40px', sm: '60px', md: '80px' }, height: { xs: '40px', sm: '60px', md: '80px' } }} />}
                      </Grid>

                      {tournamentGame?.game?.results?.status === 'CONFIRMED' ? <>{isSmallMobile ? <Typography sx={{ color: HOME_TEAM_SCORE < AWAY_TEAM_SCORE ? 'rgba(168, 202, 65, 1) !important' : '', fontWeight: 'bold', fontSize: { xs: '4em', sm: '5em' }, width: { xs: '100%', sm: 'auto' } }}>{AWAY_TEAM_SCORE}</Typography> : ''}</> : ''}
                    </Grid>
                    {tournamentGame?.game?.results?.status === 'CONFIRMED' ? (
                      <Grid container sx={{ justifyContent: 'center' }}>
                        <Button className="button-cancel" onClick={() => setOpenViewScore(true)} sx={{ fontWeight: '600 !important', fontSize: '.8em' }}>
                          Game results
                        </Button>
                        <ViewScoreModal challenge_short_id={tournamentGame?.game?.short_id} open={openViewScore} handleClose={() => setOpenViewScore(false)} />
                      </Grid>
                    ) : (
                      ''
                    )}
                    <ViewTeamLineupModal team_lineup_id={inputFields.selected_team_lineup} open={openTeamLineup} handleClose={handleCloseTeamLineup} />
                  </Grid>
                  <Grid container sx={{ minHeight: '40vh', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', bgcolor: 'rgba(24, 30, 36, 1)', pt: '50px' }}>
                    <Grid id="max-width" item xs={12} md={12} lg={12} sx={{ p: '0 5%' }}>
                      <Grid container className="address" sx={{ '& *': { color: 'rgba(247, 251, 250, 0.3) !important' } }}>
                        <Grid item xs={2} sm={2} md={2} lg={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <LocationIcon />
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10} component="a" href={`geo:${(tournamentGame?.game?.complex || tournamentGame?.game?.arena)?.location?.coordinates?.latitude},${(tournamentGame?.game?.complex || tournamentGame?.game?.arena)?.location?.coordinates?.longitude}`} target="_blank">
                          <Typography>{(tournamentGame?.game?.complex || tournamentGame?.game?.arena)?.location?.title}</Typography>

                          <Typography
                            sx={{
                              display: (tournamentGame?.game?.complex || tournamentGame?.game?.arena)?.location?.street_name === '' ? 'none' : 'inline-block',
                              width: '100%',
                            }}
                            className={'primary-address'}
                          >
                            {(tournamentGame?.game?.complex || tournamentGame?.game?.arena)?.location?.street_name}
                          </Typography>
                          <Typography
                            sx={{
                              display: (tournamentGame?.game?.complex || tournamentGame?.game?.arena)?.location?.secondary_street_name === '' ? 'none' : 'inline-block',
                              width: '100%',
                            }}
                            className={'secondary-address'}
                          >
                            {(tournamentGame?.game?.complex || tournamentGame?.game?.arena)?.location?.secondary_street_name}
                          </Typography>
                          <Typography
                            sx={{
                              display: 'inline-block',
                              width: '100%',
                            }}
                            className={'postcode'}
                          >
                            {(tournamentGame?.game?.complex || tournamentGame?.game?.arena)?.location?.postcode?.name} - {(tournamentGame?.game?.complex || tournamentGame?.game?.arena)?.location?.city?.name} {(tournamentGame?.game?.complex || tournamentGame?.game?.arena)?.location?.state?.name ? `- ${(tournamentGame?.game?.complex || tournamentGame?.game?.arena)?.location?.state?.name}` : ''}
                          </Typography>
                          <Typography
                            sx={{
                              display: 'inline-block',
                              width: '100%',
                            }}
                            className={'country'}
                          >
                            <Grid container sx={{ alignItems: 'center' }}>
                              {(tournamentGame?.game?.complex || tournamentGame?.game?.arena)?.location?.country?.name}&nbsp;
                              <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${(tournamentGame?.game?.complex || tournamentGame?.game?.arena)?.location?.country_code?.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${(tournamentGame?.game?.complex || tournamentGame?.game?.arena)?.location?.country_code?.toLowerCase()}.png 2x`} alt="" />
                            </Grid>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container className="dates" sx={{ '& *': { color: 'rgba(247, 251, 250, 0.3) !important' }, 'pt': '20px' }}>
                        <Grid item xs={2} sm={2} md={2} lg={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <CalendarMonth />
                        </Grid>
                        <Grid item xs={5} sm={5} md={5} lg={5}>
                          <Typography>
                            <Moment format="ddd D MMM YYYY">{tournamentGame?.game?.start_date}</Moment>
                          </Typography>
                        </Grid>
                        <Grid item xs={5} sm={5} md={5} lg={5} sx={{ display: 'flex', justifyContent: 'end' }}>
                          <Typography>
                            <Moment format="HH:mm">{tournamentGame?.game?.start_date}</Moment>
                          </Typography>
                          &nbsp;&rarr;&nbsp;
                          <Typography>
                            <Moment format="HH:mm">{tournamentGame?.game?.end_date}</Moment>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container className="field" sx={{ '& *': { color: 'rgba(247, 251, 250, 0.3) !important' }, 'pt': '20px' }}>
                        <Grid item xs={2} sm={2} md={2} lg={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          {FIELD_TYPES?.find((f: any) => f?.value === tournamentGame?.game?.arena?.type)?.icon}
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={10}>
                          <Typography>
                            {tournamentGame?.game?.arena?.name} ({FIELD_TYPES?.find((f: any) => f?.value === tournamentGame?.game?.arena?.type)?.label})
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container sx={{ minHeight: '15vh', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', bgcolor: 'rgba(33,35,41, .1)', mt: '30px', p: { xs: '30px 5% 100px', sm: '30px 5% 80px', md: '30px 5%' } }}>
                      <Grid id="max-width" item xs={12} md={12} lg={12} sx={{ textAlign: 'center' }}>
                        {tournamentGame?.game?.results?.status !== 'CONFIRMED' && tournamentGame?.tournament?.status === 'PLAYOFFS_STAGE_ON_GOING' && tournamentGame?.game?.start_date <= Date.now() && tournamentGame?.tournament?.admins?.find((c: any) => c?.id === loggedPlayer?.id) ? (
                          <>
                            <Button className={'button-orange'} onClick={() => setOpenManageScore(true)} size="large" sx={{ fontWeight: '600', m: '10px', width: { xs: '80%', sm: '40%', md: '30%' } }}>
                              <ScoreBoardIcon />
                              &nbsp;
                              {tournamentGame?.game?.results?.status === 'CONFIRMED' ? 'Score added' : 'Add final score'}
                            </Button>
                            <ManageScoreByAdminsModal challenge_short_id={tournamentGame?.game?.short_id} current_stage_game={tournamentGame} next_stage_game={props.next_game} open={openManageScore} handleClose={handleCloseManageScore} />
                          </>
                        ) : (
                          ''
                        )}

                        <ShareURLButton
                          button={
                            <Button className={'button-green-reverse'} size="large" sx={{ fontWeight: '600', m: '10px', width: { xs: '80%', sm: '40%', md: '30%' } }}>
                              <ShareIcon />
                              &nbsp;Share
                            </Button>
                          }
                          title={tournamentGame?.game?.title}
                          url={window.location.href}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </>
          )}

          <EditTournamentPlayoffGameModal open={openEditTournamentGame} handleClose={handleCloseEditTournamentGame} tournament_id={tournamentGame?.tournament?.id} tournament_stage_challenge_id={tournamentGame?.game?.id} current_game={props.current_game} />
          <ScheduleTournamentPlayoffGameModal open={openScheduleTournamentGame} handleClose={handleCloseEditTournamentGame} tournament_id={tournamentGame?.tournament?.id} tournament_stage_id={tournamentGame?.id} />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

ViewTournamentPlayoffGameModal.propTypes = {
  game_id: PropTypes.string,
  handleClose: PropTypes.any,
  open: PropTypes.any,
};

ViewTournamentPlayoffGameModal.defaultProps = {};

import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Tour from 'reactour';
import { IntlProvider, useIntl } from 'react-intl';
import SwipeableViews from 'react-swipeable-views';
import _ from 'lodash';

import { useQuery, gql } from '@apollo/client';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { storePersonalWallets, selectPersonalWallets } from '../../reducers/walletSlice';
import { Snackbar, Alert, AppBar, Tab, Tabs, Menu, MenuItem, ListItemIcon, Button, Divider, ListItemText, ListItem, List, Fab, IconButton, Grid, Typography } from '@mui/material';
import { ArrowBack, Help, Add, MoreVert } from '@mui/icons-material';
import { WalletIcon, LipayIcon, ExchangeIcon, FivezerLogoIcon } from '../../img/icons/Icons';

import SpendingCodeUpdateModal from '../../component-modals/purchase/SpendingCodeUpdateModal';
import DEXModal from '../../component-modals/features/DEXModal';
import { Edit } from '@mui/icons-material';
import LoadingComponent from '../../component-modals/common/LoadingComponent';
import MenuButton from '../../component-modals/layout/MenuButton';
import { selectSession } from '../../reducers/sessionSlice';

const steps = [
  {
    selector: '.step1',
    content: 'This is my first Step',
  },
  {
    selector: '.step2',
    content: 'This is my second Step',
  },
];

const Wallets: React.FC = () => {
  try {
    const FIND_ACCOUNT_PERSONAL_WALLETS_QUERY = gql`
      query findAccountPersonalWallets($account: inputAccountWallets!) {
        findAccountPersonalWallets(account: $account) {
          id
          title
          status
          is_default
          type
          owner {
            ... on Company {
              __typename
              id
              name
              logo {
                path
                filename
              }
            }
            ... on User {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on Player {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on ComplexOwner {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }

            ... on Transporter {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on Passenger {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on Patient {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
            ... on HealthWorker {
              __typename
              id
              username
              avatar {
                path
                filename
              }
            }
          }
          bank_accounts {
            id
          }
          blockchain_accounts {
            id
          }
          cards {
            ... on BankCard {
              __typename
              id
            }
            ... on BlockchainCard {
              __typename
              id
            }
          }
          payment_methods {
            id
            name
            status
            payment_source {
              ... on BankCard {
                __typename
                id
                network
              }
              ... on BlockchainCard {
                __typename
                id
                network
              }
              ... on BlockchainAccount {
                __typename
                id
                title
              }
              ... on BankAccount {
                __typename
                id
                title
              }
            }
          }
          sent_transactions {
            id
          }
        }
      }
    `;

    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const session = useAppSelector(selectSession);
    const loggedPlayer = useAppSelector(selectPlayer);
    const loggedAccountPersonalWallets = useAppSelector(selectPersonalWallets);

    const [isTourOpen, setIsTourOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [value, setValue] = React.useState(0);
    const [changeSpendingPassword, setChangeSpendingPassword] = React.useState(false);
    const [hasUpdated, setHasUpdated] = React.useState(false);
    const [openDex, setOpenDex] = React.useState(false);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    const handleChangeIndex = (index: number) => {
      setValue(index);
    };

    const loadedAccountPersonalWallets = useQuery(FIND_ACCOUNT_PERSONAL_WALLETS_QUERY, {
      context: {
        headers: {
          'Authorization': `Bearer ${session?.token?.key}`,
          'X-Anonymous-Access': 'false',
        },
      },
      skip: !session?.token?.key,
      variables: {
        account: { id: loggedPlayer?.id, username: loggedPlayer?.username },
      },
      pollInterval: loggedPlayer?.id ? 3000 : undefined,
    });

    React.useEffect(() => {
      if ((loadedAccountPersonalWallets.called && loadedAccountPersonalWallets.data?.findAccountPersonalWallets) || !_.isMatch(loggedAccountPersonalWallets, loadedAccountPersonalWallets.data?.findAccountPersonalWallets)) {
        try {
          dispatch(storePersonalWallets(loadedAccountPersonalWallets.data?.findAccountPersonalWallets));
        } catch (e) {
          console.log(e);
        }
      }
    }, [loadedAccountPersonalWallets, dispatch, loggedAccountPersonalWallets]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleTour = () => {
      setIsTourOpen(true);
      handleClose();
    };

    const handleUpdateSpendingPassword = () => {
      setChangeSpendingPassword(true);
      handleClose();
    };

    const handleOpenDEX = () => {
      setOpenDex(true);
      handleClose();
    };

    return (
      <Grid container className={`component-preferences settings-page wallets-page  ${loggedPlayer?.app_configuration?.is_dark_mode ? 'dark-mode' : 'light-mode'}`} sx={{ pb: '100px' }}>
        <Grid item xl={12} xs={12} sm={12}>
          <Grid container>
            <Grid item xs={2} md={2} lg={2} sx={{ textAlign: 'left' }}></Grid>
            <Grid item xs={8} md={8} lg={8} sx={{ textAlign: 'center' }}>
              <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' }, 'pt': '20px' }}>
                <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '40px', sm: '50px', md: '60px' }, p: '0 0' }} />
              </IconButton>
            </Grid>
            <Grid item xs={2} md={2} lg={2} sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'end', p: '0 43px' }}>
              <MenuButton />
            </Grid>
          </Grid>

          <Snackbar
            open={hasUpdated}
            autoHideDuration={3000}
            onClose={() => setHasUpdated(false)}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Alert onClose={() => setHasUpdated(false)} severity="success" sx={{ width: '100%' }}>
              {`${intl.formatMessage({ id: 'Wallets_SC_Updated' })}`}
            </Alert>
          </Snackbar>

          <Grid container className={'actions'} sx={{ alignItems: 'center' }}>
            <Grid item xl={6} xs={6} sm={6} className={'back-button'} sx={{ textAlign: 'left' }}>
              <IconButton onClick={() => navigate('/profile')} className={'button-back'}>
                <ArrowBack />
              </IconButton>
            </Grid>
            <Grid item xl={6} xs={6} sm={6} className={'menu-icon'} sx={{ textAlign: 'right', p: '0 20px' }}>
              <Tour steps={steps} isOpen={isTourOpen} onRequestClose={() => setIsTourOpen(false)} />
              <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <MoreVert />
              </IconButton>
              <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={handleTour}>
                  <ListItemIcon>
                    <Help />
                  </ListItemIcon>
                  {`${intl.formatMessage({ id: 'Wallets_How_To' })}`}
                </MenuItem>
                <MenuItem onClick={handleUpdateSpendingPassword}>
                  <ListItemIcon>
                    <Edit />
                  </ListItemIcon>
                  {`${intl.formatMessage({ id: 'Wallets_Edit_SC' })}`}
                </MenuItem>
                <MenuItem onClick={handleClose} component={Link} to={{ pathname: '/new_wallet' }}>
                  <ListItemIcon>
                    <WalletIcon />
                  </ListItemIcon>
                  {`${intl.formatMessage({ id: 'Wallets_New_Wallet' })}`}
                </MenuItem>
                <MenuItem onClick={handleOpenDEX} sx={{ fontStyle: 'italic' }}>
                  <ListItemIcon>
                    <ExchangeIcon />
                  </ListItemIcon>
                  <LipayIcon sx={{ fontSize: '2.5em' }} />
                  &nbsp;{`${intl.formatMessage({ id: 'Wallets_Exchanges' })}`}
                </MenuItem>
              </Menu>
              <DEXModal open={openDex} handleClose={() => setOpenDex(false)} />
            </Grid>
          </Grid>

          {loggedAccountPersonalWallets?.loading || !loadedAccountPersonalWallets.data?.findAccountPersonalWallets ? (
            <LoadingComponent amount={1} text={<Typography sx={{ textAlign: 'center' }}>{`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}</Typography>} />
          ) : (
            <IntlProvider locale="en" defaultLocale="en">
              <Grid container className={'title'} sx={{ pb: '20px' }}>
                <Grid item xl={12} xs={12} sm={12} sx={{ p: '0 5%' }}>
                  <Typography sx={{ fontSize: '2em', fontWeight: '800' }}>
                    {loggedAccountPersonalWallets?.length} {`${loggedAccountPersonalWallets?.length > 0 ? `${intl.formatMessage({ id: 'Wallets_Wallets_Title' })}` : `${intl.formatMessage({ id: 'Wallets_Wallet_Title' })}`}`}
                  </Typography>
                  <Typography sx={{ fontSize: '1em', fontWeight: '300' }}>
                    {`${intl.formatMessage({ id: 'Wallets_Own_By' })}`}&nbsp;
                    <Typography variant="caption" sx={{ fontSize: '1em', fontWeight: '800' }}>
                      {loggedPlayer?.owner.firstname} {loggedPlayer?.owner.lastname}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>

              <Grid container className={'wallets'}>
                <Grid item xl={12} xs={12} sm={12} className={`component-transaction-confirmation-modal ${loggedPlayer?.app_configuration?.is_dark_mode ? 'dark-mode-tabs-component' : 'light-mode-tabs-component'}`}>
                  <AppBar
                    position="sticky"
                    sx={{
                      bgcolor: 'transparent',
                      boxShadow: 'none',
                    }}
                  >
                    <Tabs value={value} onChange={handleChange} variant="fullWidth">
                      <Tab label={`${loggedAccountPersonalWallets?.length} ${intl.formatMessage({ id: 'Wallets_Tab_Personal' })}`} {...a11yProps(0)} />
                    </Tabs>
                  </AppBar>
                  <SwipeableViews axis={'x'} index={value} onChangeIndex={handleChangeIndex}>
                    <TabPanel value={value} index={0} dir={'ltr'}>
                      <Grid container id="max-width">
                        <List sx={{ width: '100%' }}>
                          {loggedAccountPersonalWallets?.map((wallet: any) => (
                            <Grid key={wallet.id}>
                              <ListItem alignItems="flex-start">
                                <ListItemText
                                  primary={
                                    <Typography
                                      sx={{
                                        fontSize: '.7em',
                                        textTransform: 'uppercase',
                                        color: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1) !important' : 'rgba(15,15,15,1)',
                                      }}
                                    >
                                      {`${intl.formatMessage({ id: 'Global_Status' })}`}: <strong className={wallet?.status}>{wallet?.status.replaceAll('_', ' ')}</strong> {wallet?.is_default ? '(default)' : ''}
                                    </Typography>
                                  }
                                  secondaryTypographyProps={{
                                    variant: 'body1',
                                  }}
                                  secondary={
                                    <Button
                                      component={Link}
                                      to={{
                                        pathname: `/w/wallet`,
                                      }}
                                      state={{
                                        wallet: {
                                          id: wallet.id,
                                          type: wallet.type,
                                        },
                                      }}
                                      sx={{
                                        width: '100%',
                                        justifyContent: 'left',
                                        color: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1) !important' : 'rgba(15,15,15,1)',
                                      }}
                                    >
                                      <Typography
                                        className={'wallet-title'}
                                        sx={{
                                          width: '100%',
                                          float: 'none',
                                        }}
                                        component="span"
                                      >
                                        {wallet.title}
                                      </Typography>
                                      <Typography variant={'caption'}>
                                        {`${intl.formatMessage({ id: 'Wallets_NB_Payment_Method' })}`}:&nbsp;
                                        <strong>{wallet?.payment_methods?.length} </strong>
                                      </Typography>
                                    </Button>
                                  }
                                />
                              </ListItem>
                              <Divider variant="middle" component="li" sx={{ bgcolor: 'rgba(247, 251, 250, .3)' }} />
                            </Grid>
                          ))}
                        </List>
                      </Grid>
                    </TabPanel>
                  </SwipeableViews>
                </Grid>

                <Grid item xl={12} xs={12} sm={12} className={'create-wallet'} sx={{ textAlign: 'center', mt: '30px' }}>
                  <Fab
                    variant="extended"
                    component={Link}
                    to={'/new_wallet'}
                    state={{
                      wallet_type: value === 1 ? 'BUSINESS' : 'PERSONAL',
                    }}
                    className={'button-green-reverse'}
                    sx={{ boxShadow: 'none' }}
                  >
                    <Add />
                    &nbsp;{`${intl.formatMessage({ id: 'Wallets_New_Wallet' })}`}
                  </Fab>
                </Grid>
              </Grid>

              <SpendingCodeUpdateModal icon={<WalletIcon />} actionOne={setHasUpdated} actionTwo={() => setChangeSpendingPassword(false)} open={changeSpendingPassword} isConfirmed={hasUpdated} />
            </IntlProvider>
          )}
        </Grid>
      </Grid>
    );
  } catch (e) {
    console.log(e);
    return null;
  }
};

export default Wallets;

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Grid role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
      {value === index && (
        <Grid>
          <Grid>{children}</Grid>
        </Grid>
      )}
    </Grid>
  );
}

function a11yProps(index: number) {
  return {
    'id': `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

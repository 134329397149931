import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Typography, Slide, Grid, AppBar, Toolbar, Stack, Fab } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import LoginIcon from '@mui/icons-material/Login';
import useScrollTrigger from '@mui/material/useScrollTrigger';

import { useAppSelector } from '../../redux/hooks';
import { selectSession } from '../../reducers/sessionSlice';

import { useScrollPosition } from '../../utilities/utilities';
import MakePaymentModal from '../purchase/MakePaymentModal';
import MenuButton from './MenuButton';
import { FormattedMessage } from 'react-intl';
import TopBarActionsButton from './TopBarActionsButton';

export default function TopBar(props: any) {
  const trigger = useScrollTrigger();
  const location: any = useLocation();
  const session = useAppSelector(selectSession);

  const isMobile = useMediaQuery('(max-width:767px)');
  const isTablet = useMediaQuery('(min-width:768px) and (max-width:1023px)');

  const positionY = useScrollPosition();
  const [openNewTx, setOpenNewTx] = React.useState(false);

  return (
    <Grid sx={{ width: '100%', position: 'relative', zIndex: '100000000' }}>
      <Grid sx={{ position: 'absolute', textAlign: 'center', width: '100%', opacity: '.2' }}>
        <Typography variant="caption">
          {/* {isMobile ? `Mobile mode` : ''}
          {isTablet ? `Tablet mode` : ''}
          {isDesktop ? `Desktop mode` : ''}
          {isTV ? `TV mode: ${isTV}` : ''} */}
        </Typography>
      </Grid>

      <MakePaymentModal open={openNewTx} handleClose={() => setOpenNewTx(false)} />

      <Slide appear={false} direction="down" in={!trigger}>
        <AppBar
          position={props.position}
          sx={{
            background: positionY > 300 ? 'linear-gradient(to top, transparent 0%, rgba(247, 251, 250, .1) 75%)' : props.backgroundColor,
            p: '5px 10px',
            backdropFilter: positionY > 300 ? 'blur(5px)' : 'blur(0)',
            transition: '400ms ease-in-out',
            zIndex: '10',
          }}
          elevation={0}
        >
          {!isMobile && !isTablet ? (
            <Toolbar>
              <Grid item xs={12} md={12} lg={12} className="topbar">
                <Grid sx={{ width: '30%', display: 'inline-block' }}>
                  {session?.status !== 'ALIVE' ? (
                    ''
                  ) : (
                    <Stack direction="row" spacing={{ xs: 1, sm: 1, md: 2 }}>
                      {/* <IconButton edge="start" onClick={handleScanQRCode}>
                        <Badge badgeContent={ALL_TRANSACTIONS?.length} color="error">
                          <ScanQRCodeIcon sx={{ 'color': `${props?.color} !important`, '&:hover': { color: `${props?.colorHover} !important` } }} />
                        </Badge>
                      </IconButton> */}
                    </Stack>
                  )}
                </Grid>

                <Grid
                  sx={{
                    width: '40%',
                    display: 'inline-block',
                    textAlign: 'center',
                  }}
                >
                  <Grid component={Link} to="/">
                    {props.logo}
                  </Grid>
                </Grid>

                <Grid
                  sx={{
                    width: '30%',
                    display: 'inline-block',
                    textAlign: 'right',
                  }}
                >
                  {session?.status !== 'ALIVE' ? (
                    <Stack direction="row" spacing={{ xs: 1, sm: 1, md: 2 }} sx={{ display: 'inline-block' }}>
                      {location.pathname !== '/login' && location.pathname !== '/signup' ? (
                        <Fab
                          variant="extended"
                          className="cta-style-2"
                          sx={{
                            'fontWeight': '700',
                            'background': 'transparent',
                            'boxShadow': 'none',
                            ':hover': {
                              background: 'transparent',
                              color: `${props?.colorHover} !important`,
                            },
                          }}
                          component={Link}
                          to={'/login'}
                        >
                          <FormattedMessage id="TopBar_Login" />
                          &nbsp;
                          <LoginIcon className="cta-style-2" />
                        </Fab>
                      ) : (
                        ''
                      )}
                      <MenuButton />
                    </Stack>
                  ) : (
                    <Stack direction="row-reverse" sx={{ alignItems: 'flex-start' }}>
                      <MenuButton />
                      <TopBarActionsButton />
                    </Stack>
                  )}
                </Grid>
              </Grid>
            </Toolbar>
          ) : (
            <Toolbar>
              <Grid item xs={12} md={12} lg={12} className="topbar">
                <Grid sx={{ width: '30%', display: 'inline-block' }}>
                  {session?.status !== 'ALIVE' ? (
                    ''
                  ) : (
                    <Stack direction="row" spacing={{ xs: 1, sm: 1, md: 2 }}>
                      {/* <IconButton edge="start" onClick={handleScanQRCode}>
                        <Badge badgeContent={ALL_TRANSACTIONS?.length} color="error">
                          <ScanQRCodeIcon sx={{ 'color': `${props?.color} !important`, '&:hover': { color: `${props?.colorHover} !important` } }} />
                        </Badge>
                      </IconButton> */}
                    </Stack>
                  )}
                </Grid>

                <Grid
                  sx={{
                    width: '40%',
                    display: 'inline-block',
                    textAlign: 'center',
                  }}
                >
                  <Grid component={Link} to="/">
                    {props.logo}
                  </Grid>
                </Grid>

                <Grid
                  sx={{
                    width: '30%',
                    display: 'inline-flex',
                    textAlign: 'right',
                    justifyContent: 'end',
                  }}
                >
                  {session?.status !== 'ALIVE' ? (
                    <Grid>
                      {location.pathname !== '/login' && location.pathname !== '/signup' ? (
                        <Fab
                          className="cta-style-2"
                          variant="extended"
                          sx={{
                            'fontWeight': '700',
                            'background': 'transparent',
                            'boxShadow': 'none',
                            '&:hover': {
                              background: 'transparent',
                              color: '#1FAF92',
                            },
                            'whiteSpace': 'nowrap',
                          }}
                          component={Link}
                          to={'/login'}
                        >
                          <FormattedMessage id="TopBar_Login" />
                          &nbsp;
                          <LoginIcon className="cta-style-2" />
                        </Fab>
                      ) : (
                        ''
                      )}
                    </Grid>
                  ) : (
                    <TopBarActionsButton />
                  )}
                </Grid>
              </Grid>
            </Toolbar>
          )}
        </AppBar>
      </Slide>
    </Grid>
  );
}

TopBar.propTypes = {
  position: PropTypes.string,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  colorHover: PropTypes.string,
  logo: PropTypes.node,
};

TopBar.defaultProps = {
  color: '#FFF',
  colorHover: 'rgba(251, 88, 2, 1)',
};

import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Divider, Fab, Grid, Avatar, TextField, Stack, Chip, CircularProgress, useMediaQuery, Autocomplete } from '@mui/material';
import { Close, Send } from '@mui/icons-material';
import { ARRAY_AS_ANY } from '../../utilities/CommonInterfaces';
import { gql, useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import { LoadingButton } from '@mui/lab';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { Controller, useForm } from 'react-hook-form';
import LoadingComponent from '../common/LoadingComponent';
import { selectSession } from '../../reducers/sessionSlice';
import { useIntl } from 'react-intl';

export default function AddTeamLineupModal(props: { team_id: string; team_sport: string; team_type: string; handleClose: any; open: any; goToLineups?: any }) {
  const EDIT_TEAM_LINEUP_MUTATION = gql`
    mutation createTeamLineup($team_lineup: inputCreateTeamLineup!) {
      createTeamLineup(team_lineup: $team_lineup) {
        id
      }
    }
  `;

  const FIND_SPORT_PLAYERS_BY_TEAM_ID_QUERY = gql`
    query findSportPlayersByTeamID($player: inputFindSportPlayersByTeamID!) {
      findSportPlayersByTeamID(player: $player) {
        ... on Footballer {
          id
          alias
          avatar {
            path
          }
          player {
            id
            username
          }
          player_positions {
            id
            team_number
            team_player {
              ... on FootballTeam {
                __typename
                id
                name
                type
              }
            }
            team_captain {
              ... on FootballTeam {
                __typename
                id
                name
                type
              }
            }
          }
        }
        ... on Basketballer {
          id
          alias
          avatar {
            path
          }
          player {
            id
            username
          }
          player_positions {
            id
            team_number
            team_player {
              ... on FootballTeam {
                __typename
                id
                name
                type
              }
            }
            team_captain {
              ... on FootballTeam {
                __typename
                id
                name
                type
              }
            }
          }
        }
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const intl = useIntl();
  const [openSearchSportPlayer, setOpenSearchSportPlayer] = React.useState(false);
  const [openSearchSportCaptain, setOpenSearchSportCaptain] = React.useState(false);

  const {
    control,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',

      all_sport_players: ARRAY_AS_ANY,
      team_lineup_players: ARRAY_AS_ANY,
      player_alias: '',

      all_sport_captains: ARRAY_AS_ANY,
      team_lineup_captains: ARRAY_AS_ANY,
      captain_alias: '',
    },
  });
  const inputFields = watch();
  const isSmallMobile = useMediaQuery('(max-width:600px)');

  const [createTeamLineup, newTeamLineup] = useMutation(EDIT_TEAM_LINEUP_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedSportPlayers = useQuery(FIND_SPORT_PLAYERS_BY_TEAM_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key || !inputFields.player_alias || inputFields.player_alias === '',
    variables: {
      player: {
        alias: inputFields.player_alias || '',
        team_sport: props.team_sport,
        team_id: props.team_id,
        team_type: props.team_type,
      },
    },
  });

  const ALL_SPORT_PLAYERS = loadedSportPlayers.data?.findSportPlayersByTeamID;
  const ALL_SPORT_CAPTAINS = inputFields.team_lineup_players;

  const handleClose = () => {
    props.handleClose();
    setValue('name', '');
    setValue('team_lineup_players', ARRAY_AS_ANY);
    setValue('team_lineup_captains', ARRAY_AS_ANY);
    reset();
  };

  const handleTeamLineup = (value: any) => {
    const newList = _.concat(inputFields.team_lineup_players, value);
    setValue('team_lineup_players', newList);
    setOpenSearchSportPlayer(false);
  };

  const handleTeamLineupCaptain = (value: any) => {
    const newList = _.concat(inputFields.team_lineup_captains, value);
    setValue('team_lineup_captains', newList);
    setOpenSearchSportCaptain(false);
  };

  const handleRemoveSportPlayer = (player_id: string) => {
    const newList = inputFields.team_lineup_players?.filter((p: any) => p?.id !== player_id);
    setValue('team_lineup_players', newList);

    handleRemoveSportCaptain(player_id);
  };

  const handleRemoveSportCaptain = (player_id: string) => {
    const newList = inputFields.team_lineup_captains?.filter((p: any) => p?.id !== player_id);
    setValue('team_lineup_captains', newList);
  };

  const handleCreateTeamLineup = async () => {
    try {
      await createTeamLineup({
        variables: {
          team_lineup: {
            logged_player_id: loggedPlayer?.id,
            team_id: props.team_id,
            name: inputFields.name,
            players_ids: inputFields.team_lineup_players
              ?.map((p: any) => p?.player_positions)
              ?.flat()
              ?.filter((p: any) => p?.team_player?.id === props.team_id)
              ?.map((p: any) => p?.id),
            captains_ids: inputFields.team_lineup_captains
              ?.map((p: any) => p?.player_positions)
              ?.flat()
              ?.filter((p: any) => p?.team_player?.id === props.team_id)
              ?.map((p: any) => p?.id),
          },
        },
      });

      handleClose();
      props.goToLineups();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={newTeamLineup.loading ? undefined : handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: { minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' }, p: '20px 10px' },
      }}
    >
      <Fab
        size="small"
        onClick={handleClose}
        disabled={newTeamLineup.loading}
        className={newTeamLineup.loading ? 'button-disabled' : 'button-cancel'}
        sx={{
          boxShadow: 'none',
          bgcolor: 'transparent',
          ml: '2%',
        }}
      >
        <Close />
      </Fab>
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>{`${intl.formatMessage({ id: 'AddTeamLineupModal_Title' })}`}</Typography>
      </DialogTitle>
      <Divider variant="middle" sx={{ width: '80%', m: 'auto' }} />
      {!props.team_id ? (
        <LoadingComponent amount={1} text={<Typography sx={{ mb: '20px', color: 'rgba(251, 88, 2, .3) !important' }}>Loading team lineup, please wait...</Typography>} loader={<CircularProgress className="orange-loader" />} />
      ) : (
        <>
          <DialogContent sx={{ pl: '0', pr: '0' }}>
            <DialogContentText id="alert-dialog-description" component="span">
              <Grid container sx={{ p: '0 5%' }}>
                <>
                  <Controller
                    name="name"
                    control={control}
                    rules={{
                      required: true,
                      maxLength: 30,
                      minLength: 4,
                    }}
                    render={({ field }: any) => <TextField {...field} label={`${intl.formatMessage({ id: 'AddTeamLineupModal_Name_Label' })}`} variant="outlined" fullWidth required className={'field-bottom-space'} value={field.value} error={errors?.name?.type !== undefined} helperText={errors?.name !== undefined ? `${errors?.name?.type ? (field.value.length < 4 ? `${intl.formatMessage({ id: 'FormNewTeam_Name_1' })}` : `${intl.formatMessage({ id: 'FormNewTeam_Name_2' })}`) : ``}` : ''} />}
                  />

                  {isSmallMobile ? (
                    <Stack
                      direction="row"
                      sx={{
                        justifyContent: 'center',
                        mb: '20px',
                      }}
                    >
                      <Grid>
                        {inputFields.team_lineup_players?.length === 0 ? (
                          <Typography
                            variant="caption"
                            sx={{
                              m: '0px',
                              p: '5px 20px',
                              textAlign: 'center',
                              bgcolor: 'rgba(0, 0, 0, .1)',
                              borderRadius: '5px',
                            }}
                          >
                            {`${intl.formatMessage({ id: 'AddTeamLineupModal_No_Teammate_Selected' })}`}
                          </Typography>
                        ) : (
                          inputFields.team_lineup_players?.map((p: any) => (
                            <Chip
                              key={p?.id}
                              variant="outlined"
                              label={<strong>{p?.alias}</strong>}
                              avatar={
                                <Avatar
                                  sx={{
                                    height: {
                                      xs: '50px !important',
                                      md: '60px !important',
                                      lg: '70px !important',
                                    },
                                    width: {
                                      xs: '50px !important',
                                      md: '60px !important',
                                      lg: '70px !important',
                                    },
                                    m: '5px 0',
                                  }}
                                  alt={p?.alias}
                                  src={p?.avatar?.path}
                                />
                              }
                              onDelete={() => handleRemoveSportPlayer(p?.id)}
                              sx={{ 'm': '5px', 'height': 'auto', 'border': 'none', 'color': 'rgba(247, 251, 250, 1)', 'bgcolor': 'rgba(199, 240, 11, 0.3) !important', '& svg': { color: 'rgba(247, 251, 250, 0.3) !important' } }}
                            />
                          ))
                        )}
                      </Grid>
                    </Stack>
                  ) : (
                    ''
                  )}

                  <Controller
                    name="all_sport_players"
                    control={control}
                    render={({ field }: any) => (
                      <Autocomplete
                        {...field}
                        open={openSearchSportPlayer}
                        onOpen={() => setOpenSearchSportPlayer(true)}
                        onClose={() => setOpenSearchSportPlayer(false)}
                        multiple
                        autoHighlight
                        filterSelectedOptions
                        fullWidth
                        value={field.value}
                        loading={loadedSportPlayers.loading}
                        inputValue={inputFields.player_alias}
                        onInputChange={(event: any, newInputValue: any) => {
                          setValue('player_alias', newInputValue);
                        }}
                        options={(ALL_SPORT_PLAYERS ? ALL_SPORT_PLAYERS : [])?.filter((cf: any) => inputFields.team_lineup_players?.find((p: any) => p?.id === cf?.id) === undefined)}
                        getOptionLabel={(option: any) => option?.id}
                        defaultValue={ARRAY_AS_ANY}
                        renderOption={(props: any, option: any) => (
                          <Grid
                            container
                            key={option?.id}
                            onClick={() => handleTeamLineup(option)}
                            sx={{
                              alignItems: 'center',
                              p: '10px 15px',
                            }}
                          >
                            <Avatar src={option?.avatar?.path} />
                            &nbsp;&nbsp;
                            <strong>{option?.alias}</strong>
                          </Grid>
                        )}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            fullWidth
                            label={`${intl.formatMessage({ id: 'AddTeamLineupModal_Players_Label' })}`}
                            placeholder={`${intl.formatMessage({ id: 'AddTeamLineupModal_Players_Placeholder' })}`}
                            className={'field-bottom-space'}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <>
                                  {isSmallMobile ? (
                                    ''
                                  ) : (
                                    <Grid>
                                      {inputFields.team_lineup_players?.length === 0 ? (
                                        <Typography
                                          variant="caption"
                                          sx={{
                                            m: '0px',
                                            p: '5px 20px',
                                            textAlign: 'center',
                                            bgcolor: 'rgba(0, 0, 0, .1)',
                                            borderRadius: '5px',
                                          }}
                                        >
                                          {`${intl.formatMessage({ id: 'AddTeamLineupModal_No_Teammate_Selected' })}`}
                                        </Typography>
                                      ) : (
                                        inputFields.team_lineup_players?.map((p: any) => (
                                          <Chip
                                            key={p?.id}
                                            variant="outlined"
                                            label={<strong>{p?.alias}</strong>}
                                            avatar={
                                              <Avatar
                                                sx={{
                                                  height: {
                                                    xs: '50px !important',
                                                    md: '60px !important',
                                                    lg: '70px !important',
                                                  },
                                                  width: {
                                                    xs: '50px !important',
                                                    md: '60px !important',
                                                    lg: '70px !important',
                                                  },
                                                  m: '5px 0',
                                                }}
                                                alt={p?.alias}
                                                src={p?.avatar?.path}
                                              />
                                            }
                                            onDelete={() => handleRemoveSportPlayer(p?.id)}
                                            sx={{ 'm': '5px', 'height': 'auto', 'border': 'none', 'color': 'rgba(247, 251, 250, 1)', 'bgcolor': 'rgba(199, 240, 11, 0.3) !important', '& svg': { color: 'rgba(247, 251, 250, 0.3) !important' } }}
                                          />
                                        ))
                                      )}
                                    </Grid>
                                  )}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    )}
                  />

                  {isSmallMobile ? (
                    <Stack
                      direction="row"
                      sx={{
                        justifyContent: 'center',
                        mb: '20px',
                      }}
                    >
                      <Grid>
                        {inputFields.team_lineup_captains?.length === 0 ? (
                          <Typography
                            variant="caption"
                            sx={{
                              m: '0px',
                              p: '5px 20px',
                              textAlign: 'center',
                              bgcolor: 'rgba(0, 0, 0, .1)',
                              borderRadius: '5px',
                            }}
                          >
                            {`${intl.formatMessage({ id: 'AddTeamLineupModal_No_Teammate_Selected' })}`}
                          </Typography>
                        ) : (
                          inputFields.team_lineup_captains?.map((p: any) => (
                            <Chip
                              key={p?.id}
                              variant="outlined"
                              label={<strong>{p?.alias}</strong>}
                              avatar={
                                <Avatar
                                  sx={{
                                    height: {
                                      xs: '50px !important',
                                      md: '60px !important',
                                      lg: '70px !important',
                                    },
                                    width: {
                                      xs: '50px !important',
                                      md: '60px !important',
                                      lg: '70px !important',
                                    },
                                    m: '5px 0',
                                  }}
                                  alt={p?.alias}
                                  src={p?.avatar?.path}
                                />
                              }
                              onDelete={() => handleRemoveSportCaptain(p?.id)}
                              sx={{ 'm': '5px', 'height': 'auto', 'border': 'none', 'color': 'rgba(247, 251, 250, 1)', 'bgcolor': 'rgba(199, 240, 11, 0.3) !important', '& svg': { color: 'rgba(247, 251, 250, 0.3) !important' } }}
                            />
                          ))
                        )}
                      </Grid>
                    </Stack>
                  ) : (
                    ''
                  )}

                  <Controller
                    name="all_sport_captains"
                    control={control}
                    render={({ field }: any) => (
                      <Autocomplete
                        {...field}
                        open={openSearchSportCaptain}
                        onOpen={() => setOpenSearchSportCaptain(true)}
                        onClose={() => setOpenSearchSportCaptain(false)}
                        multiple
                        autoHighlight
                        filterSelectedOptions
                        fullWidth
                        value={field.value}
                        loading={loadedSportPlayers.loading}
                        inputValue={inputFields.player_alias}
                        onInputChange={(event: any, newInputValue: any) => {
                          setValue('captain_alias', newInputValue);
                        }}
                        options={(ALL_SPORT_CAPTAINS ? ALL_SPORT_CAPTAINS : [])?.filter((cf: any) => inputFields.team_lineup_captains?.find((p: any) => p?.id === cf?.id) === undefined)}
                        getOptionLabel={(option: any) => option?.id}
                        defaultValue={ARRAY_AS_ANY}
                        renderOption={(props: any, option: any) => (
                          <Grid
                            container
                            key={option?.id}
                            onClick={() => handleTeamLineupCaptain(option)}
                            sx={{
                              alignItems: 'center',
                              p: '10px 15px',
                            }}
                          >
                            <Avatar src={option?.avatar?.path} />
                            &nbsp;&nbsp;
                            <strong>{option?.alias}</strong>
                          </Grid>
                        )}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            fullWidth
                            label={`${intl.formatMessage({ id: 'AddTeamLineupModal_Captains_Label' })}`}
                            placeholder={`${intl.formatMessage({ id: 'AddTeamLineupModal_Captains_Placeholder' })}`}
                            className={'field-bottom-space'}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <>
                                  {isSmallMobile ? (
                                    ''
                                  ) : (
                                    <Grid>
                                      {inputFields.team_lineup_captains?.length === 0 ? (
                                        <Typography
                                          variant="caption"
                                          sx={{
                                            m: '0px',
                                            p: '5px 20px',
                                            textAlign: 'center',
                                            bgcolor: 'rgba(0, 0, 0, .1)',
                                            borderRadius: '5px',
                                          }}
                                        >
                                          {`${intl.formatMessage({ id: 'AddTeamLineupModal_No_Teammate_Selected' })}`}
                                        </Typography>
                                      ) : (
                                        inputFields.team_lineup_captains?.map((p: any) => (
                                          <Chip
                                            key={p?.id}
                                            variant="outlined"
                                            label={<strong>{p?.alias}</strong>}
                                            avatar={
                                              <Avatar
                                                sx={{
                                                  height: {
                                                    xs: '50px !important',
                                                    md: '60px !important',
                                                    lg: '70px !important',
                                                  },
                                                  width: {
                                                    xs: '50px !important',
                                                    md: '60px !important',
                                                    lg: '70px !important',
                                                  },
                                                  m: '5px 0',
                                                }}
                                                alt={p?.alias}
                                                src={p?.avatar?.path}
                                              />
                                            }
                                            onDelete={() => handleRemoveSportCaptain(p?.id)}
                                            sx={{ 'm': '5px', 'height': 'auto', 'border': 'none', 'color': 'rgba(247, 251, 250, 1)', 'bgcolor': 'rgba(199, 240, 11, 0.3) !important', '& svg': { color: 'rgba(247, 251, 250, 0.3) !important' } }}
                                          />
                                        ))
                                      )}
                                    </Grid>
                                  )}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </>
              </Grid>
            </DialogContentText>
            <DialogActions sx={{ p: '20px 5% 0' }}>
              <LoadingButton loading={newTeamLineup.loading} loadingPosition="end" endIcon={<Send />} disabled={inputFields.name === '' || inputFields.team_lineup_captains?.length === 0 || inputFields.team_lineup_players?.length === 0} className={inputFields.name === '' || inputFields.team_lineup_captains?.length === 0 || inputFields.team_lineup_players?.length === 0 ? 'button-disabled' : 'button-green'} fullWidth onClick={handleCreateTeamLineup}>
                {`${intl.formatMessage({ id: 'AddTeamLineupModal_Button_Create' })}`}
              </LoadingButton>
            </DialogActions>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}

AddTeamLineupModal.propTypes = {
  team_id: PropTypes.string,
  handleClose: PropTypes.any,
  open: PropTypes.any,
};

AddTeamLineupModal.defaultProps = {};

import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import VisibilitySensor from 'react-visibility-sensor';
import _ from 'lodash';
import { useQuery, gql, useMutation, useSubscription } from '@apollo/client';
import { useAppSelector } from '../../redux/hooks';
import { useForm, Controller } from 'react-hook-form';
import useLongPress from '../../utilities/LongPressHook';
import { Stack, MenuItem, Menu, TextField, CardHeader, Card, IconButton, Avatar, AvatarGroup, Grid, Typography, CircularProgress, Fab } from '@mui/material';
import { Done, DoneAll, MoreVert, Send } from '@mui/icons-material';
import { MicrophoneIcon } from '../../img/icons/Icons';
import BackButton from '../../component-modals/common/BackButton';
import { UNDEFINED_AS_ANY, ARRAY_AS_ANY } from '../../utilities/CommonInterfaces';
import LoadingComponent from '../../component-modals/common/LoadingComponent';
import { selectSession } from '../../reducers/sessionSlice';
import MessageOptionsDrawer from '../../component-modals/contacts/MessageOptionsDrawer';
import { selectPlayer } from '../../reducers/playerSlice';
import { useIntl } from 'react-intl';

const Conversation: React.FC = () => {
  const FIND_CONVERSATION_BY_ID_QUERY = gql`
    query findConversationByID($conversation: inputFindConversationByID!) {
      findConversationByID(conversation: $conversation) {
        id
        short_id
        title
        avatar {
          path
        }
        description
        created_at
        edited_at
        topic {
          ... on Order {
            __typename
            id
            title
            short_id
          }
          ... on Deal {
            __typename
            id
            title
            short_id
          }
          ... on Challenge {
            __typename
            id
            title
            short_id
          }
        }
        participants {
          ... on Company {
            __typename
            id
            name
            logo {
              path
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on ComplexOwner {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Transporter {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Passenger {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Patient {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on HealthWorker {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
        messages {
          id
          status
          content
          sender {
            ... on Company {
              __typename
              id
              name
              label
              logo {
                path
              }
            }
            ... on User {
              __typename
              id
              username
              avatar {
                path
              }
            }
            ... on Player {
              __typename
              id
              username
              avatar {
                path
              }
            }
            ... on ComplexOwner {
              __typename
              id
              username
              avatar {
                path
              }
            }

            ... on Transporter {
              __typename
              id
              username
              avatar {
                path
              }
            }
            ... on Passenger {
              __typename
              id
              username
              avatar {
                path
              }
            }
            ... on Patient {
              __typename
              id
              username
              avatar {
                path
              }
            }
            ... on HealthWorker {
              __typename
              id
              username
              avatar {
                path
              }
            }
          }
          viewers {
            ... on Company {
              __typename
              id
              name
              label
            }
            ... on User {
              __typename
              id
              username
            }
            ... on Player {
              __typename
              id
              username
            }
            ... on ComplexOwner {
              __typename
              id
              username
            }

            ... on Transporter {
              __typename
              id
              username
            }
            ... on Passenger {
              __typename
              id
              username
            }
            ... on Patient {
              __typename
              id
              username
            }
            ... on HealthWorker {
              __typename
              id
              username
            }
          }
          created_at
          edited_at
        }
      }
    }
  `;

  const SEND_MESSAGE_TO_CONVERSATION_MUTATION = gql`
    mutation sendMessageToConversation($message: inputSendMessageToConversation!) {
      sendMessageToConversation(message: $message) {
        id
      }
    }
  `;

  const VIEWED_MESSAGE_MUTATION = gql`
    mutation viewedMessage($message: inputViewedMessage!) {
      viewedMessage(message: $message)
    }
  `;

  const RECEIVED_NEW_MESSAGE_SUBSCRIPTION = gql`
    subscription postedMessage {
      postedMessage {
        id
        status
        content
        sender {
          ... on Company {
            __typename
            id
            name
            label
            logo {
              path
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on ComplexOwner {
            __typename
            id
            username
            avatar {
              path
            }
          }

          ... on Transporter {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Passenger {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Patient {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on HealthWorker {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
        viewers {
          ... on Company {
            __typename
            id
            name
            label
          }
          ... on User {
            __typename
            id
            username
          }
          ... on Player {
            __typename
            id
            username
          }
          ... on ComplexOwner {
            __typename
            id
            username
          }

          ... on Transporter {
            __typename
            id
            username
          }
          ... on Passenger {
            __typename
            id
            username
          }
          ... on Patient {
            __typename
            id
            username
          }
          ... on HealthWorker {
            __typename
            id
            username
          }
        }
        created_at
        edited_at
      }
    }
  `;

  const SEEN_MESSAGE_SUBSCRIPTION = gql`
    subscription postedMessage {
      seenMessage {
        id
        status
        content
        sender {
          ... on Company {
            __typename
            id
            name
            label
            logo {
              path
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on ComplexOwner {
            __typename
            id
            username
            avatar {
              path
            }
          }

          ... on Transporter {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Passenger {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Patient {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on HealthWorker {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
        viewers {
          ... on Company {
            __typename
            id
            name
            label
          }
          ... on User {
            __typename
            id
            username
          }
          ... on Player {
            __typename
            id
            username
          }
          ... on ComplexOwner {
            __typename
            id
            username
          }

          ... on Transporter {
            __typename
            id
            username
          }
          ... on Passenger {
            __typename
            id
            username
          }
          ... on Patient {
            __typename
            id
            username
          }
          ... on HealthWorker {
            __typename
            id
            username
          }
        }
        created_at
        edited_at
      }
    }
  `;

  const { conversationId } = useParams();
  const intl = useIntl();
  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);

  const [conversation, setConversation] = React.useState(UNDEFINED_AS_ANY);
  const [messages, setMessages] = React.useState(ARRAY_AS_ANY);
  const [offset, setOffset] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openMSGOptions, setOpenMSGOptions] = React.useState(false);
  const gridContainerRef: any = React.useRef(null);

  const { control, watch, setValue, resetField } = useForm({
    defaultValues: {
      content: '',
      sender_id: '',
      selected_message: UNDEFINED_AS_ANY,
    },
  });
  const inputFields = watch();

  const loadedNewMessage = useSubscription(RECEIVED_NEW_MESSAGE_SUBSCRIPTION);
  const loadedSeenMessage = useSubscription(SEEN_MESSAGE_SUBSCRIPTION);
  const [sendMessageToConversation, sentMessage] = useMutation(SEND_MESSAGE_TO_CONVERSATION_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [viewedMessage, updatedMessage] = useMutation(VIEWED_MESSAGE_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedConversation = useQuery(FIND_CONVERSATION_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      conversation: {
        short_id: conversationId,
        participants: _.concat(loggedPlayer?.id),
        offset: offset,
      },
    },
  });

  const ALL_SENDERS = conversation?.participants?.filter((p: any) => p?.id === loggedPlayer?.id);

  const handleRefetch = async () => {
    try {
      const result = await loadedConversation.refetch({
        conversation: {
          short_id: conversation?.short_id,
          participants: _.concat(loggedPlayer?.id),
          offset: offset,
        },
      });

      if (result) {
        setConversation(result.data?.findConversationByID);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const loadPreviousMsgs = async () => {
    try {
      const result = await loadedConversation.refetch({
        conversation: {
          short_id: conversation?.short_id,
          participants: _.concat(loggedPlayer?.id),
          offset: offset === 0 ? messages?.length : offset,
        },
      });

      if (result) {
        const msgs = result.data?.findConversationByID?.messages;
        const newList = _.concat([...msgs].reverse(), messages);
        setMessages(newList);

        offset === 0 ? setOffset(messages?.length + msgs?.length) : setOffset(offset + msgs?.length);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSendNewMessage = async (event: any) => {
    if (event.charCode === 13 || event.charCode === undefined) {
      try {
        await sendMessageToConversation({
          variables: {
            message: {
              conversation_id: conversation?.id,
              app_client_id: session?.app_client?.id,
              sender_id: inputFields.sender_id,
              content: inputFields.content,
              medias: [],
            },
          },
        });
      } catch (e) {
        console.log(e);
      }

      resetField('content');
    }
  };

  // const handleSendNewMood = (event: any) => {
  //   if (event.charCode === 13 || event.charCode === undefined) {

  //   }
  // };

  const handleRecordNewAudioMessage = () => {
    console.log('Recording...');
  };

  const handleSendNewAudioMessage = () => {
    console.log('Send audio...');
  };
  const backspaceLongPress = useLongPress(handleRecordNewAudioMessage, handleSendNewAudioMessage, 200);

  const handleViewedMessages = async (msg: any) => {
    if (inputFields.sender_id !== '' && (msg?.status === 'UNREAD' || msg?.status === 'PARTIALLY_SEEN') && !msg?.viewers?.find((v: any) => v?.id === inputFields.sender_id)) {
      try {
        await viewedMessage({
          variables: {
            message: {
              id: msg?.id,
              viewer_id: inputFields.sender_id,
              seen_by_all: msg?.viewers?.length >= conversation?.participants?.length - 1,
            },
          },
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleOpenMessageOptions = (message: any) => {
    setValue('selected_message', message);
    setOpenMSGOptions(true);
  };

  const handleCloseMSGOptions = async () => {
    await handleRefetch();
    setOpenMSGOptions(false);
  };

  const handleButtonClick = () => {
    // Scroll to the bottom of the grid container
    if (gridContainerRef.current) {
      gridContainerRef.current.scrollTop = gridContainerRef.current.scrollHeight + 100;
    }
  };

  React.useEffect(() => {
    if (conversation === undefined && loadedConversation.data?.findConversationByID) {
      setConversation(loadedConversation.data?.findConversationByID);
    }

    if (messages?.length === 0 && loadedConversation.data?.findConversationByID?.messages?.length > 0) {
      const msgs = loadedConversation.data?.findConversationByID?.messages;
      setMessages([...msgs].reverse());
    }

    if (inputFields.sender_id === '' && ALL_SENDERS?.length > 0) {
      setValue('sender_id', ALL_SENDERS[0]?.id);
    }
  }, [inputFields, setValue, loadedConversation, conversation, setConversation, messages, setMessages]);

  try {
    const messageId = React.useMemo(() => loadedNewMessage.data?.postedMessage?.id, [loadedNewMessage.data?.postedMessage?.id]);
    if (messageId && !messages?.find((m: any) => m?.id === messageId)) {
      setMessages((prevMessages: any) => {
        const newList = _.concat(prevMessages, loadedNewMessage.data?.postedMessage);
        return newList;
      });
    }

    if (loadedSeenMessage.data?.seenMessage?.id && messages?.find((m: any) => m?.id === loadedSeenMessage.data?.seenMessage?.id)?.status === 'PARTIALLY_SEEN' && loadedSeenMessage.data?.seenMessage?.status === 'SEEN') {
      setMessages((prevMessages: any) => {
        // Find the index of the message to be replaced based on its id
        const messageIndex = prevMessages.findIndex((message: any) => message.id === loadedSeenMessage.data?.seenMessage.id);

        // If the message with the specified id exists, replace it; otherwise, keep the original array
        if (messageIndex !== -1) {
          const updatedMessages = [...prevMessages];
          updatedMessages[messageIndex] = loadedSeenMessage.data?.seenMessage;
          return updatedMessages;
        } else {
          return prevMessages;
        }
      });
    }
  } catch (e) {
    console.log(e);
  }

  return (
    <Grid className={`component-contacts conversation-page ${loggedPlayer?.app_configuration?.is_dark_mode ? 'dark-mode' : 'light-mode'}`}>
      <Grid container className={'options'} sx={{ position: 'fixed', zIndex: 100, bgcolor: 'rgb(15, 15, 15, .6)', backdropFilter: 'blur(5px)' }}>
        <Grid className={'left'} item xl={2} xs={2} sm={2} sx={{ p: '10px' }}>
          <BackButton color={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverBGColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15,15,15,.04)'} />
        </Grid>
        <Grid className={'middle'} item xl={8} xs={8} sm={8}>
          {loadedConversation.data?.findConversationByID ? (
            <Stack direction="column" sx={{ p: '10px 0' }}>
              <Typography sx={{ fontWeight: '700', textAlign: 'center', pb: '6px' }}>
                {conversation?.title}
                {conversation?.topic ? (
                  <>
                    <Typography variant="caption" component={Link} to={conversation?.topic?.__typename === 'Deal' ? `/d/${conversation?.topic?.short_id}` : '' || conversation?.topic?.__typename === 'Order' ? `/o/${conversation?.topic?.short_id}` : '' || conversation?.topic?.__typename === 'Challenge' ? `/ch/${conversation?.topic?.short_id}` : ''} sx={{ whiteSpace: 'nowrap', fontWeight: '100', textAlign: 'center', color: 'rgba(31, 175, 146, 1)', textDecoration: 'none', p: '5px 0' }}>
                      &nbsp;({`${intl.formatMessage({ id: 'Global_Button_View' })}`} {conversation?.topic?.__typename})
                    </Typography>
                  </>
                ) : (
                  ''
                )}
              </Typography>

              <AvatarGroup max={3} total={conversation?.participants?.length} spacing="small" component="span" sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <Controller
                  name="sender_id"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field }: any) => (
                    <Grid sx={{ display: 'inline-flex', alignItems: 'center' }}>
                      <Typography variant="caption" sx={{ textAlign: 'center' }}>
                        {`${intl.formatMessage({ id: 'Conversation_Sender_As' })} ${ALL_SENDERS?.find((s: any) => s?.id === inputFields.sender_id)?.username || ALL_SENDERS?.find((s: any) => s?.id === inputFields.sender_id)?.name}`}
                      </Typography>
                      <TextField
                        {...field}
                        value={field.value}
                        select
                        SelectProps={{
                          sx: {
                            'width': '65px',
                            '& fieldset': {
                              border: 'none',
                            },
                            '& .MuiSelect-select': {
                              p: '0 15px !important',
                              mr: '5px',
                              height: '50px !important',
                            },
                            '& svg': { display: 'none' },
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            width: '100%',
                            textAlign: 'center',
                            overflow: 'visible',
                            color: '#FFF',
                          },
                        }}
                      >
                        {ALL_SENDERS?.map((p: any, index: number) => (
                          <MenuItem key={index} value={p?.id}>
                            <Grid container sx={{ alignItems: 'center', justifyContent: 'center', fontSize: '.8em' }}>
                              <Avatar src={p?.avatar?.path || p?.logo?.path} alt={p?.username || p?.label} sx={{ width: '45px', height: '45px' }} />
                              &nbsp;<strong>{p?.username || p?.label}</strong>
                            </Grid>
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  )}
                />
                {conversation?.participants
                  ?.filter((s: any) => s?.id !== inputFields.sender_id)
                  ?.map((p: any) => (
                    <Link key={p?.id} to={{ pathname: p?.__typename === 'Company' ? `/${p?.name}` : `/u/${p?.username}` }}>
                      <Avatar src={p?.avatar?.path || p?.logo?.path} alt={p?.username || p?.label} />
                    </Link>
                  ))}
              </AvatarGroup>
            </Stack>
          ) : (
            ''
          )}
        </Grid>
        <Grid className={'right'} sx={{ display: 'inline-flex', justifyContent: 'end', alignItems: 'top', p: '10px' }} item xl={2} xs={2} sm={2}>
          {!conversation ? (
            ''
          ) : (
            <>
              <Grid>
                <IconButton onClick={handleClick}>
                  <MoreVert />
                </IconButton>
              </Grid>
              <Menu id="conversation-menu" anchorEl={anchorEl} keepMounted style={{ top: '40px', zIndex: 13000 }} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem component={Link} to={{ pathname: `/c/conversation/edit` }} state={{ conversation: { id: conversation?.id, participant_id: inputFields.sender_id } }}>
                  {`${intl.formatMessage({ id: 'Conversation_Button_Edit' })}`}
                </MenuItem>
              </Menu>
            </>
          )}
        </Grid>
      </Grid>
      {loadedConversation.loading ? (
        <LoadingComponent amount={1} text={<Typography sx={{ mb: '20px' }}>{`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}</Typography>} loader={<CircularProgress className="orange-loader" sx={{ mt: '150px' }} />} />
      ) : (
        <>
          <Grid className={'conversation'} item xl={12} xs={12} sm={12}>
            <Grid container ref={gridContainerRef} sx={{ scrollBehavior: 'smooth', alignItems: messages?.length === 0 ? 'center' : 'end', justifyContent: 'center', height: '85vh', overflowY: 'auto', p: '150px 2% 50px' }}>
              {messages?.length === 0 ? (
                <Typography sx={{ opacity: '.4' }}>{`${intl.formatMessage({ id: 'Conversation_First_Messager' })}`}</Typography>
              ) : (
                <Grid id="max-width" sx={{ display: 'inline-block', width: '100%' }}>
                  {loadedConversation.data?.findConversationByID?.messages?.length === 0 ? (
                    ''
                  ) : (
                    <Grid container sx={{ justifyContent: 'center', p: '20px 0' }}>
                      <Fab variant="extended" onClick={loadPreviousMsgs} size="small" disabled={loadedConversation.loading} className={loadedConversation.loading ? 'button-disabled' : 'button-green-reverse'} sx={{ boxShadow: 'none', fontSize: '.7em', border: 'none !important' }}>
                        {loadedConversation.loading ? `${intl.formatMessage({ id: 'Conversation_Loding_Messages_1' })}` : `${intl.formatMessage({ id: 'Conversation_Loding_Messages_2' })}`}
                      </Fab>
                    </Grid>
                  )}

                  {messages?.map((msg: any, index: number) => (
                    <VisibilitySensor onChange={() => handleViewedMessages(msg)} key={index}>
                      <Card onDoubleClick={msg?.sender?.id === inputFields.sender_id ? () => handleOpenMessageOptions(msg) : undefined} sx={{ m: msg?.sender?.id === inputFields.sender_id ? '0 0 30px auto' : '0 0 30px 0', width: '60%', borderRadius: '20px', bgcolor: msg?.sender?.id === inputFields.sender_id ? 'rgba(49, 161, 224, 0.3)' : 'rgba(247, 251, 250, .1)' }}>
                        <CardHeader
                          avatar={msg?.sender?.id !== inputFields.sender_id ? <Avatar src={msg?.sender?.avatar?.path || msg?.sender?.logo?.path} alt={msg?.sender?.username || msg?.sender?.label} className={'avatar'} sx={{ m: '0 5px 0 15px' }} /> : ''}
                          action={msg?.sender?.id === inputFields.sender_id ? <Avatar src={msg?.sender?.avatar?.path || msg?.sender?.logo?.path} alt={msg?.sender?.username || msg?.sender?.label} className={'avatar'} sx={{ m: '0 5px 0 15px' }} /> : ''}
                          title={
                            <>
                              <Typography variant="caption" sx={{ fontWeight: '700', fontSize: '1.2em', display: 'inline-block', width: '100%' }}>
                                {msg?.sender?.username}
                              </Typography>
                              <Moment fromNow>{msg?.created_at}</Moment>
                            </>
                          }
                          subheader={
                            msg?.status === 'REMOVED' ? (
                              <Typography sx={{ fontStyle: 'italic', color: 'rgba(247, 251, 250, .5)', fontSize: '.7em' }}>
                                {`${intl.formatMessage({ id: 'Conversation_Message_Removed' })}`}(<Moment format="D/MM/YYYY HH:mm:ss">{msg?.edited_at}</Moment>)
                              </Typography>
                            ) : (
                              msg?.content
                            )
                          }
                          titleTypographyProps={{ fontSize: '.7em', color: 'rgba(247, 251, 250, .7)', mb: '10px', textAlign: msg?.sender?.id === inputFields.sender_id ? 'right' : 'left' }}
                          subheaderTypographyProps={{ fontSize: '1em', color: 'rgba(247, 251, 250, 1)' }}
                          sx={{ alignItems: 'flex-start', pb: '0' }}
                        />
                        <Grid container sx={{ justifyContent: 'end', p: '0px 15px 0px', position: 'relative', top: '-5px' }}>
                          <Typography sx={{ fontSize: '.7em', color: 'rgba(247, 251, 250, .7)', textAlign: 'right' }}>{msg?.edited_at > 0 && msg?.status !== 'REMOVED' ? `${intl.formatMessage({ id: 'Conversation_Message_Edited' })}` : ''}</Typography>
                          {msg?.status === 'SEEN' ? <DoneAll sx={{ color: 'rgba(31, 175, 146, 1)' }} /> : <Done sx={{ color: 'rgba(247, 251, 250, .4)' }} />}
                        </Grid>
                      </Card>
                    </VisibilitySensor>
                  ))}
                </Grid>
              )}
            </Grid>

            <Grid id="max-width" container>
              <Controller
                name="content"
                control={control}
                rules={{
                  required: true,
                  maxLength: 30,
                  minLength: 4,
                  pattern: /^[a-zA-Z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ._\s-]{5,60}$/,
                }}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    placeholder={`${intl.formatMessage({ id: 'Conversation_Content_Placeholder' })}`}
                    fullWidth
                    disabled={sentMessage.loading || updatedMessage.loading}
                    value={_.trimStart(field.value)}
                    variant="outlined"
                    autoComplete={'off'}
                    onClick={handleButtonClick}
                    onKeyPress={(event: any) => handleSendNewMessage(event)}
                    InputProps={{
                      endAdornment: (
                        <Grid sx={{ textAlign: 'center' }}>
                          <IconButton onClick={(event: any) => handleSendNewMessage(event)} disabled={_.trimStart(field.value) === ''} className={'button-sender'}>
                            <Send />
                          </IconButton>
                          {/* <IconButton onClick={(event: any) => handleSendNewMood(event)} className={'button-sender'}>
                    <MoodFaceIcon />
                  </IconButton> */}
                        </Grid>
                      ),
                      startAdornment: (
                        <IconButton {...backspaceLongPress} disabled={sentMessage.loading || updatedMessage.loading}>
                          <MicrophoneIcon />
                        </IconButton>
                      ),
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': { bgcolor: 'rgba(247, 251, 250, .1)', borderRadius: '10px' },
                      '& input': { bgcolor: 'rgba(247, 251, 250, .9)', pl: '15px', pr: '15px' },
                    }}
                  />
                )}
              />
            </Grid>
            <MessageOptionsDrawer open={openMSGOptions} handleClose={handleCloseMSGOptions} message={inputFields.selected_message} />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Conversation;

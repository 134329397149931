import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { IconButton, SwipeableDrawer, List, Grid, ListItemButton, Avatar, Typography, Button, Divider, Collapse, useMediaQuery } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import { ChallengeIcon, FieldIcon, FivezerIcon, HomeIcon, MenuIcon, NewsIcon, SingleMemberIcon, TeamsIcon, VersusIcon } from '../../img/icons/Icons';
import DarkModeSwitch from '../common/DarkModeSwitch';
import { useAppSelector } from '../../redux/hooks';
import { selectSession } from '../../reducers/sessionSlice';
import { selectPlayer } from '../../reducers/playerSlice';
import SwitchActiveProfile from '../common/SwitchActiveProfile';
import SwitchLanguageButton from '../common/SwitchLanguageButton';
import { useIntl } from 'react-intl';
import NewFieldModal from '../challenge/FormNewFieldModal';

const MenuButton: React.FC = () => {
  const session = useAppSelector(selectSession);
  const loggedUser = useAppSelector(selectPlayer);
  const intl = useIntl();
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const [extended, setExtended] = React.useState(true);
  const [state, setState] = React.useState({
    bottom: false,
    right: false,
  });
  const [newField, setNewField] = React.useState(false);

  type Anchor = 'bottom' | 'right';
  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    const action: any = event;
    if (action && action.type === 'keydown' && (action.key === 'Tab' || action.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleOpenNewField = () => {
    setNewField(true);
  };

  return (
    <>
      {isDesktop ? (
        <IconButton edge="end" aria-label="open drawer" className="cta-style-2" onClick={toggleDrawer('right', !state.right)} sx={{ 'bgcolor': 'transparent !important', '&:hover': { bgcolor: 'rgba(247,251,250,.3) !important' } }}>
          <MenuIcon sx={{ 'color': `${loggedUser?.app_configuration?.is_dark_mode ? 'rgba(251, 88, 2, 1)' : 'rgba(251, 88, 2, 1)'} !important`, '&:hover': { color: `${loggedUser?.app_configuration?.is_dark_mode ? 'rgba(251, 88, 2, 1)' : 'rgba(251, 88, 2, 1)'} !important` } }} />
        </IconButton>
      ) : (
        ''
      )}
      <SwipeableDrawer className="modal-feature" anchor={'right'} open={state['right']} onClose={toggleDrawer('right', false)} onOpen={toggleDrawer('right', true)} ModalProps={{ keepMounted: false, sx: { zIndex: '1000000000' } }} PaperProps={{ sx: { bgcolor: 'rgba(77, 86, 90, 1)', borderBottomLeftRadius: '25px', borderTopLeftRadius: '25px' } }}>
        <List sx={{ padding: '40px 0px 0' }}>
          {session?.status ? (
            <Grid>
              <ListItemButton sx={{ display: 'inline-block', textAlign: 'center', width: '100%' }}>
                <Grid container sx={{ textDecoration: 'none', justifyContent: 'center' }} component={Link} to={session?.status ? `/profile` : '/login'} onClick={toggleDrawer('right', false)} onKeyDown={toggleDrawer('right', false)}>
                  <Grid container>
                    {session?.status ? (
                      <Avatar
                        src={loggedUser?.avatar?.path}
                        sx={{
                          background: '#FFF',
                          border: 'solid 4px #FFF',
                          height: {
                            xs: 40,
                            sm: 60,
                            md: 80,
                            xl: 100,
                          },
                          width: {
                            xs: 40,
                            sm: 60,
                            md: 80,
                            xl: 100,
                          },
                          margin: 'auto',
                        }}
                      />
                    ) : (
                      <SingleMemberIcon />
                    )}
                  </Grid>
                  <Typography sx={{ fontWeight: '700', color: '#FFF' }}>{`${loggedUser?.owner?.firstname} ${loggedUser?.owner?.lastname}`}</Typography>
                </Grid>
                <Grid container sx={{ pt: '20px', justifyContent: 'center' }}>
                  <SwitchActiveProfile color={'#FFF'} />
                </Grid>
              </ListItemButton>

              <ListItemButton>
                <Button
                  fullWidth
                  onClick={() => setExtended(!extended)}
                  startIcon={<FivezerIcon />}
                  sx={{
                    'padding': '10px 50px',
                    'justifyContent': 'left',
                    'alignItems': 'center',
                    'color': '#FFF !important',
                    '&:hover': { bgcolor: 'transparent !important' },
                  }}
                >
                  {_.shuffle(['#DifferentAnimal', '#SameBeast'])[0]}
                </Button>
              </ListItemButton>
              <Divider variant="fullWidth" />
              <Collapse in={extended} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton onClick={toggleDrawer('right', false)} onKeyDown={toggleDrawer('right', false)}>
                    <Button
                      variant="text"
                      component={Link}
                      to={session?.status ? '/new_challenge' : '/'}
                      fullWidth
                      sx={{
                        'color': '#FFF !important',
                        'fontWeight': '700',
                        'p': '10px 50px',
                        'justifyContent': 'left',
                        '&:hover': { bgcolor: 'transparent !important', color: `${loggedUser?.app_configuration?.is_dark_mode ? 'rgba(251, 88, 2, 1)' : 'rgba(251, 88, 2, 1)'} !important` },
                      }}
                      startIcon={<VersusIcon />}
                    >
                      {`${intl.formatMessage({ id: 'App_New_Challenge' })}`}
                    </Button>
                  </ListItemButton>
                  <Divider variant="middle" />
                </List>
              </Collapse>

              {window.location.pathname !== '/' ? (
                <Grid>
                  <ListItemButton onClick={toggleDrawer('right', false)} onKeyDown={toggleDrawer('right', false)}>
                    <Button
                      component={Link}
                      fullWidth
                      to={session?.status ? '/' : '/'}
                      startIcon={<HomeIcon />}
                      sx={{
                        'color': '#FFF !important',
                        'fontWeight': '700',
                        'p': '10px 50px',
                        'justifyContent': 'left',
                        '&:hover': { bgcolor: 'transparent !important', color: `${loggedUser?.app_configuration?.is_dark_mode ? 'rgba(251, 88, 2, 1)' : 'rgba(251, 88, 2, 1)'} !important` },
                      }}
                    >
                      {`${intl.formatMessage({ id: 'App_Home' })}`}
                    </Button>
                  </ListItemButton>
                  <Divider variant="middle" />
                </Grid>
              ) : (
                ''
              )}

              <ListItemButton onClick={toggleDrawer('right', false)} onKeyDown={toggleDrawer('right', false)}>
                <Button
                  variant="text"
                  component={Link}
                  to={session?.status ? '/challenges' : '/'}
                  fullWidth
                  sx={{
                    'color': '#FFF !important',
                    'fontWeight': '700',
                    'p': '10px 50px',
                    'justifyContent': 'left',
                    '&:hover': { bgcolor: 'transparent !important', color: `${loggedUser?.app_configuration?.is_dark_mode ? 'rgba(251, 88, 2, 1)' : 'rgba(251, 88, 2, 1)'} !important` },
                  }}
                  startIcon={<ChallengeIcon />}
                >
                  {`${intl.formatMessage({ id: 'App_Challenges' })}`}
                </Button>
              </ListItemButton>
              <Divider variant="middle" />

              <ListItemButton onClick={toggleDrawer('right', false)} onKeyDown={toggleDrawer('right', false)}>
                <Button
                  component={Link}
                  fullWidth
                  to={session?.status ? `/feeds` : '/login'}
                  startIcon={<NewsIcon />}
                  sx={{
                    'color': '#FFF !important',
                    'fontWeight': '700',
                    'p': '10px 50px',
                    'justifyContent': 'left',
                    '&:hover': { bgcolor: 'transparent !important', color: `${loggedUser?.app_configuration?.is_dark_mode ? 'rgba(251, 88, 2, 1)' : 'rgba(251, 88, 2, 1)'} !important` },
                  }}
                >
                  {`${intl.formatMessage({ id: 'App_Feeds' })}`}
                </Button>
              </ListItemButton>
              <Divider variant="middle" />

              <ListItemButton onClick={toggleDrawer('right', false)} onKeyDown={toggleDrawer('right', false)}>
                <Button
                  component={Link}
                  fullWidth
                  to={session?.status ? `/teams` : '/login'}
                  startIcon={<TeamsIcon />}
                  sx={{
                    'color': '#FFF !important',
                    'fontWeight': '700',
                    'p': '10px 50px',
                    'justifyContent': 'left',
                    '&:hover': { bgcolor: 'transparent !important', color: `${loggedUser?.app_configuration?.is_dark_mode ? 'rgba(251, 88, 2, 1)' : 'rgba(251, 88, 2, 1)'} !important` },
                  }}
                >
                  {`${intl.formatMessage({ id: 'App_Teams' })}`}
                </Button>
              </ListItemButton>
              <Divider variant="middle" />

              <ListItemButton onClick={toggleDrawer('right', false)} onKeyDown={toggleDrawer('right', false)}>
                <Button
                  onClick={handleOpenNewField}
                  fullWidth
                  startIcon={<FieldIcon />}
                  sx={{
                    'color': '#FFF !important',
                    'fontWeight': '700',
                    'p': '10px 50px',
                    'justifyContent': 'left',
                    '&:hover': { bgcolor: 'transparent !important', color: `${loggedUser?.app_configuration?.is_dark_mode ? 'rgba(251, 88, 2, 1)' : 'rgba(251, 88, 2, 1)'} !important` },
                  }}
                >
                  {`${intl.formatMessage({ id: 'App_New_Field' })}`}
                </Button>
              </ListItemButton>
              <Divider variant="middle" />
            </Grid>
          ) : (
            <Grid>
              {location.pathname !== '/' ? (
                <ListItemButton divider onClick={toggleDrawer('right', false)} onKeyDown={toggleDrawer('right', false)}>
                  <Button
                    component={Link}
                    fullWidth
                    to={session?.status ? '/' : '/'}
                    startIcon={<HomeIcon />}
                    sx={{
                      'fontWeight': '700',
                      'color': '#FFF !important',
                      'p': '10px 50px',
                      'justifyContent': 'left',
                      '&:hover': { bgcolor: 'transparent !important', color: `${loggedUser?.app_configuration?.is_dark_mode ? 'rgba(251, 88, 2, 1)' : 'rgba(251, 88, 2, 1)'} !important` },
                    }}
                  >
                    {`${intl.formatMessage({ id: 'App_Home' })}`}
                  </Button>
                </ListItemButton>
              ) : (
                ''
              )}
              {location.pathname !== '/signup' ? (
                <ListItemButton divider onClick={toggleDrawer('right', false)} onKeyDown={toggleDrawer('right', false)}>
                  <Button
                    component={Link}
                    fullWidth
                    to={'/signup'}
                    startIcon={<LoginIcon />}
                    sx={{
                      'fontWeight': '700',
                      'color': '#FFF !important',
                      'p': '10px 50px',
                      'justifyContent': 'left',
                      '&:hover': { bgcolor: 'transparent !important', color: `${loggedUser?.app_configuration?.is_dark_mode ? 'rgba(251, 88, 2, 1)' : 'rgba(251, 88, 2, 1)'} !important` },
                    }}
                  >
                    {`${intl.formatMessage({ id: 'App_Menu_Button_Signup' })}`}
                  </Button>
                </ListItemButton>
              ) : (
                ''
              )}
              {location.pathname !== '/login' ? (
                <ListItemButton divider onClick={toggleDrawer('right', false)} onKeyDown={toggleDrawer('right', false)}>
                  <Button
                    component={Link}
                    fullWidth
                    to={'/login'}
                    startIcon={<LoginIcon />}
                    sx={{
                      'fontWeight': '700',
                      'color': '#FFF !important',
                      'p': '10px 50px',
                      'justifyContent': 'left',
                      '&:hover': { bgcolor: 'transparent !important', color: `${loggedUser?.app_configuration?.is_dark_mode ? 'rgba(251, 88, 2, 1)' : 'rgba(251, 88, 2, 1)'} !important` },
                    }}
                  >
                    {`${intl.formatMessage({ id: 'App_Menu_Button_Login' })}`}
                  </Button>
                </ListItemButton>
              ) : (
                ''
              )}
            </Grid>
          )}
        </List>
        <Grid container sx={{ color: 'rgba(247, 251, 250, 0.3) !important', alignItems: 'center', justifyContent: 'center', pt: '20px' }}>
          <DarkModeSwitch />
        </Grid>
        <Grid sx={{ color: 'rgba(247, 251, 250, 0.3) !important', textAlign: 'center' }}>
          <SwitchLanguageButton />
        </Grid>
      </SwipeableDrawer>
      <NewFieldModal open={newField} handleClose={() => setNewField(false)} />
    </>
  );
};

export default MenuButton;

MenuButton.propTypes = {};

MenuButton.defaultProps = {};

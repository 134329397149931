import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Dialog, DialogTitle, DialogContent, DialogContentText, Divider, Fab, Grid, Avatar, List, ListItem, IconButton, ListItemAvatar, ListItemText } from '@mui/material';
import { Add, Close, Delete } from '@mui/icons-material';
import { gql, useMutation } from '@apollo/client';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { selectSession } from '../../reducers/sessionSlice';
import { TOURNAMENT_PLAYOFF_STAGES } from '../../utilities/utilities';
import _ from 'lodash';

export default function SelectTeamTournamentModal(props: { tournament: any; handleClose: any; open: any; handleRefetch: any }) {
  const SELECT_TEAM_LINEUP_IN_TOURNAMENT_MUTATION = gql`
    mutation selectTeamLineupInTournament($tournament: inputSelectionTeamLineupInTournament!) {
      selectTeamLineupInTournament(tournament: $tournament)
    }
  `;

  const UNSELECT_TEAM_LINEUP_IN_TOURNAMENT_MUTATION = gql`
    mutation unselectTeamLineupInTournament($tournament: inputSelectionTeamLineupInTournament!) {
      unselectTeamLineupInTournament(tournament: $tournament)
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);

  const [selectTeamLineupInTournament, selectedTeam] = useMutation(SELECT_TEAM_LINEUP_IN_TOURNAMENT_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [unselectTeamLineupInTournament, unselectedTeam] = useMutation(UNSELECT_TEAM_LINEUP_IN_TOURNAMENT_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });

  const handleClose = () => {
    props.handleClose();
  };

  const handleRegisterTeamLineup = async (team_lineup_id: string) => {
    try {
      await selectTeamLineupInTournament({
        context: {
          headers: {
            'Authorization': `Bearer ${session?.token?.key}`,
            'X-Anonymous-Access': 'false',
          },
        },
        variables: {
          tournament: {
            id: props.tournament?.id,
            team_lineup_id: team_lineup_id,
            player_id: loggedPlayer?.id,
          },
        },
      });

      props.handleRefetch();
    } catch (e) {
      console.error(e);
    }
  };

  const handleUnregisterTeamLineup = async (team_lineup_id: string) => {
    try {
      await unselectTeamLineupInTournament({
        context: {
          headers: {
            'Authorization': `Bearer ${session?.token?.key}`,
            'X-Anonymous-Access': 'false',
          },
        },
        variables: {
          tournament: {
            id: props.tournament?.id,
            team_lineup_id: team_lineup_id,
            player_id: loggedPlayer?.id,
          },
        },
      });

      props.handleRefetch();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={selectedTeam.loading || unselectedTeam.loading ? undefined : handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: { minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' }, p: '20px 10px' },
      }}
    >
      <Grid container>
        <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'left' }}>
          <Fab
            size="small"
            onClick={selectedTeam.loading || unselectedTeam.loading ? undefined : handleClose}
            disabled={selectedTeam.loading || unselectedTeam.loading}
            className={selectedTeam.loading || unselectedTeam.loading ? 'button-disabled' : 'button-cancel'}
            sx={{
              boxShadow: 'none',
              bgcolor: 'transparent',
              ml: '2%',
            }}
          >
            <Close />
          </Fab>
        </Grid>
        <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'right' }}></Grid>
      </Grid>

      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>Select team candidate</Typography>
      </DialogTitle>
      <Divider variant="middle" sx={{ width: '80%', m: 'auto' }} />
      <DialogContent sx={{ pl: '0', pr: '0' }}>
        <DialogContentText id="alert-dialog-description" component="span">
          <List>
            {props.tournament?.team_candidates?.map((t: any) => (
              <ListItem
                key={t?.id}
                secondaryAction={
                  props.tournament?.selected_teams?.find((st: any) => st?.id === t?.id) ? (
                    <IconButton className="button-red-reversed" onClick={() => handleUnregisterTeamLineup(t?.id)}>
                      <Delete />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => handleRegisterTeamLineup(t?.id)}
                      className={props.tournament?.selected_teams?.length === TOURNAMENT_PLAYOFF_STAGES.find((s: any) => s.value === _.reverse(_.orderBy(_.uniqBy(props.tournament?.phase_playoffs, 'stage_name'), (s: any) => parseInt(s?.stage_name?.replaceAll('FINAL_', '')))?.map((s: any) => s?.stage_name))[0])?.nb_teams || props.tournament?.selected_teams?.length === props.tournament?.phase_groups?.length * 4 ? 'button-disabled' : 'button-green-reverse'}
                      disabled={props.tournament?.selected_teams?.length === TOURNAMENT_PLAYOFF_STAGES.find((s: any) => s.value === _.reverse(_.orderBy(_.uniqBy(props.tournament?.phase_playoffs, 'stage_name'), (s: any) => parseInt(s?.stage_name?.replaceAll('FINAL_', '')))?.map((s: any) => s?.stage_name))[0])?.nb_teams || props.tournament?.selected_teams?.length === props.tournament?.phase_groups?.length * 4}
                    >
                      <Add />
                    </IconButton>
                  )
                }
              >
                <ListItemAvatar>
                  <Avatar src={t?.team?.avatar?.path} alt={t?.name} />
                </ListItemAvatar>
                <ListItemText primary={t?.name} secondary={props.tournament?.selected_teams?.find((st: any) => st?.id === t?.id) ? 'Selected' : 'Not selected'} primaryTypographyProps={{ fontWeight: '900', color: 'rgba(247, 251, 250, 1)' }} secondaryTypographyProps={{ color: props.tournament?.selected_teams?.find((st: any) => st?.id === t?.id) ? 'rgba(0, 129, 21, 1)' : 'rgba(247, 251, 250, 0.3)' }} />
              </ListItem>
            ))}
          </List>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

SelectTeamTournamentModal.propTypes = {
  tournament: PropTypes.any.isRequired,
  handleClose: PropTypes.any.isRequired,
  open: PropTypes.any.isRequired,
  handleRefetch: PropTypes.any.isRequired,
};

SelectTeamTournamentModal.defaultProps = {};

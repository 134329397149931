import React from 'react';
import { Link } from 'react-router-dom';
import { Button, CardMedia, Grid, Typography } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import SwipeableViews from 'react-swipeable-views';
import { BallIcon, FivezerIcon } from '../../img/icons/Icons';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';

const OnBoarding: React.FC = () => {
  const STEPS = [
    {
      top_word: <FormattedMessage id="Onboarding_Create" />,
      middle_word: <FormattedMessage id="Onboarding_Your_Team" />,
      bottom_word: <FormattedMessage id="Onboarding_Team" />,
      content: <FormattedMessage id="Onboarding_Create_Content" />,
    },
    {
      top_word: <FormattedMessage id="Onboarding_Book" />,
      middle_word: <FormattedMessage id="Onboarding_Your_Arena" />,
      bottom_word: <FormattedMessage id="Onboarding_Arena" />,
      content: <FormattedMessage id="Onboarding_Book_Content" />,
    },
    {
      top_word: <FormattedMessage id="Onboarding_Climb" />,
      middle_word: <FormattedMessage id="Onboarding_Climb_The" />,
      bottom_word: <FormattedMessage id="Onboarding_Climb_Ranking" />,
      content: <FormattedMessage id="Onboarding_Climb_Content" />,
    },
    {
      top_word: <FormattedMessage id="Onboarding_Conquer" />,
      middle_word: <FormattedMessage id="Onboarding_And_Become" />,
      bottom_word: <FormattedMessage id="Onboarding_Legend" />,
      content: <FormattedMessage id="Onboarding_Conquer_Content" />,
    },
  ];

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Grid container className={'component-profile boarding on-boarding'} sx={{ minHeight: '100vh', bgcolor: 'rgba(15,15,15,1)', justifyContent: 'center' }}>
      <Helmet>
        <title>Fivezer #DifferentAnimalSameBeast</title>
        <meta name="description" content="Fivezer is a next-generation Five League, where the best teams from all around the world compete against local and overseas teams, anywhere, anytime. Fivezer is a gateway to the largest competition/friendly games, where each player/team can monitor their performances and stats (individual/collective) by using our various ioT tools (bracelet, balls, nets and more)." />
        <link rel="canonical" href={`${window.location.origin}` + window.location.pathname} />
      </Helmet>
      <Grid container sx={{ 'position': 'absolute', 'justifyContent': 'center', 'p': '20px 0 0', '& svg': { fontSize: '70px' } }}>
        <FivezerIcon />
      </Grid>
      <CardMedia
        sx={{
          backgroundImage: `linear-gradient(to bottom, rgba(15, 15, 15 ,0) 0%, rgba(15, 15, 15 ,1) 90%), url(${`../../img/on_borading-${activeStep}.jpg`})`,
          backgroundSize: {
            xs: 'cover',
            sm: 'cover',
            md: 'cover',
            lg: 'cover',
          },
          backgroundPosition: {
            xs: '30% 0%',
            sm: '70% 20%',
            md: '90% 20%',
            lg: '100% 30%',
          },
          width: '100%',
          height: '70vh',
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid>
          <SwipeableViews axis={'x'} index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents>
            {STEPS?.map((step: any, index: number) => (
              <Grid key={index}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <>
                    <Grid item xl={12} xs={12} sm={12} sx={{ textAlign: 'center', p: '0 5%' }}>
                      <Typography sx={{ fontSize: { xs: '3em', sm: '3.5em', md: '4em' }, textTransform: 'uppercase', fontWeight: '700', WebkitTextStroke: '1px rgba(255, 255, 255, 1)', color: 'transparent' }}>{step.top_word}</Typography>
                      <Typography id="tag-font" sx={{ fontSize: { xs: '3em', sm: '3.5em', md: '4em' }, textTransform: 'uppercase', fontWeight: '700', color: 'rgba(199, 240, 11, 1)', position: 'relative', m: { xs: '-45px 0', sm: '-50px 0', md: '-65px 0' } }}>
                        {step.middle_word}
                      </Typography>
                      <Typography sx={{ fontSize: { xs: '3em', sm: '3.5em', md: '4em' }, textTransform: 'uppercase', fontWeight: '700' }}>{step.bottom_word}</Typography>
                    </Grid>
                    <Typography sx={{ fontWeight: '100', textAlign: 'center', p: { xs: '0 33%', sm: '0 30%', md: '0 30%' }, fontSize: { xs: '1em', sm: '1.4em', md: '1.4em' } }}>{step.content}</Typography>
                  </>
                ) : null}
              </Grid>
            ))}
          </SwipeableViews>
        </Grid>
      </CardMedia>
      <Grid
        item
        xl={12}
        xs={12}
        sm={12}
        className={'actions'}
        sx={{
          textAlign: 'center',
          padding: '0 20% 0',
          margin: '0 auto',
        }}
      >
        {activeStep === 0 ? (
          ''
        ) : (
          <Button onClick={handleBack} startIcon={<KeyboardArrowLeft />} size={'large'} className={'button-green'} sx={{ padding: '15px', alignItems: 'start', fontWeight: '700', width: '40%', m: '0 5%' }}>
            <FormattedMessage id="Onboarding_Back" />
          </Button>
        )}
        {activeStep < STEPS?.length - 1 ? (
          <Button onClick={handleNext} endIcon={<KeyboardArrowRight />} size={'large'} className={'button-green'} sx={{ padding: '15px', alignItems: 'start', fontWeight: '700', width: '40%', m: '0 5%' }}>
            <FormattedMessage id="Onboarding_Next" />
          </Button>
        ) : (
          <Button component={Link} to={`/new_footballer`} replace endIcon={<BallIcon />} size={'large'} className={'button-orange'} sx={{ padding: '15px', alignItems: 'start', fontWeight: '700', width: '40%', m: '0 5%' }}>
            <FormattedMessage id="Onboarding_Lets_Ball" />
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default OnBoarding;

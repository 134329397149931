export const chinese_translations = {
  value: 'CN',
  content: {
    // GLOBAL
    Global_Meta_Title: 'Fivezer x 不同的动物，同样的野兽！',
    Global_Meta_Description: 'Fivezer 是新一代五人制足球联赛，来自世界各地的最佳球队可以随时随地与本地和海外球队对抗。Fivezer 是通往最大规模比赛/友谊赛的门户，每个球员/团队可以使用我们的各种物联网工具（手环、球、网等）监控他们的表现和统计数据（个人/集体）。',
    Global_Button_Pay_Now: '立即支付',
    Global_Button_Pay_Now_Caption_1: '预订必须支付',
    Global_Button_Pay_Now_Caption_2: '您必须支付您的比赛费用',
    Global_Status: '状态',
    Global_Pictures_Label: '图片',
    Global_Pictures_Left: '剩下',
    Global_Pictures_Max: '最大',
    Global_Button_Add: '添加',
    Global_Button_Add_Cover: '添加封面',
    Global_Button_Add_Logo: '添加标志',
    Global_Button_Add_PDF: '添加PDF',
    Global_Button_Add_IMG_File: '添加图片文件',
    Global_Button_Resize: '点击图片进行调整大小',
    Global_Button_Cancel: '取消',
    Global_Button_Crop: '裁剪',
    Global_Button_Close: '关闭',
    Global_Button_Ratio: '图像未保持所需的纵横比',
    Global_Button_Delete: '删除',
    Global_Button_Save_Updates: '保存更新',
    Global_Button_Reset: '重置',
    Global_Button_Share: '分享',
    Global_Button_Share_Caption_1: '您可以在以下位置了解更多关于',
    Global_Button_Share_Caption_2: '的信息',
    Global_Button_Save_Translation: '保存翻译',
    Global_Button_Loading: '加载中...',
    Global_Button_Loading_Wait: '加载中，请稍候...',
    Global_Button_Load_More: '加载更多',
    Global_Button_See_More: '查看更多',
    Global_Button_See: '查看',
    Global_Button_See_Less: '查看更少',
    Global_Button_View: '查看',
    Global_Button_Follow: '关注',
    Global_Button_Unfollow: '取消关注',
    Global_Follows_You: '关注了你',
    Global_Button_Next: '下一步',
    Global_Button_Back: '上一步',
    Global_Button_Done: '完成',
    Global_Button_Update: '更新',
    Global_Address_Title_1: '地址标题',
    Global_Address_Title_2: '总部，办公室，地址等...',
    Global_Address_Street_Name_1: '街道名称',
    Global_Address_Street_Name_2: '和平路83号',
    Global_Address_Complement_1: '街道名称（补充）',
    Global_Address_Complement_2: '街道名称的补充',
    Global_Address_Complement_3: '公寓，套房，建筑，楼层等...',
    Global_Address_Postcode_1: '邮政编码',
    Global_Address_Postcode_2: '75000',
    Global_Address_City_1: '城市',
    Global_Address_City_2: '和平城市',
    Global_Address_County_1: '县',
    Global_Address_County_2: '和平县',
    Global_Address_State_1: '州',
    Global_Address_State_2: '和平州',
    Global_Address_Country: '国家',
    Global_Address_GPS_1: '请在地图上定位上述地址。',
    Global_Address_GPS_2: '点击标记以移动它，并尽量准确！',
    Global_No_Description: '暂无描述',
    Global_Sport_Team_Type_1: '联赛',
    Global_Sport_Team_Type_2: '友谊赛',
    Global_Sport_1: '足球',
    Global_Sport_2: '篮球',
    Global_Social_Media_Title: '社交媒体',
    Global_Social_Media_Button_1: '添加社交媒体',
    Global_Select_Translations: '翻译',
    Global_Stats_Games: '比赛',
    Global_Stats_Games_Played: '比赛场次',
    Global_Stats_Wins: '胜利',
    Global_Stats_Draws: '平局',
    Global_Stats_Loss: '失败',
    Global_Stats_Goals: '进球',
    Global_Stats_Assists: '助攻',
    Global_Stats_Conceded: '失球',
    Global_Stats_World: '秩',
    Global_Stats_World_Sign_1: 'st',
    Global_Stats_World_Sign_2: 'nd',
    Global_Stats_World_Sign_3: 'rd',
    Global_Stats_World_Sign: 'th',
    Global_Stats_Followers: '关注者',
    Global_Stats_Follower: '关注者',
    Global_Stats_MOTM: '本场最佳球员',
    Global_Stats_Good_Passes: '成功传球',
    Global_Stats_Shots: '射门',
    Global_Stats_KM_Total: '总公里数',
    Global_Stats_KM_Average: '平均每场公里数',
    Global_Stats_KMH_Max: '最高时速',
    Global_Stats_KMH_Average: '平均时速',
    Global_Stats_Clean_Sheet_1: '零失误',
    Global_Stats_Clean_Sheet_2: '记录',
    Global_Tabs_Overview: '概览',
    Global_Tabs_Notifications: '通知',
    Global_Tabs_Calendar: '日历',
    Global_Tabs_Lineups: '阵容',
    Global_Tabs_Lineup: '阵容',
    Global_Tabs_Statistics: '统计',
    Global_Button_Add_Player: '添加球员',
    Global_Button_Add_Manager: '添加经理',
    Global_Button_Delete_Player: '删除球员',
    Global_Button_New_Lineup: '新阵容',
    Global_Button_Edit_Lineup: '编辑阵容',
    Global_Button_New_Challenge: '新挑战',
    Global_Button_No_Notifications: '还没有通知',
    Global_No_Challenges: '没有挑战',
    Global_No_Players: '没有球员',
    Global_No_Manager: '没有经理',
    Global_No_Notifications: '没有通知',
    Global_Sport_Type_1: '足球',
    Global_Sport_Type_2: '篮球',
    Global_No_Trophies: '没有奖杯',
    Global_Trophy_Gold: '金',
    Global_Trophy_Silver: '银',
    Global_Trophy_Bronze: '铜',
    Global_Trophy_Iron: '铁',
    Global_Trophy_Right: '右',
    Global_Trophy_Left: '左',
    Global_Trophy_Both: '双方',
    Global_Since: '自',
    Global_Challenge_Type_Friendly: '友谊赛',
    Global_Challenge_Type_League: '联赛',
    Global_Challenge_Type_Matchmaker: '红娘',
    Global_Dates_Start_Label: '开始日期',
    Global_Dates_End_Label: '结束日期',
    Global_Followers: '关注者',
    Global_Followings: '关注',
    Global_Gender_Female: '女性',
    Global_Gender_Male: '男性',
    Global_Gender_Other: '其他',
    Global_Position_Goalkeeper: '守门员',
    Global_Position_Defender: '后卫',
    Global_Position_Midfielder: '中场',
    Global_Position_Striker: '前锋',
    Global_Foots_Right: '右脚',
    Global_Foots_Left: '左脚',
    Global_Foots_Both: '双脚',
    Global_ID_Types_None: '选择证件类型',
    Global_ID_Types_Passport: '护照',
    Global_ID_Types_National: '国家',
    Global_ID_Types_Residential: '居住（临时）',
    Global_Permanent_Delete_Title: '永久删除',
    Global_Button_Desactivate: '停用',
    Global_Button_Activate: '启用',
    Global_Field_Types_1: '混合',
    Global_Field_Types_2: '合成',
    Global_Field_Types_3: '第二代 (2G)',
    Global_Field_Types_4: '第三代 (3G)',
    Global_Field_Types_5: '第四代 (4G)',
    Global_Field_Types_6: '第五代 (5G)',
    Global_Field_Types_7: '第六代 (6G)',
    Global_Field_Sizes_1: '1对1',
    Global_Field_Sizes_2: '2对2',
    Global_Field_Sizes_3: '3对3',
    Global_Field_Sizes_4: '4对4',
    Global_Field_Sizes_5: '5对5',
    Global_Field_Sizes_6: '6对6',
    Global_Field_Sizes_7: '7对7',
    Global_Field_Sizes_8: '8对8',
    Global_Field_Sizes_9: '9对9',
    Global_Field_Sizes_10: '10对10',
    Global_Field_Sizes_11: '11对11',
    Global_Delete_Confirmation_Caption_1: '此操作不可撤销',
    Global_Delete_Confirmation_Caption_2: '您确定要删除',
    Global_Delete_Confirmation_Caption_3: '通过书写确认',
    Global_Delete_Confirmation_Caption_4: '在下面的字段中：',
    Global_Write_Username: '输入用户名',
    Global_Write_Reset_All: '重置所有',
    Global_Filters_Title: '筛选器',
    Global_No_User_Found_Title: '未找到用户',
    Global_No_Team_Found_Title: '未找到团队',
    Global_See_Team: '查看团队',
    Global_Write_Team: '输入关键词/主题搜索',
    Global_Search_Tab_Players: '球员',
    Global_Search_Tab_Teams: '团队',
    Global_Pin_SMS_1: '您好',
    Global_Pin_SMS_2: '您的Fivezer验证码是：',
    Global_Activate_Email_Label_1: '您必须激活您的电子邮件',
    Global_Activate_Email_Label_2: '使用我们发送给您的验证电子邮件。',
    Global_Activate_Email_Button_1: '发送电子邮件',
    Global_Activate_Email_Button_2: '已发送',
    Global_Activate_Email_Button_3: '重新发送',
    Global_As_Footballer: '作为',
    Global_No_Statistics: '无统计数据',
    Global_No_History: '无转会历史记录',
    Global_NA: 'N/A',
    Global_Dark_Mode: '暗模式',
    Global_Light_Mode: '亮模式',
    Global_Player: '球员',
    Global_Manager: '经理',
    Global_Missing_Footballer_Profile_Button_1: '现在添加',
    Global_Missing_Footballer_Profile_Label_1: '您缺少足球运动员档案',
    Global_Button_Edit: '编辑',
    Global_Not_Found: '未找到',
    Global_Page_Not_Found_Title: '页面未找到',
    Global_Page_Not_Found_1: '对不起',
    Global_Page_Not_Found_2: '由于网络不佳，我们无法加载信息',
    Global_Page_Not_Found_3: '请再试一次',
    Global_Page_Not_Found_4: '访问被拒绝',
    Global_Page_Not_Found_5: '您无权访问此信息',
    Global_Page_Not_Found_6: '回到首页',
    Global_Page_Not_Found_7: '未找到',
    Global_Page_Not_Found_8: '请检查名称的拼写或使用我们的',
    Global_Page_Not_Found_9: '搜索引擎',
    Global_Page_Not_Found_10: '尝试新的搜索。',
    Global_Page_Not_Found_11: '加载失败',
    Global_Page_Not_Found_12: '请尝试重新加载页面。',

    // NOTIFICATION MESSAGES
    View: '查看',
    Accept: '接受',
    Reject: '拒绝',
    Decline: '拒绝',
    Notification_New_Tournament_Title: '新锦标赛!',
    Notification_New_Tournament_Content_1: '已创建!',
    Notification_Join_Team_Title: '想加入',
    Notification_Join_Team_Content_1: '向您发送了加入的请求',
    Notification_New_Team_Title: '新球队诞生了!',
    Notification_New_Team_Content_1: '恭喜',
    Notification_New_Team_Content_2: '您的球队已上线。',
    Notification_New_Footballer_Title: '新足球运动员诞生了!',
    Notification_New_Footballer_Content_1: '恭喜',
    Notification_New_Footballer_Content_2: '您的足球运动员资料已上线。',
    Notification_New_Flex_Title: '共同发布 Flex',
    Notification_New_Flex_Content_1: '想与您共同发布一个 flex。',
    Notification_New_Challenge_Title: '新挑战!',
    Notification_New_Challenge_Content_1: '挑战',
    Notification_New_Challenge_Content_2: '已预订，准备好您的团队!',
    Notification_New_Challenge_Content_3: '的队长必须接受或拒绝挑战!',
    Notification_New_Challenge_Content_4: '每个玩家应支付',
    Notification_New_Challenge_Content_5: '之间',
    Notification_New_Challenge_Content_6: '什么都不用，因为这个场地是免费的!',
    Notification_ScheduleTournamentModal_Title: '新预订!',
    Notification_ScheduleTournamentModal_Content_1: '已预订，请查看您的仪表板。',
    Notification_Edit_Tournament_Playoff_Modal_Title: '新预订!',
    Notification_Edit_Tournament_Playoff_Modal_Content_1: '已预订，请查看您的仪表板。',
    Notification_View_Team_Lineup_Title: '想加入您的团队!',
    Notification_View_Team_Lineup_Content_1: '向您发送了加入您的团队以玩的请求',

    // APP COMPONENT
    App_User: '用户',
    App_Home: '首页',
    App_Teams: '球队',
    App_Feeds: '新闻',
    App_Challenges: '挑战',
    App_New_Challenge: '新挑战',
    App_Find_Challenge: '寻找挑战',
    App_Find_Opponent: '寻找对手',
    App_Build_Team: '组建球队',
    App_Join_Team: '加入球队',
    App_New_Tournament: '新锦标赛',
    App_Add_PWA_Content: '下载Fivezer!',
    App_Add_PWA_Safari_Tap: '点击',
    App_Add_PWA_Safari_Content: '并选择“添加到主屏幕”',
    App_Add_PWA: '下载',
    App_New_Field: '添加新场地',
    App_Menu_Button_Signup: '注册',
    App_Menu_Button_Login: '登录',

    // TOPBAR COMPONENT
    TopBar_Login: '登录',
    // FOOTER COMPONENT
    Footer_All_Rights: '版权所有。',
    Footer_Complex_Listing: '体育场馆注册',
    Footer_Legal_Mentions: '法律提及',
    Footer_Legal_Mentions_Content: `
    **1. 介绍:**
       - "欢迎来到Fivezer，您进入国际体育和竞技的门户。在Fivezer，我们致力于打造一个充满活力的体育社区，消费者可以通过我们的平台预订体育活动，而体育场馆所有者可以高效地管理预订并通过我们的平台接收付款。我们的使命是连接来自全球的运动员和团队，为他们提供展示自己技能和使用尖端技术监控表现的机会。我们邀请您加入我们在这个激动人心的旅程中，共同改变体育界。"
    
    **2. 愿景与使命:**
       - Fivezer的愿景：
         - "我们设想一个世界，在这个世界中，无论运动员和团队身在何处，都可以在全球舞台上竞争、建立联系和脱颖而出。Fivezer是实现这一愿景的媒介，将体育社区联结起来，并提供增长和发展的工具。"
       - 使命宣言： 
         - "Fivezer致力于创建一个无缝透明的生态系统，在这个生态系统中，体育爱好者、团队和运动员可以参与、竞争和取得成功。"
    
    **3. 问题陈述:**
       - "认识到需要一个高效的平台来支持运动员、团队和体育场馆管理他们的活动，Fivezer承诺解决这个问题。"
    
    **4. 公司信息:**
       - "Fivezer由Africantum运营，是一家注册企业，总部位于法国。您可以通过contact@thebhub.io与我们联系。"
    
    **5. 使用条款:**
       - 通过使用Fivezer的服务，您同意遵守以下使用条款，这些条款构成您与Africantum之间的法律约束协议：
    
       **5.1. 账户创建与管理:**
          - "您的Fivezer账户是进入体育世界的关键。我们提供一套全面的工具和功能，帮助您管理您的体育活动、与其他运动员和团队建立联系，提高您的表现。通过注册并使用您的账户，您可以访问无限的体育机会。"
    
       **5.2. 用户行为:**
          - 用户必须遵守所有适用的法律法规。
          - 禁止的行为可能包括骚扰、作弊、垃圾邮件和任何非法活动。
    
       **5.3. 内容提交:**
          - 用户对他们提交到平台的内容负有责任。
          - 禁止的内容可能包括侵犯版权的材料、仇恨言论或有害内容。
    
       **5.4. 隐私和数据使用:**
          - 关于如何收集、存储和使用用户数据的信息。
          - 同意在平台内进行数据处理和共享。
    
       **5.5. 知识产权:**
          - 保护用户和平台的知识产权权利。
          - 举报侵犯版权或商标侵权的机制。
    
       **5.6. 交易与付款:**
          - 与支付处理相关的条款，包括费用、退款和付款方式。
          - 准确的服务描述和交付对卖家的责任。
    
       **5.7. 争议解决:**
          - 争议解决程序，包括调解或仲裁。
          - 用户之间冲突的报告和解决。
    
       **5.8. 终止与暂停:**
          - 可以终止或暂停账户的条件。
          - 受账户行动影响的用户的上诉流程。
    
       **5.9. 责任与保证:**
          - 平台和用户的责任限制。
          - 对于服务和产品的保证免责声明。
    
       **5.10. 法律和司法管辖权:**
          - 法律事务的选择法律和司法管辖权。
    
       **5.11. 条款变更:**
          - 平台更新或修改条款和条件的权利。
          - 向用户通知变更。
    
       **5.12. 赔偿:**
          - 用户同意赔偿并使平台免受法律索赔或责任。
    
       **5.13. 沟通与通知:**
          - 平台与用户的通信方式，包括电子邮件通知。
    
       **5.14. 用户评价和反馈:**
          - 关于平台上用户评价和反馈的政策。
    
       **5.15. 安全措施:**
          - 用户对维护账户安全的责任。
          - 平台保护用户数据的措施。
    
       **5.16. 可访问性和可用性:**
          - 平台可访问性要求和可用性保证。
    
       **5.17. 用户终止权利:**
          - 用户可以终止账户的条件。
    
       **5.18. 社区准则:**
          - 平台特定的社区准则和行为规则。
    
       **5.19. 遵守法律:**
          - 用户承诺遵守所有适用的法律。
    
       **5.20. 杂项:**
          - 与平台使命和用户群体相关的任何额外法律条款或免责声明。
    
    **6. 隐私政
    
    策:**
       - "Fivezer的隐私政策概述了我们如何根据数据保护法律收集、存储和处理用户数据。这包括使用先进技术提升体育体验并保护用户数据的措施。用户可以查阅我们的完整隐私政策以获取更多详细信息。"
    
    **7. 用户责任:**
       - "作为Fivezer的用户，您有责任在与其他用户的互动和在平台内遵守道德和法律标准。这包括不参与任何欺诈活动、作弊或不当行为。"
    
    **8. 知识产权:**
       - "Fivezer的所有知识产权，包括但不限于商标和版权，均归Africantum所有。用户必须尊重这些权利，任何被举报的侵权行为将会迅速处理。"
    
    **9. 争议解决:**
       - "在发生争议时，我们鼓励用户通过Fivezer指定的渠道报告问题。争议可以通过调解或仲裁来解决，如我们的争议解决流程所述。"
    
    **10. 司法管辖权和适用法律:**
        - "与Fivezer相关的法律事务的司法管辖权位于法国。任何法律争议将受到法国法律的管辖。"
    
    **11. 执行和处罚:**
        - "Fivezer保留执行这些条款和政策的权利。违反可能导致处罚，包括账户暂停或终止。"
    
    **12. 举报不当行为:**
        - "如果您在Fivezer上遇到不当行为、欺诈活动或不诚实行为，请立即向我们的支持团队报告。我们非常重视用户保护，将迅速调查和解决此类问题。"
    
    **13. 法律参考和合规性:**
        - "Fivezer承诺遵守所有适用的国内和国际法律，包括但不限于消费者保护、电子商务和数据保护法规。"
    
    **14. 数据保护和隐私:**
        - "Fivezer非常重视用户的隐私和数据保护。我们承诺遵守数据保护法律，包括《通用数据保护条例》（GDPR）和其他适用法规。"
        - "用户数据收集：我们收集和处理用户数据，用于特定目的，包括账户管理、通信、交易处理、智能市场洞察、安全和欺诈预防以及个性化。"
        - "数据安全：我们采用强大的安全措施，包括加密，以保护用户数据免受未经授权的访问或违规。"
        - "与第三方分享：用户数据可能与受信任的第三方服务提供商共享，用于支付处理和分析等用途。我们确保这些提供商也遵守数据保护法规。"
        - "用户权利：用户有权访问、更正或删除Fivezer持有的其个人数据。用户可以通过我们指定的渠道提出请求。"
        - "Cookies：我们使用Cookies来增强用户体验。用户可以通过浏览器设置管理Cookie偏好。"
        - "通过使用Fivezer的服务，您同意按照我们的隐私政策中所描述的特定目的收集、处理和使用您的数据。"    
    `,
    // INPUTDESCRIPTION COMPONENT
    InputDescriptionsFeature_Descriptions_Label: '可用语言',
    InputDescriptionsFeature_Descriptions_Placeholder: '选择一种语言',
    InputDescriptionsFeature_Add_Language: '添加语言',
    InputDescriptionsFeature_Selected_Description_Label_1: '当前描述',
    InputDescriptionsFeature_Selected_Description_Label_2: '和',
    InputDescriptionsFeature_Selected_Description_Label_3: '描述',
    InputDescriptionsFeature_Selected_Description_Label_4: '描述',
    InputDescriptionsFeature_Selected_Description_Label_5: '内容为空的描述',
    InputDescriptionsFeature_Selected_Description_Placeholder: '选择一个描述',
    InputDescriptionsFeature_Short_Descriptions_Placeholder: '添加简短描述...',
    InputDescriptionsFeature_Selected_Description_Caption: '（内容为空）',
    InputDescriptionsFeature_Selected_Description_Content: '以',
    InputDescriptionsFeature_Selected_Short_Description_Content: '简短描述为',
    InputDescriptionsFeature_Selected_Description_Content_Warning: '记得在每次更新后保存您的内容',
    InputDescriptionsFeature_African_Languages: '非洲语言',
    InputDescriptionsFeature_Other_Languages: '其他语言',
    // LOGINPAGE
    LoginPage_Hello: '嗨',
    LoginPage_Friend: '篮球高手',
    LoginPage_Welcome_Back: '欢迎回来！',
    // LOGIN FORM COMPONENT
    LoginForm_Login_Title: '登录',
    LoginForm_Identifier: '用户名 / 邮箱',
    LoginForm_Password: '密码',
    LoginForm_Forgot_Password: '忘记密码？',
    LoginForm_Turn_On_Location: '启用位置信息，以确保您的账户安全',
    LoginForm_Login: '登录',
    LoginForm_Logging: '登录中...',
    LoginForm_No_Member_Yet: '还不是会员？',
    LoginForm_Signup: '立即注册',
    LoginForm_Error_Login: '您的用户名/密码错误，请重新尝试或重置您的密码',
    LoginForm_Reset_Password: '重置密码',
    LoginForm_Remember_Password: '您记得密码吗？',
    LoginForm_Switch_User: '切换账户',
    // FORGOTPASSWORDFORM COMPONENT
    ForgotPasswordForm_Identifier: '标识符',
    ForgotPasswordForm_Email_Mobile: '电子邮件/手机号',
    ForgotPasswordForm_Pin_Verification: 'PIN 验证',
    ForgotPasswordForm_Please_Wait: '请等待...',
    ForgotPasswordForm_Sending_SMS: '发送短信中...',
    ForgotPasswordForm_Send_Via_Email: '通过电子邮件发送PIN',
    ForgotPasswordForm_Send_Via_SMS: '通过短信发送PIN',
    ForgotPasswordForm_Validation: '验证',
    ForgotPasswordForm_Is_Submitted: '您的密码已更新，请尝试登录！',
    ForgotPasswordForm_Login_Now: '立即登录',
    ForgotPasswordForm_Is_Updated: '您的密码已更新！',
    ForgotPasswordForm_New_Password: '新密码',
    ForgotPasswordForm_New_Password_Confirmation: '新密码确认',
    ForgotPasswordForm_Reset_Password: '重置密码',
    ForgotPasswordForm_Last_Step: '最后一步',
    ForgotPasswordForm_Login_Button: '登录',
    ForgotPasswordForm_Back_Button: '返回',
    ForgotPasswordForm_PIN_Verification_Button: 'PIN码验证',
    ForgotPasswordForm_Continue_Button: '继续',
    ForgotPasswordForm_Turn_On_Location: '启用您的位置以全面保护您的帐户',
    ForgotPasswordForm_Is_Email_Submitted: '您的电子邮件已发送，请查看您的收件箱',
    ForgotPasswordForm_Resend_Email: '出现问题，请重试',
    // SIGNUPFORM COMPONENT
    SignUpForm_Title: '注册',
    SignUpForm_Account_Info: '账户信息',
    SignUpForm_Username: '用户名',
    SignUpForm_Username_Taken: '已被占用',
    SignUpForm_Email_Already_Used: '已在另一个账户上使用',
    SignUpForm_Email_Incorrect: '电子邮件格式不正确',
    SignUpForm_Email: '电子邮件',
    SignUpForm_Gender: '性别',
    SignUpForm_DOB: '出生日期',
    SignUpForm_My_Gender: '我的代词是...',
    SignUpForm_Firstname: '名字',
    SignUpForm_Lastname: '姓氏',
    SignUpForm_New_Password: '新密码',
    SignUpForm_Mobile_Verification: '手机验证',
    SignUpForm_Mobile: '手机',
    SignUpForm_Sending_SMS: '正在发送短信...',
    SignUpForm_Send_SMS: '发送',
    SignUpForm_SMS_Error: '短信无法发送到',
    SignUpForm_Beta_Notice: '测试版本：这是您的PIN码',
    SignUpForm_Use_International_Number: "请使用国际格式 '+' 或 '00'",
    SignUpForm_Great: '太棒了！',
    SignUpForm_Next_Step: '下一步，将是定制您的足球球员，让我们开始吧！',
    SignUpForm_We_Sent_Email: "按下 '创建球员' 后，我们将向您发送电子邮件以验证您的账户",
    SignUpForm_To_Verify: '来验证您的账户。',
    SignUpForm_Create_Account_Button: '创建球员',
    SignUpForm_Last_Step: '最后一步',
    SignUpForm_Login_Button: '登录',
    SignUpForm_Back_Button: '返回',
    SignUpForm_PIN_Verification_Button: 'PIN 验证',
    SignUpForm_Continue_Button: '继续',
    SignUpForm_Turn_On_Location: '开启您的位置以完全保护您的账户',
    SignUpForm_Already_Member: '已经是会员？',
    SignUpForm_All_Done: '所有步骤完成 - 您已完成',
    SignUpForm_Loading_message: '正在创建您的账户，请稍候...',

    // ONBOARDING COMPONENT
    Onboarding_Create: '创建',
    Onboarding_Your_Team: '你的',
    Onboarding_Team: '团队',
    Onboarding_Create_Content: '创建你的团队和游戏。选择你的标志和团队名称。选择你的战士。管理你的团队。',
    Onboarding_Book: '预订',
    Onboarding_Your_Arena: '你的',
    Onboarding_Arena: '竞技场',
    Onboarding_Book_Content: '在全世界各地预订你的球场，并挑战其他团队。',
    Onboarding_Climb: '攀登',
    Onboarding_Climb_The: '排行榜',
    Onboarding_Climb_Ranking: '排名',
    Onboarding_Climb_Content: 'Fivezer的排名基于全世界每个团队的结果。赢得你的比赛，赚取积分，在你的城市中名列前茅，登上世界之巅。',
    Onboarding_Conquer: '征服',
    Onboarding_And_Become: '并成为',
    Onboarding_Legend: '传奇',
    Onboarding_Conquer_Content: '本能地玩游戏。玩游戏来赢。玩游戏成为第一名。',
    Onboarding_Next: '下一步',
    Onboarding_Back: '返回',
    Onboarding_Lets_Ball: '让我们打球',
    // HOME COMPONENT
    Home_Hello: '你好',
    Home_Street: '街道',
    Home_Culture: '文化',
    Home_Football: '足球',
    Home_Aka: '又名',
    Home_The_Biggest: '最大的',
    Home_Sports_Community: 'i体育社区',
    Home_Where: '在这里，最好的团队',
    Home_From: '来自世界各地',
    Home_Compete: '与本地和海外团队竞争',
    Home_Anywhere: '任何地方，任何时间',
    Home_Hashtag: '#不同的动物，同样的野兽',
    Home_New_Challenge: '新的挑战',
    Home_Throne: '征服球场',
    Home_Find_Challenge: '寻找挑战',
    Home_Upcoming_Challenges: '面临的挑战',
    Home_Upcoming_Challenge: '接受挑战',
    Home_Find_Opponent: '挑战对手',
    Home_Challengers: '挑战者正在等你',
    Home_Challenger: '挑战者正在等你',
    Home_Build_Team: '组建团队',
    Home_Become: '成为传奇',
    Home_Join_Team: '加入一个团队',
    Home_Teams: '团队（仅限好心情）',
    Home_Team: '团队（仅限好心情）',
    Home_Over: '多于',
    Home_New_Tournament: '新的锦标赛',
    Home_New_Tournaments: '新的锦标赛',
    Home_Prove: '头衔和声誉，靠努力赢得!',
    Home_Upcoming_No_Challenges: '先发起挑战吧！',
    Home_Find_No_Opponent: '立即挑战所有人！',
    Home_Find_No_Tournament: '组织自己的比赛！',

    // PAYSPORTSESSIONMODAL
    PaySportSessionModal_Pay: '支付',
    PaySportSessionModal_Title: '体育课程',
    PaySportSessionModal_Notification: '已向您的球员发送了付款金额的消息。每次付款完成时，您将收到通知。您也可以为每个人支付或选择您要为其支付的球员。',
    PaySportSessionModal_Squad: '队伍',
    PaySportSessionModal_Paid_By: '支付者',
    PaySportSessionModal_Pay_For_Yourself: '为自己付款',
    PaySportSessionModal_Pay_For_Them: '为他人付款',
    PaySportSessionModal_Player_To_Pay: '球员',
    PaySportSessionModal_Players_To_Pay: '球员',
    PaySportSessionModal_Total: '总计',
    PaySportSessionModal_Price_per_Player: '每个球员价格',
    PaySportSessionModal_Proceed_Pay_Button: '继续支付',
    PaySportSessionModal_Pay_Button: '支付',
    PaySportSessionModal_Cancel_Button: '取消',
    PaySportSessionModal_Back_Button: '返回',
    PaySportSessionModal_Payment_Method: '支付方式',
    PaySportSessionModal_Stepper_Back_Button: '返回',
    PaySportSessionModal_Stepper_Next_Button: '下一步',
    PaySportSessionModal_Stepper_Finish_Button: '完成',
    PaySportSessionModal_Snacks: '小吃',
    PaySportSessionModal_Per_Player: '每位球员',
    PaySportSessionModal_Unpaid: '未支付',
    PaySportSessionModal_Paid: '已支付',
    PaySportSessionModal_Group_Snacks: '团体小吃',
    PaySportSessionModal_Individual_Snacks: '个人小吃',
    PaySportSessionModal_Unpaid_Group_Snacks: '未支付的团体小吃',
    PaySportSessionModal_Fully_Paid_Group_Snacks: '已完全支付的团体小吃',
    PaySportSessionModal_You_Paid: '您已支付！',
    // SPENDINGCODEUPDATEMODAL COMPONENT
    SpendingCodeModal_Forgot_Spending_Code: '忘记消费密码？',
    SpendingCodeModal_Email_Sent: '邮件已发送！',
    SpendingCodeModal_Remember_Spending_Code: '您记得消费密码吗？',
    SpendingCodeModal_Identifier: '识别码',
    SpendingCodeModal_Email_Mobile: '电子邮件/手机号',
    SpendingCodeModal_Pin_Verification: 'PIN码验证',
    SpendingCodeModal_Please_Wait: '请等待...',
    SpendingCodeModal_Sending_SMS: '正在发送短信...',
    SpendingCodeModal_Send_Via_Email: '通过电子邮件发送PIN码',
    SpendingCodeModal_Send_Via_SMS: '通过短信发送PIN码',
    SpendingCodeModal_Validation: '验证',
    SpendingCodeModal_Is_Submitted: '您的密码已更新，请尝试登录！',
    SpendingCodeModal_Is_Updated: '您的消费密码已更新！',
    SpendingCodeModal_New_Password: '新消费密码',
    SpendingCodeModal_New_Password_Confirmation: '确认新消费密码',
    SpendingCodeModal_Reset_Password: '重置消费密码',
    SpendingCodeModal_Last_Step: '最后一步',
    SpendingCodeModal_Login_Button: '登录',
    SpendingCodeModal_Back_Button: '返回',
    SpendingCodeModal_PIN_Verification_Button: 'PIN码验证',
    SpendingCodeModal_Continue_Button: '继续',
    // JOINCOMPLEXNETWORK COMPONENT
    JoinComplexNetwork_1: '用Fivezer彻底改变您的综合体育场',
    JoinComplexNetwork_1_bis: '全球冠军的游乐场！',
    JoinComplexNetwork_2: '综合体育场的所有者注意了——Fivezer来了，将为您的体育世界带来革命性的转变！欢迎来到下一代Five League，将世界顶级团队引入您的门前，无论您身在何处，都能点燃令人兴奋的比赛，日夜不停！',
    JoinComplexNetwork_3: '释放尖端ioT工具的力量——从智能手环到神奇的足球，目瞪口呆地观察您的球员表现和令人惊叹的数据飙升至新的高度！',
    JoinComplexNetwork_4: '走上舞台中央，令世界着迷，使您的综合体育场成为体育卓越的中心。迎接名声、荣耀和无数机会，因为赞助商、球迷和媒体蜂拥而至，见证冠军的诞生！',
    JoinComplexNetwork_5: '时机成熟，Fivezer即将登场。准备改写您所知体育规则的篇章。加入无限伟大的精英联赛！',
    JoinComplexNetwork_6: 'Fivezer - 滋养冠军梦想，一个场地接一个场地。拥抱未来。',
    JoinComplexNetwork_6_bis: '拥抱未来。提升您的传承！',
    // SENDRECOMMENDATIONMODAL COMPONENT
    SendRecommendationModal_Tell_Them: '告诉他们了解情况',
    SendRecommendationModal_Do_You_Want: '您希望您喜爱的团队加入Fivezer吗？',
    SendRecommendationModal_We_Add_Them: '告诉业主，以便我们可以将他们添加到我们的可用场地列表中。',
    SendRecommendationModal_Send_Button: '发送',
    SendRecommendationModal_Email_Button: '电子邮件',
    SendRecommendationModal_Press_Enter: '按回车键以添加更多。',
    // TOURNAMENT COMPONENT
    Tournament_Finale_128: '128强赛',
    Tournament_Finale_64: '64强赛',
    Tournament_Finale_32: '32强赛',
    Tournament_Finale_16: '16强赛',
    Tournament_Finale_8: '八强赛',
    Tournament_Finale_4: '四强赛',
    Tournament_Finale_2: '决赛',
    Tournament_By: '由...组织',
    // AVAILABILITIES COMPONENT
    InputSelectAvailabilitiesFeature_Title_1: '选择时间可用性',
    InputSelectAvailabilitiesFeature_Title_2: '您可以稍后更新您的可用时间',
    InputSelectAvailabilitiesFeature_Title_3: '可用性',
    InputSelectAvailabilitiesFeature_Title_4: '没有可用性',
    InputSelectAvailabilitiesFeature_Title_5: '更改',
    InputSelectAvailabilitiesFeature_Title_6: '可用性',
    InputSelectAvailabilitiesFeature_Day_1: '星期一',
    InputSelectAvailabilitiesFeature_Day_2: '星期二',
    InputSelectAvailabilitiesFeature_Day_3: '星期三',
    InputSelectAvailabilitiesFeature_Day_4: '星期四',
    InputSelectAvailabilitiesFeature_Day_5: '星期五',
    InputSelectAvailabilitiesFeature_Day_6: '星期六',
    InputSelectAvailabilitiesFeature_Day_7: '星期天',
    // FORMNEWTEAM COMPONENT
    FormNewTeam_Title: '新团队',
    FormNewTeam_Create_Profile: '创建足球运动员档案',
    FormNewTeam_Sport_Type_Title: '运动',
    FormNewTeam_Sport_Type_Coming: '即将推出',
    FormNewTeam_Category_Title: '类别',
    FormNewTeam_Category_Subtitle_1: '只有联赛队伍可以累积分数和排名',
    FormNewTeam_Category_Subtitle_2: '你已经有',
    FormNewTeam_Category_Subtitle_3: '你已经为',
    FormNewTeam_Category_Subtitle_4: '在联赛中踢球',
    FormNewTeam_Category_Subtitle_5: '你已经有',
    FormNewTeam_Category_Subtitle_6: '在联赛中',
    FormNewTeam_Name_Title: '队名',
    FormNewTeam_Name_Placeholder: '巴黎圣日耳曼',
    FormNewTeam_Name_1: '名字必须至少有4个字符',
    FormNewTeam_Name_2: '您只能使用字母、数字或下划线',
    FormNewTeam_Name_3: '已被占用',
    FormNewTeam_Shortname_Title: '简称（3个字母）',
    FormNewTeam_Shortname_Placeholder: 'PSG',
    FormNewTeam_Shortname_1: '名字必须至少有3个字符',
    FormNewTeam_Shortname_2: '您只能使用字母、数字或下划线',
    FormNewTeam_Privacy_Title: '隐私？',
    FormNewTeam_Privacy_1: '是的，这是一个私人团队 🤐',
    FormNewTeam_Privacy_2: '不，这是一个公开团队 🤗',
    FormNewTeam_Bio_Title: '简介（多语言）',
    FormNewTeam_Area_Title: '你代表哪个区域？',
    FormNewTeam_Area_1: '区域名称',
    FormNewTeam_Area_2: '圣保罗',
    FormNewTeam_Button_Create: '创建团队',
    FormNewTeam_Create_Profile_Label: '您必须至少有一个足球运动员档案才能创建团队',
    Global_Manager_At: '的经理',

    // FORMEDITTEAM COMPONENT
    FormEditTeam_Title: '编辑团队',
    FormEditTeam_See_Profile: '查看简介',
    FormEditTeam_Sport_Type_Title: '运动',
    FormEditTeam_Sport_Type_Coming: '即将推出',
    FormEditTeam_Name_Title: '团队名称',
    FormEditTeam_Name_Placeholder: '巴黎圣日耳曼',
    FormEditTeam_Name_1: '名称必须至少有4个字符',
    FormEditTeam_Name_2: '您只能使用字母、数字或下划线',
    FormEditTeam_Name_3: '已经被占用了',
    FormEditTeam_Shortname_Title: '简称 (3个字母)',
    FormEditTeam_Shortname_Placeholder: 'PSG',
    FormEditTeam_Shortname_1: '名称必须至少有3个字符',
    FormEditTeam_Shortname_2: '您只能使用字母、数字或下划线',
    FormEditTeam_Privacy_Title: '隐私？',
    FormEditTeam_Privacy_1: '是的，这是一个私人团队 🤐',
    FormEditTeam_Privacy_2: '不，这是一个公开团队 🤗',
    FormEditTeam_Bio_Title: '简介（多语言）',
    FormEditTeam_Area_Title: '你代表哪个区域？',
    FormEditTeam_Area_1: '区域名称',
    FormEditTeam_Area_2: '圣保罗',
    FormEditTeam_View_Inactive_1: '查看',
    FormEditTeam_View_Inactive_2: '过去的',
    FormEditTeam_View_Inactive_3: '球员',
    FormEditTeam_View_Inactive_4: '球员',

    // FOOTBALLTEAM COMPONENT
    FootballTeam_Error_1: '很抱歉，但我们似乎找不到这支球队',
    FootballTeam_Alert_1: '您的球队需要至少有一名队长，否则您无法进行比赛',
    FootballTeam_Alert_2: '您的球队需要至少有一名球员和一名队长，否则您无法进行比赛',
    FootballTeam_Button_Edit: '编辑球队',
    FootballTeam_Squad_Title: '阵容',
    FootballTeam_Remove_Captain: '移除队长',
    FootballTeam_Promote_Captain: '提升为队长',
    FootballTeam_Choose_Number: '选择号码',
    FootballTeam_Next_Challenges_Title: '下一个挑战',
    FootballTeam_No_Challenges: '还没有挑战',
    FootballTeam_Start_New_Challenge: '立即开始一个挑战',
    FootballTeam_Last_Challenges: '最近的挑战',
    FootballTeam_No_Players: '还没有球员',
    FootballTeam_Add_them: '现在添加',
    FootballTeam_No_Lineups: '还没有阵容',
    FootballTeam_Create_Lineup: '现在创建一个',
    FootballTeam_Managers_Title: '经理',
    FootballTeam_Start_New_Manager_Quote: `足球经理 ${new Date().getFullYear()} 模式`,
    FootballTeam_New_Player_Quote: '职业模式',

    // PROFILE COMPONENT
    Profile_Share_1: '分享资料',
    Profile_Share_2: 'Fivezer 资料',
    Profile_Share_3: '查找',
    Profile_Share_4: '的 Fivezer 资料',
    Profile_Button_Edit: '编辑资料',
    Profile_Button_New_Footballer: '新足球运动员',
    Profile_Button_New_Team: '新团队',
    Profile_Button_New_Field: '新场地',
    Profile_Button_New_Tournament: '新锦标赛',
    Profile_Button_Settings: '设置',
    Profile_View_Card_1: '查看',
    Profile_View_Card_2: 'FZ卡',
    Profile_Tab_Overview: '概览',
    Profile_Tab_Team: '团队',
    Profile_Tab_Calendar: '日历',
    Profile_Tab_Statistics: '统计',
    Profile_Tab_History: '转运公司',
    Profile_Tab_Fields: '发布的场地',
    Profile_Create_Team_1: '要创建团队，您必须验证您的电子邮件',
    Profile_Create_Team_2: '创建您的团队',
    Profile_Pick_Profile: '选择一个资料',
    Profile_No_Teams: '没有团队',
    Profile_Missing_Profile: '还没有运动档案',

    // FOOTBALLCARD COMPONENT
    FootballCard_Button_Share: '分享卡片',
    FootballCard_Button_Back: '返回',
    FootballCard_Button_Jubilee: '举行您的纪念赛',
    FootballCard_Button_Edit: '编辑足球资料',
    FootballCard_Button_Share_Profile_1: '分享资料',
    FootballCard_Profile_Status: '资料是',
    // FORMNEWTOURNAMENT COMPONENT
    FormNewTournament_Title: '新赛事',
    FormNewTournament_Name: '赛事名称',
    FormNewTournament_Name_Placeholder: '曼巴5赛事',
    FormNewTournament_Name_Helper_1: '名称必须至少包含4个字符',
    FormNewTournament_Name_Helper_2: '只能使用字母、数字或下划线',
    FormNewTournament_Sport_Type_Coming: '即将推出',
    FormNewTournament_Descriptions: '描述',
    FormNewTournament_Cost_Title: '每队费用',
    FormNewTournament_Cost_Value: '费用',
    FormNewTournament_Cost_Currency: '货币',
    FormNewTournament_Trophy: '奖杯',
    FormNewTournament_Trophies: '奖杯',
    FormNewTournament_Privacy_Title: '隐私？',
    FormNewTournament_Privacy_Content_1: '是的，这是一个私人活动 🤐',
    FormNewTournament_Privacy_Content_2: '不，这是一个对公众开放的活动 🤗',
    FormNewTournament_Organisator_Title: '主要组织者',
    FormNewTournament_Organisator_Label: '主要组织者',
    FormNewTournament_Organisator_Placeholder: '组织者...',
    FormNewTournament_Organisators_Title: '其他组织者',
    FormNewTournament_Organisators_Label: '其他组织者',
    FormNewTournament_Organisators_Placeholder: '其他组织者...',
    FormNewTournament_Managers_Title: '管理员',
    FormNewTournament_Managers_Label: '管理员',
    FormNewTournament_Managers_Placeholder: '管理员...',
    FormNewTournament_Settings_Title: '赛事设置',
    FormNewTournament_Settings_Groups_1: '是的，有小组赛阶段 🤐',
    FormNewTournament_Settings_Groups_2: '不，没有小组赛阶段 🤗',
    FormNewTournament_Settings_Playoff_Label: '淘汰赛阶段',
    FormNewTournament_Settings_Playoff_Content_1: '您的赛事将有',
    FormNewTournament_Settings_Playoff_Content_2: '支队伍',
    FormNewTournament_Settings_Playoff_Content_3: '分为',
    FormNewTournament_Settings_Playoff_Content_4: '每组4支队伍，前2名将晋级决赛阶段。',
    FormNewTournament_Dates_Title: '日期',
    FormNewTournament_Location_Title: '主要地点',
    FormNewTournament_Button_New_Tournament: '创建赛事',
    // FORMEDITTOURNAMENT
    FormEditTournament_Title: '编辑比赛',
    // TOURNAMENTS COMPONENT
    Tournaments_No_Tournaments_Found: '未找到任何比赛，请重试',
    Tournaments_No_Tournament_In: '没有比赛在',
    Tournaments_No_Tournament_Yet: '尚未',
    Tournaments_Meta_Title: '比赛',
    Tournaments_Candidate: '候选人',
    Tournaments_Candidates: '候选人',

    // TOURNAMENT COMPONENT
    Tournament_Meta_Title: '比赛',
    Tournament_Not_Found: '未找到比赛',
    Tournament_Not_Found_Caption: '很抱歉，我们找不到这个比赛，请重新检查名称拼写。',
    Tournament_Button_Edit: '编辑比赛',
    Tournament_Team_Candidates: '候选球队',
    Tournament_Team_Selected: '已选球队',
    Tournament_Register_Unregister: '注册/取消注册您的球队',
    Tournament_Select_Unselect: '选择/取消选择球队',
    Tournament_Validate_Teams: '验证球队选择',
    Tournament_Confirm_Teams: '您确认选择的球队吗？',
    Tournament_Confirm_Teams_Yes: '是',
    Tournament_Confirm_Teams_No: '否',
    Tournament_Kick_Off_Group_Title: '开赛小组赛',
    Tournament_Kick_Off_Group_Caption_1: '您确认开始您的比赛小组赛吗？',
    Tournament_Kick_Off_Group_Caption_2: '此操作不可逆，请确保您已安排所有比赛',
    Tournament_Kick_Off_Group_Button_Go: '开始',
    Tournament_Kick_Off_Group_Button_Wait: '不，等待',
    Tournament_Kick_Off_Finals_Title: '开赛决赛',
    Tournament_Kick_Off_Finals_Caption_1: '您确认开始您的比赛决赛吗？',
    Tournament_Kick_Off_Finals_Caption_2: '此操作不可逆，请确保您已添加所有比赛分数',
    Tournament_Kick_Off_Finals_Button_Go: '开始',
    Tournament_Kick_Off_Finals_Button_Wait: '不，等待',
    Tournament_Cost_Per_Team: '球队',
    Tournament_Trophies_Title: '奖杯',
    Tournament_Trophies_Assign: '分配奖杯',
    Tournament_Overview_Tab: '概览',
    Tournament_Boards_Tab: '公告',
    // TOURNAMENTPLAYOFF COMPONENT
    TournamentPlayoff_Click_Schedule: '点击安排',
    TournamentPlayoff_Team: '球队',
    TournamentPlayoff_Match: '比赛',
    // TOURNAMENTGROUPS COMPONENT
    Tournament_Groups_No_Games_Yet: '尚未安排比赛',
    Tournament_Groups_No_Teams: '无球队',
    Tournament_Groups_Button_Schedule: '安排比赛',
    Tournament_Groups_Button_Add_Team: '添加球队',
    Tournament_Groups_Games: '的比赛',
    // INPUTTROPHIESFEATURE COMPONENT
    InputTrophiesFeature_Rank_Label: '奖杯排名',
    InputTrophiesFeature_Name_Label: '奖杯名称',
    InputTrophiesFeature_Name_Placeholder: 'FZ世界锦标赛第一名',
    InputTrophiesFeature_Name_Helper_1: '名称必须至少包含4个字符',
    InputTrophiesFeature_Name_Helper_2: '只能使用字母、数字或下划线',
    InputTrophiesFeature_Note_Label: '奖杯注释',
    InputTrophiesFeature_Note_Placeholder: '由X Y Z赞助商提供的奖杯',
    InputTrophiesFeature_Add_Trophy: '添加奖杯',
    // GPSMAPFEATURE COMPONENT
    GPSMapFeature_1: '请在地图上标注上面的地址',
    GPSMapFeature_2: '点击标记来移动它，尽量准确！',
    // CHALLENGEFILTERSMODAL COMPONENT
    ChallengeFiltersModal_When_Title: '时间',
    ChallengeFiltersModal_Where_Title: '地点',
    ChallengeFiltersModal_No_Complex_Selected: '未选择任何场馆',
    ChallengeFiltersModal_Field_Label: '场地',
    ChallengeFiltersModal_Search_Complex_Label: '搜索场馆',
    ChallengeFiltersModal_Search_Complex_Placeholder: '输入场馆名称',
    ChallengeFiltersModal_Button_Results: '查看结果',
    // SETTINGS COMPONENT
    Settings_Title: '设置',
    Settings_Account: '账户',
    Settings_Wallets: '钱包',
    Settings_Languages: '语言',
    Settings_Helpdesk: '帮助台',
    Settings_Legal_Mentions: '法律声明',
    Settings_Logout: '登出',
    Settings_Logging_Out: '正在登出...',
    Settings_Blocked_Accounts: '已屏蔽的帐户',
    Settings_Accounts_Title: '帐户',
    Settings_Contact_Us_Title: '联系我们',
    Settings_Contact_Us_Content: `
            在Fivezer，我们随时为您解答任何关于我们体育社区平台的问题、疑虑或反馈。您可以通过电子邮件联系我们，我们的团队将及时回复您的询问。

            客户支持邮箱：
            对于客户支持，包括帐户相关问题、技术援助或一般查询，请发送电子邮件至：
            Email: contact@thebhub.io
            
            商务咨询邮箱：
            如果您有与Fivezer相关的商务咨询、合作机会或合作提案，请联系我们的商务开发团队：
            Email: contact@thebhub.io
            
            一般查询邮箱：
            对于关于Fivezer、我们的平台或作为体育爱好者或团队入门的一般问题，您可以发送电子邮件至我们的一般查询团队：
            Email: contact@thebhub.io
            
            反馈和建议邮箱：
            我们重视您的反馈和建议，以改进Fivezer体验。如果您有想法或建议，请发送电子邮件至：
            Email: contact@thebhub.io
            
            回复时间：
            我们努力尽快回复您的电子邮件。我们的专职团队将为您提供所需的支持和信息，以充分利用您的Fivezer体验。
            
            感谢您选择Fivezer作为您在国际体育和竞赛世界中的首选目的地。我们期待通过电子邮件为您提供帮助。
          `,
    Settings_Legal_Mentions_Title: '法律声明',

    // FORMNEWFOOTBALLER
    FormNewFootballer_Title: '新足球运动员',
    FormNewFootballer_Existing_Footballer: '你已经有一个足球运动员档案',
    FormNewFootballer_See_Existing_Footballer: '查看档案',
    FormNewFootballer_Alias_Label: '足球运动员名字',
    FormNewFootballer_Alias_Placeholder: '贝利',
    FormNewFootballer_Nickname_Label: '昵称',
    FormNewFootballer_Nickname_Placeholder: '球王',
    FormNewFootballer_Bio_Title: '简介（多语言）',
    FormNewFootballer_Which_Area: '你代表哪个地区？',
    FormNewFootballer_Area_Title_Label: '地区名称',
    FormNewFootballer_Area_Title_Placeholder: '圣保罗',
    FormNewFootballer_Position_Title: '位置',
    FormNewFootballer_Jersey_Title: '球衣号码',
    FormNewFootballer_Foots_Title: '惯用脚',
    FormNewFootballer_Button_Create: '创建足球运动员',
    //FORMEDITFOOTBALLER
    FormEditFootballer_Title: '编辑球员',
    // FORMEDITPROFILE
    FormEditProfile_Title: '编辑个人资料',
    FormEditProfile_Basic_Title: '基本信息',
    FormEditProfile_Username_Label: '用户名',
    FormEditProfile_Username_1: '已被占用',
    FormEditProfile_Username_2: '只允许使用字母、数字和_',
    FormEditProfile_Email_Label: '电子邮件',
    FormEditProfile_Email_1: '已在另一个账户中使用',
    FormEditProfile_Firstname_Label: '名字',
    FormEditProfile_Lastname_Label: '姓氏',
    FormEditProfile_DOB_Label: '出生日期',
    FormEditProfile_Bio_Label: '简介',
    FormEditProfile_Bio_1: '你必须删除',
    FormEditProfile_Bio_2: '字符',
    FormEditProfile_Bio_3: '字符',
    FormEditProfile_Mobile_Label: '手机',
    FormEditProfile_Mobile_1: '验证PIN码',
    FormEditProfile_Mobile_2: '手机验证成功！',
    FormEditProfile_Passwords_Title: '密码',
    FormEditProfile_Passwords_Label: '新密码',
    FormEditProfile_Passwords_1: '输入新账户密码',
    FormEditProfile_Passwords_2: '新消费密码',
    FormEditProfile_Passwords_3: '消费密码已更新！',
    FormEditProfile_KYC_Title: '个人身份验证',
    FormEditProfile_ID_Type_Label: '证件类型',
    FormEditProfile_ID_Number_Label: '证件号码',
    FormEditProfile_New_KYC_Title: '进行新的身份验证？',
    FormEditProfile_New_KYC_Label_1: '是的！',
    FormEditProfile_New_KYC_Label_2: '不！',
    FormEditProfile_New_KYC_Exp_Label: '证件到期日',
    FormEditProfile_New_KYC_Issuer_Country_Label: '证件签发国家',
    FormEditProfile_New_KYC_Issuer_Label: '签发机构',
    FormEditProfile_New_KYC_Issuer_Placeholder: '输入签发机构名称',
    FormEditProfile_Preferenced_Sport_Title: '喜欢的运动',
    FormEditProfile_Preferenced_Sport_Label: '喜欢的运动',
    FormEditProfile_Preferenced_Sport_1: '没有选择运动',
    FormEditProfile_Locations_Title: '位置',
    FormEditProfile_Button_Add_Location: '添加地址',
    // TOURNAMENTS
    Tournaments_New_Tournaments_Tab: '即将到来的比赛',
    Tournaments_Old_Tournaments_Tab: '过去的比赛',
    // NEWCHALLENGE COMPONENT
    FormNewChallenge_Title: '新挑战',
    FormNewChallenge_No_Team_Caption_1: '无论是否是友谊赛，只有队长才能创建挑战。',
    FormNewChallenge_No_Team_Caption_2: '所以请在队伍中任命一个队长然后再回来',
    FormNewChallenge_No_Team_Caption_3: '别担心，即使你一个人，也有很多玩家会加入你！',
    FormNewChallenge_No_Team_Button_New_Team: '立即创建您的团队',
    FormNewChallenge_Challenger_Label_1: '作为',
    FormNewChallenge_Challenger_Label_2: '谁？',
    FormNewChallenge_Challenger_Placeholder: '选择您的团队',
    FormNewChallenge_Category_Title: '类别',
    FormNewChallenge_Category_Caption_League: '排名赛很简单，就是你对他们，赢家得分，输家被过人',
    FormNewChallenge_Category_Caption_Friendly: '友谊赛只是为了玩得开心，没有压力，没有分数，只有乐趣和过人',
    FormNewChallenge_Category_Caption_Matchmaker: 'Matchmaker 是一种友谊赛，它召集佣兵球员来填补你的队伍并一起比赛，这是结交新朋友的好方法',
    FormNewChallenge_Name_Label: '挑战名称',
    FormNewChallenge_Name_Placeholder: '您的团队',
    FormNewChallenge_Name_1: '名称必须至少有 4 个字符',
    FormNewChallenge_Name_2: '您只能使用字母、数字或下划线',
    FormNewChallenge_Your_Lineup_Title: '您的阵容',
    FormNewChallenge_Your_Lineup_Label_1: '创建一个新阵容',
    FormNewChallenge_Your_Lineup_Label_2: '选择一个现有阵容',
    FormNewChallenge_Team_Selected: '未选择队友',
    FormNewChallenge_New_Lineup_Label: '编写您的新阵容',
    FormNewChallenge_New_Lineup_Placeholder: '输入团队别名',
    FormNewChallenge_Edit_Lineup_Label: '现有阵容',
    FormNewChallenge_Edit_Lineup_Placeholder: '选择一个场地',
    FormNewChallenge_Against_Title: '对手？',
    FormNewChallenge_Against_Label: '未选择对手',
    FormNewChallenge_Opponent_Title: '对手',
    FormNewChallenge_Opponent_Placeholder: '输入团队名称或 ID',
    FormNewChallenge_Privacy_Title: '隐私？',
    FormNewChallenge_Privacy_Caption_1: '是的，这是私密的 🤐',
    FormNewChallenge_Privacy_Caption_2: '不，向公众开放 🤗',
    FormNewChallenge_How_Long_Title: '多久？',
    FormNewChallenge_How_Long_Hour: '小时',
    FormNewChallenge_How_Long_Hours: '小时',
    FormNewChallenge_When_Title: '什么时候？',
    FormNewChallenge_Recurring_Label_1: '安排多次 🖐',
    FormNewChallenge_Recurring_Label_2: '安排一次 👆',
    FormNewChallenge_Recurring_Label_3: '每',
    FormNewChallenge_Start_Date_1: '第一天（开始）',
    FormNewChallenge_Start_Date_2: '开始日期',
    FormNewChallenge_End_Date_1: '第一天（结束）',
    FormNewChallenge_End_Date_2: '结束日期',
    FormNewChallenge_Last_Day: '最后一天',
    FormNewChallenge_Last_Day_Label_1: '你想玩',
    FormNewChallenge_Last_Day_Label_2: '次',
    FormNewChallenge_Last_Day_Label_3: '次',
    FormNewChallenge_Last_Day_Label_4: '每周直到',
    FormNewChallenge_Where_Title: '在哪里？',
    FormNewChallenge_Complexes_Label: '搜索综合体',
    FormNewChallenge_Complexes_Placeholder: '输入综合体名称',
    FormNewChallenge_Complexes_No_Selected: '未选择综合体',
    FormNewChallenge_Field_Label: '场地',
    FormNewChallenge_Field_Placeholder: '选择一个场地',
    FormNewChallenge_Fields_Already_Booked: '已预订',
    FormNewChallenge_Fields_Hour: '小时',
    FormNewChallenge_Fields_Free: '空闲',
    FormNewChallenge_Button_Create: '创建挑战',
    // FORMEDITCHALLENGE COMPONENT
    FormEditChallenge_Title: '编辑挑战',
    // CHALLENGE COMPONENT
    Challenge_Meta_Title: '挑战',
    Challenge_Payment_Caption_1: '您还没有支付本次挑战的座位费用',
    Challenge_Button_Accept: '接受',
    Challenge_Button_Decline: '拒绝',
    Challenge_Accept_Challenge: '您接受这个挑战吗？',
    Challenge_Button_Edit: '编辑挑战',
    Challenge_Private_Event: '这是一个私人活动',
    Challenge_Button_Results: '比赛结果',
    Challenge_Group_Order_Label: '组顺序',
    Challenge_Button_Retry: '重试付款',
    Challenge_Personal_Order_Label: '个人订单',
    Challenge_Partcially_Paid_1: '等待对手确认',
    Challenge_Partcially_Paid_2: '添加最终得分',
    Challenge_Button_Rematch: '重赛',
    // CHALLENGES COMPONENT
    Challenges_Meta_Title: '挑战',
    Challenges_Tab_Challenges: '挑战',
    Challenges_Tab_Opponents: '对手',
    Challenges_No_Challenges_Found: '未找到挑战，请重试',
    Challenges_No_Challenges_In: '没有挑战在',
    Challenges_No_Challenges_Yet: '尚未',
    // FIELD COMPONENT
    Field_Meta_Title: '场地',
    Field_Not_Found: '未找到场地',
    Field_Not_Found_Caption: '很抱歉，似乎此场地不可用，请检查名称拼写是否正确。',
    Field_Button_Edit: '编辑场地',
    Field_Sport_Type_Title: '运动',
    Field_Type_Label: '类型',
    Field_Size_Label: '尺寸',
    Field_Cost_Label: '费用',
    Field_Equipments_Title: '设备',
    Field_No_Equipments: '没有设备',
    Field_No_Pictures: '没有照片',
    // FORMNEWFIELD MODAL
    FormNewField_Title: '新场地',
    FormNewField_Location: '位置',
    FormNewField_Descriptions: '描述（多语言）',
    FormNewField_Button_Add_Descriptions: '添加描述',
    FormNewField_No_Description: '没有描述',
    FormNewField_Pictures_Title: '照片',
    FormNewField_Name_Label: '名称',
    FormNewField_Name_Placeholder: '写场地名称',
    FormNewField_Button_Create: '添加场地',
    // FORMEDITFIELD
    FormEditField_Title: '编辑场地',
    // REQUESTMATCHMAKING COMPONENT
    RequestMatchMaking_Meta_Title: '匹配游戏',
    RequestMatchMaking_Not_Found_1: '匹配游戏',
    RequestMatchMaking_Not_Found_2: '未找到',
    RequestMatchMaking_Not_Found_3: '很抱歉，这个请求似乎不可用，请检查名称拼写或使用我们的',
    RequestMatchMaking_Not_Found_4: '搜索',
    RequestMatchMaking_Not_Found_5: '引擎。',
    RequestMatchMaking_Type_Label: '类型',
    RequestMatchMaking_Button_Leave_Game: '离开游戏',
    RequestMatchMaking_Button_Join_Game: '加入游戏',
    RequestMatchMaking_Players: '玩家',
    RequestMatchMaking_Player: '玩家',
    RequestMatchMaking_Joined_Players: '已加入',
    RequestMatchMaking_Joined_Player: '已加入',
    // MANAGESCORE MODAL
    ManageScore_Title: '最终结果',
    ManageScore_Intro_1: '现在比赛结束了，你可以添加比分以及你队员的统计数据。',
    ManageScore_Intro_2: '提醒一下，双方队长都必须确认比分才能验证统计数据。',
    ManageScore_BG_Title_1: '最终',
    ManageScore_BG_Title_2: '结果',
    ManageScore_Button_Add_Scores: '添加比分',
    ManageScore_Button_Unconfirm: '取消确认',
    ManageScore_Button_Confirm: '确认最终比分',
    ManageScore_Button_Save_Continue: '保存并稍后继续',
    ManageScore_Button_Continue: '继续',
    ManageScore_Confirm_Score_Content: '你确认挑战的最终结果吗？',
    ManageScore_Confirm_Score_Button_Yes: '是',
    ManageScore_Confirm_Score_Button_No: '否',
    ManageScore_Added_Score_Info_1: '你已经添加了所有的进球和助攻',
    ManageScore_Added_Score_Info_2: '现在你可以立即确认结果',
    ManageScore_Added_Score_Info_3: '或者',
    ManageScore_Added_Score_Info_4: '你可以保存并稍后继续。',
    ManageScore_Scorer_Label: '进球者',
    ManageScore_NB_Scorer_Label_1: '进球数',
    ManageScore_NB_Scorer_Label_2: '球员',
    ManageScore_Selected_Passer_Label_1: '一名球员',
    ManageScore_Selected_Passer_Label_2: '进球数',
    ManageScore_Selected_Passer_Label_3: '进球',
    ManageScore_Selected_Passer_Label_4: '并获得',
    ManageScore_Selected_Passer_Label_5: '助攻数',
    ManageScore_Selected_Passer_Label_6: '助攻',
    ManageScore_Selected_Scorer_Caption_1: '你可以添加所有的助攻',
    ManageScore_Selected_Scorer_Caption_2: '一个球员',
    ManageScore_Selected_Scorer_Caption_3: '获得的',
    ManageScore_Selected_Scorer_Label: '助攻者',
    ManageScore_NB_Assist_Label_1: '助攻数',
    ManageScore_NB_Assist_Label_2: '球员',
    ManageScore_Button_Confirm_Scorer: '确认进球者',
    ManageScore_Button_Add_Assist: '添加助攻',
    ManageScore_MOTM_Caption_1: '比赛最佳球员',
    ManageScore_MOTM_Caption_2: '比赛最佳球员',
    ManageScore_MOTM_Intro_1: '如果',
    ManageScore_MOTM_Intro_2: '对方队员',
    ManageScore_MOTM_Intro_3: '让你印象深刻，将他/她评为“比赛最佳球员”。',
    ManageScore_MOTM_Intro_4: '你的对手也会为你的球队做同样的事情',
    ManageScore_Selected_MOTM_Caption_1: '你评选了',
    ManageScore_Selected_MOTM_Label: '比赛最佳球员',
    ManageScore_No_MOTM: '无',
    // SWITCHLANGUAGEBUTTON COMPONENT
    SwitchLanguageButton_Title: '选择语言',
    SwitchLanguageButton_Current_Lang: '当前语言',
    SwitchLanguageButton_Button_Title: '语言',
    // NEWCONVERSATION MODAL
    New_Conversation_Title: '新对话',
    New_Conversation_Title_Label: '标题',
    New_Conversation_Description_Label: '话题（可选）',
    New_Conversation_Description_Placeholder: '对话的主题/目的是什么',
    New_Conversation_Description_Helper_1: '备注必须至少有0个字符',
    New_Conversation_Description_Helper_2: '在中有一个未授权的字符',
    New_Conversation_Author_Label: '以身份加入',
    New_Conversation_Participants_Label: '参与者',
    New_Conversation_Participants_Placeholder: '选择参与者',
    New_Conversation_No_Participants: '未选择参与者',
    New_Conversation_Create: '创建对话',
    // CONVERSATIONS COMPONENT
    Conversations_Meta_Title: '对话',
    Conversations_Conversations_Title: '对话',
    Conversations_Conversation_Title: '对话',
    Conversations_Button_New_Conversation: '新对话',
    Conversations_Button_Conversations: '对话',
    Conversations_Button_Add_Contact: '添加联系人',
    Conversations_Button_Contacts: '联系人',
    Conversations_Start_Now_Title: '现在开始对话！',
    Conversations_Disabled_1: '此功能已禁用',
    Conversations_Disabled_2: '即将回归',
    // CONVERSATION COMPONENT
    Conversation_Sender_As: '以',
    Conversation_Button_Edit: '编辑对话',
    Conversation_First_Messager: '成为第一个写消息的人',
    Conversation_Loding_Messages_1: '加载中...',
    Conversation_Loding_Messages_2: '加载以前的消息',
    Conversation_Message_Removed: '消息已删除',
    Conversation_Message_Edited: '（已编辑）',
    Conversation_Content_Placeholder: '写消息...',
    // NEWS COMPONENT
    News_Meta_Title: '最新新闻和时事',
    News_No_News_Title: '没有新闻',
    // MAKEPAYMENT MODAL
    MakePayment_Transactions: '交易',
    MakePayment_Transaction: '交易',
    MakePayment_To_Sign: '待签名',
    MakePayment_Wallets: '钱包',
    MakePayment_Wallet: '钱包',
    MakePayment_Need_PM: '您需要支付方式才能使用此功能',
    MakePayment_Create_Wallet: '创建您的钱包',
    MakePayment_Need_Wallet_1: '您需要一个钱包',
    MakePayment_Need_Wallet_2: '和一个支付方式',
    MakePayment_Need_Wallet_3: '才能使用此功能',
    // SCANQRCODE FEATURE
    ScanQRCode_Scanned: '已扫描!',
    ScanQRCode_Scan_QRCode: '扫描二维码',
    ScanQRCode_Scan_Off: '扫描仪当前不可用',
    // SHOWQRCODE FEATURE
    ShowQRCode_Source: '支付来源',
    // WALLETS COMPONENT
    Wallets_Meta_Title: '钱包',
    Wallets_SC_Updated: '消费码已更新!',
    Wallets_How_To: '如何使用我的钱包?',
    Wallets_Edit_SC: '更改消费码',
    Wallets_New_Wallet: '新钱包',
    Wallets_Exchanges: '交换',
    Wallets_Wallets_Title: '钱包',
    Wallets_Wallet_Title: '钱包',
    Wallets_Own_By: '拥有者',
    Wallets_Tab_Personal: '个人的',
    Wallets_NB_Payment_Method: '支付方式',
    // WALLET COMPONENT
    Wallet_Meta_Title: '钱包',
    Wallet_Meta_Recommened: '（强烈推荐）',
    Wallet_How_To: '如何使用我的钱包?',
    Wallet_Edit_Button: '编辑钱包',
    Wallet_Delete_Button: '删除钱包',
    Wallet_Lock_Button: '暂时锁定钱包',
    Wallet_Own_By: '拥有者',
    Wallet_Default: '（默认）',
    Wallet_Lipay_Info: '我们强烈建议创建一个Lipay账户，因为它提供了经济实惠的全球支付选项和附加功能。',
    Wallet_Unavailable_Title: '暂时不可用',
    Wallet_Unavailable_Label: '您的钱包不可用，请等待我们的电子邮件/通知。',
    Wallet_KYC_Wait_Title: 'KYC验证',
    Wallet_KYC_Wait_Label_1: '您的钱包正在审批中,',
    Wallet_KYC_Wait_Label_2: '请等待我们的电子邮件/通知。',
    Wallet_KYC_Wait_Label_3: '已创建',
    Wallet_KYC_Failed_Title: 'KYC验证失败',
    Wallet_KYC_Failed_Label_1: '您的钱包验证未成功,',
    Wallet_KYC_Failed_Label_2: '请在进行新验证之前核对您的信息。如果您的验证失败超过3次，请通过contact@thebhub.io联系我们。',
    Wallet_Button_New_KYC: '新钱包验证',
    Wallet_New_KYC_Title: '的新验证',
    Wallet_New_BC: '添加银行卡',
    Wallet_New_BA: '新Lipay账户',
    Wallet_New_PM: '新支付方式',
    // PAYMENTMETHOD COMPONENT
    PaymentMethod_Meta_Title: '支付方式',
    PaymentMethod_All_Tx: '所有交易',
    PaymentMethod_Tx_Me: '我发起的交易',
    PaymentMethod_Tx_Others: '他人发起的交易',
    PaymentMethod_Lock: '暂时锁定支付方式',
    PaymentMethod_Lock_Label: '您确定要锁定',
    PaymentMethod_Unlock: '解锁支付方式',
    PaymentMethod_Unlock_Label: '您确定要解锁',
    PaymentMethod_Delete: '删除支付方式',
    PaymentMethod_Select_Default: '设为默认',
    PaymentMethod_Copied: '已复制!',
    PaymentMethod_Title: '（支付方式）',
    PaymentMethod_Default: '（默认支付方式）',
    PaymentMethod_Button_Unlock: '解锁',
    PaymentMethod_Button_Lock: '锁定',
    PaymentMethod_Is_Lock: '已锁定。',
    PaymentMethod_Is_Unlock: '已解锁。',
    // ALLTRANSACTIONS COMPONENT
    AllTransactions_Received: '收到',
    AllTransactions_Sent: '发送',
    AllTransactions_No_Tx: '无交易',
    // JOINTEAM PAGE
    Join_Team_Meta_Title: '加入团队',
    Join_Team_Title: '加入团队',
    Join_Team_Button_Join: '加入',
    Join_Team_Button_Sent: '请求已发送',
    Join_Team_Button_New_Team: '新团队',
    // VIEWTEAMLINEUP MODAL
    ViewTeamLineupModal_Request_Sent: '请求已发送！',
    ViewTeamLineupModal_Request_Already_Sent: '你已经发送了请求',
    ViewTeamLineupModal_Button_Join_Team: '加入球队',
    ViewTeamLineupModal_Button_Sending_Request: '发送请求...',
    // ADDTEAMLINEUP MODAL
    AddTeamLineupModal_Title: '新的团队阵容',
    AddTeamLineupModal_Name_Label: '名称',
    AddTeamLineupModal_No_Teammate_Selected: '未选择队友',
    AddTeamLineupModal_Players_Label: '组建你的新阵容',
    AddTeamLineupModal_Players_Placeholder: '输入团队别名',
    AddTeamLineupModal_Captains_Label: '选择你的队长',
    AddTeamLineupModal_Captains_Placeholder: '输入玩家别名',
    AddTeamLineupModal_Button_Create: '创建阵容',
    // ADDTEAMPLAYER MODAL
    AddTeamPlayerModal_Title: '添加新团队玩家',
    AddTeamPlayerModal_Name_Label: '名称',
    AddTeamPlayerModal_No_Player_Selected: '未选择玩家',
    AddTeamPlayerModal_Players_Label: '玩家',
    AddTeamPlayerModal_Players_Placeholder: '输入玩家别名',
    AddTeamPlayerModal_Button_Create: '添加玩家',
    // LISTCONVERSATIONSMESSENGER COMPONENT
    ListConversationsMessenger_All_Contacts_Label: '联系人',
    ListConversationsMessenger_All_Contacts_Placeholder: '输入姓名',
    ListConversationsMessenger_Search_Conversations_Placeholder: '搜索所有对话',
    ListConversationsMessenger_No_Conversations: '没有对话',
    // NEWCONVERSATIONMESSENGER COMPONENT
    NewConversationMessenger_Title: '新对话',
    NewConversationMessenger_Title_Label: '标题',
    NewConversationMessenger_Description_Label: '主题（可选）',
    NewConversationMessenger_Description_Placeholder: '对话的主题/目的是什么',
    NewConversationMessenger_Description_Helper_1: '备注必须至少包含0个字符',
    NewConversationMessenger_Description_Helper_2: '存在未授权的字符',
    NewConversationMessenger_Author_Label: '加入为',
    NewConversationMessenger_All_Participants_Label: '参与者',
    NewConversationMessenger_All_Participants_Placeholder: '选择参与者',
    NewConversationMessenger_No_Participants: '未选择参与者',
    NewConversationMessenger_Button_View_Conversation: '查看现有对话',
    NewConversationMessenger_Button_Start_Conversation: '开始对话',
    // CONVERSATIONMESSENGER COMPONENT
    ConversationMessenger_Sender_As: '作为',
    ConversationMessenger_Edited_Msg: '(已编辑)',
    ConversationMessenger_Removed_Msg: '消息已删除',
    ConversationMessenger_Write_Msg: '写消息...',
    // ADDNEWTEAMMANAGER MODAL
    AddTeamManagerModal_Title: '添加经理',
    AddTeamManagerModal_No_Manager_Selected: '未选择经理',
    AddTeamManagerModal_Players_Label: '经理',
    AddTeamManagerModal_Players_Placeholder: '输入玩家用户名',
    AddTeamManagerModal_Button_Create: '添加经理',
    // TEAMS PAGE
    Teams_Meta_Title: '队伍',
    Teams_New_Team: '创建你的队伍',
    Teams_Button_New_Team: '新队伍',
    Teams_Verify_Email: '要创建队伍，您必须验证您的电子邮件',
  },
};

{
  /* <FormattedMessage id="" />&nbsp; */
}

import React from 'react';
import { useMutation, gql } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import { Chip, Autocomplete, Button, Avatar, Stack, TextField, MenuItem, Grid, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Clear } from '@mui/icons-material';
import { FlexIcon, LogoLilenekIcon } from '../../img/icons/Icons';
import { useAppSelector } from '../../redux/hooks';
import { selectSession } from '../../reducers/sessionSlice';

import BackButton from '../../component-modals/common/BackButton';
import { ARRAY_AS_ANY, IMAGE_LIST_AS_ANY, MEDIAS_AS_ANY } from '../../utilities/CommonInterfaces';
import UploadIMGFeature from '../../component-modals/common/UploadIMGFeature';
import { handleUpload } from '../../helpers';
import { selectPlayer } from '../../reducers/playerSlice';
import { useIntl } from 'react-intl';

interface IFormNewFlex {
  flexer?: { id?: string };
}

const FormNewFlex: React.FC = () => {
  const CREATE_FLEX_MUTATION = gql`
    mutation createFlex($flex: inputCreateFlex!) {
      createFlex(flex: $flex) {
        id
        short_id
      }
    }
  `;

  const SEND_CONNECT_COMPANY_NOTIFICATION_MUTATION = gql`
    mutation sendNotification($notification: inputSendNotification!) {
      sendNotification(notification: $notification)
    }
  `;

  const location: any = useLocation();
  const state: IFormNewFlex = location?.state;

  const loggedPlayer = useAppSelector(selectPlayer);
  const session = useAppSelector(selectSession);
  const intl = useIntl();

  const { control, watch, setValue } = useForm({
    defaultValues: {
      caption: '',
      pictures: MEDIAS_AS_ANY,
      pictures_urls: ARRAY_AS_ANY,
      flexer_id: state?.flexer?.id ? state?.flexer?.id : '',
      all_authors: ARRAY_AS_ANY,
      co_authors: ARRAY_AS_ANY,
      products_flexed: ARRAY_AS_ANY,
      articles_flexed: ARRAY_AS_ANY,
      services_flexed: ARRAY_AS_ANY,
    },
  });
  const inputFields = watch();
  const navigate = useNavigate();

  const [progress, setProgress] = React.useState(0);
  const [pictures, setPictures] = React.useState<typeof IMAGE_LIST_AS_ANY>([]);
  const [flexUrls, setFlexUrls] = React.useState([]);

  const [createFlex, newFlex] = useMutation(CREATE_FLEX_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [sendNotification, isSent] = useMutation(SEND_CONNECT_COMPANY_NOTIFICATION_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });

  const ALL_FLEXERS = _.concat({
    id: loggedPlayer?.id,
    name: loggedPlayer?.username,
    avatar: loggedPlayer?.avatar?.path,
    type: loggedPlayer?.__typename,
  });
  const ALL_CO_FLEXERS = _.concat(
    loggedPlayer?.followers?.map((f: any) => {
      return {
        id: f?.id,
        name: f?.username,
        avatar: f?.avatar?.path,
        type: f?.__typename,
      };
    })
  );

  const onChangePictures = async (imageList: typeof IMAGE_LIST_AS_ANY) => {
    setValue('pictures', imageList);
    setPictures(imageList);
  };

  const handleCoPublishers = (event: any, value: any) => {
    const newList = _.concat(value, inputFields.co_authors);
    setValue('co_authors', newList);
  };

  const handleDeleteCoPublisher = (publisher_id: string) => {
    const newList = inputFields.co_authors.filter((a: any) => a?.id !== publisher_id);
    setValue('co_authors', newList);
  };

  const handleCreateFlex = async () => {
    try {
      setProgress(1);

      const pictureUploadPromises = pictures.map(async (pix: any) => {
        await handleUpload(pix.file, flexUrls, setFlexUrls, session, loggedPlayer.id);
      });
      await Promise.all(pictureUploadPromises);

      const result = await createFlex({
        variables: {
          flex: {
            short_id: nanoid(),
            caption: _.trim(inputFields.caption),
            author_id: inputFields.flexer_id,
            co_authors: inputFields.co_authors.map((a: any) => {
              return { id: a?.id, type: a?.type };
            }),
            pictures: pictures.map((p: any, index: number) => {
              return {
                filename: p.file?.name ? p.file?.name : p.file.filename,
                size: p.file.size,
                type: p.file.type,
                path: flexUrls[index],
              };
            }),
            products_flexed: inputFields.products_flexed,
            articles_flexed: inputFields.articles_flexed,
            services_flexed: inputFields.services_flexed,
          },
        },
      });

      if (result.data?.createFlex !== undefined) {
        if (inputFields.co_authors?.length > 0) {
          inputFields.co_authors.map(
            async (author: any) =>
              await sendNotification({
                variables: {
                  notification: {
                    title: `${intl.formatMessage({ id: 'Notification_New_Flex_Title' })}`,
                    content: `${ALL_FLEXERS.find((c: any) => c?.id === inputFields.flexer_id)?.name} ${intl.formatMessage({ id: 'Notification_New_Flex_Content_1' })}`,
                    actions: [`ACCEPT_FLEX_CO_PUBLISH_@_${result.data?.createFlex?.id}_&_${author?.id}`, `VIEW_FLEX_@_${result.data?.createFlex?.short_id}`],
                    sender_id: inputFields.flexer_id,
                    receivers_ids: [author?.id],
                  },
                },
              })
          );
        }
      }

      setPictures([]);
      setFlexUrls([]);

      navigate(`/f/${result.data?.createFlex?.short_id}`, {
        replace: true,
      });
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    (async () => {
      if (inputFields.flexer_id === '') setValue('flexer_id', ALL_FLEXERS[0]?.id);
    })();
  }, [inputFields, setValue, ALL_FLEXERS]);

  return (
    <Grid container className={'form-page'} sx={{ p: '0 0 100px 0' }}>
      <Grid item xs={12} md={12} lg={12}>
        <Grid item xs={12} md={12} lg={12} sx={{ textAlign: 'center' }}>
          <LogoLilenekIcon sx={{ width: '150px', height: 'auto', p: '20px 0 0 0' }} />
        </Grid>
        <BackButton color={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverBGColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15,15,15,.04)'} />

        <Typography id="max-width" variant="h1" sx={{ fontSize: { xs: '1.4em', sm: '1.6em', md: '2em' }, p: '0 10%' }}>
          New Flex
        </Typography>

        <form className={'form-root'}>
          <UploadIMGFeature pictures={pictures} setter_pictures={onChangePictures} max_number={10} />

          <Grid id="max-width" sx={{ p: '30px 10% 0' }}>
            <Controller name="caption" control={control} rules={{ required: false }} render={({ field }: any) => <TextField {...field} label="Caption" fullWidth value={_.trimStart(field.value)} multiline rows={10} sx={{ mb: '20px' }} />} />

            <Controller
              name="flexer_id"
              control={control}
              render={({ field }: any) => (
                <TextField {...field} label={`Publish as ${ALL_FLEXERS.find((f: any) => f?.id === inputFields.flexer_id)?.name}`} select variant="outlined" fullWidth>
                  {ALL_FLEXERS.map((option: any) => (
                    <MenuItem key={option.id} value={option.id}>
                      <Grid
                        container
                        sx={{
                          justifyContent: 'start',
                          alignItems: 'center',
                        }}
                      >
                        <Avatar src={option?.avatar} />
                        &nbsp;&nbsp;
                        <strong>{option.name}</strong>
                      </Grid>
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            <Stack
              direction={'row'}
              sx={{
                display: 'inline-block',
                textAlign: 'center',
                p: '30px 0 0',
              }}
            >
              {inputFields.co_authors.map((f: any, index: number) => (
                <Chip key={index} variant="outlined" label={f?.name} avatar={<Avatar alt={f?.name} src={f?.avatar} />} onDelete={() => handleDeleteCoPublisher(f?.id)} sx={{ m: '5px' }} />
              ))}
            </Stack>
            <Controller
              name="all_authors"
              control={control}
              render={({ field }: any) => (
                <Autocomplete
                  {...field}
                  multiple
                  value={field.value}
                  autoHighlight
                  options={ALL_CO_FLEXERS.filter((cf: any) => inputFields.co_authors.find((a: any) => a?.id === cf?.id) === undefined)}
                  getOptionLabel={(option: any) => option?.name}
                  defaultValue={ARRAY_AS_ANY}
                  filterSelectedOptions
                  onChange={(event: any, value: any) => handleCoPublishers(event, value)}
                  renderOption={(props: any, option: any) => (
                    <Grid
                      container
                      key={option?.id}
                      onClick={(event: any) => handleCoPublishers(event, option)}
                      sx={{
                        alignItems: 'center',
                        p: '10px 15px',
                      }}
                    >
                      <Avatar src={option?.avatar} />
                      &nbsp;&nbsp;
                      <strong>{option?.name}</strong>
                    </Grid>
                  )}
                  renderInput={(params: any) => <TextField {...params} fullWidth label={`Co-publishers`} placeholder={`Write a name`} />}
                />
              )}
            />

            <Grid className={'actions'} sx={{ p: '50px 0 0' }}>
              <Button className={'button-cancel'} onClick={() => navigate(-1)} endIcon={<Clear />}>
                Cancel
              </Button>

              <LoadingButton onClick={handleCreateFlex} className={pictures?.length === 0 ? 'button-disabled' : 'button-green'} disabled={pictures?.length === 0} loading={newFlex.loading || isSent.loading || progress > 0} loadingPosition="end" endIcon={<FlexIcon />} sx={{ m: '0 10px' }}>
                Flex now
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default FormNewFlex;

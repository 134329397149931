import React from 'react';
import _ from 'lodash';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, CircularProgress, Grid, IconButton, Switch, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FivezerLogoIcon } from '../../img/icons/Icons';
import BackButton from '../../component-modals/common/BackButton';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { Delete, Send } from '@mui/icons-material';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import Error from '../../component-modals/common/Error';
import LoadingComponent from '../../component-modals/common/LoadingComponent';
import DeleteConfirmationModal from '../../component-modals/common/DeleteConfirmationModal';
import { Link } from 'react-router-dom';
import { selectSession } from '../../reducers/sessionSlice';
import { useIntl } from 'react-intl';

interface IFormEditChallenge {
  challenge: { id: string };
}

const FormEditChallenge: React.FC = () => {
  const EDIT_CHALLENGE_MUTATION = gql`
    mutation editChallenge($challenge: inputEditChallenge!) {
      editChallenge(challenge: $challenge) {
        id
        short_id
      }
    }
  `;

  const FIND_CHALLENGE_TO_EDIT_BY_ID_QUERY = gql`
    query findChallengeToEditByID($challenge: inputFindChallengeToEditByID!) {
      findChallengeToEditByID(challenge: $challenge) {
        id
        short_id
        title
        status
        type
        is_private
      }
    }
  `;

  const DELETE_CHALLENGE_MUTATION = gql`
    mutation deleteChallenge($challenge: inputDeleteChallenge!) {
      deleteChallenge(challenge: $challenge)
    }
  `;

  const intl = useIntl();
  const location: any = useLocation();
  const state: IFormEditChallenge = location?.state;
  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const [challenge, setChallenge] = React.useState(UNDEFINED_AS_ANY);
  const [openDeleteChallenge, setOpenDeleteChallenge] = React.useState(false);

  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      name: UNDEFINED_AS_ANY,
      is_private: UNDEFINED_AS_ANY,
    },
  });
  const inputFields = watch();
  const navigate = useNavigate();

  const [editChallenge, updatedChallenge] = useMutation(EDIT_CHALLENGE_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [deleteChallenge, deletedChallenge] = useMutation(DELETE_CHALLENGE_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedChallenge = useQuery(FIND_CHALLENGE_TO_EDIT_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      challenge: {
        id: state?.challenge?.id,
        logged_player_id: loggedPlayer?.id,
      },
    },
  });

  const handleEditChallenge = async () => {
    try {
      await editChallenge({
        variables: {
          challenge: {
            logged_player_id: loggedPlayer?.id,
            id: challenge?.id,
            title: inputFields.name,
            is_private: inputFields.is_private,
          },
        },
      });

      navigate(`/challenge/${challenge?.short_id}`, { replace: true });
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteChallenge = async () => {
    try {
      const result = await deleteChallenge({
        variables: {
          challenge: {
            id: challenge?.id,
            logged_player_id: loggedPlayer?.id,
          },
        },
      });

      if (result.data?.deleteChallenge > 0) {
        navigate(`/teams`, { replace: true });
      }
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (!challenge && loadedChallenge.data?.findChallengeToEditByID) {
      setChallenge(loadedChallenge.data?.findChallengeToEditByID);
    }

    if (challenge) {
      if (!inputFields.name && challenge?.title) setValue('name', challenge?.title);
      if (inputFields.is_private === undefined) setValue('is_private', !challenge?.is_private ? false : true);
    }
  }, [challenge, loadedChallenge]);

  return (
    <Grid container className={'form-page component-challenge new-challenge-page'} sx={{ p: '0 0 100px 0' }}>
      <Grid item xs={12} md={12} lg={12}>
        <Grid item xs={12} md={12} lg={12} sx={{ textAlign: 'center' }}>
          <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' }, 'pt': '20px' }}>
            <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '40px', sm: '50px', md: '60px' }, p: '0 0' }} />
          </IconButton>
        </Grid>
        <BackButton color={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverBGColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15,15,15,.04)'} />
        {challenge === undefined || loadedChallenge.loading ? (
          <Grid item xl={12} xs={12} sm={12} sx={{ p: '0 10%' }}>
            {challenge === undefined && loadedChallenge.called && !loadedChallenge.loading ? <Error title={`challenge`} status={loadedChallenge.error ? 403 : 404} content={loadedChallenge.error ? '' : `We are sorry but it seems like we can not find this challenge`} /> : ''}

            {loadedChallenge.loading && (challenge === undefined || !loadedChallenge.error) ? <LoadingComponent amount={1} text={<Typography sx={{ mb: '20px' }}>Loading challenge, please wait...</Typography>} loader={<CircularProgress className="orange-loader" />} /> : ''}
          </Grid>
        ) : (
          <>
            <Typography className={'title'} sx={{ p: '0 10%', fontSize: '40px', fontWeight: '800' }}>
              {`${intl.formatMessage({ id: 'FormEditChallenge_Title' })}`}
            </Typography>

            <form className={'form-root'}>
              <Grid id="max-width" sx={{ p: '20px 10% 0' }}>
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 30,
                    minLength: 4,
                    pattern: /^[^a-zA-Z0-9_\s.'-]*$/,
                  }}
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      label={`${intl.formatMessage({ id: 'FormNewChallenge_Name_Label' })}`}
                      placeholder={challenge?.name}
                      variant="outlined"
                      fullWidth
                      required
                      className={'field-bottom-space'}
                      value={_.replace(field.value, /[^a-zA-Z0-9_\s.'-]+/g, ' ')}
                      error={errors?.name?.type !== undefined}
                      helperText={errors?.name !== undefined ? `${errors?.name?.type ? (field.value.length < 4 ? `${intl.formatMessage({ id: 'FormNewChallenge_Name_1' })}` : `${intl.formatMessage({ id: 'FormNewChallenge_Name_2' })}`) : ``}` : ''}
                    />
                  )}
                />

                <Typography id="max-width" variant="h3" sx={{ fontSize: '1.4em', fontWeight: '700', p: '40px 0 20px' }}>
                  {`${intl.formatMessage({ id: 'FormNewChallenge_Privacy_Title' })}`}
                </Typography>

                <Typography>{inputFields.is_private ? `${intl.formatMessage({ id: 'FormNewChallenge_Privacy_Caption_1' })}` : `${intl.formatMessage({ id: 'FormNewChallenge_Privacy_Caption_2' })}`}</Typography>

                <Controller
                  name="is_private"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field }: any) => <Switch {...field} checked={field.value} onChange={(event: any, value: any) => setValue('is_private', value)} inputProps={{ 'aria-label': 'controlled' }} />}
                />
              </Grid>

              <Grid id="max-width" sx={{ p: '20px 10% 0' }}>
                <Button className={updatedChallenge.loading ? 'button-disabled' : 'button-red-reversed'} disabled={updatedChallenge.loading} endIcon={<Delete />} onClick={() => setOpenDeleteChallenge(true)} sx={{ mr: '10px' }}>
                  {`${intl.formatMessage({ id: 'Global_Button_Delete' })}`}
                </Button>

                <Button onClick={() => navigate(-1)} className={updatedChallenge.loading ? 'button-disabled' : 'button-cancel'} sx={{ mr: '10px' }}>
                  {`${intl.formatMessage({ id: 'Global_Button_Cancel' })}`}
                </Button>
                <LoadingButton onClick={handleEditChallenge} loading={updatedChallenge.loading} loadingPosition="end" endIcon={<Send />} className={inputFields.name === '' ? 'button-disabled' : 'button-green'} disabled={inputFields.name === ''} sx={{ mr: '10px' }}>
                  {`${intl.formatMessage({ id: 'Global_Button_Save_Updates' })}`}
                </LoadingButton>
              </Grid>
            </form>

            <DeleteConfirmationModal title={`${intl.formatMessage({ id: 'Global_Permanent_Delete_Title' })} ${challenge?.title}`} open={openDeleteChallenge} entityId={challenge?.id} entityName={`${challenge?.title}`} loading={deletedChallenge.loading} actionOne={handleDeleteChallenge} actionOneTitle={`${intl.formatMessage({ id: 'Global_Button_Delete' })}`} actionTwo={() => setOpenDeleteChallenge(false)} actionTwoTitle={`${intl.formatMessage({ id: 'Global_Button_Cancel' })}`} />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default FormEditChallenge;

import React from 'react';
import { Avatar, Card, CardHeader, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useAppSelector } from '../redux/hooks';
import { selectPlayer } from '../reducers/playerSlice';
import { Share } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { FivezerLogoIcon } from '../img/icons/Icons';
import { gql, useQuery } from '@apollo/client';
import { ARRAY_AS_ANY } from '../utilities/CommonInterfaces';
import Moment from 'react-moment';
import _ from 'lodash';
import MenuButton from '../component-modals/layout/MenuButton';
import LoadingComponent from '../component-modals/common/LoadingComponent';
import TopBarActionsButton from '../component-modals/layout/TopBarActionsButton';
import ShareURLButton from '../component-modals/common/ShareURLButton';
import { selectSession } from '../reducers/sessionSlice';
import { useIntl } from 'react-intl';

const Feeds: React.FC = () => {
  const FIND_NEWS_BY_OPTIONS_QUERY = gql`
    query findNewsByOptions($options: inputFindNewsByOptions!) {
      findNewsByOptions(options: $options) {
        id
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const intl = useIntl();
  const [news, setNews] = React.useState(ARRAY_AS_ANY);
  const [offset] = React.useState(0);

  if (news?.length === 0) {
    fetch('https://free-football-soccer-videos.p.rapidapi.com/', {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': 'pcSRu8EJskmshvFH2v1xbneOCTItp1AwPMGjsnLaf6MfsLhTJU',
        'X-RapidAPI-Host': 'free-football-soccer-videos.p.rapidapi.com',
      },
    })
      .then((response) => response.json())
      .then((result: any) => setNews(_.slice(result, 0, 21)));
  }

  const loadedNews = useQuery(FIND_NEWS_BY_OPTIONS_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      options: {
        offset: offset,
      },
    },
  });

  return (
    <Grid className={`component-team team-page ${loggedPlayer?.app_configuration?.is_dark_mode ? 'dark-mode' : 'light-mode'}`} sx={{ p: '0 0 150px', minHeight: '100%' }}>
      <Helmet>
        <title>{`${intl.formatMessage({ id: 'News_Meta_Title' })}`} x Fivezer #DifferentAnimalSameBeast</title>
        <meta name="description" content={''} />
        <link rel="canonical" href={`${window.location.origin}` + window.location.pathname} />
      </Helmet>

      <Grid container>
        <Grid item xs={4} md={4} lg={4} sx={{ textAlign: 'center' }}></Grid>
        <Grid item xs={4} md={4} lg={4} sx={{ textAlign: 'center' }}>
          <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
            <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '50px', sm: '60px', md: '70px' }, p: '20px 0 0 0' }} />
          </IconButton>
        </Grid>
        <Grid item xs={4} md={4} lg={4} sx={{ position: 'relative', zIndex: '100000', display: 'inline-flex', justifyContent: 'right', alignItems: 'center', p: '20px 43px' }}>
          <TopBarActionsButton />
          <MenuButton />
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} lg={12} sx={{ position: 'relative', zIndex: '100' }}>
        {loadedNews.loading ? (
          <LoadingComponent amount={1} text={<Typography sx={{ mb: '20px' }}>{`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}</Typography>} loader={<CircularProgress className="orange-loader" />} />
        ) : (
          <>
            {news?.length === 0 ? ( // || loadedNews.error
              <Typography
                variant="h3"
                sx={{
                  p: '50px 20px 0',
                  fontSize: '1.3em',
                  fontWeight: '100',
                  textAlign: 'center',
                }}
              >
                {`${intl.formatMessage({ id: 'News_No_News_Title' })}`}
              </Typography>
            ) : (
              <Grid container sx={{ p: { xs: '0', sm: '0' }, overflowY: 'auto', height: '90vh' }}>
                {news?.map((n: any, index: number) => (
                  <Grid key={index} item xs={12} sm={6} md={4} sx={{ p: '0', m: '10px 0' }}>
                    <Card sx={{ p: { xs: '0 0 20px', sm: '0 5% 20px' }, borderRadius: '10px', bgcolor: 'rgba(247, 251, 250, 0)', boxShadow: 'none' }}>
                      <CardHeader
                        avatar={<Avatar src={n?.thumbnail} alt={n?.title} />}
                        action={
                          <ShareURLButton
                            button={
                              <IconButton aria-label="settings">
                                <Share />
                              </IconButton>
                            }
                            url={window.location.href}
                            title={n?.title}
                            text={n?.title}
                          />
                        }
                        title={n?.title}
                        titleTypographyProps={{ color: 'rgba(247, 251, 250, 1)', fontWeight: '800' }}
                        subheader={<Moment fromNow>{n?.date}</Moment>}
                        subheaderTypographyProps={{ color: 'rgba(247, 251, 250, .4)' }}
                      />

                      <Grid dangerouslySetInnerHTML={{ __html: n?.videos[0]?.embed }} sx={{ 'height': '70vh', '& iframe': { height: '70vh !important' } }} />
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Feeds;

import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Divider, Fab, Grid, Card, CardHeader, Avatar, Button, Stepper, Step, StepButton, StepContent, Box, Stack, TextField, InputAdornment, IconButton, MenuItem, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Close, VisibilityOff, Visibility, Delete, ExpandMore } from '@mui/icons-material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { ARRAY_AS_ANY, UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { ALTERNATIVE_PAYMENTS, CARD_NETWORKS, DEPOSIT_TOKEN_REG, LIPAY_PAYMENT_OPTIONS, PAYMENT_METHODS, PRICE_IN_MAAT, TRANSACTION_REG } from '../../utilities/utilities';
import { Controller, useForm } from 'react-hook-form';
import { selectPersonalWallets } from '../../reducers/walletSlice';
import { customAlphabet } from 'nanoid';
import { selectSession } from '../../reducers/sessionSlice';
import SpendingCodeModal from './SpendingCodeModal';

export default function PayTournamentSeatSessionModal(props: { sessions_ids: string[]; tournament_id?: string; handleClose: any; open: any }) {
  const FIND_TOURNAMENT_BY_ID_QUERY = gql`
    query findTournamentByID($id: ID!) {
      findTournamentByID(id: $id) {
        id
        cost_per_team {
          value
          currency {
            currency_code
          }
        }
        main_organisator {
          ... on Player {
            __typename
            id
            username
            avatar {
              filename
              path
            }
          }
          ... on SportOrganization {
            __typename
            id
            name
            company {
              id
              name
              logo {
                filename
                path
              }
            }
          }
        }
        organisators {
          ... on Player {
            __typename
            id
            username
            avatar {
              filename
              path
            }
          }
          ... on SportOrganization {
            __typename
            id
            name
            company {
              id
              name
              logo {
                filename
                path
              }
            }
          }
        }
        admins {
          id
          username
          avatar {
            filename
            path
          }
        }
        selected_teams {
          id
          name
          players {
            id
            status
            team_number
            team_player {
              ... on FootballTeam {
                __typename
                id
                name
              }
            }
            player {
              ... on Footballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                }
              }
            }
          }
          captains {
            id
            status
            team_number
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
          team {
            ... on FootballTeam {
              __typename
              id
              short_id
              name
              shortname
              avatar {
                path
              }
            }
          }
        }
        consumers {
          id
          player {
            id
            status
            team_player {
              ... on FootballTeam {
                __typename
                id
                name
              }
            }
            player {
              ... on Footballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                  username
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                  username
                }
              }
            }
          }
          transaction {
            id
            status
          }
        }
      }
    }
  `;

  const FIND_PAYMENT_METHODS_BY_COMPANY_ACCOUNT_ID_QUERY = gql`
    query findPaymentMethodsByCompanyAccountID($company: inputFindPaymentMethodsByCompanyAccountID!) {
      findPaymentMethodsByCompanyAccountID(company: $company) {
        id
        name
        label
        status
        payment_source {
          ... on BankCard {
            __typename
            id
            network
          }
          ... on BankAccount {
            __typename
            id
            title
          }
          ... on BlockchainCard {
            __typename
            id
            network
          }
          ... on BlockchainAccount {
            __typename
            id
            title
          }
          ... on Cash {
            __typename
            id
            name
          }
          ... on Gold {
            __typename
            id
            name
          }
          ... on Waste {
            __typename
            id
            name
          }
        }
        wallets {
          id
          title
          is_default
        }
      }
    }
  `;

  const CREATE_TRANSACTION_BY_SEAT_SESSION_ID_MUTATION = gql`
    mutation createTransactionBySeatSessionID($transaction: inputCreateTXBySeatSessionID!) {
      createTransactionBySeatSessionID(transaction: $transaction) {
        id
        purchase {
          ... on SeatSession {
            __typename
            id
          }
        }
      }
    }
  `;

  const EXECUTE_DIRECT_FIAT_PAYMENT_TRANSACTION_MUTATION = gql`
    mutation executeDirectFiatPaymentTransaction($transaction: inputExecuteDirectFiatTX!) {
      executeDirectFiatPaymentTransaction(transaction: $transaction)
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const loggedAccountPersonalWallets = useAppSelector(selectPersonalWallets);

  const [tournament, setTournament] = React.useState(UNDEFINED_AS_ANY);
  const [activeStep, setActiveStep] = React.useState(0);
  const [hidePassword, setHidePassword] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [isExpanded, setIsExpanded] = React.useState(true);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const min_code_length = 10;
  const tx_nanoid = customAlphabet(TRANSACTION_REG, 21);
  const deposit_token_nanoid = customAlphabet(DEPOSIT_TOKEN_REG, 15);

  const { control, watch, setValue, reset } = useForm({
    defaultValues: {
      consumers_to_pay: ARRAY_AS_ANY,
      total_seats_to_pay_in_mat: 0,
      total_orders_to_pay_in_mat: 0,
      payment_method_id: '',
      payment_option: 'MAT',
      spending_password: '',

      item_name: '',
      item_amount: 1,
      selected_item: UNDEFINED_AS_ANY,
      selected_personal_items: ARRAY_AS_ANY,
      all_items: ARRAY_AS_ANY,

      total_cost_sessions: 0,
    },
  });
  const inputFields = watch();

  const [executeDirectFiatPaymentTransaction, exectuedTransaction] = useMutation(EXECUTE_DIRECT_FIAT_PAYMENT_TRANSACTION_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [createTransactionBySeatSessionID, newTransaction] = useMutation(CREATE_TRANSACTION_BY_SEAT_SESSION_ID_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedTournament = useQuery(FIND_TOURNAMENT_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key || !props.tournament_id,
    variables: {
      id: props.tournament_id,
    },
  });
  const loadedPaymentMethods = useQuery(FIND_PAYMENT_METHODS_BY_COMPANY_ACCOUNT_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      company: {
        logged_account_id: loggedPlayer?.id,
      },
    },
  });
  const loadedOrgnisatorPaymentMethods = useQuery(FIND_PAYMENT_METHODS_BY_COMPANY_ACCOUNT_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key || !tournament?.main_organisator?.id,
    variables: {
      company: {
        logged_account_id: tournament?.main_organisator?.id,
      },
    },
  });

  const ALL_PAYMENT_METHODS = loadedPaymentMethods.data?.findPaymentMethodsByCompanyAccountID;
  const ALL_ORGANISATOR_PAYMENT_METHODS = loadedOrgnisatorPaymentMethods.data?.findPaymentMethodsByCompanyAccountID;

  const handlePayForThem = async (seat_session: any) => {
    try {
      const found_consumer = tournament?.consumers?.find((c: any) => c?.id === seat_session?.id);
      const result = await PRICE_IN_MAAT(tournament?.cost_per_team?.value / tournament?.selected_teams?.find((t: any) => t?.team?.id === found_consumer?.player?.team_player?.id)?.players?.length, tournament?.cost_per_team?.currency?.currency_code);
      setValue('total_seats_to_pay_in_mat', inputFields.total_seats_to_pay_in_mat + result);

      const newList = _.concat(seat_session?.id, inputFields.consumers_to_pay);
      setValue('consumers_to_pay', newList);
    } catch (e) {
      console.error(e);
    }
  };

  const handleRemoveConsumer = async (seat_session: any) => {
    try {
      const found_consumer = tournament?.consumers?.find((c: any) => c?.id === seat_session?.id);
      const result = await PRICE_IN_MAAT(tournament?.cost_per_team?.value / tournament?.selected_teams?.find((t: any) => t?.team?.id === found_consumer?.player?.team_player?.id)?.players?.length, tournament?.cost_per_team?.currency?.currency_code);
      setValue('total_seats_to_pay_in_mat', inputFields.total_seats_to_pay_in_mat - result);

      const newList = inputFields.consumers_to_pay?.filter((id: string) => id !== seat_session?.id);
      setValue('consumers_to_pay', newList);
    } catch (e) {
      console.error(e);
    }
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleRemovePersonalItem = async (item_id: string) => {
    try {
      const found_item = inputFields.selected_personal_items?.find((i: any) => i?.item?.id === item_id);
      const removed_value = await PRICE_IN_MAAT(found_item?.amount * found_item?.item?.price?.value, found_item?.item?.price?.currency?.currency_code);

      setValue('total_orders_to_pay_in_mat', inputFields.total_orders_to_pay_in_mat - removed_value);

      const newList = inputFields.selected_personal_items?.filter((i: any) => i?.item?.id !== item_id);
      setValue('selected_personal_items', newList);
    } catch (e) {
      console.error(e);
    }
  };

  const handlePaySportSession = async (isVerified: boolean) => {
    try {
      if (isVerified) {
        setProgress(1);

        if (inputFields.spending_password?.length > 0 && loggedAccountPersonalWallets?.length > 0) {
          inputFields.consumers_to_pay?.map(async (seat_session_id: string) => {
            const sender_payment_method = ALL_PAYMENT_METHODS?.find((pm: any) => pm?.id === inputFields.payment_method_id);
            const current_seat_session = tournament?.consumers?.find((s: any) => s?.id === seat_session_id);

            if (!newTransaction.called && sender_payment_method && ALL_ORGANISATOR_PAYMENT_METHODS && ALL_ORGANISATOR_PAYMENT_METHODS?.length > 0) {
              try {
                const result = await createTransactionBySeatSessionID({
                  variables: {
                    transaction: {
                      seat_session_id: seat_session_id,
                      team_player_id: current_seat_session?.player?.id,
                      tx_reference: `Tx-${tx_nanoid()}-${Date.now()}`,
                      note_reference: inputFields.consumers_to_pay
                        ?.filter((i: string) => i)
                        ?.map((consumer_id: string) => `${consumer_id},`)
                        ?.toString(),
                      type: 'PAYMENT',
                      lipay_payment_option: sender_payment_method?.name === 'LIPAY' ? sender_payment_method?.payment_source?.name : undefined,
                      deposit_token: sender_payment_method?.name === 'LIPAY' ? deposit_token_nanoid() : undefined,
                      original_value: {
                        value: parseFloat(((inputFields.total_seats_to_pay_in_mat > 0 ? inputFields.total_seats_to_pay_in_mat : 0) + inputFields.total_orders_to_pay_in_mat)?.toString()),
                        currency: 'MAT',
                      },
                      maat_value: {
                        value: parseFloat(((inputFields.total_seats_to_pay_in_mat > 0 ? inputFields.total_seats_to_pay_in_mat : 0) + inputFields.total_orders_to_pay_in_mat)?.toString()),
                        currency: 'MAT',
                      },
                      tx_fee: {
                        value: 0,
                        currency: 'MAT',
                      },
                      assets: [],
                      sender_wallet_id: sender_payment_method?.wallets?.find((w: any) => w?.is_default === true)?.id,
                      sender_payment_method_id: sender_payment_method?.id,
                      receiver_wallet_id: ALL_ORGANISATOR_PAYMENT_METHODS[0]?.wallets[0]?.id,
                      receiver_payment_method_id: ALL_ORGANISATOR_PAYMENT_METHODS[0]?.id,
                      signatories: [loggedPlayer?.id],
                      sender_payment_source_id: sender_payment_method?.payment_source?.id,
                      receiver_payment_source_id: ALL_ORGANISATOR_PAYMENT_METHODS[0]?.payment_source?.id,
                      execution_date: Date.now(),
                      tournament_id: tournament?.id,
                    },
                  },
                });

                if (result.data?.createTransactionBySeatSessionID?.id) {
                  // EXECUTE TRANSACTION
                  try {
                    await executeDirectFiatPaymentTransaction({
                      variables: {
                        transaction: {
                          logged_account_id: loggedPlayer?.id,
                          spending_password: inputFields.spending_password,
                          tx_data: {
                            id: result.data?.createTransactionBySeatSessionID?.id,
                            sender_payment_method_id: sender_payment_method?.id,
                            receiver_payment_method_id: ALL_ORGANISATOR_PAYMENT_METHODS[0]?.id,
                          },
                        },
                      },
                    });
                  } catch (e) {
                    console.log(e);
                  }
                }
              } catch (e) {
                console.log(e);
              }
            }
          });

          handleClose();
        }
      } else {
        console.log('Failed...');
      }
    } catch (e) {
      console.log(e);
    } finally {
      setActiveStep(0);
      setProgress(0);
      setOpenConfirmation(false);
    }
  };

  const handleClose = () => {
    reset();
    props.handleClose();
  };

  React.useEffect(() => {
    if (props.open && (!_.isMatch(tournament, loadedTournament.data?.findTournamentByID) || (tournament === undefined && loadedTournament.called && loadedTournament.data?.findTournamentByID))) {
      setTournament(loadedTournament.data?.findTournamentByID);
    }

    if (!props.open && tournament !== undefined) {
      setTournament(UNDEFINED_AS_ANY);
    }
  }, [tournament, loadedTournament, props]);

  const steps = [
    {
      label: 'Payment Method',
      content: (
        <>
          <Controller
            name="payment_method_id"
            control={control}
            rules={{
              required: false,
              pattern: /^[0-9a-zA-Z]*$/,
            }}
            render={({ field }: any) => (
              <TextField
                {...field}
                value={field.value}
                select
                fullWidth
                className={'field-bottom-space'}
                label="Payment method"
                placeholder="Payment method"
                variant="outlined"
                sx={
                  ALL_PAYMENT_METHODS?.find((pm: any) => pm?.payment_method?.id === inputFields.payment_method_id)?.payment_method?.name === 'LIPAY' && ALL_PAYMENT_METHODS?.find((pm: any) => pm?.payment_method?.id === inputFields.payment_method_id)?.payment_source?.__typename?.includes('Blockchain')
                    ? {
                        mr: {
                          xs: '0',
                          sm: '3%',
                          md: '3%',
                        },
                        width: {
                          xs: '100%',
                          sm: '64%',
                          md: '64%',
                        },
                      }
                    : {}
                }
              >
                {ALL_PAYMENT_METHODS?.filter((pm: any) => loggedAccountPersonalWallets?.find((w: any) => w?.id === pm?.wallets[0]?.id))?.map((option: any) => (
                  <MenuItem key={option?.id} value={option?.id}>
                    <Grid
                      container
                      sx={{
                        alignItems: 'center',
                      }}
                    >
                      {PAYMENT_METHODS?.find((pm) => pm?.name === option?.name)?.icon_white}
                      &nbsp;&nbsp;
                      <Typography sx={{ display: 'flex', alignItems: 'center', fontWeight: '700' }}>
                        {_.capitalize(option?.payment_source?.name || option?.payment_source?.title || option?.payment_source?.network).replaceAll('_', ' ')}&nbsp;
                        {option?.payment_source?.network ? CARD_NETWORKS?.find((n: any) => n?.name === option?.payment_source?.network)?.icon : ''}
                        {option?.payment_source?.name ? ALTERNATIVE_PAYMENTS?.find((n: any) => n?.name === option?.payment_source?.name)?.icon : ''}
                      </Typography>
                      &nbsp;&nbsp;
                      <Typography variant="caption">from {option?.wallets[0]?.title}</Typography>
                    </Grid>
                  </MenuItem>
                ))}
              </TextField>
            )}
          />

          {ALL_PAYMENT_METHODS?.find((pm: any) => pm?.payment_method?.id === inputFields.payment_method_id)?.payment_method?.name === 'LIPAY' && ALL_PAYMENT_METHODS?.find((pm: any) => pm?.payment_method?.id === inputFields.payment_method_id)?.payment_source?.__typename?.includes('Blockchain') ? (
            <Controller
              name="payment_option"
              control={control}
              rules={{
                required: false,
                pattern: /^[0-9a-zA-Z]*$/,
              }}
              render={({ field }: any) => (
                <TextField
                  {...field}
                  value={field.value}
                  select
                  fullWidth
                  className={'field-bottom-space'}
                  label="Lipay payment option"
                  placeholder="Payment option"
                  variant="outlined"
                  sx={{
                    ml: {
                      xs: '0',
                      sm: '3%',
                      md: '3%',
                    },
                    width: {
                      xs: '100%',
                      sm: '30%',
                      md: '30%',
                    },
                  }}
                >
                  {LIPAY_PAYMENT_OPTIONS.map((option: any) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      label: 'Confirmation',
      content: (
        <>
          <Controller
            name="spending_password"
            control={control}
            rules={{
              required: loggedAccountPersonalWallets.length === 0 ? true : false,
              minLength: min_code_length,
              maxLength: 255,
            }}
            render={({ field }: any) => (
              <Grid sx={{ m: '30px 0' }}>
                <Typography sx={{ textAlign: 'left', m: '10px 0' }}>Spending Password (min {min_code_length} characters)</Typography>
                <Grid item xs={12} md={12} lg={12} sx={{ textAlign: 'center' }}></Grid>
                <TextField
                  {...field}
                  label="Please enter your spending code"
                  type={!hidePassword ? 'password' : 'text'}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={() => setHidePassword(!hidePassword)} onMouseDown={handleMouseDownPassword} edge="end">
                          {!hidePassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}
          />
        </>
      ),
    },
  ];

  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: { 'minWidth': '70%', 'maxWidth': '80%', 'p': '20px 0px', 'bgcolor': 'rgba(15, 15, 15, 1)', '& p, & span': { color: 'rgba(247, 251, 250, 1)' } },
      }}
    >
      <Fab
        size="small"
        onClick={handleClose}
        disabled={exectuedTransaction.loading || progress > 0 || newTransaction.loading}
        className={exectuedTransaction.loading || progress > 0 || newTransaction.loading ? 'button-disabled' : 'button-cancel'}
        sx={{
          boxShadow: 'none',
          bgcolor: 'transparent',
          ml: '2%',
        }}
      >
        <Close />
      </Fab>
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', pb: '0', textTransform: 'uppercase' }}>
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>
          <FormattedMessage id="PaySportSessionModal_Pay" />
          &nbsp;
          <FormattedMessage id="PaySportSessionModal_Title" />
        </Typography>
        <Typography variant="caption">
          {tournament?.cost_per_team?.currency?.currency_code} {tournament?.cost_per_team?.value}/team
        </Typography>
      </DialogTitle>
      <Divider variant="middle" sx={{ bgcolor: 'rgba(247, 251, 250, 0.3)', width: '80%', m: 'auto' }} />
      <DialogContent sx={{ pl: '0', pr: '0' }}>
        <DialogContentText id="alert-dialog-description" component="span" sx={{ p: '0 0 20px' }}>
          <Typography sx={{ fontWeight: '700', fontSize: { xs: '2em', sm: '2.5em', md: '3em' }, textAlign: 'center', background: '-webkit-linear-gradient(280deg, rgba(19, 96, 13, 1) 0%, rgba(168, 202, 65, 1) 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>{/* MAT {tournament?.cost_per_team?.value / CONSUMER_DIVIDER} */}</Typography>
        </DialogContentText>
        <Grid sx={{ bgcolor: 'rgb(33,35,41)', p: '0px 5%', mt: '0px' }}>
          {activeStep === 0 ? (
            <>
              <Grid container sx={{ justifyContent: 'end' }}>
                <Grid item xs={11.5} sm={11} md={10} lg={10} sx={{ bgcolor: 'rgba(15,15,15,1)', p: '20px 0 20px 10%', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }}>
                  <Grid container>
                    <Accordion expanded={isExpanded} sx={{ 'width': '100%', 'boxShadow': 'none', '&.MuiPaper-root': { bgcolor: 'transparent !important' } }}>
                      <AccordionSummary onClick={() => setIsExpanded(!isExpanded)} expandIcon={<ExpandMore />} aria-controls="panel1-content" id="panel1-header" sx={{ pl: '0' }}>
                        <strong>
                          Selected {inputFields.consumers_to_pay?.length} {inputFields.consumers_to_pay?.length > 1 ? 'seats' : 'seat'}
                        </strong>
                      </AccordionSummary>

                      <AccordionDetails sx={{ overflow: 'auto', maxHeight: '300px' }}>
                        {tournament?.consumers?.map((c: any) => (
                          <Card key={c?.id} sx={{ bgcolor: 'transparent', boxShadow: 'none', mb: '10px' }}>
                            <CardHeader
                              title={c?.challenge?.title}
                              titleTypographyProps={{ fontWeight: '700', fontSize: '1.1em' }}
                              subheader={
                                <>
                                  <Grid sx={{ display: 'flex', alignItems: 'center', mb: '5px' }}>
                                    <Avatar src={c?.player?.player?.avatar?.path} />
                                    &nbsp;{c?.player?.player?.alias ?? c?.player?.player?.player?.username}
                                  </Grid>
                                  <Typography>
                                    {tournament?.cost_per_team?.currency?.currency_code} {tournament?.cost_per_team?.value / tournament?.selected_teams?.find((t: any) => t?.team?.id === c?.player?.team_player?.id)?.players?.length}
                                  </Typography>

                                  <Button className={inputFields.consumers_to_pay?.find((id: string) => id === c?.id) ? 'button-green-reverse' : 'button-disabled-reverse'} onClick={inputFields.consumers_to_pay?.find((id: string) => id === c?.id) ? () => handleRemoveConsumer(c) : () => handlePayForThem(c)} sx={{ fontSize: '.8em', pt: '2px', pb: '2px' }}>
                                    Pay this session
                                  </Button>
                                </>
                              }
                            />
                          </Card>
                        ))}
                      </AccordionDetails>
                    </Accordion>

                    <Grid container className="group-items">
                      {inputFields.selected_personal_items?.length > 0 ? (
                        <Typography sx={{ color: 'rgba(168, 202, 65, 1) !important', mt: '15px', textTransform: 'uppercase', fontSize: '.8em' }}>
                          <FormattedMessage id="PaySportSessionModal_Group_Snacks" />
                          {/* &nbsp;({sportSession?.cost?.currency?.currency_code} {_.sum(inputFields.selected_personal_items?.map((i: any) => i?.item?.price?.value)) / ALL_ORGANISATORS?.length} <FormattedMessage id="PaySportSessionModal_Per_Player" />) */}
                        </Typography>
                      ) : (
                        ''
                      )}
                      {inputFields.selected_personal_items?.map((item: any, index: number) => (
                        <Grid key={index} sx={{ width: '100%' }}>
                          <Grid container sx={{ alignItems: 'center' }}>
                            <Grid item xs={8} sm={8} md={8} lg={8} sx={{ textAlign: 'left' }}>
                              <Typography component="span" sx={{ display: 'flex', alignItems: 'center' }}>
                                <IconButton className="button-red-reversed" onClick={() => handleRemovePersonalItem(item?.item?.id)}>
                                  <Delete />
                                </IconButton>
                                <Avatar
                                  sx={{
                                    height: {
                                      xs: '30px !important',
                                      md: '40px !important',
                                      lg: '50px !important',
                                    },
                                    width: {
                                      xs: '30px !important',
                                      md: '40px !important',
                                      lg: '50px !important',
                                    },
                                    m: '5px 0',
                                  }}
                                  alt={item?.item?.name}
                                  src={item?.item?.pictures?.length > 0 ? item?.item?.pictures[0]?.path : undefined}
                                />
                                &nbsp;
                                <Typography sx={{ lineHeight: '15px' }}>
                                  {item?.item?.name}
                                  <br />
                                  <Typography variant="caption">
                                    ({item?.amount} x {item?.item?.price?.currency?.currency_code} {item?.item?.price?.value})
                                  </Typography>
                                </Typography>
                              </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} sx={{ textAlign: 'right', pr: '5%' }}></Grid>
                          </Grid>
                          {index === inputFields.selected_personal_items?.length - 1 || (inputFields.selected_personal_items?.length === 1 && index === 0) ? '' : <Divider sx={{ bgcolor: 'rgba(247, 251, 250, 0.3)', m: '5px 0' }} />}
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Divider sx={{ bgcolor: 'rgba(247, 251, 250, 0.3)', m: '10px 0' }} />

                  <Grid container>
                    <Grid item xs={3} sm={3} md={3} lg={3} sx={{ textAlign: 'left' }}>
                      <Typography>
                        <FormattedMessage id="PaySportSessionModal_Total" />
                      </Typography>
                    </Grid>
                    <Grid item xs={9} sm={9} md={9} lg={9} sx={{ textAlign: 'right', pr: '5%' }}>
                      <Typography sx={{ fontWeight: '700', fontSize: { xs: '1.2em', sm: '1.5em', md: '1.8em' }, background: '-webkit-linear-gradient(280deg, rgba(19, 96, 13, 1) 0%, rgba(168, 202, 65, 1) 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>MAT {inputFields.total_seats_to_pay_in_mat + inputFields.total_orders_to_pay_in_mat}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : (
            ''
          )}
          <Typography sx={{ fontWeight: '700', fontSize: '1.2em', p: '30px 10% 15px' }}>
            <FormattedMessage id="PaySportSessionModal_Payment_Method" />
          </Typography>

          <Grid container className={'dark-stepper'} sx={{ p: '0 5%' }}>
            <Stepper nonLinear activeStep={activeStep} orientation="vertical" sx={{ width: '100%' }}>
              {steps?.map((step: any, index: number) => (
                <Step key={step?.label}>
                  <StepButton
                    color="inherit"
                    onClick={handleStep(index)}
                    sx={{
                      display: activeStep >= index ? 'inline-block' : 'none',
                    }}
                  >
                    {step?.label}
                  </StepButton>
                  <StepContent>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      sx={{
                        display: activeStep >= index ? 'inline-block' : 'none',
                        width: '100%',
                      }}
                    >
                      {step?.content}
                    </Grid>
                    <Box sx={{ pt: '0px' }}>
                      <Stack direction={'row'}>
                        {activeStep === 0 || activeStep === steps.length - 1 ? (
                          ''
                        ) : (
                          <Button disabled={activeStep === 0} onClick={handleBack} className={'button-cancel'}>
                            <FormattedMessage id="PaySportSessionModal_Stepper_Back_Button" />
                          </Button>
                        )}
                        {activeStep === steps.length - 1 ? (
                          ''
                        ) : (
                          <Button onClick={handleNext} className={inputFields.spending_password.length < 10 && (loggedAccountPersonalWallets.length === 0 || inputFields.payment_method_id === '' || inputFields.consumers_to_pay?.length === 0) ? 'button-disabled' : 'button-green-reverse'} disabled={inputFields.spending_password.length < 10 && (loggedAccountPersonalWallets.length === 0 || inputFields.payment_method_id === '' || inputFields.consumers_to_pay?.length === 0)}>
                            {activeStep === steps.length - 1 ? <FormattedMessage id="PaySportSessionModal_Stepper_Finish_Button" /> : <FormattedMessage id="PaySportSessionModal_Stepper_Next_Button" />}
                          </Button>
                        )}
                      </Stack>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Grid>
      </DialogContent>
      {activeStep === 0 ? (
        ''
      ) : (
        <DialogActions sx={{ p: '20px 5% 30px', justifyContent: 'center' }}>
          <Button className={exectuedTransaction.loading || progress > 0 || newTransaction.loading ? 'button-disabled' : 'button-cancel'} disabled={exectuedTransaction.loading || progress > 0 || newTransaction.loading} onClick={handleBack}>
            <FormattedMessage id="PaySportSessionModal_Back_Button" />
          </Button>

          <Button onClick={() => setOpenConfirmation(true)} disabled={loggedAccountPersonalWallets?.length === 0 || inputFields.consumers_to_pay?.length === 0 || inputFields.spending_password === '' || inputFields.spending_password?.length < min_code_length} className={loggedAccountPersonalWallets?.length === 0 || inputFields.consumers_to_pay?.length === 0 || inputFields.spending_password === '' || inputFields.spending_password?.length < min_code_length ? 'button-disabled' : 'button-green'}>
            <FormattedMessage id="PaySportSessionModal_Proceed_Pay_Button" />
          </Button>

          <SpendingCodeModal icon={undefined} actionOne={handlePaySportSession} actionTwo={() => setOpenConfirmation(false)} open={openConfirmation} />
        </DialogActions>
      )}
    </Dialog>
  );
}

PayTournamentSeatSessionModal.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.any,
  content: PropTypes.any,
  secondaryContent: PropTypes.any,
  actionOneTitle: PropTypes.any,
  actionTwoTitle: PropTypes.any,
  actionOne: PropTypes.any,
  actionTwo: PropTypes.any,
  open: PropTypes.any,
};

PayTournamentSeatSessionModal.defaultProps = {
  secondaryContent: '',
  loading: false,
};

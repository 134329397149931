import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useQuery, gql, useMutation } from '@apollo/client';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { Fab, Typography, MenuItem, Menu, IconButton, Grid, CircularProgress, AvatarGroup, Avatar, AppBar, Tabs, Tab, useMediaQuery, Tooltip, Stack, Alert, Button } from '@mui/material';
import { MoreVert, ArrowBack, Add } from '@mui/icons-material';
import { BankCardsIcon, FivezerLogoIcon } from '../../img/icons/Icons';
import { TOURNAMENT_PLAYOFF_STAGES, TROPHY_RANKS } from '../../utilities/utilities';
import { useAppSelector } from '../../redux/hooks';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import LoadingComponent from '../../component-modals/common/LoadingComponent';
import { selectPlayer } from '../../reducers/playerSlice';
import MenuButton from '../../component-modals/layout/MenuButton';
import FormEditFieldModal from '../../component-modals/challenge/FormEditFieldModal';
import ShareURLButton from '../../component-modals/common/ShareURLButton';
import { selectSession } from '../../reducers/sessionSlice';
import { FormattedMessage, useIntl } from 'react-intl';
import TournamentPlayoffTable from '../../component-modals/tournament/TournamentPlayoffTable';
import TournamentGroupsTable from '../../component-modals/tournament/TournamentGroupsTable';
import SwipeableViews from 'react-swipeable-views';
import RegistrationTournamentModal from '../../component-modals/tournament/RegistrationTournamentModal';
import SelectTeamTournamentModal from '../../component-modals/tournament/SelectTeamTournamentModal';
import Modal from '../../component-modals/layout/Modal';
import AssignTournamentTrophyModal from '../../component-modals/tournament/AssignTournamentTrophyModal';
import PaySportSessionsModal from '../../component-modals/purchase/PaySportSessionsModal';
import PayTournamentSeatSessionModal from '../../component-modals/purchase/PayTournamentSeatSessionModal';
import { Helmet } from 'react-helmet';
import SelectDescriptionButton from '../../component-modals/layout/SelectDescriptionButton';

const Tournament: React.FC = () => {
  const FIND_TOURNAMENT_BY_SHORT_ID_QUERY = gql`
    query findTournamentByShortID($short_id: String!) {
      findTournamentByShortID(short_id: $short_id) {
        id
        short_id
        title
        status
        start_date
        end_date
        is_private
        descriptions {
          language
          content
        }
        cost_per_team {
          value
          currency {
            currency_code
          }
        }
        organisators {
          ... on Player {
            __typename
            id
            username
            avatar {
              filename
              path
            }
          }
          ... on SportOrganization {
            __typename
            id
            name
            company {
              id
              name
              logo {
                filename
                path
              }
            }
          }
        }
        admins {
          id
          username
          avatar {
            filename
            path
          }
        }
        main_location {
          id
          coordinates
          country_code
          street_name
          secondary_street_name
          postcode {
            name
          }
          state {
            name
          }
          city {
            name
          }
          country {
            name
          }
          continent {
            name
          }
        }
        trophies {
          id
          name
          note
          rank
          winners {
            ... on TeamPlayer {
              __typename
              id
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  avatar {
                    path
                  }
                  player {
                    id
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  avatar {
                    path
                  }
                  player {
                    id
                  }
                }
              }
            }
            ... on TeamLineup {
              __typename
              id
              name
            }
            ... on FootballTeam {
              __typename
              id
              name
              avatar {
                path
              }
            }
          }
        }
        team_candidates {
          id
          name
          players {
            id
            status
            team_number
            team_player {
              ... on FootballTeam {
                __typename
                id
                name
              }
            }
            player {
              ... on Footballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                }
              }
            }
          }
          captains {
            id
            status
            team_number
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
          team {
            ... on FootballTeam {
              __typename
              id
              short_id
              name
              shortname
              avatar {
                path
              }
            }
          }
        }
        selected_teams {
          id
          name
          players {
            id
            status
            team_number
            team_player {
              ... on FootballTeam {
                __typename
                id
                name
              }
            }
            player {
              ... on Footballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                }
              }
            }
          }
          captains {
            id
            status
            team_number
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
          team {
            ... on FootballTeam {
              __typename
              id
              short_id
              name
              shortname
              avatar {
                path
              }
            }
          }
        }
        consumers {
          id
          player {
            id
            status
            team_player {
              ... on FootballTeam {
                __typename
                id
                name
              }
            }
            player {
              ... on Footballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                  username
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                  username
                }
              }
            }
          }
          transaction {
            id
            status
          }
        }
        phase_groups {
          id
          name
          games {
            id
            short_id
            title
            status
            start_date
            end_date
            home_team {
              id
              name
            }
            away_team {
              id
              name
            }
            booking {
              id
              status
              start_date
              end_date
              host {
                id
                name
              }
              field {
                id
                name
              }
              cost {
                value
                currency {
                  currency_code
                }
              }
              payment_plan {
                id
                transactions {
                  id
                  status
                  tx_reference
                  value {
                    value
                    currency {
                      currency_code
                    }
                  }
                }
              }
            }
          }
        }
        phase_playoffs {
          id
          stage_name
          position
          game {
            id
            short_id
            title
            status
            start_date
            end_date
            home_team {
              id
              name
            }
            away_team {
              id
              name
            }
            complex {
              id
              name
              location {
                id
                title
                coordinates
                country_code
                street_name
                secondary_street_name
                status
                postcode {
                  name
                }
                city {
                  name
                }
                county {
                  name
                }
                state {
                  name
                }
                country {
                  name
                }
                continent {
                  name
                }
              }
            }
            arena {
              id
              name
              type
              size
              rank
              pictures {
                path
              }
              feedbacks {
                id
              }
              location {
                id
                title
                coordinates
                country_code
                street_name
                secondary_street_name
                status
                postcode {
                  name
                }
                city {
                  name
                }
                county {
                  name
                }
                state {
                  name
                }
                country {
                  name
                }
                continent {
                  name
                }
              }
            }
            booking {
              id
              status
              start_date
              end_date
              host {
                id
                name
              }
              field {
                id
                name
              }
              cost {
                value
                currency {
                  currency_code
                }
              }
              payment_plan {
                id
                transactions {
                  id
                  status
                  tx_reference
                  value {
                    value
                    currency {
                      currency_code
                    }
                  }
                }
              }
            }
          }
        }
        created_at
        updated_at
      }
    }
  `;

  const VALIDATE_TEAMS_SELECTION_IN_TOURNAMENT_MUTATION = gql`
    mutation validateTeamsSelectionInTournament($tournament: inputValidateTeamsSelectionInTournament!) {
      validateTeamsSelectionInTournament(tournament: $tournament)
    }
  `;

  const KICK_START_GROUPS_TOURNAMENT_MUTATION = gql`
    mutation kickStartGroupsTournament($tournament: inputKickStartTournament!) {
      kickStartGroupsTournament(tournament: $tournament)
    }
  `;

  const KICK_START_STAGES_TOURNAMENT_MUTATION = gql`
    mutation kickStartStagesTournament($tournament: inputKickStartTournament!) {
      kickStartStagesTournament(tournament: $tournament)
    }
  `;

  const { tournamentId }: any = useParams();
  const navigate = useNavigate();
  const intl = useIntl();

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);

  const [tournament, setTournament] = React.useState(UNDEFINED_AS_ANY);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [fullDescription, setFullDescription] = React.useState(false);
  const isSmallDesktop = useMediaQuery('(max-width:600px)');
  const [value, setIndexValue] = React.useState(0);
  const [openRegistration, setOpenRegistration] = React.useState(false);
  const [openTeamSelection, setOpenTeamSelection] = React.useState(false);
  const [openValidateTeams, setOpenValidateTeams] = React.useState(false);
  const [openAssignTrophy, setOpenAssignTrophy] = React.useState(false);
  const [openPaySportSession, setOpenPaySportSession] = React.useState(false);
  const [openPaySeatSession, setOpenPaySeatSession] = React.useState(false);
  const [openStartTournament, setOpenStartTournament] = React.useState(false);

  const { control, watch, setValue } = useForm({
    defaultValues: {
      description_language: '',
      selected_trophy: '',
    },
  });
  const inputFields = watch();

  const [kickStartStagesTournament, startedStagesTournament] = useMutation(KICK_START_STAGES_TOURNAMENT_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [kickStartGroupsTournament, startedGroupsTournament] = useMutation(KICK_START_GROUPS_TOURNAMENT_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [validateTeamsSelectionInTournament, validatedTournament] = useMutation(VALIDATE_TEAMS_SELECTION_IN_TOURNAMENT_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedTournament = useQuery(FIND_TOURNAMENT_BY_SHORT_ID_QUERY, {
    context: {
      headers: {
        'X-Anonymous-Access': 'true',
      },
    },
    variables: {
      short_id: tournamentId,
    },
    pollInterval: loggedPlayer?.id ? 300000 : undefined,
  });

  const ALL_GAMES = _.concat(
    tournament?.phase_groups?.map((p: any) => p?.games).flat(),
    tournament?.phase_playoffs?.map((p: any) => p?.game)
  )?.filter((i: any) => i);
  const ALL_SPORT_SESSIONS = ALL_GAMES?.map((g: any) => g?.booking)?.filter((i: any) => i);
  const PAYABLE_SPORT_SESSIONS = ALL_SPORT_SESSIONS?.filter((s: any) => s?.cost?.value > 0);
  const UNPAID_SEAT_SESSIONS = tournament?.consumers?.filter((c: any) => (!c?.transaction || c?.transaction?.status !== 'COMPLETED') && c?.player?.player?.player?.id === loggedPlayer?.id);

  const handleRefetch = async () => {
    try {
      const result = await loadedTournament.refetch({
        short_id: tournamentId,
      });

      if (result) {
        setTournament(result.data?.findTournamentByShortID);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setIndexValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setIndexValue(index);
  };

  const handleClosePaySportSession = async () => {
    try {
      setOpenPaySportSession(false);
      setOpenPaySeatSession(false);
    } catch (e) {
      console.error(e);
    } finally {
      await handleRefetch();
    }
  };

  const handleOpenSelectedTrophy = (selected_trophy: string) => {
    setValue('selected_trophy', selected_trophy);
    setOpenAssignTrophy(true);
  };

  const handleCloseSelectedTrophy = async () => {
    try {
      setValue('selected_trophy', '');
      await handleRefetch();
    } catch (e) {
      console.log(e);
    } finally {
      setOpenAssignTrophy(false);
    }
  };

  const handleCloseEdit = async () => {
    try {
      await handleRefetch();
    } catch (e) {
      console.log(e);
    } finally {
      setOpenEdit(false);
    }
  };

  const handleCloseRegistration = async () => {
    try {
      await handleRefetch();
    } catch (e) {
      console.log(e);
    } finally {
      setOpenRegistration(false);
    }
  };

  const handleCloseTeamSelection = async () => {
    try {
      await handleRefetch();
    } catch (e) {
      console.log(e);
    } finally {
      setOpenTeamSelection(false);
    }
  };

  const handleValidateTeamSelection = async () => {
    try {
      await validateTeamsSelectionInTournament({
        context: {
          headers: {
            'Authorization': `Bearer ${session?.token?.key}`,
            'X-Anonymous-Access': 'false',
          },
        },
        variables: {
          tournament: {
            id: tournament?.id,
            player_id: loggedPlayer?.id,
          },
        },
      });

      await handleRefetch();
    } catch (e) {
      console.error(e);
    }
  };

  const handleStartGroupsTournament = async () => {
    try {
      await kickStartGroupsTournament({
        context: {
          headers: {
            'Authorization': `Bearer ${session?.token?.key}`,
            'X-Anonymous-Access': 'false',
          },
        },
        variables: {
          tournament: {
            id: tournament?.id,
            logged_player_id: loggedPlayer?.id,
          },
        },
      });

      await handleRefetch();

      setOpenStartTournament(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handleStartStagesTournament = async () => {
    try {
      await kickStartStagesTournament({
        context: {
          headers: {
            'Authorization': `Bearer ${session?.token?.key}`,
            'X-Anonymous-Access': 'false',
          },
        },
        variables: {
          tournament: {
            id: tournament?.id,
            logged_player_id: loggedPlayer?.id,
          },
        },
      });

      await handleRefetch();

      setOpenStartTournament(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (!_.isMatch(tournament, loadedTournament.data?.findTournamentByShortID) || (tournament === undefined && loadedTournament.called && loadedTournament.data?.findTournamentByShortID)) {
      setTournament(loadedTournament.data?.findTournamentByShortID);
    }

    if (tournament?.descriptions !== undefined && tournament?.descriptions?.length > 0) {
      if (inputFields.description_language === '' && tournament?.descriptions[0]?.language !== undefined) setValue('description_language', tournament?.descriptions[0]?.language);
    }
  }, [tournament, loadedTournament, setValue, inputFields]);

  return (
    <Grid container className={'dark-mode'}>
      <Helmet>
        <title>{tournament ? `${tournament?.title}` : `${intl.formatMessage({ id: 'Tournament_Meta_Title' })}`} x Fivezer #DifferentAnimalSameBeast</title>
        <meta name="description" content={''} />
        <link rel="canonical" href={`${window.location.origin}` + window.location.pathname} />
      </Helmet>

      <Grid container sx={{ position: 'relative', zIndex: '10' }}>
        {_.concat(tournament?.organisators, tournament?.admins)?.find((a: any) => a?.id === loggedPlayer?.id) ? (
          <>
            {PAYABLE_SPORT_SESSIONS?.filter((s: any) => s?.status === 'PENDING' || s?.status === 'PAYMENT_OVERDUE')?.length > 0 ? (
              <>
                <Alert
                  severity="info"
                  variant="filled"
                  sx={{
                    'width': '100%',
                    'display': { xs: 'inline-block', sm: 'flex' },
                    'alignItems': 'center',
                    'textAlign': 'center',
                    '& .MuiAlert-icon': { display: { xs: 'inline', sm: 'flex' }, m: '0 12px' },
                    '& .MuiAlert-action': { display: { xs: 'block', sm: 'flex' }, p: { xs: '0', sm: 'inherit' } },
                  }}
                  action={
                    <Button className="button-blue" onClick={() => setOpenPaySportSession(true)} sx={{ whiteSpace: 'nowrap', fontWeight: '600', boxShadow: 'none', alignItems: 'center' }}>
                      <BankCardsIcon />
                      &nbsp;{`${intl.formatMessage({ id: 'Global_Button_Pay_Now' })}`}
                    </Button>
                  }
                >
                  {PAYABLE_SPORT_SESSIONS?.filter((s: any) => s?.status === 'PENDING' || s?.status === 'PAYMENT_OVERDUE')?.length}&nbsp;{`${intl.formatMessage({ id: 'Global_Button_Pay_Now_Caption_1' })}`}
                </Alert>
                <PaySportSessionsModal tournament_id={tournament?.id} sessions_ids={PAYABLE_SPORT_SESSIONS?.filter((s: any) => s?.status === 'PENDING' || s?.status === 'PAYMENT_OVERDUE')?.map((s: any) => s?.id)} open={openPaySportSession} handleClose={handleClosePaySportSession} />
              </>
            ) : (
              ''
            )}
          </>
        ) : (
          ''
        )}

        {UNPAID_SEAT_SESSIONS?.filter((s: any) => !s?.transaction)?.length > 0 && (tournament?.status === 'SCHEDULING_GAMES' || tournament?.status === 'GROUPS_STAGE_ON_GOING' || tournament?.status === 'PLAYOFFS_STAGE_ON_GOING' || tournament?.status === 'TROPHY_CEREMONY') ? (
          <>
            <Alert
              severity="info"
              variant="filled"
              sx={{
                'width': '100%',
                'display': { xs: 'inline-block', sm: 'flex' },
                'alignItems': 'center',
                'textAlign': 'center',
                '& .MuiAlert-icon': { display: { xs: 'inline', sm: 'flex' }, m: '0 12px' },
                '& .MuiAlert-action': { display: { xs: 'block', sm: 'flex' }, p: { xs: '0', sm: 'inherit' } },
              }}
              action={
                <Button className="button-blue" onClick={() => setOpenPaySeatSession(true)} sx={{ whiteSpace: 'nowrap', fontWeight: '600', boxShadow: 'none', alignItems: 'center' }}>
                  <BankCardsIcon />
                  &nbsp;{`${intl.formatMessage({ id: 'Global_Button_Pay_Now' })}`}
                </Button>
              }
            >
              <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
                <AvatarGroup max={9}>
                  {tournament?.selected_teams
                    ?.filter((st: any) => UNPAID_SEAT_SESSIONS?.filter((s: any) => !s?.transaction)?.find((s: any) => s?.player?.team_player?.id === st?.team?.id))
                    ?.map((p: any, index: number) => (
                      <Tooltip key={index} title={p?.name} sx={{ display: 'block' }}>
                        <Avatar alt={p?.name} src={p?.team?.avatar?.path} />
                      </Tooltip>
                    ))}
                </AvatarGroup>
                &nbsp;&nbsp;{`${intl.formatMessage({ id: 'Global_Button_Pay_Now_Caption_2' })}`}
              </Grid>
            </Alert>
            <PayTournamentSeatSessionModal tournament_id={tournament?.id} sessions_ids={PAYABLE_SPORT_SESSIONS?.filter((s: any) => s?.status === 'PENDING' || s?.status === 'PAYMENT_OVERDUE')?.map((s: any) => s?.id)} open={openPaySeatSession} handleClose={handleClosePaySportSession} />
          </>
        ) : (
          ''
        )}

        <Grid item xs={2} md={2} lg={2} sx={{ textAlign: 'center' }}></Grid>
        <Grid item xs={8} md={8} lg={8} sx={{ textAlign: 'center' }}>
          <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
            <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '30px', sm: '35px', md: '40px' }, p: '0' }} />
          </IconButton>
        </Grid>
        <Grid item xs={2} md={2} lg={2} sx={{ textAlign: 'right', p: '0 40px' }}>
          <MenuButton />
        </Grid>
      </Grid>

      {loadedTournament.data?.findTournamentByShortID?.id === undefined || loadedTournament.loading ? (
        <Grid item xl={12} xs={12} sm={12} sx={{ pb: '150px' }}>
          {loadedTournament.loading ? (
            <LoadingComponent
              amount={1}
              text={
                <Typography variant={'h6'} sx={{ textAlign: 'center' }}>
                  {`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}
                </Typography>
              }
              loader={<CircularProgress className="orange-loader" />}
            />
          ) : (
            ''
          )}
          {loadedTournament.data?.findTournamentByShortID?.id === undefined && !loadedTournament.loading ? (
            <Grid>
              <Typography
                variant="h2"
                sx={{
                  fontSize: '2em',
                  fontWeight: '700',
                  textAlign: 'center',
                }}
              >
                {`${intl.formatMessage({ id: 'Tournament_Not_Found' })}`}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: '1.5em',
                  textAlign: 'left',
                  p: '5%',
                }}
              >
                {`${intl.formatMessage({ id: 'Tournament_Not_Found_Caption' })}`}
              </Typography>
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      ) : (
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          sx={{
            pb: '150px',
            pt: tournament?.pictures?.length > 0 ? '200px' : '0',
          }}
        >
          <Grid
            container
            sx={{
              backgroundImage: 'linear-gradient(to top, rgba(15,15,15, 1) 20%, rgba(15,15,15, .8) 60%, transparent 100%)',
              position: 'relative',
              justifyContent: 'center',
              display: 'flex',
              width: '100%',

              alignItems: 'center',
            }}
          >
            <Grid item xl={12} xs={12} sm={12} sx={{ textAlign: 'center', pb: '0' }}>
              <Typography
                variant="h1"
                sx={{
                  fontSize: {
                    xs: '30px',
                    sm: '35px',
                    md: '40px',
                  },
                  fontWeight: '800 !important',
                }}
              >
                {tournament?.title}
              </Typography>
              <Grid
                container
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  pt: '10px',
                }}
              >
                <Typography variant="caption" className={'owners'} sx={{ pr: '5px' }}>
                  <FormattedMessage id="Tournament_By" />
                </Typography>
                <AvatarGroup max={10} className={'owner-avatars'} sx={{ display: 'inline-flex' }}>
                  {tournament?.organisators?.map((owner: any, index: number) => (
                    <Avatar
                      key={index}
                      alt={owner.username}
                      src={owner.avatar.path}
                      component={Link}
                      to={`/u/${owner.username}`}
                      sx={{
                        width: '24px',
                        height: '24px',
                        bgcolor: 'rgba(255, 255, 255, 1)',
                        borderWidth: '1px !important',
                      }}
                    />
                  ))}
                </AvatarGroup>
              </Grid>
              <Typography
                sx={{
                  textTransform: 'uppercase',
                  fontSize: '.8em',
                  pt: '10px',
                }}
              >
                {`${intl.formatMessage({ id: 'Global_Status' })}`}: <strong className={tournament?.status}>{tournament?.status?.replaceAll('_', ' ')}</strong>
              </Typography>
              <Grid container sx={{ textDecoration: 'none', justifyContent: 'center' }}>
                <Typography
                  className={'location'}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {tournament?.main_location?.city?.name}, {tournament?.main_location?.country?.name}
                  &nbsp;
                  <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${tournament?.main_location?.country_code.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${tournament?.main_location?.country_code.toLowerCase()}.png 2x`} alt="" />
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            className={'options'}
            sx={{
              bgcolor: 'rgb(15,15,15)',
              position: 'relative',
              alignItems: 'center',
            }}
          >
            <Grid container id="max-width">
              <Grid
                item
                xl={6}
                xs={6}
                sm={6}
                className={'actions-left'}
                sx={{
                  display: 'flex',
                  justifyContent: 'start',
                  p: '0 5%',
                }}
              >
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowBack />
                </IconButton>
              </Grid>

              <Grid
                item
                xl={6}
                xs={6}
                sm={6}
                className={'actions-right'}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'end',
                  p: '0 5%',
                }}
              >
                <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                  <MoreVert />
                </IconButton>

                <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                  {tournament?.descriptions?.length > 0 ? <SelectDescriptionButton control={control} descriptions={tournament?.descriptions} /> : ''}

                  {(tournament?.status === 'SCHEDULING_GAMES' || tournament?.status === 'OPEN' || tournament?.status === 'PENDING') && tournament?.end_date > Date.now() && tournament?.admins?.find((a: any) => a?.id === loggedPlayer?.id) ? (
                    <Grid>
                      <MenuItem component={Link} to={`/tournament/edit`} state={{ tournament: { id: tournament?.id } }}>
                        {`${intl.formatMessage({ id: 'Tournament_Button_Edit' })}`}
                      </MenuItem>
                      <FormEditFieldModal field_id={tournament?.id} open={openEdit} handleClose={handleCloseEdit} />
                    </Grid>
                  ) : (
                    ''
                  )}

                  <ShareURLButton button={<MenuItem>{`${intl.formatMessage({ id: 'Global_Button_Share' })}`}</MenuItem>} url={window.location.href} title={`${tournament?.title}`} text={`${intl.formatMessage({ id: 'Global_Button_Share_Caption_1' })} ${tournament?.title} ${intl.formatMessage({ id: 'Global_Button_Share_Caption_2' })}`} />
                </Menu>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            className={'info'}
            sx={{
              bgcolor: 'rgb(15,15,15)',
              position: 'relative',
              p: '40px 0 20px',
            }}
          >
            <Grid container id="max-width">
              <Grid
                item
                xl={6}
                xs={12}
                sm={6}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Grid
                  container
                  sx={{
                    justifyContent: 'start',
                    alignItems: 'center',
                    p: { xs: '0 5% 20px', sm: '0 5%' },
                    textAlign: { xs: 'center', sm: 'left' },
                  }}
                >
                  <Typography sx={{ width: '100%', textTransform: 'uppercase', textAlign: 'center', fontWeight: '800' }}>
                    {tournament?.team_candidates?.filter((t: any) => !tournament?.selected_teams?.find((st: any) => st?.id === t?.id))?.length} {`${intl.formatMessage({ id: 'Tournament_Team_Candidates' })}`}
                  </Typography>
                  <Grid container sx={{ justifyContent: 'center' }}>
                    <AvatarGroup max={9}>
                      {tournament?.team_candidates
                        ?.filter((t: any) => !tournament?.selected_teams?.find((st: any) => st?.id === t?.id))
                        ?.map((p: any, index: number) => (
                          <Tooltip key={index} title={p?.name} sx={{ display: 'block' }}>
                            <Avatar alt={p?.name} src={p?.team?.avatar?.path} sx={{ m: 'auto !important' }} />
                          </Tooltip>
                        ))}
                    </AvatarGroup>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={6} xs={12} sm={6} className={'primary-info'} sx={{ position: 'relative', textAlign: 'center' }}>
                <Typography sx={{ width: '100%', textTransform: 'uppercase', textAlign: 'center', fontWeight: '800' }}>
                  {tournament?.selected_teams?.length} {`${intl.formatMessage({ id: 'Tournament_Team_Candidates' })}`}
                </Typography>
                <Grid container sx={{ justifyContent: 'center' }}>
                  <AvatarGroup max={9}>
                    {tournament?.selected_teams?.map((p: any, index: number) => (
                      <Tooltip key={index} title={p?.name} sx={{ display: 'block' }}>
                        <Avatar alt={p?.name} src={p?.team?.avatar?.path} sx={{ m: 'auto !important' }} />
                      </Tooltip>
                    ))}
                  </AvatarGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            className={'company-description'}
            sx={{
              bgcolor: 'rgba(15, 15, 15, 1)',
              position: 'relative',
              zIndex: '1',
              p: { xs: '0% 15% 5%', md: '0% 20% 5%' },
            }}
          >
            <Grid id="max-width" item xl={12} xs={12} sm={12}>
              {tournament?.descriptions?.find((d: any) => d.language === inputFields.description_language)?.content ? (
                <Typography sx={{ textAlign: 'left', p: '30px 0% 10px', whiteSpace: 'pre-line' }}>{_.truncate(tournament?.descriptions.find((d: any) => d.language === inputFields.description_language)?.content, { length: fullDescription ? tournament?.descriptions?.find((d: any) => d.language === inputFields.description_language)?.content?.length : 150 })}</Typography>
              ) : (
                <Typography
                  variant="h3"
                  sx={{
                    p: '30px 10%',
                    fontSize: '1.3em',
                    textAlign: 'center',
                    width: '100%',
                    color: 'rgba(255, 255, 255, 0.3) !important',
                  }}
                >
                  <FormattedMessage id="Global_No_Description" />
                </Typography>
              )}

              {tournament?.descriptions?.find((d: any) => d.language === inputFields.description_language)?.content && tournament?.descriptions?.find((d: any) => d.language === inputFields.description_language)?.content?.length > 150 ? (
                <Grid container sx={{ p: '0', justifyContent: 'center' }}>
                  <Fab size="small" variant="extended" sx={{ 'fontSize': '.8em', 'color': 'rgba(255, 255, 255, 0.3) !important', 'bgcolor': 'transparent', 'boxShadow': 'none', '&:hover': { bgcolor: 'rgba(247, 251, 250, 0.1)' } }} onClick={() => setFullDescription(!fullDescription)}>
                    {fullDescription ? <FormattedMessage id="Global_Button_See_Less" /> : <FormattedMessage id="Global_Button_See_More" />}
                  </Fab>
                </Grid>
              ) : (
                ''
              )}

              <Grid container sx={{ justifyContent: 'center', p: '15px 0' }}>
                {tournament?.status === 'OPEN' && tournament?.end_date > Date.now() ? (
                  <>
                    <Fab variant="extended" size="small" disabled={!loggedPlayer?.id || validatedTournament.loading} className={!loggedPlayer?.id || validatedTournament.loading ? 'button-disabled' : 'button-green'} onClick={() => setOpenRegistration(true)} sx={{ boxShadow: 'none', m: '10px 2%', p: '5px', height: 'auto' }}>
                      <Add />
                      &nbsp;{`${intl.formatMessage({ id: 'Tournament_Team_Candidates' })}`}
                    </Fab>
                    <RegistrationTournamentModal tournament_id={tournament?.id} selected_teams={tournament?.selected_teams} team_candidates={tournament?.team_candidates} open={openRegistration} handleClose={handleCloseRegistration} />

                    {tournament?.admins?.find((a: any) => a?.id === loggedPlayer?.id) ? (
                      <>
                        <Fab variant="extended" size="small" disabled={validatedTournament.loading} className={validatedTournament.loading ? 'button-disabled' : 'button-green-reverse'} onClick={() => setOpenTeamSelection(true)} sx={{ boxShadow: 'none', m: '10px 2%', p: '5px', height: 'auto' }}>
                          <Add />
                          &nbsp;{`${intl.formatMessage({ id: 'Tournament_Select_Unselect' })}`}
                        </Fab>
                        <SelectTeamTournamentModal tournament={tournament} open={openTeamSelection} handleClose={handleCloseTeamSelection} handleRefetch={handleRefetch} />
                      </>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  ''
                )}

                {tournament?.admins?.find((a: any) => a?.id === loggedPlayer?.id) &&
                tournament?.status === 'OPEN' &&
                tournament?.selected_teams?.length > 0 &&
                (tournament?.selected_teams?.length === TOURNAMENT_PLAYOFF_STAGES.find((s: any) => s.value === _.reverse(_.orderBy(_.uniqBy(tournament?.phase_playoffs, 'stage_name'), (s: any) => parseInt(s?.stage_name?.replaceAll('FINAL_', '')))?.map((s: any) => s?.stage_name))[0])?.nb_teams || tournament?.selected_teams?.length === tournament?.phase_groups?.length * 4) ? (
                  <>
                    <Fab variant="extended" size="small" className="button-green-reverse" onClick={() => setOpenValidateTeams(true)} sx={{ boxShadow: 'none', m: '0 2%' }}>
                      <Add />
                      &nbsp;
                      {`${intl.formatMessage({ id: 'Tournament_Validate_Teams' })}`}
                    </Fab>
                    <Modal open={openValidateTeams} title={''} content={`${intl.formatMessage({ id: 'Tournament_Confirm_Teams' })}`} secondaryContent={`${intl.formatMessage({ id: 'Global_Delete_Confirmation_Caption_1' })}`} actionOneTitle={`${intl.formatMessage({ id: 'Tournament_Confirm_Teams_Yes' })}`} actionOne={handleValidateTeamSelection} actionTwoTitle={`${intl.formatMessage({ id: 'Tournament_Confirm_Teams_No' })}`} actionTwo={() => setOpenValidateTeams(false)} />
                  </>
                ) : (
                  ''
                )}

                {tournament?.admins?.find((a: any) => a?.id === loggedPlayer?.id) && tournament?.status === 'SCHEDULING_GAMES' && tournament?.selected_teams?.length > 0 && _.sum(tournament?.phase_groups?.map((g: any) => g?.games?.length)) > 0 ? (
                  <>
                    <Fab variant="extended" size="small" className="button-green-reverse" onClick={() => setOpenStartTournament(true)} sx={{ boxShadow: 'none', m: '0 2%' }}>
                      📣 {`${intl.formatMessage({ id: 'Tournament_Kick_Off_Group_Title' })}`} 🎉
                    </Fab>
                    <Modal
                      open={openStartTournament}
                      title={''}
                      content={`${intl.formatMessage({ id: 'Tournament_Kick_Off_Group_Caption_1' })}`}
                      secondaryContent={`${intl.formatMessage({ id: 'Tournament_Kick_Off_Group_Caption_2' })}`}
                      actionOneTitle={`${intl.formatMessage({ id: 'Tournament_Kick_Off_Group_Button_Go' })}`}
                      actionOne={handleStartGroupsTournament}
                      actionTwoTitle={`${intl.formatMessage({ id: 'Tournament_Kick_Off_Group_Button_Wait' })}`}
                      actionTwo={() => setOpenStartTournament(false)}
                      loading={startedGroupsTournament.loading}
                    />
                  </>
                ) : (
                  ''
                )}

                {tournament?.admins?.find((a: any) => a?.id === loggedPlayer?.id) && ((tournament?.status === 'SCHEDULING_GAMES' && tournament?.phase_groups?.length === 0) || (tournament?.status === 'GROUPS_STAGE_ON_GOING' && tournament?.phase_groups?.length > 0)) && tournament?.selected_teams?.length > 0 && _.sum(tournament?.phase_playoffs?.map((g: any) => (g?.game ? 1 : 0))) > 0 ? (
                  <>
                    <Fab variant="extended" size="small" className="button-green-reverse" onClick={() => setOpenStartTournament(true)} sx={{ boxShadow: 'none', m: '0 2%' }}>
                      📣 {`${intl.formatMessage({ id: 'Tournament_Kick_Off_Finals_Title' })}`} 🎉
                    </Fab>
                    <Modal
                      open={openStartTournament}
                      title={''}
                      content={`${intl.formatMessage({ id: 'Tournament_Kick_Off_Finals_Caption_1' })}`}
                      secondaryContent={`${intl.formatMessage({ id: 'Tournament_Kick_Off_Finals_Caption_1' })}`}
                      actionOneTitle={`${intl.formatMessage({ id: 'Tournament_Kick_Off_Finals_Button_Go' })}`}
                      actionOne={handleStartStagesTournament}
                      actionTwoTitle={`${intl.formatMessage({ id: 'Tournament_Kick_Off_Finals_Button_Wait' })}`}
                      actionTwo={() => setOpenStartTournament(false)}
                      loading={startedStagesTournament.loading}
                    />
                  </>
                ) : (
                  ''
                )}
              </Grid>

              {tournament?.cost_per_team?.value ? (
                <Typography sx={{ fontWeight: '800', fontSize: { xs: '2em', sm: '2.5em', md: '3em' }, textAlign: 'center', background: '-webkit-linear-gradient(280deg, rgba(19, 96, 13, 1) 0%, rgba(168, 202, 65, 1) 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>
                  {_.split(tournament?.cost_per_team?.currency?.currency_code, '_')?.length > 1 ? _.split(tournament?.cost_per_team?.currency?.currency_code, '_')[1] : tournament?.cost_per_team?.currency?.currency_code} {tournament?.cost_per_team?.value?.toLocaleString()}/{`${intl.formatMessage({ id: 'Tournament_Cost_Per_Team' })}`}
                </Typography>
              ) : (
                ''
              )}
            </Grid>
          </Grid>

          {tournament?.trophies?.length === 0 ? (
            ''
          ) : (
            <>
              <Typography
                id="max-width"
                sx={{
                  width: '100%',
                  fontSize: '1.1em',
                  fontWeight: '800',
                  bgcolor: 'rgb(15,15,15)',
                  position: 'relative',
                  p: { xs: '0 5% 10px', md: '0 2% 10px' },
                  textAlign: { xs: 'left', md: 'center' },
                }}
              >
                {_.upperCase(`${intl.formatMessage({ id: 'Tournament_Trophies_Title' })}`)}
              </Typography>
              <Grid
                container
                sx={{
                  bgcolor: 'rgb(15,15,15)',
                  position: 'relative',
                  overflow: 'auto',
                  p: '0 5% 20px',
                }}
              >
                <Stack
                  direction="row"
                  sx={{
                    m: '20px 0',
                    width: '100%',
                    justifyContent: { xs: 'start', md: 'center' },
                    display: 'inline-flex',
                  }}
                >
                  {_.sortBy(tournament?.trophies, (trophy) => _.indexOf(['GOLD', 'SILVER', 'BRONZE', 'IRON'], trophy?.rank))?.map((t: any, index: number) => (
                    <Grid key={index} sx={{ minWidth: '200px', maxWidth: '350px', textAlign: 'center', m: '0 10px', bgcolor: 'rgba(247, 251, 250, .04)', borderRadius: '10px', p: '10px' }}>
                      <Grid sx={{ '& .MuiSvgIcon-root': { fontSize: '4em' } }}>
                        {TROPHY_RANKS?.find((r: any) => r.value === t?.rank)?.icon}
                        <Typography sx={{ fontSize: '1.3em', fontWeight: '800' }}>{t?.name}</Typography>

                        {t?.winners?.length > 0 ? (
                          <Grid sx={{ textAlign: 'center', m: '10px 0' }}>
                            <Avatar src={t?.winners?.find((t: any) => t?.avatar)?.avatar?.path} alt={t?.winners?.find((t: any) => t?.avatar)?.name} sx={{ height: '70px', width: '70px', m: 'auto' }} />
                            <Typography sx={{ fontWeight: '800' }}>{t?.winners?.find((t: any) => t?.avatar)?.name}</Typography>
                            <AvatarGroup max={6} sx={{ justifyContent: 'center' }}>
                              {t?.winners
                                ?.filter((w: any) => w?.__typename === 'TeamPlayer')
                                ?.map((p: any) => (
                                  <Avatar key={p?.id} src={p?.player?.avatar?.path} alt={p?.player?.name} />
                                ))}
                            </AvatarGroup>
                          </Grid>
                        ) : (
                          ''
                        )}

                        <Typography variant="caption">{t?.note}</Typography>
                      </Grid>

                      {!t?.winners || t?.winners?.length > 0 || tournament?.status !== 'TROPHY_CEREMONY' ? (
                        ''
                      ) : tournament?.admins?.find((a: any) => a?.id === loggedPlayer?.id) ? (
                        <Fab variant="extended" size="small" sx={{ mt: '10px', boxShadow: 'none' }} disabled={!t?.winners || t?.winners?.length > 0} className={!t?.winners || t?.winners?.length > 0 ? 'button-disabled' : 'button-green'} onClick={tournament?.status === 'TROPHY_CEREMONY' ? () => handleOpenSelectedTrophy(t?.id) : undefined}>
                          <Add />
                          &nbsp;{`${intl.formatMessage({ id: 'Tournament_Trophies_Assign' })}`}
                        </Fab>
                      ) : (
                        ''
                      )}
                    </Grid>
                  ))}
                </Stack>

                {tournament?.admins?.find((a: any) => a?.id === loggedPlayer?.id) ? <AssignTournamentTrophyModal tournament_id={tournament?.id} trophy_id={inputFields.selected_trophy} open={openAssignTrophy} handleClose={handleCloseSelectedTrophy} /> : ''}
              </Grid>
            </>
          )}

          <Grid container className={`component-transaction-confirmation-modal ${loggedPlayer?.app_configuration?.is_dark_mode ? 'dark-mode-tabs-component' : 'light-mode-tabs-component'}`}>
            <AppBar position="sticky">
              <Tabs value={value} onChange={handleChange} scrollButtons={false} aria-label="scrollable" variant={isSmallDesktop ? 'scrollable' : 'fullWidth'} centered={!isSmallDesktop}>
                <Tab
                  label={`${intl.formatMessage({ id: 'Tournament_Overview_Tab' })}`}
                  {...a11yProps(0)}
                  style={{
                    paddingLeft: '5%',
                    paddingRight: '5%',
                  }}
                />
                <Tab
                  label={`${intl.formatMessage({ id: 'Tournament_Boards_Tab' })}`}
                  {...a11yProps(1)}
                  style={{
                    paddingLeft: '5%',
                    paddingRight: '5%',
                  }}
                />
              </Tabs>
            </AppBar>
          </Grid>
          <SwipeableViews axis={'x'} index={value} onChangeIndex={handleChangeIndex}>
            <TabPanel value={value} index={0}>
              <Grid container sx={{ p: '20px 0' }}>
                <Grid
                  container
                  sx={{
                    bgcolor: 'rgb(15,15,15)',
                    justifyContent: 'center',
                    overflow: 'auto',
                    p: '0',
                  }}
                >
                  {tournament?.status !== 'PLAYOFFS_STAGE_ON_GOING' && tournament?.status !== 'SCHEDULING_GAMES' && tournament?.status !== 'OPEN' && tournament?.status !== 'TROPHY_CEREMONY' && tournament?.status !== 'OVER' ? (
                    ''
                  ) : (
                    <Grid item xs={12} md={12} lg={12} sx={{ p: '0 10%', textAlign: 'center' }}>
                      <TournamentPlayoffTable tournament_id={tournament?.id} />
                    </Grid>
                  )}
                </Grid>
                {tournament?.phase_groups?.length === 0 || (tournament?.status !== 'GROUPS_STAGE_ON_GOING' && tournament?.status !== 'SCHEDULING_GAMES' && tournament?.status !== 'OPEN') ? '' : <TournamentGroupsTable tournament_id={tournament?.id} />}
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Grid container sx={{ p: '20px 0' }}>
                <Grid
                  container
                  sx={{
                    bgcolor: 'rgb(15,15,15)',
                    justifyContent: 'center',
                    overflow: 'auto',
                    p: '0',
                  }}
                >
                  <Grid item xs={12} md={12} lg={12} sx={{ p: '0 10%', textAlign: 'center' }}>
                    <TournamentPlayoffTable tournament_id={tournament?.id} />
                  </Grid>
                </Grid>
                {tournament?.phase_groups?.length === 0 ? '' : <TournamentGroupsTable tournament_id={tournament?.id} />}
              </Grid>
            </TabPanel>
          </SwipeableViews>

          {tournament?.phase_playoffs?.length === 0 ? '' : <></>}
        </Grid>
      )}
    </Grid>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Grid container role="tabpanel" hidden={value !== index} id={`scrollable-auto-tabpanel-${index}`} aria-labelledby={`scrollable-auto-tab-${index}`} {...other}>
      {value === index && (
        <Grid container>
          <Grid container>{children}</Grid>
        </Grid>
      )}
    </Grid>
  );
}

function a11yProps(index: any) {
  return {
    'id': `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default Tournament;

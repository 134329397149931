import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Divider, Fab, Grid, AvatarGroup, Badge, Avatar, TextField, MenuItem } from '@mui/material';
import { Close, Delete, Send } from '@mui/icons-material';
import { gql, useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import { CaptainIcon } from '../../img/icons/Icons';
import { LoadingButton } from '@mui/lab';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { selectSession } from '../../reducers/sessionSlice';
import { Controller, useForm } from 'react-hook-form';

export default function RegistrationTournamentModal(props: { tournament_id: string; team_candidates: any[]; selected_teams: any[]; handleClose: any; open: any }) {
  const FIND_TEAM_LINEUPS_BY_SPORT_QUERY = gql`
    query findTeamLineupsBySport($team_lineup: inputFindTeamLineupsBySport!) {
      findTeamLineupsBySport(team_lineup: $team_lineup) {
        id
        name
        players {
          id
          status
          team_number
          team_player {
            ... on FootballTeam {
              __typename
              id
              name
            }
          }
          player {
            ... on Footballer {
              __typename
              id
              alias
              position
              best_foot
              jersey_number
              avatar {
                path
              }
              player {
                id
              }
            }
            ... on Basketballer {
              __typename
              id
              alias
              position
              best_foot
              jersey_number
              avatar {
                path
              }
              player {
                id
              }
            }
          }
        }
        captains {
          id
          status
          team_number
          player {
            ... on Footballer {
              __typename
              id
              alias
              player {
                id
              }
            }
            ... on Basketballer {
              __typename
              id
              alias
              player {
                id
              }
            }
          }
        }
        team {
          ... on FootballTeam {
            __typename
            id
            short_id
            name
            shortname
            type
            avatar {
              path
            }
            cover {
              path
            }
            captains {
              id
              status
              team_number
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const REGISTER_TEAM_LINEUP_IN_TOURNAMENT_MUTATION = gql`
    mutation registerTeamLineupInTournament($tournament: inputRegistrationTeamLineupInTournament!) {
      registerTeamLineupInTournament(tournament: $tournament)
    }
  `;

  const UNREGISTER_TEAM_LINEUP_IN_TOURNAMENT_MUTATION = gql`
    mutation unregisterTeamLineupInTournament($tournament: inputRegistrationTeamLineupInTournament!) {
      unregisterTeamLineupInTournament(tournament: $tournament)
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);

  const { control, watch } = useForm({
    defaultValues: {
      team_lineup_id: '',
    },
  });
  const inputFields = watch();

  const loadedTeamLineups = useQuery(FIND_TEAM_LINEUPS_BY_SPORT_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      team_lineup: {
        player_id: loggedPlayer?.id,
        sport_type: 'FOOTBALL',
      },
    },
  });

  const [registerTeamLineupInTournament, registeredTeam] = useMutation(REGISTER_TEAM_LINEUP_IN_TOURNAMENT_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [unregisterTeamLineupInTournament, unregisteredTeam] = useMutation(UNREGISTER_TEAM_LINEUP_IN_TOURNAMENT_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });

  const ALL_TEAM_LINEUPS = loadedTeamLineups.data?.findTeamLineupsBySport?.filter((t: any) => !props.selected_teams?.find((st: any) => st?.id === t?.id));
  const SELECTED_TEAM_LINEUP = loadedTeamLineups.data?.findTeamLineupsBySport?.find((t: any) => t?.id === inputFields.team_lineup_id);

  const handleClose = () => {
    props.handleClose();
  };

  const handleRegisterTeamLineup = async () => {
    try {
      await registerTeamLineupInTournament({
        context: {
          headers: {
            'Authorization': `Bearer ${session?.token?.key}`,
            'X-Anonymous-Access': 'false',
          },
        },
        variables: {
          tournament: {
            id: props.tournament_id,
            team_lineup_id: SELECTED_TEAM_LINEUP?.id,
            player_id: loggedPlayer?.id,
          },
        },
      });

      handleClose();
    } catch (e) {
      console.error(e);
    }
  };

  const handleUnregisterTeamLineup = async () => {
    try {
      await unregisterTeamLineupInTournament({
        context: {
          headers: {
            'Authorization': `Bearer ${session?.token?.key}`,
            'X-Anonymous-Access': 'false',
          },
        },
        variables: {
          tournament: {
            id: props.tournament_id,
            team_lineup_id: SELECTED_TEAM_LINEUP?.id,
            player_id: loggedPlayer?.id,
          },
        },
      });

      handleClose();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={registeredTeam.loading || unregisteredTeam.loading ? undefined : handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: { minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' }, p: '20px 10px' },
      }}
    >
      <Grid container>
        <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'left' }}>
          <Fab
            size="small"
            onClick={registeredTeam.loading || unregisteredTeam.loading ? undefined : handleClose}
            disabled={registeredTeam.loading || unregisteredTeam.loading}
            className={registeredTeam.loading || unregisteredTeam.loading ? 'button-disabled' : 'button-cancel'}
            sx={{
              boxShadow: 'none',
              bgcolor: 'transparent',
              ml: '2%',
            }}
          >
            <Close />
          </Fab>
        </Grid>
        <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'right' }}></Grid>
      </Grid>

      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>Select team lineup</Typography>
      </DialogTitle>
      <Divider variant="middle" sx={{ width: '80%', m: 'auto' }} />
      <DialogContent sx={{ pl: '0', pr: '0' }}>
        <DialogContentText id="alert-dialog-description" component="span">
          <Controller
            control={control}
            name="team_lineup_id"
            render={({ field }: any) => (
              <TextField {...field} value={field.value} select label={'Teams'} variant="outlined" required fullWidth className={'field-bottom-space'}>
                {ALL_TEAM_LINEUPS?.map((option: any) => (
                  <MenuItem key={option.id} value={option.id} disabled={option?.players?.length <= 0}>
                    <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
                      <Avatar src={option?.team?.avatar?.path} alt={option?.team?.name} />
                      &nbsp;&nbsp;
                      <strong>{option?.name}</strong>
                      {props.team_candidates?.find((tc: any) => tc?.id === option?.id) ? (
                        <Typography variant="caption" className="ACCEPTED">
                          &nbsp;registered
                        </Typography>
                      ) : (
                        ''
                      )}
                    </Grid>
                    <Grid container sx={{ justifyContent: 'center' }}>
                      <AvatarGroup spacing={-20}>
                        {option?.players?.map((p: any, index: number) => (
                          <Badge key={index} overlap="circular" anchorOrigin={{ vertical: 'top', horizontal: 'right' }} badgeContent={option?.captains?.find((c: any) => c?.id === p?.id) ? <CaptainIcon /> : undefined} sx={{ display: 'block' }}>
                            <Avatar alt={p?.player?.alias} src={p?.player?.avatar?.path} sx={{ m: 'auto !important' }} />
                            <Typography variant="caption">{_.truncate(p?.player?.alias, { length: 20 })}</Typography>
                          </Badge>
                        ))}
                      </AvatarGroup>
                    </Grid>
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </DialogContentText>
        <DialogActions sx={{ p: '20px 0 0' }}>
          {SELECTED_TEAM_LINEUP?.id && props.tournament_id && !props.team_candidates?.find((t: any) => t?.id === SELECTED_TEAM_LINEUP?.id) ? (
            <>
              <LoadingButton loading={registeredTeam.loading} loadingPosition="end" endIcon={<Send />} className={'button-green'} fullWidth onClick={handleRegisterTeamLineup}>
                {registeredTeam.loading ? 'Joining tournament...' : 'Register'}
              </LoadingButton>
            </>
          ) : (
            ''
          )}

          {SELECTED_TEAM_LINEUP?.id && props.tournament_id && props.team_candidates?.find((t: any) => t?.id === SELECTED_TEAM_LINEUP?.id) && !props.selected_teams?.find((t: any) => t?.id === SELECTED_TEAM_LINEUP?.id) ? (
            <>
              <LoadingButton loading={registeredTeam.loading} loadingPosition="end" endIcon={<Delete />} className={'button-red-reversed'} fullWidth onClick={handleUnregisterTeamLineup}>
                {unregisteredTeam.loading ? 'Leaving tournament...' : 'Unregister'}
              </LoadingButton>
            </>
          ) : (
            ''
          )}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

RegistrationTournamentModal.propTypes = {
  tournament_id: PropTypes.string.isRequired,
  team_candidates: PropTypes.array.isRequired,
  handleClose: PropTypes.any.isRequired,
  open: PropTypes.any.isRequired,
};

RegistrationTournamentModal.defaultProps = {};

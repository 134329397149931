import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useQuery, gql, useMutation } from '@apollo/client';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import _ from 'lodash';

import { Alert, TextField, MenuItem, Menu, Avatar, Grid, Typography, Fab, CardMedia, IconButton, Button, AvatarGroup, CircularProgress } from '@mui/material';
import { Home, ArrowBack, Comment, Favorite, FavoriteBorder, MoreVert } from '@mui/icons-material';
import { LogoLilenekWhiteIcon, SearchIcon, ShareIcon } from '../../img/icons/Icons';

import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { selectTeams } from '../../reducers/teamSlice';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import LoadingComponent from '../../component-modals/common/LoadingComponent';
import { selectSession } from '../../reducers/sessionSlice';

const Flex: React.FC = () => {
  const FIND_FLEX_BY_ID_QUERY = gql`
    query findFlexByID($short_id: String!) {
      findFlexByID(short_id: $short_id) {
        id
        short_id
        status
        caption
        likes
        liked_by {
          ... on Company {
            __typename
            id
          }
          ... on User {
            __typename
            id
          }
        }
        pictures {
          path
        }
        shared_with {
          ... on Company {
            __typename
            id
            name
            label
            logo {
              path
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
        comments {
          id
          title
          mood
          content
          likes
          author {
            ... on Company {
              __typename
              id
              name
              label
              logo {
                path
              }
            }
            ... on User {
              __typename
              id
              username
              avatar {
                path
              }
            }
          }
        }
        author {
          ... on Company {
            __typename
            id
            name
            logo {
              path
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
        co_authors {
          ... on Company {
            __typename
            id
            name
            logo {
              path
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
        co_author_confirmations
        products_flexed {
          id
          short_id
          name
        }
        articles_flexed {
          id
          short_id
          name
          product {
            id
            short_id
            name
          }
        }
        services_flexed {
          id
          short_id
          name
        }
      }
    }
  `;

  const LIKE_FLEX_BY_VIEWER_MUTATION = gql`
    mutation likeFlexByViewer($flex: inputLikeFlexByViewer!) {
      likeFlexByViewer(flex: $flex)
    }
  `;

  const UNLIKE_FLEX_BY_VIEWER_MUTATION = gql`
    mutation unlikeFlexByViewer($flex: inputLikeFlexByViewer!) {
      unlikeFlexByViewer(flex: $flex)
    }
  `;

  const location: any = useLocation();
  const navigate = useNavigate();
  const session = useAppSelector(selectSession);
  const loggedUser = useAppSelector(selectPlayer);
  const loggedUserTeams = useAppSelector(selectTeams);

  const [flex, setFlex] = React.useState(UNDEFINED_AS_ANY);
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
  const [activeStep, setActiveStep] = React.useState(0);
  const [didLike, setDidLike] = React.useState(false);
  const [didUnlike, setDidUnlike] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { control, watch, setValue } = useForm({
    defaultValues: {
      flexer_id: '',
    },
  });
  const inputFields = watch();
  const ALL_FLEXERS = _.concat(
    {
      id: loggedUser?.id,
      name: loggedUser?.username,
      avatar: loggedUser?.avatar?.path,
    },
    loggedUserTeams?.map((c: any) => {
      return { id: c?.id, name: c?.label, avatar: c?.logo?.path };
    })
  );

  const [likeFlexByViewer] = useMutation(LIKE_FLEX_BY_VIEWER_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [unlikeFlexByViewer] = useMutation(UNLIKE_FLEX_BY_VIEWER_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedFlex = useQuery(FIND_FLEX_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: { short_id: location.pathname.split('/')[2] },
    pollInterval: loggedUser?.id ? 3000 : undefined,
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const handleLikeFlex = async () => {
    const result = await likeFlexByViewer({
      variables: {
        flex: {
          id: flex?.id,
          viewer_id: ALL_FLEXERS.find((f: any) => f?.id === inputFields.flexer_id)?.id,
        },
      },
    });

    if (result.data?.likeFlexByViewer !== undefined) {
      setDidLike(result.data?.likeFlexByViewer);
      setDidUnlike(false);
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleUnlikeFlex = async () => {
    const result = await unlikeFlexByViewer({
      variables: {
        flex: {
          id: flex?.id,
          viewer_id: ALL_FLEXERS.find((f: any) => f?.id === inputFields.flexer_id)?.id,
        },
      },
    });

    if (result.data?.unlikeFlexByViewer !== undefined) {
      setDidUnlike(result.data?.unlikeFlexByViewer);
      setCurrentIndex(currentIndex - 1);
    }
  };

  React.useEffect(() => {
    if (flex === undefined && loadedFlex.called && loadedFlex.data?.findFlexByID) {
      setFlex(loadedFlex.data?.findFlexByID);
    }

    if (inputFields.flexer_id === '') setValue('flexer_id', ALL_FLEXERS[0]?.id);
  }, [flex, loadedFlex, inputFields, setValue, ALL_FLEXERS]);

  return (
    <Grid className={'component-profile flex-page dark-mode'} sx={{ minHeight: '100%', pb: '150px' }}>
      <Grid
        item
        xl={12}
        xs={12}
        sm={12}
        sx={{
          bgcolor: 'rgb(15,15,15)',
          position: 'relative',
          zIndex: '1',
          textAlign: 'center',
        }}
      >
        <LogoLilenekWhiteIcon
          sx={{
            opacity: '.5',
            height: '30px',
            width: 'auto',
            m: '2% 0',
          }}
        />
      </Grid>
      {(flex?.status === 'PENDING' && ALL_FLEXERS.find((f: any) => f?.id === flex?.author?.id) === undefined && flex?.co_authors.filter((f: any) => ALL_FLEXERS.find((fl: any) => fl?.id === f?.id) !== undefined)?.length === 0) || flex === undefined || loadedFlex.loading ? (
        <Grid sx={{ p: '25% 10% 0' }}>
          {loadedFlex.data?.findFlexByID === undefined && !loadedFlex.loading ? (
            <Grid>
              <Typography
                variant="h2"
                sx={{
                  fontSize: '2em',
                  fontWeight: '700',
                  textAlign: 'center',
                }}
              >
                Flex{' '}
                <Grid component="span" sx={{ fontWeight: '500' }}>
                  not found
                </Grid>
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: '1.5em',
                  textAlign: 'left',
                  p: '5%',
                }}
              >
                We are sorry but it seems like this flex is not available, please try to double check the spelling of the name or use our{' '}
                <Fab
                  variant="extended"
                  sx={{
                    'p': '0',
                    'fontWeight': '700',
                    'fontSize': '.8em',
                    'boxShadow': 'none',
                    'bgcolor': 'transparent',
                    'color': 'inherit',
                    ':hover': {
                      bgcolor: 'transparent',
                      color: 'rgba(31, 175, 146, 1)',
                    },
                  }}
                >
                  <SearchIcon sx={{ color: 'rgba(31, 175, 146, 1)' }} /> search
                </Fab>{' '}
                engine.
              </Typography>
            </Grid>
          ) : (
            ''
          )}

          {flex?.status === 'PENDING' && ALL_FLEXERS.find((f: any) => f?.id === flex?.author?.id) === undefined && flex?.co_authors.filter((f: any) => ALL_FLEXERS.find((fl: any) => fl?.id === f?.id) !== undefined)?.length === 0 ? (
            <Grid>
              <Typography
                variant="h2"
                sx={{
                  fontSize: '2em',
                  fontWeight: '700',
                  textAlign: 'center',
                }}
              >
                Flex{' '}
                <Grid component="span" sx={{ fontWeight: '500' }}>
                  coming soon
                </Grid>
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: '1.5em',
                  textAlign: 'left',
                  p: '5%',
                }}
              >
                A bit more patience, they are finalizing detail but in the meantime you check the latest news on the{' '}
                <Fab
                  component={Link}
                  to="/"
                  variant="extended"
                  sx={{
                    'p': '0',
                    'fontWeight': '700',
                    'fontSize': '.8em',
                    'boxShadow': 'none',
                    'bgcolor': 'transparent',
                    'color': 'inherit',
                    ':hover': {
                      bgcolor: 'transparent',
                      color: 'rgba(31, 175, 146, 1)',
                    },
                  }}
                >
                  <Home sx={{ color: 'rgba(31, 175, 146, 1)' }} /> Home
                </Fab>{' '}
                page.
              </Typography>
            </Grid>
          ) : (
            ''
          )}

          {loadedFlex.loading ? (
            <LoadingComponent
              amount={3}
              text={
                <Typography variant={'h6'} sx={{ textAlign: 'center', fontWeight: '700' }}>
                  loading flex, please wait...
                </Typography>
              }
              loader={<CircularProgress className="orange-loader" />}
            />
          ) : (
            ''
          )}
        </Grid>
      ) : (
        <Grid>
          <Grid container sx={{ p: '0 2% 5px' }}>
            <Grid item xl={10} xs={10} sm={10} sx={{ display: 'flex', justifyContent: 'start' }}>
              <Grid
                container
                sx={{
                  justifyContent: 'start',
                  alignItems: 'center',
                }}
              >
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowBack />
                </IconButton>
                <Avatar src={flex?.author?.avatar?.path || flex?.author?.logo?.path} component={Link} to={flex?.author?.__typename === 'Company' ? `/${flex?.author?.name}` : `/u/${flex?.author?.username}`} />
                {flex?.co_authors?.length > 0 ? (
                  <Grid
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    &nbsp;&nbsp;X&nbsp;&nbsp;
                    <AvatarGroup max={6}>
                      {_.shuffle(flex?.co_authors).map((author: any) => (
                        <Avatar key={author?.id} alt={author?.name || author?.username} src={author?.avatar?.path || author?.logo?.path} component={Link} to={author?.__typename === 'Company' ? `/${author?.name}` : `/u/${author?.username}`} />
                      ))}
                    </AvatarGroup>
                  </Grid>
                ) : (
                  <Typography
                    component={Link}
                    to={flex?.author?.__typename === 'Company' ? `/${flex?.author?.name}` : `/u/${flex?.author?.username}`}
                    sx={{
                      fontWeight: '700',
                      textDecoration: 'none',
                      color: 'inherit',
                    }}
                  >
                    &nbsp;&nbsp;{flex?.author?.username}
                    {flex?.author?.name}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item xl={2} xs={2} sm={2} sx={{ display: 'flex', justifyContent: 'end' }}>
              <IconButton aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                <MoreVert />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {ALL_FLEXERS.find((f: any) => f?.id === flex?.author?.id) !== undefined ? (
                  <MenuItem onClick={handleClose} component={Link} to={`/f/flex/edit`} state={{ flex: { id: flex?.id } }}>
                    Edit Flex
                  </MenuItem>
                ) : (
                  ''
                )}
                <MenuItem onClick={handleClose}>Add as Favorite</MenuItem>
              </Menu>
            </Grid>
          </Grid>
          <Grid
            container
            className={'images-slider'}
            sx={{
              height: '500px',
              textAlign: 'center',
              overflow: 'hidden',
              display: 'inline-block',
            }}
          >
            <Grid item xl={12} xs={12} sm={12} sx={{ height: '100%', display: 'inline-block' }}>
              {flex?.pictures?.length > 0 ? (
                <AutoPlaySwipeableViews axis={'x'} autoplay={false} index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents>
                  {flex?.pictures?.map((step: any, index: number) => (
                    <Grid
                      key={index}
                      sx={{
                        justifyContent: 'center',
                        display: 'flex',
                      }}
                    >
                      {Math.abs(activeStep - index) <= 2 ? <CardMedia component={'img'} sx={{ height: '500px', width: 'auto' }} src={step?.path} alt={_.truncate(flex?.caption)} /> : null}
                    </Grid>
                  ))}
                </AutoPlaySwipeableViews>
              ) : (
                <Grid
                  className={'empty-cover'}
                  sx={{
                    backgroundImage: flex?.pictures?.length > 0 ? 'linear-gradient(to top, rgb(15,15,15) 0%, #C7C7C7 100%)' : 'linear-gradient(to top, rgb(15,15,15) 70%, #C7C7C7 100%)',
                    height: '300px',
                    width: '100%',
                    position: 'relative',
                  }}
                ></Grid>
              )}
            </Grid>
          </Grid>
          <Grid container sx={{ justifyContent: 'center' }}>
            {flex?.co_author_confirmations?.length >= flex?.co_authors?.length ? (
              ''
            ) : (
              <Alert severity="info">
                Waiting confirmation from{' '}
                {flex?.co_authors
                  .filter((a: any) => flex?.co_author_confirmations.find((author_id: any) => author_id === a?.id) === undefined)
                  ?.map((u: any) => (
                    <Typography
                      key={u?.id}
                      variant="caption"
                      sx={{
                        p: '0 2px',
                        fontSize: 'inherit',
                        fontWeight: '700',
                      }}
                    >
                      {u?.name || u?.username},
                    </Typography>
                  ))}{' '}
                to make your flex visible to the public.
              </Alert>
            )}
          </Grid>
          <Grid container sx={{ p: '0 5%' }}>
            <Grid
              item
              xl={6}
              xs={6}
              sm={6}
              sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
              }}
            >
              <Fab
                sx={{
                  'bgcolor': 'transparent',
                  'boxShadow': 'none',
                  '&:hover': { bgcolor: 'transparent' },
                }}
              >
                {(flex?.liked_by.find((f: any) => f?.id === inputFields.flexer_id) || didLike) && !didUnlike ? (
                  <Favorite
                    sx={{
                      color: 'rgba(238, 44, 32, 1) !important',
                    }}
                    onClick={handleUnlikeFlex}
                  />
                ) : (
                  <FavoriteBorder
                    onClick={handleLikeFlex}
                    sx={{
                      'color': 'rgba(247, 251, 250, .3)',
                      '&:hover': {
                        color: 'rgba(238, 44, 32, .3)',
                      },
                    }}
                  />
                )}
              </Fab>
              <Fab
                sx={{
                  'bgcolor': 'transparent',
                  'boxShadow': 'none',
                  '&:hover': { bgcolor: 'transparent' },
                }}
              >
                <Comment
                  sx={{
                    'color': 'rgba(247, 251, 250, .3)',
                    '&:hover': {
                      color: 'rgba(247, 251, 250, 1)',
                    },
                  }}
                />
              </Fab>
              <Fab
                sx={{
                  'bgcolor': 'transparent',
                  'boxShadow': 'none',
                  '&:hover': { bgcolor: 'transparent' },
                }}
              >
                <ShareIcon
                  sx={{
                    'color': 'rgba(247, 251, 250, .3)',
                    '&:hover': {
                      color: 'rgba(247, 251, 250, 1)',
                    },
                  }}
                />
              </Fab>
            </Grid>
            <Grid
              item
              xl={6}
              xs={6}
              sm={6}
              sx={{
                display: 'flex',
                justifyContent: 'end',
                p: '20px 0 0',
              }}
            >
              <Controller
                name="flexer_id"
                control={control}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    label={`As`}
                    select
                    variant="outlined"
                    className="no-borders"
                    SelectProps={{
                      IconComponent: undefined,
                      sx: {
                        'color': 'rgba(247, 251, 250, 1)',
                        'border': 'none',
                        'bgcolor': 'transparent',
                        '& #mui-component-select-flexer_id': { p: '10px 0' },
                        '&::-ms-expand': {
                          display: 'none',
                        },
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        color: 'rgba(247, 251, 250, 1)',
                        p: '0 6px',
                        borderRadius: '5px',
                        textAlign: 'center',
                        width: '100%',
                      },
                    }}
                    sx={{ m: '0', p: '0', width: 'auto' }}
                  >
                    {ALL_FLEXERS.map((option: any) => (
                      <MenuItem key={option.id} value={option.id}>
                        <Grid
                          container
                          sx={{
                            justifyContent: 'start',
                            alignItems: 'center',
                          }}
                          onClick={() => setDidLike(false)}
                        >
                          <Avatar
                            src={option?.avatar}
                            sx={{
                              width: '25px',
                              height: '25px',
                            }}
                          />
                          &nbsp;&nbsp;
                          <Typography variant="caption">{option.name}</Typography>
                        </Grid>
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid className="caption" item xl={12} xs={12} sm={12}>
              {flex?.liked_by?.length + currentIndex === 0 ? (
                ''
              ) : (
                <Typography sx={{ pt: '10px', fontWeight: '700' }}>
                  {flex?.liked_by?.length + currentIndex} {flex?.liked_by?.length + currentIndex > 1 ? 'likes' : 'like'}
                </Typography>
              )}
              <Typography sx={{ pt: '15px' }}>
                <Typography
                  variant="caption"
                  component={Link}
                  to={flex?.author?.__typename === 'Company' ? `/${flex?.author?.name}` : `/u/${flex?.author?.username}`}
                  sx={{
                    fontWeight: '700',
                    fontSize: 'inherit',
                    textDecoration: 'none',
                    color: 'inherit',
                  }}
                >
                  {flex?.author?.username}
                  {flex?.author?.name}
                </Typography>
                &nbsp;&nbsp;
                {flex?.caption}
              </Typography>
            </Grid>
            <Grid className="comments" item xl={12} xs={12} sm={12} sx={{ pt: '20px' }}>
              {flex?.comments?.length === 0 ? (
                <Button
                  sx={{
                    color: 'rgba(247, 251, 250, .3) !important',
                  }}
                >
                  Be the first to write a comment
                </Button>
              ) : (
                <Button
                  sx={{
                    color: 'rgba(247, 251, 250, .3) !important',
                  }}
                >
                  View all {flex?.comments?.length > 0 ? flex?.comments?.length : ''} {flex?.comments?.length > 1 ? 'comments' : 'comment'}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Flex;

import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import _ from 'lodash';
import { MenuItem, Menu, Button, Grid, Box, CircularProgress, Typography, IconButton, Fade, CardMedia, Avatar, Alert, useMediaQuery, Card, CardHeader, CardContent, Divider } from '@mui/material';
import { CalendarMonth, Edit, MoreVert, Refresh, ThumbDown, ThumbUp } from '@mui/icons-material';
import { VersusIcon, FivezerWhiteIcon, LocationIcon, ShareIcon, BankCardsIcon, ScoreBoardIcon, FivezerLogoIcon } from '../../img/icons/Icons';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { Helmet } from 'react-helmet';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import Error from '../../component-modals/common/Error';
import LoadingComponent from '../../component-modals/common/LoadingComponent';
import BackButton from '../../component-modals/common/BackButton';
import { CHALLENGE_TYPES, FIELD_TYPES, MIN_CODE_LENGTH } from '../../utilities/utilities';
import PaySportSessionModal from '../../component-modals/purchase/PaySportSessionModal';
import { LoadingButton } from '@mui/lab';
import ViewTeamLineupModal from '../../component-modals/team/ViewTeamLineupModal';
import { useForm } from 'react-hook-form';
import ShareURLButton from '../../component-modals/common/ShareURLButton';
import ManageScoreModal from '../../component-modals/challenge/ManageScoreModal';
import ViewScoreModal from '../../component-modals/challenge/ViewScoreModal';
import MenuButton from '../../component-modals/layout/MenuButton';
import { selectSession } from '../../reducers/sessionSlice';
import InputSpendingCodeModal from '../../component-modals/purchase/InputSpendingCodeModal';
import { useIntl } from 'react-intl';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Challenge: React.FC = () => {
  const FIND_CHALLENGE_BY_ID_QUERY = gql`
    query findChallengeByID($challenge: inputFindChallengeByID!) {
      findChallengeByID(challenge: $challenge) {
        id
        short_id
        title
        status
        type
        is_private
        start_date
        end_date
        home_team {
          id
          name
          status
          players {
            id
            status
            team_number
            team_player {
              ... on FootballTeam {
                __typename
                id
                name
              }
            }
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
          captains {
            id
            status
            team_number
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
          team {
            ... on FootballTeam {
              __typename
              id
              name
              avatar {
                path
              }
              captains {
                id
                status
                team_number
                player {
                  ... on Footballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                  }
                  ... on Basketballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                  }
                }
              }
            }
          }
        }
        away_team {
          id
          name
          status
          players {
            id
            status
            team_number
            team_player {
              ... on FootballTeam {
                __typename
                id
                name
              }
            }
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
          captains {
            id
            status
            team_number
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
          team {
            ... on FootballTeam {
              __typename
              id
              name
              avatar {
                path
              }
              captains {
                id
                status
                team_number
                player {
                  ... on Footballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                  }
                  ... on Basketballer {
                    __typename
                    id
                    alias
                    player {
                      id
                    }
                  }
                }
              }
            }
          }
        }
        complex {
          id
          name
          location {
            id
            title
            coordinates
            country_code
            street_name
            secondary_street_name
            status
            postcode {
              name
            }
            city {
              name
            }
            county {
              name
            }
            state {
              name
            }
            country {
              name
            }
            continent {
              name
            }
          }
        }
        arena {
          id
          name
          type
          size
          pictures {
            path
          }
          location {
            id
            title
            coordinates
            country_code
            street_name
            secondary_street_name
            status
            postcode {
              name
            }
            city {
              name
            }
            county {
              name
            }
            state {
              name
            }
            country {
              name
            }
            continent {
              name
            }
          }
        }
        booking {
          id
          short_id
          status
          title
          start_date
          end_date
          cost {
            value
            currency {
              currency_code
            }
          }
          group_order {
            id
            status
            articles_bundles {
              id
              quantity
              item {
                id
                name
                pictures {
                  path
                }
              }
            }
            services_bundles {
              id
              quantity
              item {
                id
                name
                pictures {
                  path
                }
              }
            }
          }
          consumers {
            id
            payment_status
            individual_order {
              id
              status
              articles_bundles {
                id
                quantity
                item {
                  id
                  name
                  pictures {
                    path
                  }
                }
              }
              services_bundles {
                id
                quantity
                item {
                  id
                  name
                  pictures {
                    path
                  }
                }
              }
            }
            transaction {
              id
              status
              tx_reference
              note_reference
              execution_date
              confirmed_at
            }
            player {
              id
              status
              team_number
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  avatar {
                    path
                  }
                  player {
                    id
                    username
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  avatar {
                    path
                  }
                  player {
                    id
                    username
                  }
                }
              }
            }
          }
          payment_plan {
            id
            transactions {
              id
            }
          }
        }
        results {
          id
          status
          statistics {
            id
            status
            nb_goals
            team_player {
              id
              team_player {
                ... on FootballTeam {
                  __typename
                  id
                  name
                }
              }
            }
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
          captains_confirmations {
            ... on Footballer {
              __typename
              id
              alias
              avatar {
                path
              }
              player {
                id
              }
            }
            ... on Basketballer {
              __typename
              id
              alias
              avatar {
                path
              }
              player {
                id
              }
            }
          }
        }
      }
    }
  `;

  const ACCEPT_CHALLENGE_MUTATION = gql`
    mutation acceptChallenge($challenge: inputResponseChallenge!) {
      acceptChallenge(challenge: $challenge)
    }
  `;

  const DECLINE_CHALLENGE_MUTATION = gql`
    mutation declineChallenge($challenge: inputResponseChallenge!) {
      declineChallenge(challenge: $challenge)
    }
  `;

  const EXECUTE_DIRECT_FIAT_TRANSACTION_PAYMENT_MUTATION = gql`
    mutation executeDirectFiatPaymentTransaction($transaction: inputExecuteDirectTX!) {
      executeDirectFiatPaymentTransaction(transaction: $transaction)
    }
  `;

  const { challengeId } = useParams();
  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const intl = useIntl();
  const [challenge, setChallenge] = React.useState(UNDEFINED_AS_ANY);
  const [openPaySportSession, setOpenPaySportSession] = React.useState(false);
  const [openTeamLineup, setOpenTeamLineup] = React.useState(false);
  const [openManageScore, setOpenManageScore] = React.useState(false);
  const [openViewScore, setOpenViewScore] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [activeStep, setActiveStep] = React.useState(0);
  const isSmallMobile = useMediaQuery('(max-width:600px)');
  const [openConfirm, setOpenConfirm] = React.useState(false);

  const { watch, setValue, control } = useForm({
    defaultValues: {
      selected_team_lineup: '',
      selected_tx: '',
      spending_code: '',
    },
  });
  const inputFields = watch();

  const [executeDirectFiatPaymentTransaction, executedFiatTx] = useMutation(EXECUTE_DIRECT_FIAT_TRANSACTION_PAYMENT_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [acceptChallenge, acceptedChallenge] = useMutation(ACCEPT_CHALLENGE_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [declineChallenge, declinedChallenge] = useMutation(DECLINE_CHALLENGE_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedChallenge = useQuery(FIND_CHALLENGE_BY_ID_QUERY, {
    context: {
      headers: {
        'X-Anonymous-Access': 'true',
      },
    },
    variables: {
      challenge: {
        short_id: challengeId,
      },
    },
  });

  const ALL_TEAM_PLAYERS = _.concat(challenge?.home_team?.players, challenge?.away_team?.players);
  const ALL_AWAY_TEAM_CAPTAINS = _.concat(challenge?.away_team?.captains, challenge?.away_team?.team?.captains);
  const ALL_CAPTAINS = _.concat(challenge?.home_team?.captains, challenge?.away_team?.captains);
  const HOME_TEAM = challenge?.home_team;
  const AWAY_TEAM = challenge?.away_team;
  const HOME_TEAM_SCORE = _.sum(challenge?.results?.statistics?.filter((s: any) => s?.team_player?.team_player?.id === HOME_TEAM?.team?.id)?.map((s: any) => s?.nb_goals));
  const AWAY_TEAM_SCORE = _.sum(challenge?.results?.statistics?.filter((s: any) => s?.team_player?.team_player?.id === AWAY_TEAM?.team?.id)?.map((s: any) => s?.nb_goals));
  const ALL_TRANSACTIONS_TO_RETRY = challenge?.booking?.payment_plan?.transactions;

  const handleRefetch = async () => {
    try {
      const result = await loadedChallenge.refetch({
        challenge: {
          short_id: challengeId,
        },
      });

      if (result) {
        setChallenge(result.data?.findChallengeByID);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const handleClosePaySportSession = async () => {
    await handleRefetch();
    setOpenPaySportSession(false);
  };

  const handleCloseTeamLineup = () => {
    setValue('selected_team_lineup', '');
    setOpenTeamLineup(false);
  };

  const handleOpenTeamLineup = (team_lineup_id: string) => {
    setValue('selected_team_lineup', team_lineup_id);
    setOpenTeamLineup(true);
  };

  const handleCloseManageScore = async () => {
    await handleRefetch();
    setOpenManageScore(false);
  };

  const handleOpenConfirm = (tx_id: string) => {
    setValue('selected_tx', tx_id);
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setValue('selected_tx', '');
    setOpenConfirm(false);
  };

  const handleRetryTransaction = async () => {
    try {
      const found_tx = ALL_TRANSACTIONS_TO_RETRY?.find((tx: any) => tx?.id === inputFields.selected_tx);

      if (!(inputFields.spending_code?.length < MIN_CODE_LENGTH) && found_tx) {
        // note: we only use ID from found_tx, the rest of the values are placeholders
        await executeDirectFiatPaymentTransaction({
          context: {
            headers: {
              'Authorization': `Bearer ${session?.token?.key}`,
              'X-Anonymous-Access': 'false',
            },
          },
          variables: {
            transaction: {
              logged_account_id: loggedPlayer?.id,
              tx_data: {
                id: found_tx?.id,
                tx_reference: found_tx?.tx_reference || '',
                type: 'PAYMENT',
                original_value: {
                  value: parseFloat((found_tx?.value?.value || 0)?.toString()),
                  currency: found_tx?.value?.currency?.currency_code || '',
                },
                maat_value: {
                  value: parseFloat((found_tx?.maat_value?.value || 0)?.toString()),
                  currency: 'MAT',
                },
                tx_fee: {
                  value: parseFloat((found_tx?.tx_fee?.value || 0)?.toString()),
                  currency: 'MAT',
                },
                assets: [],
                sender_wallet_id: found_tx?.sender_wallet?.id || '',
                sender_payment_method_id: found_tx?.sender_payment_method?.id || '',
                receiver_wallet_id: found_tx?.receiver_wallet?.id || '',
                receiver_payment_method_id: found_tx?.receiver_payment_method?.id || '',
                signatories: [],
                sender_payment_source_id: found_tx?.sender_payment_method?.payment_source?.id || '',
                receiver_payment_source_id: found_tx?.sender_payment_method?.payment_source?.id || '',
                execution_date: parseFloat(Date.now().toString()),
              },
              spending_password: inputFields.spending_code,
            },
          },
        });
      }

      await handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  const handleAcceptChallenge = async () => {
    try {
      await acceptChallenge({
        variables: {
          challenge: {
            short_id: challenge?.short_id,
            logged_player_id: loggedPlayer?.id,
          },
        },
      });
      await handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeclineChallenge = async () => {
    try {
      await declineChallenge({
        variables: {
          challenge: {
            short_id: challenge?.short_id,
            logged_player_id: loggedPlayer?.id,
          },
        },
      });
      await handleRefetch();
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if ((!challenge && loadedChallenge.called && loadedChallenge.data?.findChallengeByID) || !_.isMatch(challenge, loadedChallenge.data?.findChallengeByID)) {
      setChallenge(loadedChallenge.data?.findChallengeByID);
    }
  }, [challenge, loadedChallenge]);

  return (
    <Grid className={`component-challenge challenge-page ${loggedPlayer?.id && !loggedPlayer?.app_configuration?.is_dark_mode ? 'light-mode' : 'dark-mode'}`} sx={{ p: '0', minHeight: '100%' }}>
      <Helmet>
        <title>{challenge ? `${challenge?.title} (${intl.formatMessage({ id: 'Challenge_Meta_Title' })})` : `${intl.formatMessage({ id: 'Challenge_Meta_Title' })}`} x Fivezer #DifferentAnimalSameBeast</title>
        <meta name="description" content={''} />
        <link rel="canonical" href={`${window.location.origin}` + window.location.pathname} />
      </Helmet>

      <Grid item xs={12} md={12} lg={12}>
        {challenge === undefined || loadedChallenge.loading ? (
          <Grid item xl={12} xs={12} sm={12} sx={{ p: '0 10%' }}>
            {challenge === undefined && loadedChallenge.called && !loadedChallenge.loading ? <Error title={`${challengeId}`} status={loadedChallenge.error ? 403 : 404} content={loadedChallenge.error ? '' : `We are sorry but it seems like we can not find this challenge`} /> : ''}

            {loadedChallenge.loading && (challenge === undefined || !loadedChallenge.error) ? <LoadingComponent amount={1} text={<Typography sx={{ mb: '20px' }}>Loading {challengeId}, please wait...</Typography>} loader={<CircularProgress className="orange-loader" />} /> : ''}
          </Grid>
        ) : (
          <Grid item xl={12} xs={12} sm={12} sx={{}}>
            {challenge?.booking?.cost?.value > 0 && challenge?.status === 'ACCEPTED' && ALL_TEAM_PLAYERS?.find((p: any) => p?.player?.player?.id === loggedPlayer?.id) && !challenge?.booking?.consumers?.find((c: any) => c?.player?.player?.player?.id === loggedPlayer?.id) ? (
              <Alert
                severity="info"
                variant="filled"
                sx={{
                  'width': '100%',
                  'display': { xs: 'inline-block', sm: 'flex' },
                  'textAlign': 'center',
                  '& .MuiAlert-icon': { display: { xs: 'inline', sm: 'flex' }, m: '0 12px' },
                  '& .MuiAlert-action': { display: { xs: 'block', sm: 'flex' }, p: { xs: '0', sm: 'inherit' } },
                }}
                action={
                  <Button className="button-blue" onClick={() => setOpenPaySportSession(true)} sx={{ whiteSpace: 'nowrap', fontWeight: '600', boxShadow: 'none', alignItems: 'center' }}>
                    <BankCardsIcon />
                    &nbsp;{`${intl.formatMessage({ id: 'Global_Button_Pay_Now' })}`}
                  </Button>
                }
              >
                {`${intl.formatMessage({ id: 'Challenge_Payment_Caption_1' })}`}
              </Alert>
            ) : (
              ''
            )}

            {(challenge?.status === 'PENDING' || challenge?.status === 'WAITING_RESPONSE') && ALL_AWAY_TEAM_CAPTAINS?.find((p: any) => p?.player?.player?.id === loggedPlayer?.id) ? (
              <Alert
                severity="info"
                variant="filled"
                sx={{
                  'width': '100%',
                  'display': { xs: 'inline-block', sm: 'flex' },
                  'textAlign': 'center',
                  '& .MuiAlert-icon': { display: { xs: 'inline', sm: 'flex' }, m: '0 12px' },
                  '& .MuiAlert-action': { display: { xs: 'block', sm: 'flex' }, p: { xs: '0', sm: 'inherit' }, m: 'auto', width: { xs: '100%', sm: '50%' } },
                }}
                action={
                  <Grid container sx={{ justifyContent: 'center' }}>
                    <LoadingButton className={declinedChallenge.loading ? 'button-disabled' : 'button-green'} disabled={declinedChallenge.loading} loading={acceptedChallenge.loading} endIcon={<ThumbUp />} loadingPosition="end" onClick={handleAcceptChallenge} sx={{ whiteSpace: 'nowrap', fontWeight: '700', boxShadow: 'none', mr: '10%', alignItems: 'center' }}>
                      {`${intl.formatMessage({ id: 'Challenge_Button_Accept' })}`}
                    </LoadingButton>

                    <LoadingButton className={acceptedChallenge.loading ? 'button-disabled' : 'button-red-reversed'} disabled={acceptedChallenge.loading} loading={declinedChallenge.loading} endIcon={<ThumbDown />} loadingPosition="end" onClick={handleDeclineChallenge} sx={{ whiteSpace: 'nowrap', fontWeight: '700', boxShadow: 'none', ml: '10%', alignItems: 'center' }}>
                      {`${intl.formatMessage({ id: 'Challenge_Button_Decline' })}`}
                    </LoadingButton>
                  </Grid>
                }
              >
                {`${intl.formatMessage({ id: 'Challenge_Accept_Challenge' })}`}
              </Alert>
            ) : (
              ''
            )}
            <AutoPlaySwipeableViews axis={'x-reverse'} index={activeStep} onChangeIndex={handleStepChange} enableMouseEvents>
              <CardMedia
                sx={{
                  height: '40vh',
                  // backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.8) 50%), url(${challenge?.arena?.pictures[activeStep]?.path})`,
                  backgroundImage: ` url(${challenge?.arena?.pictures[activeStep]?.path})`,
                }}
              >
                <Grid container>
                  <Grid item xs={2} md={2} lg={2} sx={{ textAlign: 'center' }}></Grid>
                  <Grid item xs={8} md={8} lg={8} sx={{ textAlign: 'center' }}>
                    <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
                      <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '30px', sm: '35px', md: '40px' }, p: '0' }} />
                    </IconButton>
                  </Grid>
                  <Grid item xs={2} md={2} lg={2} sx={{ textAlign: 'right', p: '0 40px' }}>
                    <MenuButton />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={6} md={6} lg={6} sx={{ textAlign: 'left', p: '0' }}>
                    <BackButton color={!loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(15,15,15,1)' : 'rgba(247, 251, 250, 1)'} hoverColor={!loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(15,15,15,1)' : 'rgba(247, 251, 250, 1)'} hoverBGColor={!loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(15,15,15,.04)' : 'rgba(247, 251, 250, .3)'} />
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    md={6}
                    lg={6}
                    sx={{
                      textAlign: 'right',
                      p: '0',
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'end',
                    }}
                  >
                    {challenge?.is_private && !ALL_TEAM_PLAYERS?.find((p: any) => p?.player?.player?.id === loggedPlayer?.id) ? (
                      ''
                    ) : (
                      <>
                        <Box>
                          <IconButton id="fade-button" aria-controls={open ? 'fade-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                            <MoreVert />
                          </IconButton>
                        </Box>

                        <Menu
                          id="fade-menu"
                          keepMounted
                          MenuListProps={{
                            'aria-labelledby': 'fade-button',
                            'dense': true,
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          TransitionComponent={Fade}
                        >
                          {ALL_TEAM_PLAYERS?.find((p: any) => p?.player?.player?.id === loggedPlayer?.id) && (challenge?.home_team?.team || challenge?.away_team?.team) ? (
                            <Grid>
                              {loggedPlayer?.id ? (
                                <Grid>
                                  {loggedPlayer?.email?.status !== 'VERIFIED' || challenge?.status === 'CANCELLED' ? (
                                    ''
                                  ) : (
                                    <MenuItem component={Link} to="/challenge/edit" state={{ challenge: { id: challenge?.id } }}>
                                      <Button
                                        variant="text"
                                        sx={{
                                          '&:hover': {
                                            background: 'transparent !important',
                                          },
                                        }}
                                        startIcon={<Edit />}
                                      >
                                        {`${intl.formatMessage({ id: 'Challenge_Button_Edit' })}`}
                                      </Button>
                                    </MenuItem>
                                  )}
                                </Grid>
                              ) : (
                                ''
                              )}
                            </Grid>
                          ) : (
                            ''
                          )}

                          <ShareURLButton
                            button={
                              <MenuItem>
                                <Button
                                  variant="text"
                                  sx={{
                                    '&:hover': {
                                      background: 'transparent !important',
                                    },
                                  }}
                                  startIcon={<ShareIcon />}
                                >
                                  {`${intl.formatMessage({ id: 'Global_Button_Share' })}`}
                                </Button>
                              </MenuItem>
                            }
                            url={window.location.href}
                            title={`${challenge?.title}`}
                            text={`${intl.formatMessage({ id: 'Global_Button_Share_Caption_1' })} ${challenge?.title} ${intl.formatMessage({ id: 'Global_Button_Share_Caption_2' })} `}
                          />
                        </Menu>
                      </>
                    )}
                  </Grid>
                </Grid>
              </CardMedia>
            </AutoPlaySwipeableViews>

            {challenge?.is_private && !ALL_TEAM_PLAYERS?.find((p: any) => p?.player?.player?.id === loggedPlayer?.id) ? (
              <Grid container sx={{ height: '60vh', justifyContent: 'center', alignItems: 'center', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', position: 'relative', mt: '-30px', bgcolor: 'rgba(15, 15, 15, 1)' }}>
                <Typography sx={{ fontSize: '1.3em', textAlign: 'center', position: 'relative' }}>{`${intl.formatMessage({ id: 'Challenge_Private_Event' })}`}</Typography>
              </Grid>
            ) : (
              <Grid item xs={12} md={12} lg={12} sx={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px', position: 'relative', mt: '-30px', bgcolor: 'rgba(15, 15, 15, 1)' }}>
                <Grid container sx={{ justifyContent: 'center', position: 'relative', top: '-10px' }}>
                  <Grid sx={{ borderRadius: '20px', display: 'flex', alignItems: 'center', p: '5px 20px', bgcolor: 'rgba(24, 30, 36, 1)' }}>{CHALLENGE_TYPES?.find((c: any) => c?.value === challenge?.type)?.icon}</Grid>
                  <Typography className={challenge?.status} sx={{ fontWeight: '800', width: '100%', textAlign: 'center', fontSize: '.8em', m: '5px 0 0' }}>
                    {challenge?.status?.replaceAll('_', ' ')}
                  </Typography>
                </Grid>
                <Grid container sx={{ p: '10px 10% 30px', textAlign: 'center' }}>
                  <Grid item xs={5.5} sm={5.5} md={5.5} lg={5.5} sx={{ 'display': { xs: 'inline-block', sm: 'inline-flex' }, 'textAlign': 'center', 'alignItems': 'center', 'justifyContent': 'center', '&:hover': { '& .MuiAvatar-root': { border: challenge?.home_team?.id ? 'solid 3px rgba(199, 240, 11, 1)' : '' } } }} onClick={challenge?.home_team?.id ? () => handleOpenTeamLineup(challenge?.home_team?.id) : undefined}>
                    <Grid sx={{ textAlign: 'center' }}>
                      <Typography
                        sx={{
                          lineHeight: '15px',
                          fontWeight: '800',
                          textTransform: 'uppercase',
                          height: '58px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {challenge?.home_team?.name}
                      </Typography>
                      {challenge?.home_team?.team?.avatar?.path ? <Avatar src={challenge?.home_team?.team?.avatar?.path} alt={challenge?.home_team?.name} sx={{ m: 'auto', width: { xs: '60px', sm: '80px', md: '100px' }, height: { xs: '60px', sm: '80px', md: '100px' } }} /> : <FivezerWhiteIcon sx={{ m: 'auto', opacity: '.05', width: { xs: '40px', sm: '60px', md: '80px' }, height: { xs: '40px', sm: '60px', md: '80px' } }} />}
                    </Grid>
                    {challenge?.results?.status === 'CONFIRMED' ? <Typography sx={{ color: HOME_TEAM_SCORE > AWAY_TEAM_SCORE ? 'rgba(168, 202, 65, 1) !important' : '', fontWeight: 'bold', fontSize: { xs: '4em', sm: '5em' }, width: { xs: '100%', sm: 'auto' } }}>{HOME_TEAM_SCORE}</Typography> : ''}
                  </Grid>
                  <Grid className="separator" item xs={1} sm={1} md={1} lg={1} xl={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <VersusIcon sx={{ m: '0', color: 'rgba(238, 44, 32, 1) !important', fontSize: { xs: '50px', sm: '50px' } }} />
                  </Grid>
                  <Grid item xs={5.5} sm={5.5} md={5.5} lg={5.5} sx={{ 'display': { xs: 'inline-block', sm: 'inline-flex' }, 'textAlign': 'center', 'alignItems': 'center', 'justifyContent': 'center', '&:hover': { '& .MuiAvatar-root': { border: challenge?.away_team?.id ? 'solid 3px rgba(199, 240, 11, 1)' : '' } } }} onClick={challenge?.away_team?.id ? () => handleOpenTeamLineup(challenge?.away_team?.id) : undefined}>
                    {challenge?.results?.status === 'CONFIRMED' ? <>{isSmallMobile ? '' : <Typography sx={{ color: HOME_TEAM_SCORE < AWAY_TEAM_SCORE ? 'rgba(168, 202, 65, 1) !important' : '', fontWeight: 'bold', fontSize: { xs: '4em', sm: '5em' }, width: { xs: '100%', sm: 'auto' } }}>{AWAY_TEAM_SCORE}</Typography>}</> : ''}
                    <Grid sx={{ textAlign: 'center' }}>
                      <Typography
                        sx={{
                          lineHeight: '15px',
                          fontWeight: '800',
                          textTransform: 'uppercase',
                          height: '58px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {challenge?.away_team?.name}
                      </Typography>
                      {challenge?.away_team?.team?.avatar?.path ? <Avatar src={challenge?.away_team?.team?.avatar?.path} alt={challenge?.away_team?.name} sx={{ m: 'auto', width: { xs: '60px', sm: '80px', md: '100px' }, height: { xs: '60px', sm: '80px', md: '100px' } }} /> : <FivezerWhiteIcon sx={{ m: 'auto', opacity: '.05', width: { xs: '40px', sm: '60px', md: '80px' }, height: { xs: '40px', sm: '60px', md: '80px' } }} />}
                    </Grid>

                    {challenge?.results?.status === 'CONFIRMED' ? <>{isSmallMobile ? <Typography sx={{ color: HOME_TEAM_SCORE < AWAY_TEAM_SCORE ? 'rgba(168, 202, 65, 1) !important' : '', fontWeight: 'bold', fontSize: { xs: '4em', sm: '5em' }, width: { xs: '100%', sm: 'auto' } }}>{AWAY_TEAM_SCORE}</Typography> : ''}</> : ''}
                  </Grid>
                  {challenge?.results?.status === 'CONFIRMED' ? (
                    <Grid container sx={{ justifyContent: 'center' }}>
                      <Button className="button-cancel" onClick={() => setOpenViewScore(true)} sx={{ fontWeight: '600 !important', fontSize: '.8em' }}>
                        {`${intl.formatMessage({ id: 'Challenge_Button_Results' })}`}
                      </Button>
                      <ViewScoreModal challenge_short_id={challenge?.short_id} open={openViewScore} handleClose={() => setOpenViewScore(false)} />
                    </Grid>
                  ) : (
                    ''
                  )}
                  <ViewTeamLineupModal team_lineup_id={inputFields.selected_team_lineup} open={openTeamLineup} handleClose={handleCloseTeamLineup} />
                </Grid>
                <Grid container sx={{ minHeight: '40vh', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', bgcolor: 'rgba(24, 30, 36, 1)', pt: '50px' }}>
                  <Grid id="max-width" item xs={12} md={12} lg={12} sx={{ p: '0 5%' }}>
                    <Typography sx={{ textAlign: 'center', fontSize: '1.6em', fontWeight: '800', lineHeight: '25px', pb: '20px' }}>{challenge?.title}</Typography>
                    <Grid container className="address" sx={{ '& *': { color: 'rgba(247, 251, 250, 0.3) !important' } }}>
                      <Grid item xs={2} sm={2} md={2} lg={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <LocationIcon />
                      </Grid>
                      <Grid item xs={10} sm={10} md={10} lg={10} component="a" href={`geo:${(challenge?.complex || challenge?.arena)?.location?.coordinates?.latitude},${(challenge?.complex || challenge?.arena)?.location?.coordinates?.longitude}`} target="_blank">
                        <Typography>{(challenge?.complex || challenge?.arena)?.location?.title}</Typography>

                        <Typography
                          sx={{
                            display: (challenge?.complex || challenge?.arena)?.location?.street_name === '' ? 'none' : 'inline-block',
                            width: '100%',
                          }}
                          className={'primary-address'}
                        >
                          {(challenge?.complex || challenge?.arena)?.location?.street_name}
                        </Typography>
                        <Typography
                          sx={{
                            display: (challenge?.complex || challenge?.arena)?.location?.secondary_street_name === '' ? 'none' : 'inline-block',
                            width: '100%',
                          }}
                          className={'secondary-address'}
                        >
                          {(challenge?.complex || challenge?.arena)?.location?.secondary_street_name}
                        </Typography>
                        <Typography
                          sx={{
                            display: 'inline-block',
                            width: '100%',
                          }}
                          className={'postcode'}
                        >
                          {(challenge?.complex || challenge?.arena)?.location?.postcode?.name} - {(challenge?.complex || challenge?.arena)?.location?.city?.name} {(challenge?.complex || challenge?.arena)?.location?.state?.name ? `- ${(challenge?.complex || challenge?.arena)?.location?.state?.name}` : ''}
                        </Typography>
                        <Typography
                          sx={{
                            display: 'inline-block',
                            width: '100%',
                          }}
                          className={'country'}
                        >
                          <Grid container sx={{ alignItems: 'center' }}>
                            {(challenge?.complex || challenge?.arena)?.location?.country?.name}&nbsp;
                            <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${(challenge?.complex || challenge?.arena)?.location?.country_code?.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${(challenge?.complex || challenge?.arena)?.location?.country_code?.toLowerCase()}.png 2x`} alt="" />
                          </Grid>
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container className="dates" sx={{ '& *': { color: 'rgba(247, 251, 250, 0.3) !important' }, 'pt': '20px' }}>
                      <Grid item xs={2} sm={2} md={2} lg={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <CalendarMonth />
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5}>
                        <Typography>
                          <Moment format="ddd D MMM YYYY">{challenge?.start_date}</Moment>
                        </Typography>
                      </Grid>
                      <Grid item xs={5} sm={5} md={5} lg={5} sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Typography>
                          <Moment format="HH:mm">{challenge?.start_date}</Moment>
                        </Typography>
                        &nbsp;&rarr;&nbsp;
                        <Typography>
                          <Moment format="HH:mm">{challenge?.end_date}</Moment>
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container className="field" sx={{ '& *': { color: 'rgba(247, 251, 250, 0.3) !important' }, 'pt': '20px' }}>
                      <Grid item xs={2} sm={2} md={2} lg={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {FIELD_TYPES?.find((f: any) => f?.value === challenge?.arena?.type)?.icon}
                      </Grid>
                      <Grid item xs={10} sm={10} md={10} lg={10}>
                        <Typography>
                          {challenge?.arena?.name} ({`${intl.formatMessage({ id: FIELD_TYPES?.find((f: any) => f?.value === challenge?.arena?.type)?.label })}`})
                        </Typography>
                      </Grid>
                    </Grid>

                    {ALL_TEAM_PLAYERS?.find((p: any) => p?.player?.player?.id === loggedPlayer?.id) && challenge?.booking?.group_order ? (
                      <>
                        <Divider
                          variant="middle"
                          sx={{
                            width: '-webkit-fill-available',
                            borderColor: 'rgba(255, 255, 255, .3)',
                            mt: '30px',
                          }}
                        />
                        <Typography sx={{ fontWeight: '800', fontSize: '1.6em', mt: '20px' }}>{`${intl.formatMessage({ id: 'Challenge_Group_Order_Label' })}`}</Typography>
                        <Grid container sx={{ 'p': '10px 0', 'width': '100%', '& *': { color: 'rgba(247, 251, 250, 1)' }, 'pt': '20px' }}>
                          {_.concat(challenge?.booking?.group_order?.articles_bundles, challenge?.booking?.group_order?.services_bundles)?.map((i: any) => (
                            <Grid key={i?.id} sx={{ p: '5px' }}>
                              <Card
                                sx={{
                                  bgcolor: 'rgba(247, 251, 250, .1)',
                                  p: '0px',
                                  width: '100%',
                                  borderRadius: '20px',
                                  m: '0px',
                                }}
                              >
                                <CardHeader
                                  avatar={<Avatar variant="rounded" src={i?.item?.pictures[0]?.path} aria-label={i?.item?.name} />}
                                  title={`${i?.quantity} x ${i?.item?.name}`}
                                  titleTypographyProps={{}}
                                  subheader={
                                    <>
                                      {`${intl.formatMessage({ id: 'Global_Status' })}`}: <strong className={challenge?.booking?.group_order?.status}>{challenge?.booking?.group_order?.status?.replaceAll('_', ' ')}</strong>
                                    </>
                                  }
                                  subheaderTypographyProps={{ textTransform: 'uppercase', fontSize: '.7em !important', color: 'rgba(247, 251, 250, 1)' }}
                                />
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                        <Divider
                          variant="middle"
                          sx={{
                            width: '-webkit-fill-available',
                            borderColor: 'rgba(255, 255, 255, .3)',
                            m: '10px 0',
                          }}
                        />
                      </>
                    ) : (
                      ''
                    )}

                    {ALL_TEAM_PLAYERS?.find((p: any) => p?.player?.player?.id === loggedPlayer?.id) && challenge?.booking?.consumers?.length > 0 ? (
                      <>
                        <Typography sx={{ fontWeight: '800', fontSize: '1.6em', mt: '20px' }}>
                          {challenge?.booking?.consumers?.length ?? 0} {challenge?.booking?.consumers?.length > 1 ? 'payments' : 'payment'}
                        </Typography>
                        <Grid container sx={{ 'p': '10px 0', 'width': '100%', '& *': { color: 'rgba(247, 251, 250, 1)' }, 'pt': '20px' }}>
                          {challenge?.booking?.consumers?.map((c: any) => (
                            <Card key={c?.id} sx={{ width: '100%', bgcolor: 'rgba(247, 251, 250, 0.1)', boxShadow: 'none' }}>
                              <CardHeader
                                avatar={<Avatar src={c?.player?.player?.avatar?.path || c?.player?.player?.player?.avatar?.path} aria-label={c?.player?.player?.alias} />}
                                action={
                                  c?.payment_status !== 'COMPLETED' && c?.player?.player?.player?.id === loggedPlayer?.id ? (
                                    <LoadingButton size="small" onClick={() => handleOpenConfirm(c?.transaction?.id)} loading={executedFiatTx.loading} loadingPosition="end" endIcon={<Refresh />} className={'WARNING'}>
                                      {`${intl.formatMessage({ id: 'Challenge_Button_Retry' })}`}
                                    </LoadingButton>
                                  ) : (
                                    ''
                                  )
                                }
                                title={`${c?.player?.player?.player?.username} ${c?.player?.player?.alias ? `aka ${c?.player?.player?.alias}` : ''}`}
                                titleTypographyProps={{}}
                                subheader={
                                  <>
                                    {`${intl.formatMessage({ id: 'Global_Status' })}`}: <strong className={c?.payment_status}>{c?.payment_status?.replaceAll('_', ' ')}</strong>
                                  </>
                                }
                                subheaderTypographyProps={{ textTransform: 'uppercase', fontSize: '.7em !important', color: 'rgba(247, 251, 250, 1)' }}
                              />

                              {c?.individual_order ? (
                                <CardContent sx={{ pb: '0', pt: '0' }}>
                                  <Typography sx={{ fontWeight: '800', fontSize: '1.2em' }}>{`${intl.formatMessage({ id: 'Challenge_Personal_Order_Label' })}`}</Typography>
                                  <Grid container sx={{ 'p': '10px 0', 'width': '100%', '& *': { color: 'rgba(247, 251, 250, 1)' }, 'pt': '20px' }}>
                                    {_.concat(c?.individual_order?.articles_bundles, c?.individual_order?.services_bundles)?.map((i: any) => (
                                      <Grid key={i?.id} sx={{ p: '5px' }}>
                                        <Card
                                          sx={{
                                            bgcolor: 'rgba(247, 251, 250, .1)',
                                            p: '0px',
                                            width: '100%',
                                            borderRadius: '20px',
                                            m: '0',
                                          }}
                                        >
                                          <CardHeader
                                            avatar={<Avatar variant="rounded" src={i?.item?.pictures[0]?.path} aria-label={i?.item?.name} />}
                                            title={`${i?.quantity} x ${i?.item?.name}`}
                                            titleTypographyProps={{}}
                                            subheader={
                                              <>
                                                {`${intl.formatMessage({ id: 'Global_Status' })}`}: <strong className={c?.individual_order?.status}>{c?.individual_order?.status?.replaceAll('_', ' ')}</strong>
                                              </>
                                            }
                                            subheaderTypographyProps={{ textTransform: 'uppercase', fontSize: '.7em !important', color: 'rgba(247, 251, 250, 1)' }}
                                          />
                                        </Card>
                                      </Grid>
                                    ))}
                                  </Grid>
                                </CardContent>
                              ) : (
                                ''
                              )}
                            </Card>
                          ))}
                        </Grid>
                        <InputSpendingCodeModal open={openConfirm} control={control} setter={setValue} input={inputFields.spending_code} actionOne={handleRetryTransaction} actionTwo={handleCloseConfirm} />
                      </>
                    ) : (
                      ''
                    )}
                  </Grid>

                  <Grid container sx={{ minHeight: '15vh', borderTopLeftRadius: '20px', borderTopRightRadius: '20px', bgcolor: 'rgba(247, 251, 250, .1)', mt: '30px', p: { xs: '30px 5% 100px', sm: '30px 5% 80px', md: '30px 5%' } }}>
                    <Grid id="max-width" item xs={12} md={12} lg={12} sx={{ textAlign: 'center' }}>
                      {challenge?.booking?.cost?.value > 0 && challenge?.status === 'ACCEPTED' && ALL_TEAM_PLAYERS?.find((p: any) => p?.player?.player?.id === loggedPlayer?.id) && !challenge?.booking?.consumers?.find((c: any) => c?.player?.player?.player?.id === loggedPlayer?.id) ? (
                        <>
                          <Button className={'button-green'} onClick={() => setOpenPaySportSession(true)} size="large" sx={{ fontWeight: '600', m: '10px', width: { xs: '80%', sm: '40%', md: '30%' } }}>
                            <BankCardsIcon />
                            &nbsp;{`${intl.formatMessage({ id: 'Global_Button_Pay_Now' })}`}
                          </Button>
                          <PaySportSessionModal session_id={challenge?.booking?.id} open={openPaySportSession} handleClose={handleClosePaySportSession} />
                        </>
                      ) : (
                        ''
                      )}

                      {challenge?.results?.status !== 'CONFIRMED' && challenge?.end_date <= Date.now() && ALL_CAPTAINS?.find((c: any) => c?.player?.player?.id === loggedPlayer?.id) ? (
                        <>
                          <Button className={challenge?.results?.status === 'PARTIALLY_CONFIRMED' && challenge?.results?.captains_confirmations?.find((p: any) => p?.player?.id === loggedPlayer?.id) ? 'button-disabled' : 'button-orange'} onClick={() => setOpenManageScore(true)} size="large" sx={{ fontWeight: '600', m: '10px', width: { xs: '80%', sm: '40%', md: '30%' } }}>
                            <ScoreBoardIcon />
                            &nbsp;
                            {challenge?.results?.status === 'PARTIALLY_CONFIRMED' && challenge?.results?.captains_confirmations?.find((p: any) => p?.player?.id === loggedPlayer?.id) ? `${intl.formatMessage({ id: 'Challenge_Partcially_Paid_1' })}` : `${intl.formatMessage({ id: 'Challenge_Partcially_Paid_2' })}`}
                          </Button>
                          <ManageScoreModal challenge_short_id={challenge?.short_id} open={openManageScore} handleClose={handleCloseManageScore} />
                        </>
                      ) : (
                        ''
                      )}

                      {challenge?.booking?.cost?.value > 0 && challenge?.results?.status === 'CONFIRMED' && challenge?.end_date <= Date.now() && ALL_CAPTAINS?.find((c: any) => c?.player?.player?.id === loggedPlayer?.id) ? (
                        <>
                          <Button component={Link} to={'/new_challenge'} state={{ rematch_challenge: challenge }} className={'button-green'} size="large" sx={{ fontWeight: '600', m: '10px', width: { xs: '80%', sm: '40%', md: '30%' } }}>
                            {`${intl.formatMessage({ id: 'Global_Button_Share' })}`}
                          </Button>
                        </>
                      ) : (
                        ''
                      )}

                      <ShareURLButton
                        button={
                          <Button className={'button-green-reverse'} size="large" sx={{ fontWeight: '600', m: '10px', width: { xs: '80%', sm: '40%', md: '30%' } }}>
                            <ShareIcon />
                            &nbsp;{`${intl.formatMessage({ id: 'Global_Button_Share' })}`}
                          </Button>
                        }
                        title={challenge?.title}
                        url={window.location.href}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Challenge;

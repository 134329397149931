import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Add, Delete } from '@mui/icons-material';
import { Fab, Grid, ListSubheader, MenuItem, TextField, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { LANGUAGES } from '../../utilities/utilities';
import { Controller } from 'react-hook-form';

interface IInputDescriptionsFeature {
  descriptions: any;
  selected_description: any;
  selected_description_content: string;
  short_descriptions?: any;
  selected_short_description?: any;
  selected_short_description_content?: string;
  translated_desc_to_add: string;
  setter: any;
  control: any;
}

const InputDescriptionsFeature: React.FC<IInputDescriptionsFeature> = (props: IInputDescriptionsFeature) => {
  const intl = useIntl();

  const handleNewDescriptionTranslation = (country_code: string) => {
    if (props.descriptions.find((desc: any) => desc.language === country_code) === undefined && country_code !== '') {
      const new_descriptions = _.concat(props.descriptions, {
        __typename: 'Description',
        language: country_code,
        content: '',
      });
      props.setter('descriptions', new_descriptions);
    }

    if (props.short_descriptions?.find((desc: any) => desc.language === country_code) === undefined && country_code !== '') {
      const new_short_descriptions = _.concat(props.short_descriptions, {
        __typename: 'Description',
        language: country_code,
        content: '',
      });
      props.setter('short_descriptions', new_short_descriptions);
    }
  };

  const handleSaveDescription = () => {
    const new_translation = {
      __typename: 'Description',
      language: props.selected_description.language,
      content: props.selected_description_content,
    };
    const new_descriptions = _.concat(
      props.descriptions.filter((d: any) => d.language !== props.selected_description.language),
      new_translation
    );

    props.setter('descriptions', new_descriptions);

    if (props.short_descriptions) {
      const new_short_translation = {
        __typename: 'Description',
        language: props.selected_short_description.language,
        content: props.selected_short_description_content,
      };
      const new_short_descriptions = _.concat(
        props.short_descriptions.filter((d: any) => d.language !== props.selected_short_description.language),
        new_short_translation
      );
      props.setter('short_descriptions', new_short_descriptions);
    }
  };

  const handleDeleteDescription = () => {
    const new_descriptions = props.descriptions.filter((d: any) => d.language !== props.selected_description.language);
    props.setter('descriptions', new_descriptions);
    props.setter('selected_description', new_descriptions[new_descriptions.length - 1]);
    props.setter('selected_description_content', new_descriptions[new_descriptions.length - 1]?.content);

    if (props.short_descriptions) {
      const new_short_descriptions = props.short_descriptions.filter((d: any) => d.language !== props.selected_short_description.language);
      props.setter('short_descriptions', new_short_descriptions);
      props.setter('selected_short_description', new_short_descriptions[new_short_descriptions.length - 1]);
      props.setter('selected_short_description_content', new_short_descriptions[new_short_descriptions.length - 1]?.content);
    }
  };

  const handleSwitchDescription = (value: any) => {
    props.setter(
      'selected_description',
      props.descriptions.find((d: any) => d.language === value.language)
    );
    props.setter('selected_description_content', value?.content);

    if (props.short_descriptions) {
      props.setter(
        'selected_short_description',
        props.short_descriptions.find((d: any) => d.language === value.language)
      );
      props.setter('selected_short_description_content', props.short_descriptions.find((d: any) => d.language === value.language)?.content);
    }
  };

  return (
    <>
      <Controller
        control={props.control}
        name="translated_desc_to_add"
        render={({ field }: any) => (
          <TextField
            {...field}
            value={field.value}
            select
            SelectProps={{
              MenuProps: {
                sx: {
                  zIndex: '1000000000',
                },
              },
            }}
            className={'field-bottom-space'}
            label={<FormattedMessage id="InputDescriptionsFeature_Descriptions_Label" />}
            placeholder={`${intl.formatMessage({ id: 'InputDescriptionsFeature_Descriptions_Placeholder' })}`}
            variant="outlined"
            fullWidth
          >
            {LANGUAGES?.filter((l: any) => !props.descriptions?.find((d: any) => d?.language === l?.value)).map((option: any, index: number) =>
              option.value === 'subheader' ? (
                <ListSubheader key={index}>
                  {option.label === 'AFRICAN_LANGUAGES' ? <FormattedMessage id="InputDescriptionsFeature_African_Languages" /> : ''}
                  {option.label === 'OTHER_LANGUAGES' ? <FormattedMessage id="InputDescriptionsFeature_Other_Languages" /> : ''}
                </ListSubheader>
              ) : (
                <MenuItem key={index} value={option.value} divider={index !== LANGUAGES.length - 1}>
                  <img loading="lazy" width="20" src={option.value !== 'AU' ? `https://flagcdn.com/w20/${_.split(option.value, '_', 1)[0].toLowerCase()}.png` : `https://flagcdn.com/org/w20/${_.split(option.value, '_', 1)[0].toLowerCase()}.png`} srcSet={option.value !== 'AU' ? `https://flagcdn.com/w40/${_.split(option.value, '_', 1)[0].toLowerCase()}.png 2x` : `https://flagcdn.com/org/w40/${_.split(option.value, '_', 1)[0].toLowerCase()}.png 2x`} alt="" />
                  &nbsp;
                  {option.label}
                </MenuItem>
              )
            )}
          </TextField>
        )}
      />
      <Grid container sx={{ justifyContent: 'center' }}>
        <Fab
          className="button-green-reverse"
          variant="extended"
          size="small"
          onClick={() => handleNewDescriptionTranslation(props.translated_desc_to_add)}
          sx={{
            background: 'transparent',
            boxShadow: 'none',
            mb: '40px',
          }}
        >
          <Add />
          &nbsp;
          <FormattedMessage id="InputDescriptionsFeature_Add_Language" />
        </Fab>
      </Grid>

      {props?.descriptions?.length === 0 || !props?.descriptions ? (
        ''
      ) : (
        <>
          <Controller
            control={props.control}
            name="selected_description"
            render={({ field }: any) => (
              <TextField
                {...field}
                value={field.value}
                select
                SelectProps={{
                  MenuProps: {
                    sx: {
                      zIndex: '1000000000',
                    },
                  },
                }}
                className={'field-bottom-space'}
                label={`${intl.formatMessage({ id: 'InputDescriptionsFeature_Selected_Description_Label_1' })} (${props.descriptions.length}) ${
                  props.descriptions?.filter((d: any) => d?.content?.length === 0)?.length > 0
                    ? `${intl.formatMessage({ id: 'InputDescriptionsFeature_Selected_Description_Label_2' })} ${props.descriptions?.filter((d: any) => d?.content?.length === 0)?.length} ${props.descriptions?.filter((d: any) => d?.content?.length === 0)?.length > 1 ? `${intl.formatMessage({ id: 'InputDescriptionsFeature_Selected_Description_Label_3' })}` : `${intl.formatMessage({ id: 'InputDescriptionsFeature_Selected_Description_Label_4' })}`} ${intl.formatMessage({
                        id: 'InputDescriptionsFeature_Selected_Description_Label_5',
                      })}`
                    : ''
                }`}
                placeholder={`${intl.formatMessage({ id: 'InputDescriptionsFeature_Selected_Description_Placeholder' })}`}
                variant="outlined"
                fullWidth
                onChange={(value: any) => handleSwitchDescription(value.target.value)}
              >
                {props.descriptions?.map((option: any, index: number) => (
                  <MenuItem key={index} value={option} divider={index !== props.descriptions.length - 1}>
                    <img loading="lazy" width="20" src={option.language !== 'AU' ? `https://flagcdn.com/w20/${_.split(option.language, '_', 1)[0].toLowerCase()}.png` : `https://flagcdn.com/org/w20/${_.split(option.language, '_', 1)[0].toLowerCase()}.png`} srcSet={option.language !== 'AU' ? `https://flagcdn.com/w40/${_.split(option.language, '_', 1)[0].toLowerCase()}.png 2x` : `https://flagcdn.com/org/w40/${_.split(option.language, '_', 1)[0].toLowerCase()}.png 2x`} alt="" />
                    &nbsp;
                    {LANGUAGES.find((c: any) => c.value === option.language)?.label}
                    &nbsp;&nbsp;
                    <Typography variant="caption">{props.descriptions?.find((d: any) => d?.language === option.language)?.content?.length > 1 ? '' : <FormattedMessage id="InputDescriptionsFeature_Selected_Description_Caption" />}</Typography>
                  </MenuItem>
                ))}
              </TextField>
            )}
          />

          {props.selected_description?.content === undefined ? (
            ''
          ) : (
            <Grid>
              {props.short_descriptions ? (
                <Controller
                  control={props.control}
                  name="selected_short_description_content"
                  render={({ field }: any) => (
                    <TextField
                      {...field}
                      label={
                        <span>
                          Short Description in
                          <strong>
                            &nbsp;
                            {LANGUAGES.find((c: any) => c.value === props.selected_description.language)?.label}
                            &nbsp;
                            <img
                              loading="lazy"
                              width="20"
                              src={props.selected_description.language !== 'AU' ? `https://flagcdn.com/w20/${_.split(props.selected_description.language, '_', 1)[0].toLowerCase()}.png` : `https://flagcdn.com/org/w20/${_.split(props.selected_description.language, '_', 1)[0].toLowerCase()}.png`}
                              srcSet={props.selected_description.language !== 'AU' ? `https://flagcdn.com/w40/${_.split(props.selected_description.language, '_', 1)[0].toLowerCase()}.png 2x` : `https://flagcdn.com/org/w40/${_.split(props.selected_description.language, '_', 1)[0].toLowerCase()}.png 2x`}
                              alt=""
                            />
                          </strong>
                        </span>
                      }
                      placeholder={`${intl.formatMessage({ id: 'InputDescriptionsFeature_Short_Descriptions_Placeholder' })}`}
                      variant="outlined"
                      fullWidth
                      multiline
                      minRows={3}
                      className={'field-bottom-space'}
                    />
                  )}
                />
              ) : (
                ''
              )}
              <Controller
                control={props.control}
                name="selected_description_content"
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    label={
                      <span>
                        <FormattedMessage id="InputDescriptionsFeature_Selected_Description_Content" />
                        <strong>
                          &nbsp;
                          {LANGUAGES.find((c: any) => c.value === props.selected_description.language)?.label}
                          &nbsp;
                          <img
                            loading="lazy"
                            width="20"
                            src={props.selected_description.language !== 'AU' ? `https://flagcdn.com/w20/${_.split(props.selected_description.language, '_', 1)[0].toLowerCase()}.png` : `https://flagcdn.com/org/w20/${_.split(props.selected_description.language, '_', 1)[0].toLowerCase()}.png`}
                            srcSet={props.selected_description.language !== 'AU' ? `https://flagcdn.com/w40/${_.split(props.selected_description.language, '_', 1)[0].toLowerCase()}.png 2x` : `https://flagcdn.com/org/w40/${_.split(props.selected_description.language, '_', 1)[0].toLowerCase()}.png 2x`}
                            alt=""
                          />
                        </strong>
                      </span>
                    }
                    placeholder=""
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={3}
                  />
                )}
              />

              {props.descriptions?.filter((d: any) => d?.language === props.selected_description.language && (d?.content?.length < 1 || !_.isEqual(props.descriptions?.find((d: any) => d?.language === props.selected_description.language).content, props.selected_description_content)))?.length > 0 ? (
                <Typography variant="caption" className="WARNING" sx={{ pb: '20px', fontWeight: '700', display: 'inline-block', width: '100%', textAlign: 'center' }}>
                  <FormattedMessage id="InputDescriptionsFeature_Selected_Description_Content_Warning" />
                </Typography>
              ) : (
                ''
              )}

              <Fab
                variant="extended"
                className="button-red-reversed"
                size="small"
                sx={{
                  background: 'transparent',
                  boxShadow: 'none',
                }}
                onClick={handleDeleteDescription}
              >
                <FormattedMessage id="Global_Button_Delete" />
                &nbsp;
                <Delete />
              </Fab>

              {props.descriptions?.filter((d: any) => d?.language === props.selected_description.language && (d?.content?.length < 1 || !_.isEqual(props.descriptions?.find((d: any) => d?.language === props.selected_description.language).content, props.selected_description_content)))?.length > 0 ? (
                <Fab
                  variant="extended"
                  className="button-green-reverse"
                  size="small"
                  sx={{
                    background: 'transparent',
                    boxShadow: 'none',
                  }}
                  onClick={handleSaveDescription}
                >
                  <FormattedMessage id="Global_Button_Save_Translation" />
                </Fab>
              ) : (
                ''
              )}
            </Grid>
          )}
        </>
      )}
    </>
  );
};

InputDescriptionsFeature.propTypes = {
  descriptions: PropTypes.any.isRequired,
  selected_description: PropTypes.any.isRequired,
  selected_description_content: PropTypes.string.isRequired,
  short_descriptions: PropTypes.any,
  selected_short_description: PropTypes.any,
  selected_short_description_content: PropTypes.string,
  translated_desc_to_add: PropTypes.string.isRequired,
  setter: PropTypes.any.isRequired,
  control: PropTypes.any.isRequired,
};

InputDescriptionsFeature.defaultProps = {
  translated_desc_to_add: 'GB',
  selected_description_content: '',
};

export default InputDescriptionsFeature;

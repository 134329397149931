import React from 'react';
import { Helmet } from 'react-helmet';
import SwipeableViews from 'react-swipeable-views';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { Stack, CardMedia, CircularProgress, Button, IconButton, MenuItem, Menu, Fade, Grid, Avatar, Typography, AppBar, Tabs, Tab, AvatarGroup, CardContent, Skeleton, Card, Fab, Divider } from '@mui/material';
import { Edit, Settings, Add, CalendarMonth, MoreHoriz, Logout } from '@mui/icons-material';
import _ from 'lodash';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useQuery, useMutation, gql } from '@apollo/client';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer, selectPlayerActiveProfile } from '../../reducers/playerSlice';
import { AverageDistanceIcon, AverageMaxSpeedIcon, ChatsIcon, FieldIcon, FivezerLogoIcon, FootBallIcon, GamesPlayedIcon, GoalsIcon, HistoryIcon, LocationIcon, ManOfTheMatchIcon, OverviewIcon, StatisticsIcon, SucceedPassesIcon, TacklesIcon, TeamsIcon, TournamentIcon } from '../../img/icons/Icons';
import { LoadingButton } from '@mui/lab';
import FollowersDrawer from '../../component-modals/profile/FollowersDrawer';
import { ALL_SOCIAL_MEDIAS, CHALLENGE_TYPES, ONE_DAY_MILLISEC, SPORT_TEAM_TYPES } from '../../utilities/utilities';
import LoadingComponent from '../../component-modals/common/LoadingComponent';
import { ARRAY_AS_ANY, UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import MenuButton from '../../component-modals/layout/MenuButton';
import ViewAvailabilitiesModal from '../../component-modals/common/ViewAvailabilitiesModal';
import ShareURLButton from '../../component-modals/common/ShareURLButton';
import Moment from 'react-moment';
import ListNotifications from './ListNotifications';
import ShowPlayerQRCode from '../../component-modals/features/ShowPlayerQRCode';
import TeamHistory from './TeamHistory';
import NumberFormatter from '../../component-modals/layout/NumberFormatter';
import NewFieldModal from '../../component-modals/challenge/FormNewFieldModal';
import ListPublishedFields from './ListPublishedFields';
import { selectSession } from '../../reducers/sessionSlice';
import TopBarActionsButton from '../../component-modals/layout/TopBarActionsButton';
import { useIntl } from 'react-intl';
import MessengerModal from '../../component-modals/messenger/MessengerModal';
import LogoutButton from '../../component-modals/common/LogoutButton';
import FootballCardModal from '../../component-modals/profile/FootballCardModal';
import BackButton from '../../component-modals/common/BackButton';

const Profile: React.FC = () => {
  const FIND_PLAYER_BY_USERNAME_QUERY = gql`
    query findPlayerByUsername($username: String!) {
      findPlayerByUsername(username: $username) {
        id
        username
        status
        bio
        email {
          address
        }
        avatar {
          id
          filename
          path
        }
        cover {
          id
          filename
          path
        }
        social_medias {
          id
          name
          handle
          link
        }
        profiles {
          ... on Footballer {
            __typename
            id
            alias
            avatar {
              path
            }
          }
          ... on Basketballer {
            __typename
            id
            alias
            avatar {
              path
            }
          }
        }
        manage_teams {
          ... on FootballTeam {
            __typename
            id
            short_id
            name
            avatar {
              path
            }
            cover {
              path
            }
          }
        }
        followings {
          ... on FootballTeam {
            __typename
            id
            short_id
            name
            avatar {
              path
              filename
            }
          }
          ... on Company {
            __typename
            id
            name
            label
            logo {
              path
              filename
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on ComplexOwner {
            __typename
            id
            username
            avatar {
              path
            }
          }

          ... on Transporter {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Passenger {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Patient {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on HealthWorker {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
        followers {
          ... on FootballTeam {
            __typename
            id
            short_id
            name
            avatar {
              path
              filename
            }
          }
          ... on Company {
            __typename
            id
            name
            label
            logo {
              path
              filename
            }
          }
          ... on User {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Player {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on ComplexOwner {
            __typename
            id
            username
            avatar {
              path
            }
          }

          ... on Transporter {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Passenger {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on Patient {
            __typename
            id
            username
            avatar {
              path
            }
          }
          ... on HealthWorker {
            __typename
            id
            username
            avatar {
              path
            }
          }
        }
        owner {
          id
          firstname
          lastname
          companies {
            id
            name
          }
        }
      }
    }
  `;

  const FIND_PLAYER_STATS_BY_USERNAME_QUERY = gql`
    query findPlayerStatsByUsername($username: String!) {
      findPlayerStatsByUsername(username: $username) {
        id
        username
        followers {
          ... on FootballTeam {
            __typename
            id
          }
          ... on User {
            __typename
            id
          }
          ... on Player {
            __typename
            id
          }
        }
        profiles {
          ... on Footballer {
            __typename
            id
            alias
            total_points
            cover {
              path
            }
            avatar {
              path
            }
            social_medias {
              name
              handle
              link
            }
            statistics {
              id
              nb_goals
              pace
              shooting
              passing
              dribbling
              defending
              physical
              goal_keeping
            }
            given_assists {
              id
              nb_assists
            }
            motm_awards {
              id
            }
            player_positions {
              id
              player_team_lineup {
                id
                home_challenges {
                  id
                  results {
                    id
                    winner_team {
                      id
                    }
                  }
                }
                away_challenges {
                  id
                  results {
                    id
                    winner_team {
                      id
                    }
                  }
                }
              }
            }
          }
          ... on Basketballer {
            __typename
            id
            alias
            total_points
            cover {
              path
            }
            avatar {
              path
            }
            social_medias {
              name
              handle
              link
            }
            statistics {
              id
              nb_goals
              pace
              shooting
              passing
              dribbling
              defending
              physical
              goal_keeping
            }
            given_assists {
              id
              nb_assists
            }
            motm_awards {
              id
            }
            player_positions {
              id
              player_team_lineup {
                id
                home_challenges {
                  id
                  results {
                    id
                    winner_team {
                      id
                    }
                  }
                }
                away_challenges {
                  id
                  results {
                    id
                    winner_team {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const FOLLOW_USER_MUTATION = gql`
    mutation followUser($request: inputRequestFollowing!) {
      followUser(request: $request)
    }
  `;

  const UNFOLLOW_USER_MUTATION = gql`
    mutation unfollowUser($request: inputRequestFollowing!) {
      unfollowUser(request: $request)
    }
  `;

  const FIND_SPORT_TEAMS_BY_PLAYER_ID_QUERY = gql`
    query findSportTeamsByPlayerID($player: inputFindSportTeamsByPlayerID!) {
      findSportTeamsByPlayerID(player: $player) {
        ... on FootballTeam {
          id
          short_id
          name
          shortname
          type
          avatar {
            path
          }
          cover {
            path
          }
          availabilities {
            day
            timeslots
          }
          originate_location {
            id
            title
            coordinates
            country_code
            street_name
            secondary_street_name
            status
            postcode {
              name
            }
            city {
              name
            }
            county {
              name
            }
            state {
              name
            }
            country {
              name
            }
            continent {
              name
            }
          }
          created_at
          updated_at
        }
      }
    }
  `;

  const FIND_CHALLENGES_BY_PLAYER_ID_QUERY = gql`
    query findChallengesByPlayerID($player: inputFindChallengesByPlayerID!) {
      findChallengesByPlayerID(player: $player) {
        id
        short_id
        title
        status
        type
        start_date
        end_date
        is_private
        complex {
          id
          name
          location {
            id
            title
            coordinates
            country_code
            street_name
            secondary_street_name
            status
            postcode {
              name
            }
            city {
              name
            }
            county {
              name
            }
            state {
              name
            }
            country {
              name
            }
            continent {
              name
            }
          }
        }
        arena {
          id
          name
          type
          size
          pictures {
            path
          }
          location {
            id
            title
            coordinates
            country_code
            street_name
            secondary_street_name
            status
            postcode {
              name
            }
            city {
              name
            }
            county {
              name
            }
            state {
              name
            }
            country {
              name
            }
            continent {
              name
            }
          }
        }
      }
    }
  `;

  const FIND_FIELDS_BY_PUBLISHER_ID_QUERY = gql`
    query findFieldsByPublisherID($publisher_id: ID!) {
      findFieldsByPublisherID(publisher_id: $publisher_id) {
        id
        short_id
        name
        size
        type
        sports
        status
        pictures {
          path
        }
        location {
          id
          coordinates
          country_code
          street_name
          secondary_street_name
          postcode {
            name
          }
          state {
            name
          }
          city {
            name
          }
          country {
            name
          }
          continent {
            name
          }
        }
      }
    }
  `;

  const FIND_WORLD_RANK_BY_SPORT_PLAYER_ID = gql`
    query findWorldRankBySportPlayerID($id: ID!, $type: String!) {
      findWorldRankBySportPlayerID(id: $id, type: $type)
    }
  `;

  const location: any = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();
  const { userId } = useParams();
  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const loggedPlayerActiveProfile = useAppSelector(selectPlayerActiveProfile);

  const [openFollowersList, setOpenFollowersList] = React.useState(false);
  const [playerByURL, setPlayerByURL] = React.useState(UNDEFINED_AS_ANY);
  const [leagueTeams, setLeagueTeams] = React.useState(ARRAY_AS_ANY);
  const [friendlyTeams, setFriendlyTeams] = React.useState(ARRAY_AS_ANY);
  const [challenges, setChallenges] = React.useState(UNDEFINED_AS_ANY);
  const [selectedProfile, setSelectedProfile] = React.useState(UNDEFINED_AS_ANY);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isSmallDesktop = useMediaQuery('(max-width:999px)');
  const [drawerIndex, setDrawerIndex] = React.useState(0);
  const [value, setValue] = React.useState(0);
  const [offsetChallenges, setOffsetChallenges] = React.useState(0);
  const [showQRC, setShowQRC] = React.useState(false);
  const [newField, setNewField] = React.useState(false);
  const [openSendMessage, setOpenSendMessage] = React.useState(false);
  const [openViewCard, setOpenViewCard] = React.useState(false);

  const [followUser, followedUser] = useMutation(FOLLOW_USER_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const [unfollowUser, unfollowedUser] = useMutation(UNFOLLOW_USER_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });
  const loadedPlayerByURL = useQuery(FIND_PLAYER_BY_USERNAME_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      username: userId || loggedPlayer?.username,
    },
    pollInterval: loggedPlayer?.id ? 300000 : undefined,
  });
  const loadedPlayerStatsByURL = useQuery(FIND_PLAYER_STATS_BY_USERNAME_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key || (!userId && !loggedPlayer?.id),
    variables: {
      username: userId || loggedPlayer?.username,
    },
    pollInterval: loggedPlayer?.id ? 300000 : undefined,
  });
  const loadedSportTeam = useQuery(FIND_SPORT_TEAMS_BY_PLAYER_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key || (userId ? !playerByURL?.id : !loggedPlayer?.id),
    variables: {
      player: {
        id: userId ? playerByURL?.id : loggedPlayer?.id,
      },
    },
  });
  const loadedChallenges = useQuery(FIND_CHALLENGES_BY_PLAYER_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key || (userId ? !playerByURL?.id : !loggedPlayer?.id),
    variables: {
      player: {
        id: userId ? playerByURL?.id : loggedPlayer?.id,
        offset: offsetChallenges,
      },
    },
  });
  const loadedFields = useQuery(FIND_FIELDS_BY_PUBLISHER_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key || (userId ? !playerByURL?.id : !loggedPlayer?.id),
    variables: {
      publisher_id: userId ? playerByURL?.id : loggedPlayer?.id,
    },
  });
  const loadedWorldRankProfile = useQuery(FIND_WORLD_RANK_BY_SPORT_PLAYER_ID, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key || !selectedProfile?.id || !selectedProfile?.type,
    variables: {
      id: selectedProfile?.id,
      type: selectedProfile?.type,
    },
  });

  const ALL_PUBLISHED_FIELDS = loadedFields.data?.findFieldsByPublisherID;
  const SELECTED_PROFILE = loadedPlayerStatsByURL.data?.findPlayerStatsByUsername?.profiles?.find((p: any) => p?.alias === selectedProfile?.alias);
  const SELECTED_LINEUPS = SELECTED_PROFILE?.player_positions?.map((p: any) => p?.player_team_lineup)?.flat();
  const NB_GOALS = _.sum(SELECTED_PROFILE?.statistics?.map((s: any) => s?.nb_goals));
  const NB_ASSISTS = _.sum(SELECTED_PROFILE?.given_assists?.map((a: any) => a?.nb_assists));
  const NB_GAMES =
    SELECTED_LINEUPS?.map((t: any) => _.concat(t?.home_challenges, t?.away_challenges))
      ?.flat()
      ?.filter((g: any) => g?.results)?.length ?? 0;
  const NB_GAMES_WON =
    SELECTED_LINEUPS?.map((t: any) => _.concat(t?.home_challenges, t?.away_challenges))
      ?.flat()
      ?.filter((g: any) => g?.results)
      ?.filter((c: any) => SELECTED_LINEUPS?.find((t: any) => t?.id === c?.results?.winner_team?.id))?.length ?? 0;
  const NB_GAMES_LOST =
    SELECTED_LINEUPS?.map((t: any) => _.concat(t?.home_challenges, t?.away_challenges))
      ?.flat()
      ?.filter((g: any) => g?.results)
      ?.filter((c: any) => c?.results && !SELECTED_LINEUPS?.find((t: any) => t?.id === c?.results?.winner_team?.id))?.length ?? 0;
  const NB_GAMES_DRAW =
    SELECTED_LINEUPS?.map((t: any) => _.concat(t?.home_challenges, t?.away_challenges))
      ?.flat()
      ?.filter((g: any) => g?.results)
      ?.filter((c: any) => c?.results && !c?.results?.winner_team?.id)?.length ?? 0;
  const NB_MOTM = SELECTED_PROFILE?.motm_awards?.length ?? 0;
  const NB_FOLLOWERS = loadedPlayerStatsByURL.data?.findPlayerStatsByUsername?.followers?.length ?? 0;
  const WORLD_RANK = loadedWorldRankProfile.data?.findWorldRankBySportPlayerID;

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDrawer = (isOpen: boolean, index: number) => {
    setOpenFollowersList(isOpen);
    setDrawerIndex(index);
  };

  const handleOpenNewField = () => {
    handleClose();
    setNewField(true);
  };

  const handleCloseNewField = () => {
    handleClose();
    setNewField(false);
  };

  const handleRefetch = async () => {
    try {
      const result = await loadedPlayerByURL.refetch({
        username: userId || loggedPlayer?.username,
      });

      if (result) {
        setPlayerByURL(result.data?.findPlayerByUsername);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSendMessage = () => {
    handleClose();
    setOpenSendMessage(true);
  };

  const handleFollow = async (toFollow: any) => {
    if (toFollow && toFollow?.id !== loggedPlayer?.id) {
      try {
        await followUser({
          variables: {
            request: {
              follower: {
                id: loggedPlayer?.id,
                username: loggedPlayer?.username,
                type: loggedPlayer?.__typename,
              },
              followed: {
                id: toFollow.id,
                username: toFollow.username,
                type: toFollow.__typename,
              },
            },
          },
        });

        await handleRefetch();
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleUnfollow = async (toUnfollow: any) => {
    if (toUnfollow && toUnfollow?.id !== loggedPlayer?.id) {
      try {
        await unfollowUser({
          variables: {
            request: {
              follower: {
                id: loggedPlayer?.id,
                username: loggedPlayer?.username,
                type: loggedPlayer?.__typename,
              },
              followed: {
                id: toUnfollow.id,
                username: toUnfollow.username,
                type: toUnfollow.__typename,
              },
            },
          },
        });

        await handleRefetch();
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleLoadMoreChallenges = async () => {
    try {
      const result = await loadedChallenges.refetch({
        player: {
          id: loggedPlayer?.id,
          offset: offsetChallenges,
        },
      });
      const newList = _.concat(challenges, result.data?.findChallengesByPlayerID);
      setChallenges(newList);
      setOffsetChallenges(offsetChallenges + result.data?.findChallengesByPlayerID?.length);
    } catch (e) {
      console.log(e);
    }
  };

  const handleOpenCard = () => {
    setOpenViewCard(true);
  };

  const handleCloseCard = () => {
    setOpenViewCard(false);
  };

  React.useEffect(() => {
    if ((!playerByURL && loadedPlayerByURL.data?.findPlayerByUsername) || !_.isMatch(playerByURL, loadedPlayerByURL.data?.findPlayerByUsername)) {
      setPlayerByURL(loadedPlayerByURL.data?.findPlayerByUsername);
    }
  }, [loadedPlayerByURL, location, playerByURL, setPlayerByURL]);

  React.useEffect(() => {
    if (loggedPlayer?.id === undefined) {
      navigate('/');
    }
  }, [navigate, loggedPlayer]);

  React.useEffect(() => {
    if (
      (leagueTeams?.length === 0 && loadedSportTeam.data?.findSportTeamsByPlayerID && loadedSportTeam.data?.findSportTeamsByPlayerID?.filter((t: any) => t?.type === 'LEAGUE')?.length > 0) ||
      (leagueTeams?.length > 0 &&
        !_.isMatch(
          leagueTeams,
          loadedSportTeam.data?.findSportTeamsByPlayerID?.filter((t: any) => t?.type === 'LEAGUE')
        ))
    ) {
      setLeagueTeams(loadedSportTeam.data?.findSportTeamsByPlayerID?.filter((t: any) => t?.type === 'LEAGUE'));
    }

    if (
      (friendlyTeams?.length === 0 && loadedSportTeam.data?.findSportTeamsByPlayerID && loadedSportTeam.data?.findSportTeamsByPlayerID?.filter((t: any) => t?.type !== 'LEAGUE')?.length > 0) ||
      (friendlyTeams?.length > 0 &&
        !_.isMatch(
          friendlyTeams,
          loadedSportTeam.data?.findSportTeamsByPlayerID?.filter((t: any) => t?.type !== 'LEAGUE')
        ))
    ) {
      setFriendlyTeams(loadedSportTeam.data?.findSportTeamsByPlayerID?.filter((t: any) => t?.type !== 'LEAGUE'));
    }

    if (!challenges && loadedChallenges.data?.findChallengesByPlayerID && loadedChallenges.data?.findChallengesByPlayerID?.length > 0) {
      setChallenges(loadedChallenges.data?.findChallengesByPlayerID);
    }

    if (offsetChallenges === 0 && challenges?.length > 1) {
      setOffsetChallenges(challenges?.length);
    }
  }, [selectedProfile, loggedPlayerActiveProfile, leagueTeams, friendlyTeams, loadedSportTeam, challenges, loadedChallenges, offsetChallenges]);

  React.useEffect(() => {
    if ((userId && !playerByURL?.profiles?.find((p: any) => p?.alias === selectedProfile?.alias)) || ((location.pathname === '/profile' || loggedPlayer?.username === userId) && !loggedPlayer?.profiles?.find((p: any) => p?.alias === selectedProfile?.alias))) {
      setSelectedProfile(UNDEFINED_AS_ANY);
    }

    if (!selectedProfile?.alias && playerByURL?.profiles?.length > 0 && ((playerByURL?.profiles && playerByURL?.profiles?.length > 0) || loggedPlayerActiveProfile)) {
      setSelectedProfile({ id: playerByURL?.profiles[0]?.id || loggedPlayerActiveProfile?.id, alias: playerByURL?.profiles[0]?.alias || loggedPlayerActiveProfile?.alias, type: playerByURL?.profiles[0]?.__typename || loggedPlayerActiveProfile?.__typename });
    }
  }, [selectedProfile, loggedPlayerActiveProfile, playerByURL, userId, location, loggedPlayer]);

  return (
    <Grid className={`profile-page ${loggedPlayer?.app_configuration?.is_dark_mode ? 'dark-mode' : 'light-mode'}`} sx={{ p: '0 0 150px', minHeight: '100%' }}>
      <Helmet>
        <title>{`${playerByURL?.username ? playerByURL?.username : loggedPlayer?.username}`} x Fivezer #DifferentAnimalSameBeast</title>
        <meta name="description" content={loggedPlayer?.bio ? loggedPlayer?.bio : playerByURL?.bio} />
        <link rel="canonical" href={`${window.location.origin}/u/${playerByURL?.username ? playerByURL?.username : loggedPlayer?.username}`} />
      </Helmet>

      <CardMedia
        src={userId && !playerByURL ? '' : SELECTED_PROFILE?.cover?.path || playerByURL?.cover?.path || loggedPlayer?.cover?.path}
        sx={{
          minHeight: '100vh',
          backgroundSize: { xs: '150%', sm: '100%' },
          backgroundPosition: 'top',
          backgroundImage: loadedPlayerByURL.loading ? '' : `linear-gradient(to right, rgba(0,0,0,.5) 0%, rgba(0,0,0,0) 15%, rgba(0,0,0,0) 85%, rgba(0,0,0,.5) 100%), url(${userId && !playerByURL ? '' : (SELECTED_PROFILE?.cover?.path || playerByURL?.cover?.path || loggedPlayer?.cover?.path)?.includes('cover.png') ? '' : SELECTED_PROFILE?.cover?.path || playerByURL?.cover?.path || loggedPlayer?.cover?.path})`,
        }}
      >
        <Grid container sx={{ pb: '60px' }}>
          <Grid
            item
            xs={2}
            md={2}
            lg={2}
            sx={{
              textAlign: 'left',
              p: { xs: '10px', sm: '20px 0px 20px 30px' },
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'start',
            }}
          ></Grid>
          <Grid item xs={8} md={8} lg={8} sx={{ textAlign: 'center', p: '0' }}>
            <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
              <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '30px', sm: '35px', md: '40px' }, p: '0' }} />
            </IconButton>
          </Grid>

          <Grid
            item
            xs={2}
            md={2}
            lg={2}
            sx={{
              textAlign: 'right',
              p: { xs: '10px', sm: '20px 30px 20px 0' },
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'end',
            }}
          >
            <TopBarActionsButton />
            <MenuButton />
          </Grid>
        </Grid>

        {(userId && !playerByURL) || loadedPlayerByURL.loading ? (
          <>
            {loadedPlayerByURL.loading ? <LoadingComponent amount={1} text={<Typography sx={{ mb: '20px' }}>{`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}</Typography>} loader={<CircularProgress className="orange-loader" />} /> : ''}

            {userId && !playerByURL && !loadedPlayerByURL.loading ? (
              <>
                <Typography sx={{ textAlign: 'center', fontWeight: '800', fontSize: '2em' }}>
                  <BackButton color={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(251, 88, 2, 1)' : 'rgba(251, 88, 2, 1)'} hoverColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(251, 88, 2, 1)' : 'rgba(251, 88, 2, 1)'} hoverBGColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(251, 88, 2, .1)' : 'rgba(251, 88, 2, .1)'} />
                  <br />
                  {userId} {`${intl.formatMessage({ id: 'Global_Not_Found' })}`}
                </Typography>
              </>
            ) : (
              ''
            )}
          </>
        ) : (
          <>
            <Grid
              container
              sx={{
                m: 'auto',
                width: '100%',
                backgroundImage: {
                  xs: `linear-gradient(to bottom, rgba(15,15,15,0) 2%, rgba(15,15,15,1) 25%)`,
                  sm: `linear-gradient(to bottom, rgba(15,15,15,0) 2%, rgba(15,15,15,1) 20%)`,
                  md: `linear-gradient(to bottom, rgba(15,15,15,0) 5%, rgba(15,15,15,1) 40%)`,
                  lm: `linear-gradient(to bottom, rgba(15,15,15,0) 20%, rgba(15,15,15,1) 60%)`,
                },
                p: {
                  xs: '0px 5% 50px',
                  sm: '0px 5% 50px',
                  md: '0px 20% 50px',
                  lm: '0px 20% 50px',
                  lx: '0px 20% 50px',
                },
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} className={'avatar'} sx={{ textAlign: 'center', pr: '0' }}>
                <Avatar
                  onClick={() => setShowQRC(true)}
                  src={playerByURL ? playerByURL?.avatar?.path : loggedPlayer?.avatar?.path}
                  sx={{
                    'background': 'transparent',
                    'boxSizing': 'border-box',
                    'border': 'solid 1px rgba(247, 251, 250, .4)',
                    'height': { xs: 100, sm: 150, md: 200, xl: 250 },
                    'width': { xs: 100, sm: 150, md: 200, xl: 250 },
                    'm': 'auto',
                    '& img': { p: '0px', borderRadius: '100%' },
                  }}
                />
                <ShowPlayerQRCode open={showQRC} handleClose={() => setShowQRC(false)} username={playerByURL ? playerByURL?.username : loggedPlayer?.username} logo={playerByURL ? playerByURL?.avatar?.path : loggedPlayer?.avatar?.path} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={'profile-info'}
                sx={{
                  p: '10px 0 0',
                  textAlign: 'center',
                }}
              >
                <Typography variant="h1" sx={{ m: '0', fontSize: '2em', fontWeight: '800 !important', lineHeight: '32px' }}>
                  {(playerByURL ?? loggedPlayer)?.owner?.firstname} {(playerByURL ?? loggedPlayer)?.owner?.lastname}
                </Typography>

                <Stack direction="row" className={'social_medias'} sx={{ display: 'inline-flex', alignItems: 'center' }}>
                  <Typography sx={{ m: '0', fontSize: '.9em', color: 'rgba(247, 251, 250, .6) !important' }}>@{playerByURL ? playerByURL?.username : loggedPlayer?.username}</Typography>
                  {(playerByURL ? playerByURL : loggedPlayer)?.social_medias?.length > 0 ? <>&nbsp;&nbsp;x&nbsp;&nbsp;</> : ''}
                  <Grid container gap={1}>
                    {(playerByURL ? playerByURL : loggedPlayer)?.social_medias?.map((s: any) => (
                      <Grid key={s?.id} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                        <Grid sx={{ width: '100%', textAlign: 'center', display: 'flex' }}>
                          <Typography sx={{ 'transform': 'scale(.8)', 'display': 'inline-flex', 'alignItems': 'center', 'fontWeight': '700', 'textDecoration': 'none', 'color': loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(255, 255, 255, 1)' : 'rgba(15,15,15,1)', '&:hover': { color: 'rgba(102, 130, 19,1)' } }} component={'a'} target={'_blank'} href={s?.link}>
                            {ALL_SOCIAL_MEDIAS?.find((m: any) => m?.value === s?.name)?.icon_white}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Stack>

                <AvatarGroup sx={{ justifyContent: 'center' }}>
                  {(playerByURL ? playerByURL?.profiles : loggedPlayer?.profiles)?.map((p: any) => (
                    <Grid key={p?.id} onClick={() => setSelectedProfile({ alias: p?.alias, type: p?.__typename })} sx={{ '& svg': { fontSize: '1em', color: selectedProfile?.alias === p?.alias ? 'rgba(168, 202, 65, 1)' : 'rgba(255, 255, 255, 1)' } }}>
                      <Avatar src={p?.avatar?.path} alt={p?.alias} sx={{ 'opacity': selectedProfile?.alias === p?.alias ? '1' : '.3', '&:hover': { opacity: '1' } }} />
                    </Grid>
                  ))}
                </AvatarGroup>
                {playerByURL?.profiles?.find((p: any) => p?.alias === selectedProfile?.alias) || loggedPlayer?.profiles?.find((p: any) => p?.alias === selectedProfile?.alias) ? (
                  <>
                    <Button onClick={handleOpenCard} className="button-cancel" sx={{ opacity: '.6', fontSize: '.9em', mb: { xs: '10px', sm: '20px' } }}>
                      {`${intl.formatMessage({ id: 'Profile_View_Card_1' })}`}&nbsp;
                      <Typography component={'span'} id="tag-font" sx={{ color: 'rgb(251, 88, 2)', fontWeight: '800' }}>{`${intl.formatMessage({ id: 'Profile_View_Card_2' })}`}</Typography>
                    </Button>
                    {selectedProfile?.type === 'Footballer' ? <FootballCardModal alias={selectedProfile?.alias} open={openViewCard} handleClose={handleCloseCard} /> : ''}
                  </>
                ) : (
                  ''
                )}

                {playerByURL?.bio ? (
                  <Typography
                    variant="body1"
                    sx={{
                      m: '25px 0',
                      fontSize: '.8em',
                      fontWeight: '500',
                      whiteSpace: 'pre-line',
                    }}
                  >
                    {_.truncate(playerByURL?.bio ? playerByURL?.bio : loggedPlayer?.bio, {
                      length: 300,
                    })}
                  </Typography>
                ) : (
                  ''
                )}
              </Grid>

              {selectedProfile?.alias ? (
                <>
                  {SELECTED_PROFILE?.social_medias?.length > 0 ? (
                    <Grid sx={{ p: '0', overflow: 'auto', width: '100%', textAlign: 'center', mb: '10px' }}>
                      <Stack direction="row" className={'locations'} sx={{ display: 'inline-flex', p: '0 5%', justifyContent: 'center' }}>
                        {SELECTED_PROFILE?.social_medias?.map((s: any, index: number) => (
                          <Grid key={index} component={'a'} target={'_blank'} href={s?.link} sx={{ width: '80px' }}>
                            <Button variant="text" size="large" sx={{ 'bgcolor': { xs: 'rgba(247, 251, 250, .0)', sm: 'rgba(247, 251, 250, .0)' }, '&:hover': { bgcolor: 'rgba(247, 251, 250, 0.08)' }, 'borderRadius': '10px', 'height': '50px', 'width': { xs: '50px' }, 'display': 'inline-flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
                              <Typography sx={{ transform: 'scale(1.5)', display: 'inline-flex', alignItems: 'center', fontWeight: '700', textDecoration: 'none', color: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(255, 255, 255, 1)' : 'rgba(15,15,15,1)' }}>{ALL_SOCIAL_MEDIAS?.find((m: any) => m?.value === s?.name)?.icon_white}</Typography>
                            </Button>
                          </Grid>
                        ))}
                      </Stack>
                    </Grid>
                  ) : (
                    ''
                  )}
                  <Grid sx={{ justifyContent: { xs: 'start', sm: 'center' }, overflow: 'auto', width: '100%', display: { xs: 'inline-flex', sm: 'inline-block' } }}>
                    <Grid container className={'profile-followers'} sx={{ minWidth: '560px', textAlign: 'center', m: { xs: '0px auto 0', sm: '20px auto 0' }, width: '90%' }}>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        <Button variant="text" size="large" sx={{ 'bgcolor': 'rgba(247, 251, 250, .04)', '&:hover': { bgcolor: 'rgba(247, 251, 250, 0.08)' }, 'borderRadius': '10px', 'mb': { xs: '15px', sm: '0' }, 'height': { xs: '100px' }, 'width': { xs: '100px' }, 'display': 'inline-flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
                          <Grid>
                            <Typography
                              variant="body1"
                              sx={{
                                m: '0',
                                fontSize: '2.5em',
                                fontWeight: '700',
                              }}
                            >
                              <NumberFormatter number={NB_GAMES ?? <>0</>} />
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                m: '0',
                                fontSize: '.9em',
                                fontWeight: '200',
                                color: 'rgba(247, 251, 250, 0.3) !important',
                                textTransform: 'capitalize',
                              }}
                            >
                              {`${intl.formatMessage({ id: 'Global_Stats_Games' })}`}
                            </Typography>
                          </Grid>
                        </Button>
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        <Button variant="text" size="large" sx={{ 'bgcolor': 'rgba(247, 251, 250, .04)', '&:hover': { bgcolor: 'rgba(247, 251, 250, 0.08)' }, 'borderRadius': '10px', 'mb': { xs: '15px', sm: '0' }, 'height': { xs: '100px' }, 'width': { xs: '100px' }, 'display': 'inline-flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
                          <Grid>
                            <Typography
                              variant="body1"
                              sx={{
                                m: '0',
                                fontSize: '2.5em',
                                fontWeight: '700',
                              }}
                            >
                              <NumberFormatter number={NB_GAMES_WON ?? <>0</>} />
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                m: '0',
                                fontSize: '.9em',
                                fontWeight: '200',
                                color: 'rgba(247, 251, 250, 0.3) !important',
                                textTransform: 'capitalize',
                              }}
                            >
                              {`${intl.formatMessage({ id: 'Global_Stats_Wins' })}`}
                            </Typography>
                          </Grid>
                        </Button>
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        <Button variant="text" size="large" sx={{ 'bgcolor': 'rgba(247, 251, 250, .04)', '&:hover': { bgcolor: 'rgba(247, 251, 250, 0.08)' }, 'borderRadius': '10px', 'mb': { xs: '15px', sm: '0' }, 'height': { xs: '100px' }, 'width': { xs: '100px' }, 'display': 'inline-flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
                          <Grid>
                            <Typography
                              variant="body1"
                              sx={{
                                m: '0',
                                fontSize: '2.5em',
                                fontWeight: '700',
                              }}
                            >
                              <NumberFormatter number={NB_GAMES_DRAW ?? <>0</>} />
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                m: '0',
                                fontSize: '.9em',
                                fontWeight: '200',
                                color: 'rgba(247, 251, 250, 0.3) !important',
                                textTransform: 'capitalize',
                              }}
                            >
                              {`${intl.formatMessage({ id: 'Global_Stats_Draws' })}`}
                            </Typography>
                          </Grid>
                        </Button>
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        <Button variant="text" size="large" sx={{ 'bgcolor': 'rgba(247, 251, 250, .04)', '&:hover': { bgcolor: 'rgba(247, 251, 250, 0.08)' }, 'borderRadius': '10px', 'mb': { xs: '15px', sm: '0' }, 'height': { xs: '100px' }, 'width': { xs: '100px' }, 'display': 'inline-flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
                          <Grid>
                            <Typography
                              variant="body1"
                              sx={{
                                m: '0',
                                fontSize: '2.5em',
                                fontWeight: '700',
                              }}
                            >
                              <NumberFormatter number={NB_GAMES_LOST ?? <>0</>} />
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                m: '0',
                                fontSize: '.9em',
                                fontWeight: '200',
                                color: 'rgba(247, 251, 250, 0.3) !important',
                                textTransform: 'capitalize',
                              }}
                            >
                              {`${intl.formatMessage({ id: 'Global_Stats_Loss' })}`}
                            </Typography>
                          </Grid>
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid container className={'profile-business'} sx={{ minWidth: '560px', textAlign: 'center', m: { xs: '0px auto 0', sm: '20px auto 0' }, width: '90%' }}>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        <Button variant="text" size="large" sx={{ 'bgcolor': 'rgba(247, 251, 250, .04)', '&:hover': { bgcolor: 'rgba(247, 251, 250, 0.08)' }, 'borderRadius': '10px', 'mb': { xs: '15px', sm: '0' }, 'height': { xs: '100px' }, 'width': { xs: '100px' }, 'display': 'inline-flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
                          <Grid>
                            <Typography
                              variant="body1"
                              sx={{
                                m: '0',
                                fontSize: '2.5em',
                                fontWeight: '700',
                              }}
                            >
                              <NumberFormatter number={NB_GOALS ?? <>0</>} />
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                m: '0',
                                fontSize: '.9em',
                                fontWeight: '200',
                                color: 'rgba(247, 251, 250, 0.3) !important',
                                textTransform: 'capitalize',
                              }}
                            >
                              {`${intl.formatMessage({ id: 'Global_Stats_Goals' })}`}
                            </Typography>
                          </Grid>
                        </Button>
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        <Button variant="text" size="large" sx={{ 'bgcolor': 'rgba(247, 251, 250, .04)', '&:hover': { bgcolor: 'rgba(247, 251, 250, 0.08)' }, 'borderRadius': '10px', 'mb': { xs: '15px', sm: '0' }, 'height': { xs: '100px' }, 'width': { xs: '100px' }, 'display': 'inline-flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
                          <Grid>
                            <Typography
                              variant="body1"
                              sx={{
                                m: '0',
                                fontSize: '2.5em',
                                fontWeight: '700',
                              }}
                            >
                              <NumberFormatter number={NB_ASSISTS ?? <>0</>} />
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                m: '0',
                                fontSize: '.9em',
                                fontWeight: '200',
                                color: 'rgba(247, 251, 250, 0.3) !important',
                                textTransform: 'capitalize',
                              }}
                            >
                              {`${intl.formatMessage({ id: 'Global_Stats_Assists' })}`}
                            </Typography>
                          </Grid>
                        </Button>
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        <Button variant="text" size="large" sx={{ 'color': 'rgba(199, 240, 11, 1)', 'bgcolor': 'rgba(247, 251, 250, .04)', '&:hover': { bgcolor: 'rgba(247, 251, 250, 0.08)' }, 'borderRadius': '10px', 'mb': { xs: '15px', sm: '0' }, 'height': { xs: '100px' }, 'width': { xs: '100px' }, 'display': 'inline-flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
                          <Grid>
                            <Typography
                              variant="body1"
                              sx={{
                                m: '0',
                                fontSize: '2.5em',
                                fontWeight: '700',
                                // color: 'rgba(199, 240, 11, 1) !important',
                                textTransform: 'capitalize',
                              }}
                            >
                              {WORLD_RANK ? (
                                <>
                                  <NumberFormatter number={WORLD_RANK} />
                                  <Typography variant="caption" sx={{ fontSize: '.4em', textTransform: 'lowercase' }}>{`${intl.formatMessage({ id: WORLD_RANK >= 1 && WORLD_RANK <= 3 ? `Global_Stats_World_Sign_${WORLD_RANK}` : 'Global_Stats_World_Sign' })}`}</Typography>
                                </>
                              ) : (
                                <>&infin;</>
                              )}
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                m: '0',
                                fontSize: '.9em',
                                fontWeight: '200',
                                // color: 'rgba(199, 240, 11, 1) !important',
                                textTransform: 'capitalize',
                              }}
                            >
                              {`${intl.formatMessage({ id: 'Global_Stats_World' })}`}
                            </Typography>
                          </Grid>
                        </Button>
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}>
                        <Button variant="text" size="large" onClick={() => handleOpenDrawer(!openFollowersList, 0)} sx={{ 'color': 'rgba(199, 240, 11, 1)', 'bgcolor': 'rgba(247, 251, 250, .04)', '&:hover': { bgcolor: 'rgba(247, 251, 250, 0.08)' }, 'borderRadius': '10px', 'mb': { xs: '15px', sm: '0' }, 'height': { xs: '100px' }, 'width': { xs: '100px' }, 'display': 'inline-flex', 'alignItems': 'center', 'justifyContent': 'center' }}>
                          <Grid>
                            <Typography
                              variant="body1"
                              sx={{
                                m: '0',
                                fontSize: '2.5em',
                                fontWeight: '700',
                                // color: 'rgba(199, 240, 11, 1) !important',
                              }}
                            >
                              {NB_FOLLOWERS ?? <>0</>}
                              {/* <NumberFormatter number={} /> */}{' '}
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                m: '0',
                                fontSize: '.9em',
                                fontWeight: '200',
                                // color: 'rgba(199, 240, 11, 1) !important',
                                textTransform: 'capitalize',
                              }}
                            >
                              {(userId ? playerByURL?.followers?.length : loggedPlayer?.followers?.length) > 1 ? `${intl.formatMessage({ id: 'Global_Stats_Followers' })}` : `${intl.formatMessage({ id: 'Global_Stats_Follower' })}`}
                            </Typography>
                          </Grid>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (playerByURL?.profiles ?? loggedPlayer?.profiles)?.length === 0 ? (
                ''
              ) : (
                <Typography className="INFO" sx={{ textAlign: 'center', fontWeight: '700', width: '100%', textTransform: 'uppercase' }}>
                  Pick a profile
                </Typography>
              )}

              <FollowersDrawer open={openFollowersList} trigger={setOpenFollowersList} index={drawerIndex} followers={playerByURL?.followers} followings={playerByURL?.followings} handleRefetch={handleRefetch} />

              <Grid sx={{ 'display': 'inline-block', 'width': '100%', 'textAlign': 'center', 'color': 'rgba(247, 251, 250, .3)', 'mt': '20px', '& button': { 'border': 'none !important', '&:hover': { color: 'rgba(102, 130, 19, 1) !important' } } }}>
                {loggedPlayer?.id !== playerByURL?.id && playerByURL !== undefined ? (
                  playerByURL?.followers?.find((u: any) => u.id === loggedPlayer?.id) === undefined ? (
                    <LoadingButton className={'button-green-reverse'} onClick={() => handleFollow(playerByURL)} loading={followedUser.loading} size="small" sx={{ m: { xs: '5% 2.5%' } }}>
                      {`${intl.formatMessage({ id: 'Global_Button_Follow' })}`}
                    </LoadingButton>
                  ) : (
                    <LoadingButton className={'button-cancel'} onClick={() => handleUnfollow(playerByURL)} loading={unfollowedUser.loading} size="small" sx={{ m: { xs: '5% 2.5%' }, opacity: '.4' }}>
                      {`${intl.formatMessage({ id: 'Global_Button_Unfollow' })}`}
                    </LoadingButton>
                  )
                ) : (
                  ''
                )}

                <ViewAvailabilitiesModal owners_ids={(playerByURL || loggedPlayer)?.profiles?.map((p: any) => p?.id)} owner_type={(playerByURL || loggedPlayer)?.profiles?.[0]?.__typename} />

                {loggedPlayer?.email?.status === 'VERIFIED' && playerByURL?.username && loggedPlayer?.username && loggedPlayer?.username !== playerByURL?.username ? (
                  <>
                    <IconButton className="button-white-reverse" onClick={handleSendMessage} sx={{ border: 'none !important', opacity: '.4', m: { xs: '5% 2.5%' } }}>
                      <ChatsIcon />
                    </IconButton>
                    <MessengerModal
                      open={openSendMessage}
                      handleClose={() => setOpenSendMessage(false)}
                      new_conversation={{ title: `${playerByURL?.username} x ${loggedPlayer?.username} conversation`, author_id: loggedPlayer?.id, mandatory_participants: _.concat({ id: playerByURL?.id, name: playerByURL?.username, label: playerByURL?.username, avatar: playerByURL?.avatar?.path }), participants: _.concat({ id: loggedPlayer?.id, avatar: loggedPlayer?.avatar?.path }, { id: playerByURL?.id, avatar: playerByURL?.avatar?.path }) }}
                    />
                  </>
                ) : (
                  ''
                )}

                <IconButton id="fade-button" aria-controls={open ? 'fade-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                  <MoreHoriz />
                </IconButton>
                <Menu
                  id="fade-menu"
                  keepMounted
                  MenuListProps={{
                    'aria-labelledby': 'fade-button',
                    'dense': true,
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  {loggedPlayer?.id !== playerByURL?.id && playerByURL !== undefined ? (
                    <Grid>
                      <ShareURLButton button={<MenuItem>{`${intl.formatMessage({ id: 'Profile_Share_1' })}`}</MenuItem>} url={`/u/${playerByURL?.username || loggedPlayer?.username}`} title={`${intl.formatMessage({ id: 'Profile_Share_2' })}`} text={`${intl.formatMessage({ id: 'Profile_Share_3' })} ${playerByURL?.username || loggedPlayer?.username}${intl.formatMessage({ id: 'Profile_Share_4' })}`} />
                    </Grid>
                  ) : (
                    <Grid>
                      <MenuItem component={Link} to="/profile/edit">
                        <Button
                          variant="text"
                          sx={{
                            '&:hover': {
                              background: 'transparent !important',
                            },
                          }}
                          startIcon={<Edit />}
                        >
                          {`${intl.formatMessage({ id: 'Profile_Button_Edit' })}`}
                        </Button>
                      </MenuItem>
                      {loggedPlayer?.profiles?.find((p: any) => p?.__typename === 'Footballer') || loggedPlayer?.email?.status !== 'VERIFIED' ? (
                        ''
                      ) : (
                        <MenuItem component={Link} to="/new_footballer">
                          <Button
                            variant="text"
                            sx={{
                              '&:hover': {
                                background: 'transparent !important',
                              },
                            }}
                            startIcon={<FootBallIcon />}
                          >
                            {`${intl.formatMessage({ id: 'Profile_Button_New_Footballer' })}`}
                          </Button>
                        </MenuItem>
                      )}
                      {loggedPlayer?.email?.status !== 'VERIFIED' || loggedPlayer?.profiles?.length === 0 ? (
                        ''
                      ) : (
                        <MenuItem component={Link} to="/new_team">
                          <Button
                            variant="text"
                            sx={{
                              '&:hover': {
                                background: 'transparent !important',
                              },
                            }}
                            startIcon={<TeamsIcon />}
                          >
                            {`${intl.formatMessage({ id: 'Profile_Button_New_Team' })}`}
                          </Button>
                        </MenuItem>
                      )}

                      {loggedPlayer?.email?.status !== 'VERIFIED' || loggedPlayer?.profiles?.length === 0 ? (
                        ''
                      ) : (
                        <>
                          <MenuItem onClick={handleOpenNewField}>
                            <Button
                              variant="text"
                              sx={{
                                '&:hover': {
                                  background: 'transparent !important',
                                },
                              }}
                              startIcon={<FieldIcon />}
                            >
                              {`${intl.formatMessage({ id: 'Profile_Button_New_Field' })}`}
                            </Button>
                          </MenuItem>
                          <NewFieldModal open={newField} handleClose={handleCloseNewField} />
                        </>
                      )}

                      {loggedPlayer?.email?.status !== 'VERIFIED' || loggedPlayer?.profiles?.length === 0 ? (
                        ''
                      ) : (
                        <MenuItem component={Link} to="/new_tournament">
                          <Button
                            variant="text"
                            sx={{
                              '&:hover': {
                                background: 'transparent !important',
                              },
                            }}
                            startIcon={<TournamentIcon />}
                          >
                            {`${intl.formatMessage({ id: 'Profile_Button_New_Tournament' })}`}
                          </Button>
                        </MenuItem>
                      )}

                      {loggedPlayer?.email?.status !== 'VERIFIED' ? (
                        <MenuItem>
                          <Button
                            variant="text"
                            sx={{
                              '&:hover': {
                                background: 'transparent !important',
                              },
                            }}
                            startIcon={<Logout />}
                          >
                            <LogoutButton showIcon={false} />
                          </Button>
                        </MenuItem>
                      ) : (
                        <MenuItem component={Link} to="/settings">
                          <Button
                            variant="text"
                            sx={{
                              '&:hover': {
                                background: 'transparent !important',
                              },
                            }}
                            startIcon={<Settings />}
                          >
                            {`${intl.formatMessage({ id: 'Profile_Button_Settings' })}`}
                          </Button>
                        </MenuItem>
                      )}

                      <ShareURLButton
                        button={
                          <MenuItem>
                            <Button
                              variant="text"
                              sx={{
                                '&:hover': {
                                  background: 'transparent !important',
                                },
                              }}
                              startIcon={
                                <Avatar
                                  src={loggedPlayer?.avatar?.path}
                                  sx={{
                                    height: { xs: '20px' },
                                    width: { xs: '20px' },
                                  }}
                                />
                              }
                            >
                              {`${intl.formatMessage({ id: 'Profile_Share_1' })}`}
                            </Button>
                          </MenuItem>
                        }
                        url={`/u/${playerByURL?.username || loggedPlayer?.username}`}
                        title={`${intl.formatMessage({ id: 'Profile_Share_2' })}`}
                        text={`${intl.formatMessage({ id: 'Profile_Share_3' })} ${playerByURL?.username || loggedPlayer?.username}${intl.formatMessage({ id: 'Profile_Share_4' })}`}
                      />
                    </Grid>
                  )}
                </Menu>
              </Grid>
            </Grid>

            {(playerByURL?.profiles ?? loggedPlayer?.profiles)?.length === 0 ? (
              <Grid sx={{ width: '100%', textAlign: 'center' }}>
                {playerByURL?.id === loggedPlayer?.id ? (
                  <>
                    <Typography sx={{ fontWeight: '800', fontSize: '1.4em' }}>{`${intl.formatMessage({ id: 'Global_Missing_Footballer_Profile_Label_1' })}`}</Typography>
                    <Button size="small" className="button-blue" component={Link} to="/new_footballer">
                      {`${intl.formatMessage({ id: 'Global_Missing_Footballer_Profile_Button_1' })}`}
                    </Button>
                  </>
                ) : (
                  <>
                    <Typography
                      variant="h3"
                      sx={{
                        p: '50px 20px 0',
                        fontSize: '1.3em',
                        textAlign: 'center',
                        color: 'rgba(247, 251, 250, 0.3)',
                        display: 'inline-block',
                        width: '100%',
                        minHeight: '20vh',
                      }}
                    >
                      {playerByURL?.username}&nbsp;{`${intl.formatMessage({ id: 'Profile_Missing_Profile' })}`}
                    </Typography>
                  </>
                )}
              </Grid>
            ) : (
              <>
                <Grid className={`${loggedPlayer?.app_configuration?.is_dark_mode ? 'dark-mode-tabs-component' : 'light-mode-tabs-component'}`} sx={{ bgcolor: 'rgba(15,15,15,1)' }}>
                  <AppBar position="sticky">
                    <Tabs value={value} onChange={handleChange} scrollButtons={false} aria-label="scrollable auto tabs example" variant={isSmallDesktop ? 'scrollable' : 'fullWidth'} centered={!isSmallDesktop}>
                      <Tab
                        label={playerByURL?.id && playerByURL?.id !== loggedPlayer?.id ? `${intl.formatMessage({ id: 'Profile_Tab_History' })}` : `${intl.formatMessage({ id: 'Profile_Tab_Overview' })}`}
                        icon={playerByURL?.id && playerByURL?.id !== loggedPlayer?.id ? <HistoryIcon /> : <OverviewIcon />}
                        {...a11yProps(0)}
                        style={{
                          paddingLeft: '5%',
                          paddingRight: '5%',
                        }}
                      />

                      <Tab
                        label={`${intl.formatMessage({ id: 'Profile_Tab_Team' })}`}
                        icon={<TeamsIcon />}
                        {...a11yProps(1)}
                        style={{
                          paddingLeft: '5%',
                          paddingRight: '5%',
                        }}
                      />
                      <Tab
                        label={`${intl.formatMessage({ id: 'Profile_Tab_Calendar' })}`}
                        icon={<CalendarMonth />}
                        {...a11yProps(2)}
                        style={{
                          paddingLeft: '5%',
                          paddingRight: '5%',
                        }}
                      />
                      <Tab
                        label={`${intl.formatMessage({ id: 'Profile_Tab_Statistics' })}`}
                        icon={<StatisticsIcon />}
                        {...a11yProps(3)}
                        style={{
                          paddingLeft: '5%',
                          paddingRight: '5%',
                        }}
                      />
                      {playerByURL?.id && playerByURL?.id !== loggedPlayer?.id ? undefined : (
                        <Tab
                          label={`${intl.formatMessage({ id: 'Profile_Tab_History' })}`}
                          icon={<HistoryIcon />}
                          {...a11yProps(4)}
                          style={{
                            paddingLeft: '5%',
                            paddingRight: '5%',
                          }}
                        />
                      )}

                      {!ALL_PUBLISHED_FIELDS || ALL_PUBLISHED_FIELDS?.length === 0 ? undefined : (
                        <Tab
                          label={`${intl.formatMessage({ id: 'Profile_Tab_Fields' })}`}
                          icon={<FieldIcon />}
                          {...a11yProps(5)}
                          style={{
                            paddingLeft: '5%',
                            paddingRight: '5%',
                          }}
                        />
                      )}
                    </Tabs>
                  </AppBar>

                  <SwipeableViews axis={'x'} index={value} onChangeIndex={handleChangeIndex}>
                    <TabPanel value={value} index={0}>
                      {(loadedPlayerByURL.error && loadedPlayerByURL.called) || loadedPlayerByURL.loading ? (
                        loadedPlayerByURL.error ? (
                          <Typography
                            variant="h3"
                            sx={{
                              p: '50px 20px 0',
                              fontSize: '1.3em',
                              textAlign: 'center',
                              color: 'rgba(247, 251, 250, 0.3)',
                              display: 'inline-block',
                              width: '100%',
                              minHeight: '20vh',
                            }}
                          >
                            {`${intl.formatMessage({ id: 'Global_No_Notifications' })}`}
                          </Typography>
                        ) : (
                          <LoadingComponent amount={1} text={<Typography sx={{ mb: '20px', color: 'rgba(251, 88, 2, .3) !important' }}>{`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}</Typography>} loader={<CircularProgress className="orange-loader" />} />
                        )
                      ) : (
                        <Grid container className={'list-flexs'} sx={{ p: { xs: '0px 0% 150px', sm: '0px 10% 150px' }, minHeight: '30vh' }}>
                          <Grid item xs={12} md={12} lg={12} className={'actions'} sx={{ textAlign: 'center', m: '10px auto 0' }}>
                            {playerByURL?.id && playerByURL?.id !== loggedPlayer?.id ? <TeamHistory player_id={playerByURL?.id || loggedPlayer?.id} /> : <ListNotifications dark_mode={loggedPlayer?.app_configuration?.is_dark_mode} />}
                          </Grid>
                        </Grid>
                      )}
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                      {loadedSportTeam.loading ? (
                        <LoadingComponent amount={1} text={<Typography sx={{ mb: '20px', color: 'rgba(251, 88, 2, .3) !important' }}>{`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}</Typography>} loader={<CircularProgress className="orange-loader" />} />
                      ) : (
                        <Grid container className={'list-flexs'} sx={{ p: '0px 0% 150px' }}>
                          <Grid item xs={12} md={12} lg={12} className={'actions'} sx={{ textAlign: 'center', m: '0 auto' }}>
                            {(playerByURL ?? loggedPlayer)?.manage_teams?.length === 0 && leagueTeams?.length === 0 && friendlyTeams?.length === 0 ? (
                              <>
                                {playerByURL?.id !== loggedPlayer?.id ? (
                                  <Typography
                                    variant="h3"
                                    sx={{
                                      p: '50px 20px 0',
                                      fontSize: '1.3em',
                                      textAlign: 'center',
                                      color: 'rgba(247, 251, 250, 0.3)',
                                      display: 'inline-block',
                                      width: '100%',
                                      minHeight: '20vh',
                                    }}
                                  >
                                    {`${intl.formatMessage({ id: 'Profile_No_Teams' })}`}
                                  </Typography>
                                ) : (
                                  <CardMedia
                                    sx={{
                                      display: 'inline-flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      p: '0 0 100px',
                                      height: '40vh',
                                      width: '100%',
                                      backgroundImage: `linear-gradient(to bottom, rgba(15, 15, 15 , .6) 0%,rgba(15, 15, 15 , 1) 95%), url(../../../../img/menu77.jpg)`,
                                    }}
                                  >
                                    {loggedPlayer?.email?.status !== 'VERIFIED' ? (
                                      <Fab variant="extended" size="small" className="button-green-reverse" sx={{ boxShadow: 'none' }}>
                                        {`${intl.formatMessage({ id: 'Profile_Create_Team_1' })}`}
                                      </Fab>
                                    ) : (
                                      <Fab component={Link} to={'/new_team'} variant="extended" size="small" className="button-green-reverse" sx={{ boxShadow: 'none' }}>
                                        <TeamsIcon />
                                        &nbsp;{`${intl.formatMessage({ id: 'Profile_Create_Team_2' })}`}
                                      </Fab>
                                    )}
                                  </CardMedia>
                                )}
                              </>
                            ) : (
                              <>
                                <Grid item xs={12} md={12} lg={12} sx={{ p: { xs: '0', sm: '0' } }}>
                                  {leagueTeams?.map((team: any) => (
                                    <Card
                                      key={team?.id}
                                      className={'card'}
                                      sx={{
                                        bgcolor: 'rgba(247, 251, 250, .1)',
                                        p: '0px',
                                        width: '100%',
                                        m: '0 auto',
                                      }}
                                    >
                                      <CardMedia
                                        sx={{
                                          backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.8) 50%), url(${team?.cover?.path})`,
                                        }}
                                      >
                                        <CardContent className={'content'} sx={{ textAlign: 'center', p: '60px 0 !important' }}>
                                          {team?.avatar?.path ? (
                                            <Avatar
                                              src={team?.avatar?.path}
                                              component={Link}
                                              to={team?.__typename === 'FootballTeam' ? `/football_team/${team?.short_id}` : team?.__typename === 'BasketballTeam' ? `/basketball_team/${team?.short_id}` : ''}
                                              sx={{
                                                m: 'auto',
                                                width: '100px',
                                                height: '100px',
                                              }}
                                            />
                                          ) : (
                                            <Skeleton className="white-skeloton" variant="circular" width="100px" height="100px" sx={{ m: 'auto' }} />
                                          )}
                                          {leagueTeams?.length === 0 ? (
                                            ''
                                          ) : (
                                            <>
                                              <Typography
                                                component={Link}
                                                to={team?.__typename === 'FootballTeam' ? `/football_team/${team?.short_id}` : team?.__typename === 'BasketballTeam' ? `/basketball_team/${team?.short_id}` : ''}
                                                sx={{
                                                  'fontWeight': '800',
                                                  'fontSize': '1.9em',
                                                  'textDecoration': 'none',
                                                  'color': 'rgba(247, 251, 250, 1)',
                                                  '&:hover': {
                                                    color: 'rgba(251, 88, 2, 1)',
                                                  },
                                                }}
                                              >
                                                {team?.name}
                                              </Typography>
                                              <Typography
                                                sx={{
                                                  fontWeight: '600',
                                                  fontSize: '.8em',
                                                  textTransform: 'uppercase',
                                                }}
                                              >
                                                {`${intl.formatMessage({ id: SPORT_TEAM_TYPES.find((t: any) => t.value === team?.type)?.label })}`}&nbsp;{_.replace(team?.__typename, 'Team', ' Team')}
                                              </Typography>
                                              <Typography
                                                variant="caption"
                                                sx={{
                                                  color: 'rgba(247, 251, 250, 1)',
                                                  width: '100%',
                                                  display: 'inline-block',
                                                }}
                                              >
                                                {`${intl.formatMessage({ id: 'Global_Since' })}`}&nbsp;<Moment format="D MMM YYYY">{team?.created_at}</Moment>
                                              </Typography>
                                              <Typography
                                                variant="caption"
                                                sx={{
                                                  color: 'rgba(247, 251, 250, 1)',
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  justifyContent: 'center',
                                                }}
                                              >
                                                <LocationIcon
                                                  sx={{
                                                    color: 'rgba(19, 96, 13, .5)',
                                                  }}
                                                />
                                                &nbsp;
                                                {team?.originate_location?.city?.name}, {team?.originate_location?.country?.name}
                                                &nbsp;
                                                <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${team?.originate_location?.country_code?.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${team?.originate_location?.country_code?.toLowerCase()}.png 2x`} alt="" />
                                              </Typography>
                                            </>
                                          )}
                                        </CardContent>
                                      </CardMedia>
                                    </Card>
                                  ))}
                                </Grid>

                                <Grid container sx={{ justifyContent: 'center', p: { xs: '30px 0 0', sm: '30px 0 0' } }}>
                                  {(playerByURL ?? loggedPlayer)?.manage_teams?.map((team: any) => (
                                    <Grid key={team?.id} item xs={12} sm={12} md={6} lg={4} xl={4} sx={{ p: '0 30px' }}>
                                      <Card
                                        className={'card'}
                                        sx={{
                                          bgcolor: 'rgba(247, 251, 250, .1)',
                                          p: '0px',
                                          width: '100%',
                                          borderRadius: '20px',
                                          m: '0 auto 30px',
                                        }}
                                      >
                                        <CardMedia
                                          sx={{
                                            backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.8) 50%), url(${team?.cover?.path})`,
                                          }}
                                        >
                                          <CardContent className={'content'} sx={{ textAlign: 'center', p: '30px 0 !important' }}>
                                            {team?.avatar?.path ? (
                                              <Avatar
                                                src={team?.avatar?.path}
                                                component={Link}
                                                to={team?.__typename === 'FootballTeam' ? `/football_team/${team?.short_id}` : team?.__typename === 'BasketballTeam' ? `/basketball_team/${team?.short_id}` : ''}
                                                sx={{
                                                  m: 'auto',
                                                  width: '100px',
                                                  height: '100px',
                                                }}
                                              />
                                            ) : (
                                              <Skeleton className="white-skeloton" variant="circular" component={Link} to={team?.__typename === 'FootballTeam' ? `/football_team/${team?.short_id}` : team?.__typename === 'BasketballTeam' ? `/basketball_team/${team?.short_id}` : ''} width="100px" height="100px" sx={{ m: 'auto' }} />
                                            )}
                                            {(playerByURL ?? loggedPlayer)?.manage_teams?.length === 0 ? (
                                              ''
                                            ) : (
                                              <>
                                                <Typography variant="caption" sx={{ color: 'rgba(247, 251, 250, 1)', width: '100%', display: 'inline-block' }}>{`${intl.formatMessage({ id: 'Global_Manager_At' })}`}</Typography>
                                                <Typography
                                                  component={Link}
                                                  to={team?.__typename === 'FootballTeam' ? `/football_team/${team?.short_id}` : team?.__typename === 'BasketballTeam' ? `/basketball_team/${team?.short_id}` : ''}
                                                  sx={{
                                                    'fontWeight': '800',
                                                    'fontSize': '1.9em',
                                                    'textDecoration': 'none',
                                                    'color': 'rgba(247, 251, 250, 1)',
                                                    '&:hover': {
                                                      color: 'rgba(251, 88, 2, 1)',
                                                    },
                                                  }}
                                                >
                                                  {team?.name}
                                                </Typography>
                                              </>
                                            )}
                                          </CardContent>
                                        </CardMedia>
                                      </Card>
                                    </Grid>
                                  ))}
                                </Grid>

                                <Grid container sx={{ justifyContent: 'center', p: { xs: '30px 0 0', sm: '30px 0 0' } }}>
                                  {friendlyTeams?.map((team: any) => (
                                    <Grid key={team?.id} item xs={12} sm={12} md={6} lg={4} xl={4} sx={{ p: '0 30px' }}>
                                      <Card
                                        className={'card'}
                                        sx={{
                                          bgcolor: 'rgba(247, 251, 250, .1)',
                                          p: '0px',
                                          width: '100%',
                                          borderRadius: '20px',
                                          m: '0 auto 30px',
                                        }}
                                      >
                                        <CardMedia
                                          sx={{
                                            backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.8) 50%), url(${team?.cover?.path})`,
                                          }}
                                        >
                                          <CardContent className={'content'} sx={{ textAlign: 'center', p: '30px 0 !important' }}>
                                            {team?.avatar?.path ? (
                                              <Avatar
                                                src={team?.avatar?.path}
                                                component={Link}
                                                to={team?.__typename === 'FootballTeam' ? `/football_team/${team?.short_id}` : team?.__typename === 'BasketballTeam' ? `/basketball_team/${team?.short_id}` : ''}
                                                sx={{
                                                  m: 'auto',
                                                  width: '100px',
                                                  height: '100px',
                                                }}
                                              />
                                            ) : (
                                              <Skeleton className="white-skeloton" variant="circular" width="100px" height="100px" sx={{ m: 'auto' }} />
                                            )}
                                            {friendlyTeams?.length === 0 ? (
                                              ''
                                            ) : (
                                              <>
                                                <Typography
                                                  component={Link}
                                                  to={team?.__typename === 'FootballTeam' ? `/football_team/${team?.short_id}` : team?.__typename === 'BasketballTeam' ? `/basketball_team/${team?.short_id}` : ''}
                                                  sx={{
                                                    'fontWeight': '800',
                                                    'fontSize': '1.9em',
                                                    'textDecoration': 'none',
                                                    'color': 'rgba(247, 251, 250, 1)',
                                                    '&:hover': {
                                                      color: 'rgba(251, 88, 2, 1)',
                                                    },
                                                  }}
                                                >
                                                  {team?.name}
                                                </Typography>
                                                <Typography
                                                  sx={{
                                                    fontWeight: '600',
                                                    fontSize: '.8em',
                                                    textTransform: 'uppercase',
                                                  }}
                                                >
                                                  {`${intl.formatMessage({ id: SPORT_TEAM_TYPES.find((t: any) => t.value === team?.type)?.label })}`}&nbsp;{_.replace(team?.__typename, 'Team', ` Team`)}
                                                </Typography>
                                                <Typography
                                                  variant="caption"
                                                  sx={{
                                                    color: 'rgba(247, 251, 250, 1)',
                                                    width: '100%',
                                                    display: 'inline-block',
                                                  }}
                                                >
                                                  {`${intl.formatMessage({ id: 'Global_Since' })}`}&nbsp;<Moment format="D MMM YYYY">{team?.created_at}</Moment>
                                                </Typography>
                                                <Typography
                                                  variant="caption"
                                                  sx={{
                                                    color: 'rgba(247, 251, 250, 1)',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                  }}
                                                >
                                                  <LocationIcon
                                                    sx={{
                                                      color: 'rgba(19, 96, 13, .5)',
                                                    }}
                                                  />
                                                  &nbsp;
                                                  {team?.originate_location?.city?.name}, {team?.originate_location?.country?.name}
                                                  &nbsp;
                                                  <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${team?.originate_location?.country_code?.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${team?.originate_location?.country_code?.toLowerCase()}.png 2x`} alt="" />
                                                </Typography>
                                              </>
                                            )}
                                          </CardContent>
                                        </CardMedia>
                                      </Card>
                                    </Grid>
                                  ))}
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </TabPanel>

                    <TabPanel value={value} index={2}>
                      {!challenges || challenges?.length === 0 || loadedChallenges.loading ? (
                        !challenges || challenges?.length === 0 || loadedChallenges.error ? (
                          <Typography
                            variant="h3"
                            sx={{
                              p: '50px 100px 0',
                              fontSize: '1.3em',
                              textAlign: 'center',
                              color: 'rgba(247, 251, 250, 0.3)',
                              display: 'inline-block',
                              width: '100%',
                              minHeight: '20vh',
                            }}
                          >
                            {`${intl.formatMessage({ id: 'Global_No_Challenges' })}`}
                          </Typography>
                        ) : (
                          <LoadingComponent amount={1} text={<Typography sx={{ mb: '20px', color: 'rgba(251, 88, 2, .3) !important' }}>{`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}</Typography>} loader={<CircularProgress className="orange-loader" />} />
                        )
                      ) : (
                        <Grid container className={'list-flexs'} sx={{ p: '50px 10% 150px' }}>
                          <Grid container sx={{ textAlign: 'center', m: '10px auto 0' }}>
                            {challenges?.map((challenge: any, index: number) => (
                              <Grid key={index} item xs={12} sm={12} md={6} lg={4} xl={4} sx={{ p: { xs: '0', sm: '0 30px' }, m: { xs: '0 0 30px' } }}>
                                <Card
                                  className={'card'}
                                  sx={{
                                    'bgcolor': 'rgba(247, 251, 250, .1)',
                                    'width': { xs: '100%', md: '100%' },
                                    'height': { xs: '200px', md: '200px' },
                                    'display': 'flex',
                                    'alignItems': 'center',
                                    'opacity': challenge?.end_date > Date.now() ? '1' : '.3',
                                    '&:hover': { opacity: '1' },
                                  }}
                                >
                                  <CardMedia
                                    sx={{
                                      height: '-webkit-fill-available',
                                      backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,.8) 50%), url(${challenge?.arena?.pictures[_.random(0, challenge?.arena?.pictures?.length - 1)]?.path})`,
                                      display: 'inline-flex',
                                      alignItems: 'center',
                                      width: '100%',
                                      p: '0 20px',
                                    }}
                                  >
                                    <Grid container sx={{ justifyContent: 'center' }}>
                                      <Typography
                                        component={Link}
                                        to={`/challenge/${challenge?.short_id}`}
                                        sx={{
                                          'lineHeight': '20px',
                                          'fontWeight': '800',
                                          'fontSize': '1.1em',
                                          'textDecoration': 'none',
                                          'color': 'rgba(247, 251, 250, 1)',
                                          '&:hover': {
                                            color: 'rgba(251, 88, 2, 1)',
                                          },
                                        }}
                                      >
                                        {challenge?.title}
                                      </Typography>
                                      {challenge?.status === 'CANCELLED' ? (
                                        <Typography
                                          className={challenge?.status}
                                          sx={{
                                            fontSize: '1em',
                                            fontWeight: '700',
                                            textTransform: 'uppercase',
                                            width: '100%',
                                          }}
                                        >
                                          {challenge?.status}
                                        </Typography>
                                      ) : (
                                        ''
                                      )}
                                      <Typography
                                        sx={{
                                          fontSize: '.7em',
                                          textTransform: 'uppercase',
                                          width: '100%',
                                        }}
                                      >
                                        {`${intl.formatMessage({ id: CHALLENGE_TYPES.find((t: any) => t.value === challenge?.type)?.label })}`}
                                      </Typography>

                                      <Typography
                                        variant="caption"
                                        sx={{
                                          mt: '10px',
                                          width: '100%',
                                          color: 'rgba(247, 251, 250, 1)',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                        }}
                                      >
                                        <Moment format="D MMM YYYY">{challenge?.start_date}</Moment>
                                        {challenge?.start_date + ONE_DAY_MILLISEC > challenge?.end_date ? (
                                          <>
                                            &nbsp;[<Moment format="HH:mm">{challenge?.start_date}</Moment>
                                            &nbsp;-&gt;&nbsp;
                                            <Moment format="HH:mm">{challenge?.end_date}</Moment>]
                                          </>
                                        ) : (
                                          <>
                                            &nbsp;-&nbsp;
                                            <Moment format="D MMM YYYY">{challenge?.end_date}</Moment>
                                          </>
                                        )}
                                      </Typography>

                                      <Typography
                                        variant="caption"
                                        sx={{
                                          width: '100%',
                                          color: 'rgba(247, 251, 250, 1)',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                        }}
                                      >
                                        <LocationIcon
                                          sx={{
                                            color: 'rgba(19, 96, 13, .5)',
                                          }}
                                        />
                                        &nbsp;
                                        {(challenge?.complex || challenge?.arena)?.location?.city?.name}, {(challenge?.complex || challenge?.arena)?.location?.country?.name}
                                        &nbsp;
                                        <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${(challenge?.complex || challenge?.arena)?.location?.country_code?.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${(challenge?.complex || challenge?.arena)?.location?.country_code?.toLowerCase()}.png 2x`} alt="" />
                                      </Typography>
                                    </Grid>
                                  </CardMedia>
                                </Card>
                              </Grid>
                            ))}
                            <Grid container sx={{ justifyContent: 'center' }}>
                              {loadedChallenges.data?.findChallengesByPlayerID?.length === 0 ? (
                                ''
                              ) : (
                                <Fab
                                  sx={{
                                    'color': 'rgba(247, 251, 250, 1)',
                                    'bgcolor': 'transparent',
                                    'boxShadow': 'none',
                                    ':hover': {
                                      bgcolor: 'transparent',
                                      color: 'rgba(168, 202, 65, 1)',
                                    },
                                  }}
                                  variant="extended"
                                  onClick={handleLoadMoreChallenges}
                                >
                                  {loadedChallenges.loading ? (
                                    <Grid container sx={{ alignItems: 'center' }}>
                                      <CircularProgress className="green-loader" size={15} />
                                      &nbsp;&nbsp;{`${intl.formatMessage({ id: 'Global_Button_Loading' })}`}
                                    </Grid>
                                  ) : (
                                    <Grid container sx={{ alignItems: 'center' }}>
                                      <Add sx={{ color: 'rgba(168, 202, 65, 1)' }} />
                                      &nbsp;{`${intl.formatMessage({ id: 'Global_Button_Load_More' })}`}
                                    </Grid>
                                  )}
                                </Fab>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </TabPanel>

                    <TabPanel value={value} index={3}>
                      {(loadedPlayerByURL.error && loadedPlayerByURL.called) || loadedPlayerByURL.loading ? (
                        loadedPlayerByURL.error ? (
                          <Typography
                            variant="h3"
                            sx={{
                              p: '50px 20px 0',
                              fontSize: '1.3em',
                              textAlign: 'center',
                              color: 'rgba(247, 251, 250, 0.3)',
                              display: 'inline-block',
                              width: '100%',
                              minHeight: '20vh',
                            }}
                          >
                            {`${intl.formatMessage({ id: 'Global_No_Statistics' })}`}
                          </Typography>
                        ) : (
                          <LoadingComponent amount={1} text={<Typography sx={{ mb: '20px', color: 'rgba(251, 88, 2, .3) !important' }}>{`${intl.formatMessage({ id: 'Global_Button_Loading_Wait' })}`}</Typography>} loader={<CircularProgress className="orange-loader" />} />
                        )
                      ) : (
                        <Grid container className={'list-flexs'} sx={{ p: '50px 5% 150px' }}>
                          {selectedProfile?.alias ? (
                            <Grid container className={'actions'} sx={{ textAlign: 'center', m: '10px auto 0' }}>
                              <Grid className="stats" item xs={12} sm={6} lg={4} sx={{ m: '0px 0 40px', p: '0 20px' }}>
                                <Grid container>
                                  <Grid item xs={10} sm={10} lg={10}>
                                    <Typography sx={{ fontWeight: '800', fontSize: '3em', textAlign: 'left' }}>{NB_GOALS ?? <>0</>}</Typography>
                                  </Grid>
                                  <Grid item xs={2} sm={2} lg={2} sx={{ '& svg': { fontSize: '2em' } }}>
                                    <GoalsIcon />
                                  </Grid>
                                </Grid>
                                <Typography sx={{ opacity: '.4', textAlign: 'left', p: '0 0 10px', fontSize: '1.1em' }}>{`${intl.formatMessage({ id: 'Global_Stats_Goals' })}`}</Typography>
                                <Divider variant="fullWidth" sx={{ bgcolor: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(255, 255, 255, .3)' : 'rgba(15, 15, 15, .4)' }} />
                              </Grid>

                              <Grid className="stats" item xs={12} sm={6} lg={4} sx={{ m: '0px 0 40px', p: '0 20px' }}>
                                <Grid container>
                                  <Grid item xs={10} sm={10} lg={10}>
                                    <Typography sx={{ fontWeight: '800', fontSize: '3em', textAlign: 'left' }}>{NB_ASSISTS ?? <>0</>}</Typography>
                                  </Grid>
                                  <Grid item xs={2} sm={2} lg={2} sx={{ '& svg': { fontSize: '3em' } }}>
                                    <SucceedPassesIcon />
                                  </Grid>
                                </Grid>
                                <Typography sx={{ opacity: '.4', textAlign: 'left', p: '0 0 10px', fontSize: '1.1em' }}>{`${intl.formatMessage({ id: 'Global_Stats_Assists' })}`}</Typography>
                                <Divider variant="fullWidth" sx={{ bgcolor: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(255, 255, 255, .3)' : 'rgba(15, 15, 15, .4)' }} />
                              </Grid>

                              <Grid className="stats" item xs={12} sm={6} lg={4} sx={{ m: '0px 0 40px', p: '0 20px' }}>
                                <Grid container>
                                  <Grid item xs={10} sm={10} lg={10}>
                                    <Typography sx={{ fontWeight: '800', fontSize: '3em', textAlign: 'left' }}>{NB_GAMES ?? <>0</>}</Typography>
                                  </Grid>
                                  <Grid item xs={2} sm={2} lg={2} sx={{ '& svg': { fontSize: '2em' } }}>
                                    <GamesPlayedIcon />
                                  </Grid>
                                </Grid>
                                <Typography sx={{ opacity: '.4', textAlign: 'left', p: '0 0 10px', fontSize: '1.1em' }}>{`${intl.formatMessage({ id: 'Global_Stats_Games_Played' })}`}</Typography>
                                <Divider variant="fullWidth" sx={{ bgcolor: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(255, 255, 255, .3)' : 'rgba(15, 15, 15, .4)' }} />
                              </Grid>

                              <Grid className="stats" item xs={12} sm={6} lg={4} sx={{ m: '0px 0 40px', p: '0 20px' }}>
                                <Grid container>
                                  <Grid item xs={10} sm={10} lg={10}>
                                    <Typography sx={{ fontWeight: '800', fontSize: '3em', textAlign: 'left' }}>{NB_MOTM ?? <>0</>}</Typography>
                                  </Grid>
                                  <Grid item xs={2} sm={2} lg={2} sx={{ '& svg': { fontSize: '2em' } }}>
                                    <ManOfTheMatchIcon />
                                  </Grid>
                                </Grid>
                                <Typography sx={{ opacity: '.4', textAlign: 'left', p: '0 0 10px', fontSize: '1.1em' }}>{`${intl.formatMessage({ id: 'Global_Stats_MOTM' })}`}</Typography>
                                <Divider variant="fullWidth" sx={{ bgcolor: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(255, 255, 255, .3)' : 'rgba(15, 15, 15, .4)' }} />
                              </Grid>

                              <Grid className="stats" item xs={12} sm={6} lg={4} sx={{ m: '0px 0 40px', p: '0 20px' }}>
                                <Grid container>
                                  <Grid item xs={10} sm={10} lg={10}>
                                    <Typography sx={{ fontWeight: '800', fontSize: '3em', textAlign: 'left' }}>&infin;</Typography>
                                  </Grid>
                                  <Grid item xs={2} sm={2} lg={2} sx={{ '& svg': { fontSize: '3em' } }}>
                                    <SucceedPassesIcon />
                                  </Grid>
                                </Grid>
                                <Typography sx={{ opacity: '.4', textAlign: 'left', p: '0 0 10px', fontSize: '1.1em' }}>{`${intl.formatMessage({ id: 'Global_Stats_Good_Passes' })}`}</Typography>
                                <Divider variant="fullWidth" sx={{ bgcolor: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(255, 255, 255, .3)' : 'rgba(15, 15, 15, .4)' }} />
                              </Grid>

                              <Grid className="stats" item xs={12} sm={6} lg={4} sx={{ m: '0px 0 40px', p: '0 20px' }}>
                                <Grid container>
                                  <Grid item xs={10} sm={10} lg={10}>
                                    <Typography sx={{ fontWeight: '800', fontSize: '3em', textAlign: 'left' }}>&infin;</Typography>
                                  </Grid>
                                  <Grid item xs={2} sm={2} lg={2} sx={{ '& svg': { fontSize: '2em' } }}>
                                    <TacklesIcon />
                                  </Grid>
                                </Grid>
                                <Typography sx={{ opacity: '.4', textAlign: 'left', p: '0 0 10px', fontSize: '1.1em' }}>{`${intl.formatMessage({ id: 'Global_Stats_Shots' })}`}</Typography>
                                <Divider variant="fullWidth" sx={{ bgcolor: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(255, 255, 255, .3)' : 'rgba(15, 15, 15, .4)' }} />
                              </Grid>

                              <Grid className="stats" item xs={12} sm={6} lg={4} sx={{ m: '0px 0 40px', p: '0 20px' }}>
                                <Grid container>
                                  <Grid item xs={10} sm={10} lg={10}>
                                    <Typography sx={{ fontWeight: '800', fontSize: '3em', textAlign: 'left' }}>&infin;</Typography>
                                  </Grid>
                                  <Grid item xs={2} sm={2} lg={2} sx={{ '& svg': { fontSize: '2em' } }}>
                                    <AverageDistanceIcon />
                                  </Grid>
                                </Grid>
                                <Typography sx={{ opacity: '.4', textAlign: 'left', p: '0 0 10px', fontSize: '1.1em' }}>{`${intl.formatMessage({ id: 'Global_Stats_KM_Total' })}`}</Typography>
                                <Divider variant="fullWidth" sx={{ bgcolor: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(255, 255, 255, .3)' : 'rgba(15, 15, 15, .4)' }} />
                              </Grid>

                              <Grid className="stats" item xs={12} sm={6} lg={4} sx={{ m: '0px 0 40px', p: '0 20px' }}>
                                <Grid container>
                                  <Grid item xs={10} sm={10} lg={10}>
                                    <Typography sx={{ fontWeight: '800', fontSize: '3em', textAlign: 'left' }}>&infin;</Typography>
                                  </Grid>
                                  <Grid item xs={2} sm={2} lg={2} sx={{ '& svg': { fontSize: '2em' } }}>
                                    <AverageDistanceIcon />
                                  </Grid>
                                </Grid>
                                <Typography sx={{ opacity: '.4', textAlign: 'left', p: '0 0 10px', fontSize: '1.1em' }}>{`${intl.formatMessage({ id: 'Global_Stats_KM_Average' })}`}</Typography>
                                <Divider variant="fullWidth" sx={{ bgcolor: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(255, 255, 255, .3)' : 'rgba(15, 15, 15, .4)' }} />
                              </Grid>

                              <Grid className="stats" item xs={12} sm={6} lg={4} sx={{ m: '0px 0 40px', p: '0 20px' }}>
                                <Grid container>
                                  <Grid item xs={10} sm={10} lg={10}>
                                    <Typography sx={{ fontWeight: '800', fontSize: '3em', textAlign: 'left' }}>&infin;</Typography>
                                  </Grid>
                                  <Grid item xs={2} sm={2} lg={2} sx={{ '& svg': { fontSize: '2em' } }}>
                                    <AverageMaxSpeedIcon />
                                  </Grid>
                                </Grid>
                                <Typography sx={{ opacity: '.4', textAlign: 'left', p: '0 0 10px', fontSize: '1.1em' }}>{`${intl.formatMessage({ id: 'Global_Stats_KMH_Max' })}`}</Typography>
                                <Divider variant="fullWidth" sx={{ bgcolor: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(255, 255, 255, .3)' : 'rgba(15, 15, 15, .4)' }} />
                              </Grid>

                              <Grid className="stats" item xs={12} sm={6} lg={4} sx={{ m: '0px 0 40px', p: '0 20px' }}>
                                <Grid container>
                                  <Grid item xs={10} sm={10} lg={10}>
                                    <Typography sx={{ fontWeight: '800', fontSize: '3em', textAlign: 'left' }}>&infin;</Typography>
                                  </Grid>
                                  <Grid item xs={2} sm={2} lg={2} sx={{ '& svg': { fontSize: '2em' } }}>
                                    <AverageMaxSpeedIcon />
                                  </Grid>
                                </Grid>
                                <Typography sx={{ opacity: '.4', textAlign: 'left', p: '0 0 10px', fontSize: '1.1em' }}>{`${intl.formatMessage({ id: 'Global_Stats_KMH_Average' })}`}</Typography>
                                <Divider variant="fullWidth" sx={{ bgcolor: loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(255, 255, 255, .3)' : 'rgba(15, 15, 15, .4)' }} />
                              </Grid>
                            </Grid>
                          ) : (
                            <Typography className="INFO" sx={{ textAlign: 'center', fontWeight: '700', width: '100%', textTransform: 'uppercase' }}>
                              {`${intl.formatMessage({ id: 'Profile_Pick_Profile' })}`}
                            </Typography>
                          )}
                        </Grid>
                      )}
                    </TabPanel>

                    {playerByURL?.id && playerByURL?.id !== loggedPlayer?.id ? undefined : (
                      <TabPanel value={value} index={4}>
                        <Grid container className={'list-flexs'} sx={{ p: '50px 10% 150px' }}>
                          <Grid item xs={12} md={12} lg={12} className={'actions'} sx={{ textAlign: 'center', m: '10px auto 0' }}>
                            <TeamHistory player_id={playerByURL?.id || loggedPlayer?.id} />
                          </Grid>
                        </Grid>
                      </TabPanel>
                    )}

                    {!ALL_PUBLISHED_FIELDS || ALL_PUBLISHED_FIELDS?.length === 0 ? undefined : (
                      <TabPanel value={value} index={5}>
                        <Grid container className={'list-flexs'} sx={{ p: '50px 10% 150px' }}>
                          <Grid item xs={12} md={12} lg={12} className={'actions'} sx={{ textAlign: 'center', m: '10px auto 0' }}>
                            <ListPublishedFields fields={ALL_PUBLISHED_FIELDS} />
                          </Grid>
                        </Grid>
                      </TabPanel>
                    )}
                  </SwipeableViews>
                </Grid>
              </>
            )}
          </>
        )}
      </CardMedia>
    </Grid>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Grid container role="tabpanel" hidden={value !== index} id={`scrollable-auto-tabpanel-${index}`} aria-labelledby={`scrollable-auto-tab-${index}`} {...other}>
      {value === index && (
        <Grid container>
          <Grid container>{children}</Grid>
        </Grid>
      )}
    </Grid>
  );
}

function a11yProps(index: any) {
  return {
    'id': `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default Profile;

// curl -X POST https://developer.dev.danapay.io/authorization/token -H "Content-Type: application/x-www-form-urlencoded" -d "grant_type=client_credentials&client_id=f358ecc1-e458-4f8f-8ddf-ff00def139e0&client_secret=fc2630d2f2cf401e6f2155b6020410ea7cf082f3af8b8b84f6b2799ec9e75829"

import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Dialog, DialogTitle, DialogContent, DialogContentText, Fab, Grid, Avatar, useMediaQuery, Stack } from '@mui/material';
import { Close } from '@mui/icons-material';
import { UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { gql, useQuery } from '@apollo/client';
import _ from 'lodash';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { FivezerWhiteIcon, FootBallIcon, MidfielderIcon, VersusIcon } from '../../img/icons/Icons';
import GiveSportSessionFeedbackModal from './GiveSportSessionFeedbackModal';
import { selectSession } from '../../reducers/sessionSlice';

export default function ViewScoreModal(props: { challenge_short_id: string; handleClose: any; open: any }) {
  const FIND_CHALLENGE_BY_ID_QUERY = gql`
    query findChallengeByID($challenge: inputFindChallengeByID!) {
      findChallengeByID(challenge: $challenge) {
        id
        short_id
        title
        status
        type
        complex {
          id
          name
        }
        arena {
          id
          name
        }
        home_team {
          id
          name
          status
          players {
            id
            status
            team_number
            team_player {
              ... on FootballTeam {
                __typename
                id
                name
              }
            }
            player {
              ... on Footballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                }
              }
            }
          }
          captains {
            id
            status
            team_number
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
          team {
            ... on FootballTeam {
              __typename
              id
              name
              avatar {
                path
              }
            }
          }
        }
        away_team {
          id
          name
          status
          players {
            id
            status
            team_number
            team_player {
              ... on FootballTeam {
                __typename
                id
                name
              }
            }
            player {
              ... on Footballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                avatar {
                  path
                }
                player {
                  id
                }
              }
            }
          }
          captains {
            id
            status
            team_number
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
          team {
            ... on FootballTeam {
              __typename
              id
              name
              avatar {
                path
              }
            }
          }
        }
        booking {
          id
          feedbacks {
            id
            author {
              ... on Player {
                __typename
                id
              }
            }
          }
          consumers {
            id
            payment_status
            transaction {
              id
              status
              tx_reference
              note_reference
              execution_date
              confirmed_at
            }
            player {
              id
              status
              team_number
              player {
                ... on Footballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
              }
            }
          }
        }
        results {
          id
          status
          home_team {
            id
            name
          }
          away_team {
            id
            name
          }
          captains_confirmations {
            ... on Footballer {
              __typename
              id
              alias
              avatar {
                path
              }
              player {
                id
              }
            }
            ... on Basketballer {
              __typename
              id
              alias
              avatar {
                path
              }
              player {
                id
              }
            }
          }
          men_of_the_match {
            ... on Footballer {
              __typename
              id
              alias
              player {
                id
              }
            }
            ... on Basketballer {
              __typename
              id
              alias
              player {
                id
              }
            }
          }
          statistics {
            id
            status
            nb_goals
            given_assists {
              id
              nb_assists
              giver {
                ... on Footballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
              }
              receiver {
                ... on Footballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
                ... on Basketballer {
                  __typename
                  id
                  alias
                  player {
                    id
                  }
                }
              }
            }
            pace
            shooting
            passing
            dribbling
            defending
            physical
            goal_keeping
            team_player {
              id
              team_player {
                ... on FootballTeam {
                  __typename
                  id
                  name
                }
              }
            }
            player {
              ... on Footballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
              ... on Basketballer {
                __typename
                id
                alias
                player {
                  id
                }
              }
            }
          }
        }
      }
    }
  `;

  const FIND_FEEDBACKS_BY_SESSION_SPORT_ID_QUERY = gql`
    query findFeedbacksBySportSessionID($sport_session: inputFindFeedbacksBySportSessionID!) {
      findFeedbacksBySportSessionID(sport_session: $sport_session) {
        id
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const isSmallMobile = useMediaQuery('(max-width:600px)');
  const [challenge, setChallenge] = React.useState(UNDEFINED_AS_ANY);

  const loadedChallenge = useQuery(FIND_CHALLENGE_BY_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      challenge: {
        short_id: props.challenge_short_id,
        logged_player_id: loggedPlayer?.id,
      },
    },
  });
  const loadedFeedbacks = useQuery(FIND_FEEDBACKS_BY_SESSION_SPORT_ID_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      sport_session: {
        id: challenge?.booking?.id,
      },
    },
  });

  const HOME_TEAM = challenge?.home_team;
  const AWAY_TEAM = challenge?.away_team;
  const HOME_TEAM_STATS = challenge?.results?.statistics?.filter((s: any) => s?.team_player?.team_player?.id === HOME_TEAM?.team?.id);
  const AWAY_TEAM_STATS = challenge?.results?.statistics?.filter((s: any) => s?.team_player?.team_player?.id === AWAY_TEAM?.team?.id);
  const HOME_TEAM_SCORE = _.sum(HOME_TEAM_STATS?.map((s: any) => s?.nb_goals));
  const AWAY_TEAM_SCORE = _.sum(AWAY_TEAM_STATS?.map((s: any) => s?.nb_goals));

  const handleClose = () => {
    props.handleClose();
  };

  React.useEffect(() => {
    if (props.open && ((!challenge && loadedChallenge.called && loadedChallenge.data?.findChallengeByID) || !_.isMatch(challenge, loadedChallenge.data?.findChallengeByID))) {
      setChallenge(loadedChallenge.data?.findChallengeByID);
    }

    if (!props.open && challenge) {
      setChallenge(UNDEFINED_AS_ANY);
    }
  }, [challenge, loadedChallenge, setChallenge, props]);

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: { minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' }, p: '20px 10px' },
      }}
    >
      <Fab
        size="small"
        onClick={handleClose}
        className={'button-cancel'}
        sx={{
          boxShadow: 'none',
          bgcolor: 'transparent',
          ml: '2%',
        }}
      >
        <Close />
      </Fab>
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: 'center', pt: '0px', textTransform: 'uppercase' }}>
        <Typography sx={{ fontWeight: '700', fontSize: '1.1em' }}>Game results</Typography>
      </DialogTitle>
      <DialogContent sx={{ p: { xs: '20px 0px' } }}>
        <DialogContentText id="alert-dialog-description" component="span">
          <Grid
            container
            sx={{
              justifyContent: 'start',
              alignItems: 'center',
              p: '0',
              color: 'rgba(247, 251, 250, 1)',
            }}
          >
            <Typography
              component="span"
              className="secondary-font"
              sx={{
                pt: '50px',
                fontSize: {
                  xs: '8em',
                  sm: '9em',
                  md: '13em',
                  lg: '15em',
                },
                position: 'absolute',
                top: '0',
                fontStyle: 'italic',
                textTransform: 'uppercase',
                fontWeight: 'bold',
                color: 'transparent',
                width: '100%',
                display: 'inline-block',
                lineHeight: {
                  xs: '100px',
                  sm: '110px',
                  md: '160px',
                  lg: '180px',
                },
              }}
            >
              <Grid sx={{ textAlign: 'left', position: 'relative', left: '-70px', WebkitTextStroke: '2px rgba(199, 240, 11, .1)' }}>Final</Grid>
              <Grid sx={{ textAlign: 'center', position: 'relative', WebkitTextStroke: '2px rgba(199, 240, 11, .1)' }}>Results</Grid>
            </Typography>

            {!loadedFeedbacks?.error && !challenge?.booking?.feedbacks?.find((f: any) => f?.author?.id === loggedPlayer?.id) && challenge?.booking?.consumers?.find((c: any) => c?.player?.player?.player?.id === loggedPlayer?.id) ? (
              <>
                <GiveSportSessionFeedbackModal challenge_short_id={challenge?.short_id} />
                <Grid container sx={{ minHeight: '60vh', p: '40px 0 0' }}>
                  <Typography sx={{ fontSize: '1.5em', textAlign: 'center' }}>
                    Before you look at the scores
                    <br />
                    Tell us about your experience on {challenge?.arena?.name} at {challenge?.complex?.name}
                  </Typography>
                </Grid>
              </>
            ) : (
              <>
                <Grid container>
                  <Grid item xs={5.5} sm={5.5} md={5.5} lg={5.5} sx={{ display: { xs: 'inline-block', sm: 'inline-flex' }, textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                    <Grid sx={{ textAlign: 'center' }}>
                      <Typography sx={{ fontWeight: '700', lineHeight: '15px', textTransform: 'uppercase', height: '58px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{challenge?.home_team?.name}</Typography>
                      {challenge?.home_team?.team?.avatar?.path ? <Avatar src={challenge?.home_team?.team?.avatar?.path} alt={challenge?.home_team?.name} sx={{ m: 'auto', width: { xs: '40px', sm: '60px', md: '80px' }, height: { xs: '40px', sm: '60px', md: '80px' } }} /> : <FivezerWhiteIcon sx={{ m: 'auto', opacity: '.05', width: { xs: '40px', sm: '60px', md: '80px' }, height: { xs: '40px', sm: '60px', md: '80px' } }} />}
                    </Grid>
                    <Typography sx={{ color: HOME_TEAM_SCORE > AWAY_TEAM_SCORE ? 'rgba(168, 202, 65, 1)' : '', fontWeight: 'bold', fontSize: { xs: '4em', sm: '5em' }, width: { xs: '100%', sm: 'auto' } }}>{HOME_TEAM_SCORE}</Typography>
                  </Grid>
                  <Grid className="separator" item xs={1} sm={1} md={1} lg={1} xl={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <VersusIcon sx={{ m: '0', color: 'rgba(238, 44, 32, 1) !important', fontSize: { xs: '50px', sm: '50px' } }} />
                  </Grid>
                  <Grid item xs={5.5} sm={5.5} md={5.5} lg={5.5} sx={{ display: { xs: 'inline-block', sm: 'inline-flex' }, textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                    {isSmallMobile ? '' : <Typography sx={{ color: HOME_TEAM_SCORE < AWAY_TEAM_SCORE ? 'rgba(168, 202, 65, 1)' : '', fontWeight: 'bold', fontSize: { xs: '4em', sm: '5em' }, width: { xs: '100%', sm: 'auto' } }}>{AWAY_TEAM_SCORE}</Typography>}
                    <Grid sx={{ textAlign: 'center' }}>
                      <Typography sx={{ fontWeight: '700', lineHeight: '15px', textTransform: 'uppercase', height: '58px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{challenge?.away_team?.name}</Typography>
                      {challenge?.away_team?.team?.avatar?.path ? <Avatar src={challenge?.away_team?.team?.avatar?.path} alt={challenge?.away_team?.name} sx={{ m: 'auto', width: { xs: '40px', sm: '60px', md: '80px' }, height: { xs: '40px', sm: '60px', md: '80px' } }} /> : <FivezerWhiteIcon sx={{ m: 'auto', opacity: '.05', width: { xs: '40px', sm: '60px', md: '80px' }, height: { xs: '40px', sm: '60px', md: '80px' } }} />}
                    </Grid>
                    {isSmallMobile ? <Typography sx={{ color: HOME_TEAM_SCORE < AWAY_TEAM_SCORE ? 'rgba(168, 202, 65, 1)' : '', fontWeight: 'bold', fontSize: { xs: '4em', sm: '5em' }, width: { xs: '100%', sm: 'auto' } }}>{AWAY_TEAM_SCORE}</Typography> : ''}
                  </Grid>
                </Grid>

                <Grid container sx={{ minHeight: '60vh', p: '40px 0 0' }}>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Stack direction="column" sx={{ p: { xs: '0', sm: '0 10%', md: '0 15%' } }}>
                      {challenge?.home_team?.players?.map((p: any, index: any) => (
                        <Grid container key={index} sx={{ justifyContent: { xs: 'center', sm: 'start' }, alignItems: 'center', m: '0 0 20px' }}>
                          <Avatar src={p?.player?.avatar?.path} alt={p?.player?.alias} />
                          &nbsp;
                          <Typography sx={{ fontWeight: '600', width: { xs: '100%', sm: 'auto' }, textAlign: { xs: 'center', sm: 'left' } }}>{p?.player?.alias}</Typography>
                          <Typography sx={{ width: { xs: '100%', sm: 'auto' }, fontSize: '1em', fontWeight: '800', display: 'inline-flex', justifyContent: 'center', color: 'rgba(168, 202, 65, 1)' }}>
                            {HOME_TEAM_STATS?.find((s: any) => s?.player?.id === p?.player?.id) ? (
                              <>
                                &nbsp;&nbsp;
                                {HOME_TEAM_STATS?.find((s: any) => s?.player?.id === p?.player?.id)?.nb_goals}x<FootBallIcon sx={{ fontSize: '1.2em' }} />
                                &nbsp;
                              </>
                            ) : (
                              ''
                            )}

                            {_.sum(
                              HOME_TEAM_STATS?.map((s: any) => {
                                return s?.given_assists?.filter((a: any) => a?.receiver?.player?.id === p?.player?.player?.id);
                              })
                                ?.flat()
                                ?.map((p: any) => p?.nb_assists)
                            ) > 0 ? (
                              <>
                                &nbsp;
                                {_.sum(
                                  HOME_TEAM_STATS?.map((s: any) => {
                                    return s?.given_assists?.filter((a: any) => a?.receiver?.player?.id === p?.player?.player?.id);
                                  })
                                    ?.flat()
                                    ?.map((p: any) => p?.nb_assists)
                                )}
                                x<MidfielderIcon sx={{ fontSize: '1.2em' }} />
                                &nbsp;&nbsp;
                              </>
                            ) : (
                              ''
                            )}
                          </Typography>
                        </Grid>
                      ))}
                    </Stack>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Stack direction="column" sx={{ p: { xs: '0', sm: '0 10%', md: '0 15%' } }}>
                      {challenge?.away_team?.players?.map((p: any, index: any) => (
                        <Grid container key={index} sx={{ justifyContent: { xs: 'center', sm: 'start' }, alignItems: 'center', m: '0 0 20px' }}>
                          <Avatar src={p?.player?.avatar?.path} alt={p?.player?.alias} />
                          &nbsp;
                          <Typography sx={{ fontWeight: '600', width: { xs: '100%', sm: 'auto' }, textAlign: { xs: 'center', sm: 'left' } }}>{p?.player?.alias}</Typography>
                          <Typography sx={{ width: { xs: '100%', sm: 'auto' }, fontSize: '1em', fontWeight: '800', display: 'inline-flex', justifyContent: 'center', color: 'rgba(168, 202, 65, 1)' }}>
                            {AWAY_TEAM_STATS?.find((s: any) => s?.player?.id === p?.player?.id) ? (
                              <>
                                &nbsp;&nbsp;
                                {AWAY_TEAM_STATS?.find((s: any) => s?.player?.id === p?.player?.id)?.nb_goals}x<FootBallIcon sx={{ fontSize: '1.2em' }} />
                              </>
                            ) : (
                              ''
                            )}

                            {_.sum(
                              AWAY_TEAM_STATS?.map((s: any) => {
                                return s?.given_assists?.filter((a: any) => a?.receiver?.player?.id === p?.player?.player?.id);
                              })
                                ?.flat()
                                ?.map((p: any) => p?.nb_assists)
                            ) > 0 ? (
                              <>
                                &nbsp;&nbsp;
                                {_.sum(
                                  AWAY_TEAM_STATS?.map((s: any) => {
                                    return s?.given_assists?.filter((a: any) => a?.receiver?.player?.id === p?.player?.player?.id);
                                  })
                                    ?.flat()
                                    ?.map((p: any) => p?.nb_assists)
                                )}
                                x<MidfielderIcon sx={{ fontSize: '1.2em' }} />
                                &nbsp;&nbsp;
                              </>
                            ) : (
                              ''
                            )}
                          </Typography>
                        </Grid>
                      ))}
                    </Stack>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

ViewScoreModal.propTypes = {
  challenge_id: PropTypes.string,
  handleClose: PropTypes.any,
  open: PropTypes.any,
};

ViewScoreModal.defaultProps = {};

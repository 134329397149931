import React from 'react';
import { Autocomplete, Avatar, Button, Chip, Fab, Grid, IconButton, InputAdornment, MenuItem, Stack, Switch, TextField, Typography } from '@mui/material';
import { useAppSelector } from '../../redux/hooks';
import { selectPlayer } from '../../reducers/playerSlice';
import { FivezerLogoIcon } from '../../img/icons/Icons';
import _ from 'lodash';
import BackButton from '../../component-modals/common/BackButton';
import { Link, useNavigate } from 'react-router-dom';
import { selectSession } from '../../reducers/sessionSlice';
import { customAlphabet } from 'nanoid';
import { Controller, useForm } from 'react-hook-form';
import { BLOCKCHAIN_CURRENCIES, COUNTRIES, CURRENCIES, SPORT_TYPES, TOURNAMENT_PLAYOFF_STAGES, TOURNAMENT_REG, TO_TIMESTAMP } from '../../utilities/utilities';
import { gql, useMutation } from '@apollo/client';
import InputDescriptionsFeature from '../../component-modals/common/InputDescriptionsFeature';
import { ARRAY_AS_ANY, CountryType, DATE_AS_ANY, DESCRIPTION_AS_ANY, INITIAL_COUNTRY, UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { FormattedMessage, useIntl } from 'react-intl';
import GPSMapFeature from '../../component-modals/common/GPSMapFeature';
import AutofillAddressInput from '../../component-modals/layout/AutofillAddressInput';
import { AppContext } from '../../App';
import { DateTimePicker } from '@mui/x-date-pickers';
import { OrganizationChart } from 'primereact/organizationchart';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import { LoadingButton } from '@mui/lab';
import { Send } from '@mui/icons-material';
import InputTrophiesFeature from '../../component-modals/common/InputTrophiesFeature';

const FormNewTournament: React.FC = () => {
  const CREATE_TOURNAMENT_MUTATION = gql`
    mutation createTournament($tournament: inputCreateTournament!) {
      createTournament(tournament: $tournament) {
        id
        short_id
      }
    }
  `;

  const SEND_NOTIFICATION_MUTATION = gql`
    mutation sendNotification($notification: inputSendNotification!) {
      sendNotification(notification: $notification)
    }
  `;

  const session = useAppSelector(selectSession);
  const loggedPlayer = useAppSelector(selectPlayer);
  const appContext = React.useContext(AppContext);
  const intl = useIntl();

  const [createTournament, newTournament] = useMutation(CREATE_TOURNAMENT_MUTATION);
  const [sendNotification, isSent] = useMutation(SEND_NOTIFICATION_MUTATION, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
  });

  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      name: '',
      sport_type: 'FOOTBALL',
      is_private: false,
      start_date: DATE_AS_ANY,
      end_date: DATE_AS_ANY,
      short_descriptions: ARRAY_AS_ANY,
      descriptions: ARRAY_AS_ANY,
      translated_desc_to_add: '',
      selected_description: DESCRIPTION_AS_ANY,
      selected_short_description: DESCRIPTION_AS_ANY,
      selected_short_description_content: '',
      selected_description_content: '',

      position: UNDEFINED_AS_ANY,
      address_title: '',
      street_name: '',
      secondary_street_name: '',
      postcode: '',
      city: '',
      county: '',
      state: '',
      country: COUNTRIES?.find((c: any) => c?.value === appContext?.currentPosition?.country?.code) || INITIAL_COUNTRY,

      all_organisators: ARRAY_AS_ANY,
      selected_organisators: ARRAY_AS_ANY,
      organisator_name: '',

      main_organisator_id: '',

      all_admins: ARRAY_AS_ANY,
      selected_admins: ARRAY_AS_ANY,
      admin_name: '',

      as_phase_groups: false,
      playoff_stage: 'FINAL_16',

      trophies: ARRAY_AS_ANY,

      value: 0,
      currency: 'MAT',
    },
  });
  const inputFields = watch();

  const navigate = useNavigate();
  const tournament_nanoid = customAlphabet(TOURNAMENT_REG, 21);
  const [country, setCountry] = React.useState<CountryType>(COUNTRIES?.find((c: any) => c?.value === appContext?.currentPosition?.country?.code) || INITIAL_COUNTRY);

  const ALL_ORGANISATORS = _.concat(
    loggedPlayer?.followings
      ?.filter((f: any) => f?.__typename !== 'Company' && f?.__typename !== 'FootballTeam')
      ?.map((f: any) => {
        return {
          id: f?.id,
          username: f?.username || f?.name,
          person: {
            id: f?.owner?.id,
            firstname: f?.owner?.firstname || f?.label,
            lastname: f?.owner?.lastname,
          },
          avatar: f?.avatar?.path || f?.logo?.path,
          type: f?.__typename,
        };
      }),
    {
      id: loggedPlayer?.id,
      username: loggedPlayer?.username,
      person: {
        id: loggedPlayer?.owner?.id,
        firstname: loggedPlayer?.owner?.firstname,
        lastname: loggedPlayer?.owner?.lastname,
      },
      avatar: loggedPlayer?.avatar?.path,
      type: loggedPlayer?.__typename,
    }
  );
  const TOTAL_NB_TEAMS = (inputFields.as_phase_groups ? 2 : 1) * (TOURNAMENT_PLAYOFF_STAGES?.find((s: any) => s.value === inputFields.playoff_stage)?.nb_teams ?? 0);

  const onChangeCountry = (event: any, country: CountryType) => {
    if (country) {
      setCountry(country);
      setValue('country', country);
    }
  };

  const handleStartDate = async (date: any) => {
    const d = new Date(date);
    const date_end = new Date(d.setHours(d.getHours() + 2));
    setValue('start_date', date);
    setValue('end_date', date_end);
  };

  const handleEndDate = async (date: any) => {
    setValue('end_date', date);
  };

  const handleAdmins = (event: any, value: any) => {
    const newList = _.concat(value, inputFields.selected_admins);
    setValue('selected_admins', newList);
  };

  const handleDeleteAdmin = (publisher_id: string) => {
    const newList = inputFields.selected_admins.filter((a: any) => a?.id !== publisher_id);
    setValue('selected_admins', newList);
  };

  const handleMainOrganisator = (event: any, value: any) => {
    setValue('main_organisator_id', value?.id);
  };

  const handleOrganisators = (event: any, value: any) => {
    const newList = _.concat(value, inputFields.selected_organisators)?.filter((i: any) => i);
    setValue('selected_organisators', newList);
  };

  const handleDeleteOrganisator = (publisher_id: string) => {
    const newList = inputFields.selected_organisators.filter((a: any) => a?.id !== publisher_id);
    setValue('selected_organisators', newList);
  };

  const nodeTemplate = (node: any) => {
    return (
      <div className="flex flex-column">
        <div className="flex flex-column align-items-center">
          <span className="font-bold mb-2">{node.label}</span>
          <img alt={node.data?.name} src={node.data?.image} className="mb-3 w-3rem h-3rem" />
          <span className="font-bold mb-2">{node.data?.name}</span>
          <span>{node.data?.title}</span>
        </div>
      </div>
    );
  };

  const handleCreateTournament = async () => {
    try {
      const result = await createTournament({
        variables: {
          tournament: {
            short_id: tournament_nanoid(),
            name: inputFields.name,
            sport: inputFields.sport_type,
            is_private: inputFields.is_private,
            start_date: TO_TIMESTAMP(inputFields.start_date?.toString()),
            end_date: TO_TIMESTAMP(inputFields.end_date?.toString()),
            descriptions: inputFields.descriptions.map((d: any) => {
              return {
                language: d.language,
                content: d.content,
              };
            }),
            cost_per_team: {
              value: parseFloat(inputFields.value?.toString()),
              currency: inputFields.currency,
            },
            main_organisator_id: inputFields.main_organisator_id,
            organisators_ids: inputFields.selected_organisators?.map((p: any) => p?.id) ?? [],
            admins_ids: inputFields.selected_admins?.map((p: any) => p?.id) ?? [],
            main_location: {
              coordinations: {
                latitude: inputFields.position?.lat,
                longitude: inputFields.position?.lng,
              },
              title: inputFields.address_title,
              street_name: inputFields.street_name,
              secondary_street_name: inputFields.secondary_street_name,
              postcode: inputFields.postcode,
              city: inputFields.city,
              county: inputFields.county,
              state: inputFields.state,
              country_code: country.value,
              country: country.label,
              continent: country.continent,
            },
            trophies: inputFields.trophies,
            phase_groups: !inputFields.as_phase_groups
              ? []
              : _.times(TOTAL_NB_TEAMS / 4, (index: number) => {
                  return {
                    name: `Group ${index + 1}`,
                  };
                }),
            phase_playoffs: TOURNAMENT_PLAYOFF_STAGES?.find((s: any) => s.value === inputFields.playoff_stage)
              ?.composition?.map((c: any) => {
                if (c?.amount > 0) {
                  return {
                    stage_name: c?.value,
                    amount: c?.amount,
                  };
                }
              })
              ?.filter((o: any) => o),
          },
        },
      });

      _.concat(inputFields.selected_admins?.map((p: any) => p?.id))
        ?.filter((p: any) => p !== undefined)
        ?.map(async (player_id: any) => {
          try {
            await sendNotification({
              variables: {
                notification: {
                  title: `${intl.formatMessage({ id: 'Notification_New_Tournament_Title' })}`,
                  content: `${inputFields.name} ${intl.formatMessage({ id: 'Notification_New_Tournament_Content_1' })}`,
                  actions: [`VIEW_TOURNAMENT_@_${result?.data?.createTournament?.short_id}`],
                  sender_id: loggedPlayer?.id,
                  receivers_ids: [player_id],
                },
              },
            });
          } catch (e) {
            console.log(e);
          }
        });

      if (result?.data?.createTournament?.short_id) {
        navigate(`/tournament/${result?.data?.createTournament?.short_id}`, { replace: true });
      }
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (inputFields.translated_desc_to_add === '') setValue('translated_desc_to_add', 'GB');
    if (inputFields.descriptions?.length > 0 && inputFields.selected_description_content === undefined && inputFields.selected_description?.content !== '') setValue('selected_description_content', inputFields.selected_description?.content || '');
  }, [inputFields, setValue]);

  return (
    <Grid container className={'form-page component-challenge new-challenge-page'} sx={{ p: '0 0 100px 0' }}>
      <Grid item xs={12} md={12} lg={12}>
        <Grid item xs={12} md={12} lg={12} sx={{ textAlign: 'center' }}>
          <IconButton component={Link} to="/" sx={{ '&:hover': { bgcolor: 'transparent !important' } }}>
            <FivezerLogoIcon sx={{ width: 'auto', height: { xs: '50px', sm: '60px', md: '70px' }, p: '20px 0 0 0' }} />
          </IconButton>
        </Grid>
        <BackButton color={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, 1)' : 'rgba(15,15,15,1)'} hoverBGColor={loggedPlayer?.app_configuration?.is_dark_mode ? 'rgba(247, 251, 250, .3)' : 'rgba(15,15,15,.04)'} />

        <Typography className={'title'} sx={{ p: '0 10%', fontSize: '40px', fontWeight: '800' }}>
          {`${intl.formatMessage({ id: 'FormNewTournament_Title' })}`}
        </Typography>

        {loggedPlayer?.profiles?.length === 0 ? (
          <Grid id="max-width" sx={{ textAlign: 'center' }}>
            <Typography sx={{ m: '30px 0', p: '0 10%', fontSize: '1.3em' }}>
              <FormattedMessage id="FormNewTeam_Create_Profile_Label" />
            </Typography>

            <Fab variant="extended" size="small" className="button-green" component={Link} sx={{ mt: '20px' }} to={`/new_footballer`}>
              <FormattedMessage id="FormNewTeam_Create_Profile" />
            </Fab>
          </Grid>
        ) : (
          <form className={'form-root'}>
            <Grid id="max-width" sx={{ p: '50px 10% 0' }}>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: true,
                  maxLength: 30,
                  minLength: 4,
                  pattern: /^[^a-zA-Z0-9_\s.'-]*$/,
                }}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    label={`${intl.formatMessage({ id: 'FormNewTournament_Name' })}`}
                    placeholder={`${intl.formatMessage({ id: 'FormNewTournament_Name_Placeholder' })}`}
                    variant="outlined"
                    fullWidth
                    required
                    className={'field-bottom-space'}
                    value={_.replace(field.value, /[^a-zA-Z0-9_\s.'-]+/g, ' ')}
                    error={errors?.name?.type !== undefined}
                    helperText={errors?.name !== undefined ? `${errors?.name?.type ? (field.value.length < 4 ? `${intl.formatMessage({ id: 'FormNewTournament_Name_Helper_1' })}` : `${intl.formatMessage({ id: 'FormNewTournament_Name_Helper_2' })}`) : ``}` : ''}
                  />
                )}
              />

              <Grid id="max-width" className={'position field-bottom-space'} sx={{ p: '0', overflow: 'auto', textAlign: 'center' }}>
                <Stack direction="row" sx={{ display: 'inline-flex', p: '0 5% 0 0', minWidth: '300px' }}>
                  {SPORT_TYPES.map((type: any) => (
                    <Button
                      key={type.value}
                      value={type.value}
                      sx={{
                        'height': '110px',
                        'width': '150px',
                        'm': '0 20px 0',
                        'display': 'inline-block',
                        'borderRadius': '10px',
                        'border': inputFields.sport_type === type.value ? 'solid 1px rgba(168, 202, 65, 1)' : 'solid 1px rgba(247, 251, 250, 0.3)',
                        'color': inputFields.sport_type === type.value ? 'rgba(168, 202, 65, 1) !important' : 'rgba(247, 251, 250, 0.3) !important',
                        '& svg': { fontSize: '40px', color: inputFields.sport_type === type.value ? 'rgba(168, 202, 65, 1) !important' : 'rgba(247, 251, 250, 0.3) !important' },
                      }}
                    >
                      {type?.icon}
                      <br />
                      {intl.formatMessage({ id: type.label })}
                      {type.value === 'FOOTBALL' ? (
                        ''
                      ) : (
                        <>
                          <br />
                          <Typography sx={{ fontSize: '.7em' }}>{`${intl.formatMessage({ id: 'FormNewTournament_Sport_Type_Coming' })}`}</Typography>
                        </>
                      )}
                    </Button>
                  ))}
                </Stack>
              </Grid>

              <Typography
                id="max-width"
                variant="h3"
                sx={{
                  fontSize: '1.4em',
                  fontWeight: '700',
                  m: '40px 0 20px',
                }}
              >
                {`${intl.formatMessage({ id: 'FormNewTournament_Descriptions' })}`}
              </Typography>
              <InputDescriptionsFeature descriptions={inputFields.descriptions} selected_description={inputFields.selected_description} selected_description_content={inputFields.selected_description_content} translated_desc_to_add={inputFields.translated_desc_to_add} setter={setValue} control={control} />

              <Typography
                id="max-width"
                variant="h3"
                sx={{
                  fontSize: '1.4em',
                  fontWeight: '700',
                  m: '40px 0 20px',
                }}
              >
                {`${intl.formatMessage({ id: 'FormNewTournament_Cost_Title' })}`}
              </Typography>
              <Controller
                name="value"
                control={control}
                rules={{
                  required: true,
                  min: 0,
                  max: 1000000,
                }}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    label={`${intl.formatMessage({ id: 'FormNewTournament_Cost_Value' })}`}
                    placeholder={'0'}
                    type="number"
                    variant="outlined"
                    value={field.value <= -1 ? 0 : field.value && field.value > 1000000 ? 1000000 : field.value}
                    sx={{
                      mr: {
                        xs: '0',
                        sm: '3%',
                        md: '3%',
                      },
                      width: {
                        xs: '100%',
                        sm: '64%',
                        md: '64%',
                      },
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">{_.concat(CURRENCIES, BLOCKCHAIN_CURRENCIES).find((c) => c.value === inputFields.currency)?.value}</InputAdornment>,
                    }}
                    className={'field-bottom-space'}
                  />
                )}
              />

              <Controller
                name="currency"
                control={control}
                rules={{
                  required: true,
                  pattern: /^[0-9a-zA-Z]*$/,
                }}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    value={field.value}
                    select
                    SelectProps={{
                      MenuProps: {
                        sx: {
                          zIndex: '1000000000',
                        },
                      },
                    }}
                    label={`${intl.formatMessage({ id: 'FormNewTournament_Cost_Currency' })}`}
                    variant="outlined"
                    sx={{
                      ml: {
                        xs: '0',
                        sm: '3%',
                        md: '3%',
                      },
                      width: {
                        xs: '100%',
                        sm: '30%',
                        md: '30%',
                      },
                    }}
                  >
                    {_.concat(BLOCKCHAIN_CURRENCIES, CURRENCIES).map((option: any) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />

              <Typography
                id="max-width"
                variant="h3"
                sx={{
                  fontSize: '1.4em',
                  fontWeight: '700',
                  m: '40px 0 20px',
                }}
              >
                {inputFields.trophies?.length || 0} {(inputFields.trophies?.length || 0) > 1 ? `${intl.formatMessage({ id: 'FormNewTournament_Trophies' })}` : `${intl.formatMessage({ id: 'FormNewTournament_Trophy' })}`}
              </Typography>
              <InputTrophiesFeature trophies={inputFields.trophies} setter={setValue} control={control} />

              <Typography id="max-width" variant="h3" sx={{ fontSize: '1.4em', fontWeight: '700', p: '40px 0 20px' }}>
                {`${intl.formatMessage({ id: 'FormNewTournament_Privacy_Title' })}`}
              </Typography>

              <Typography>{inputFields.is_private ? `${intl.formatMessage({ id: 'FormNewTournament_Privacy_Content_1' })}` : `${intl.formatMessage({ id: 'FormNewTournament_Privacy_Content_2' })}`}</Typography>
              <Controller
                name="is_private"
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field }: any) => <Switch {...field} checked={field.value} onChange={(event: any, value: any) => setValue('is_private', value)} inputProps={{ 'aria-label': 'controlled' }} />}
              />

              <Typography id="max-width" variant="h3" sx={{ fontSize: '1.4em', fontWeight: '700', p: '40px 0 0px' }}>
                {`${intl.formatMessage({ id: 'FormNewTournament_Organisator_Title' })}`}
              </Typography>
              {inputFields.main_organisator_id && inputFields.main_organisator_id !== '' ? (
                <Stack
                  direction={'row'}
                  sx={{
                    display: 'inline-block',
                    textAlign: 'center',
                  }}
                >
                  <Chip
                    variant="outlined"
                    label={
                      <strong>
                        {ALL_ORGANISATORS?.find((o: any) => o?.id === inputFields.main_organisator_id)?.person?.firstname} {ALL_ORGANISATORS?.find((o: any) => o?.id === inputFields.main_organisator_id)?.person?.lastname}
                      </strong>
                    }
                    avatar={<Avatar alt={ALL_ORGANISATORS?.find((o: any) => o?.id === inputFields.main_organisator_id)?.username} src={ALL_ORGANISATORS?.find((o: any) => o?.id === inputFields.main_organisator_id)?.avatar} />}
                    sx={{ m: '5px', color: 'rgba(247, 251, 250, 1)' }}
                  />
                </Stack>
              ) : (
                ''
              )}

              <Controller
                name="all_organisators"
                control={control}
                render={({ field }: any) => (
                  <Autocomplete
                    {...field}
                    multiple
                    value={field.value}
                    autoHighlight
                    options={ALL_ORGANISATORS}
                    getOptionLabel={(option: any) => option?.username}
                    defaultValue={ARRAY_AS_ANY}
                    filterSelectedOptions
                    onChange={(event: any, value: any) => handleMainOrganisator(event, value)}
                    renderOption={(props: any, option: any) => (
                      <Grid
                        container
                        key={option?.id}
                        onClick={(event: any) => handleMainOrganisator(event, option)}
                        sx={{
                          alignItems: 'center',
                          p: '10px 15px',
                        }}
                      >
                        <Avatar src={option?.avatar} />
                        &nbsp;&nbsp;
                        <strong>
                          {option?.person?.firstname} {option?.person?.lastname}
                        </strong>
                        &nbsp;({option?.username})
                      </Grid>
                    )}
                    renderInput={(params: any) => <TextField {...params} fullWidth label={`${intl.formatMessage({ id: 'FormNewTournament_Organisator_Label' })}`} placeholder={`${intl.formatMessage({ id: 'FormNewTournament_Organisator_Placeholder' })}`} />}
                  />
                )}
              />

              <Typography id="max-width" variant="h3" sx={{ fontSize: '1.4em', fontWeight: '700', p: '40px 0 0px' }}>
                {`${intl.formatMessage({ id: 'FormNewTournament_Organisators_Title' })}`}
              </Typography>
              <Stack
                direction={'row'}
                sx={{
                  display: 'inline-block',
                  textAlign: 'center',
                }}
              >
                {inputFields.selected_organisators?.map((f: any, index: number) => (
                  <Chip
                    key={index}
                    variant="outlined"
                    label={
                      <strong>
                        {f?.person?.firstname} {f?.person?.lastname}
                      </strong>
                    }
                    avatar={<Avatar alt={f?.username} src={f?.avatar} />}
                    onDelete={() => handleDeleteOrganisator(f?.id)}
                    sx={{ 'm': '5px', 'color': 'rgba(247, 251, 250, 1)', '& .MuiChip-deleteIcon': { color: 'rgba(247, 251, 250, .4)' } }}
                  />
                ))}
              </Stack>

              <Controller
                name="all_organisators"
                control={control}
                render={({ field }: any) => (
                  <Autocomplete
                    {...field}
                    multiple
                    value={field.value}
                    autoHighlight
                    options={ALL_ORGANISATORS?.filter((cf: any) => inputFields?.selected_organisators?.find((a: any) => a?.id === cf?.id) === undefined)}
                    getOptionLabel={(option: any) => option?.username}
                    defaultValue={ARRAY_AS_ANY}
                    filterSelectedOptions
                    onChange={(event: any, value: any) => handleOrganisators(event, value)}
                    renderOption={(props: any, option: any) => (
                      <Grid
                        container
                        key={option?.id}
                        onClick={(event: any) => handleOrganisators(event, option)}
                        sx={{
                          alignItems: 'center',
                          p: '10px 15px',
                        }}
                      >
                        <Avatar src={option?.avatar} />
                        &nbsp;&nbsp;
                        <strong>
                          {option?.person?.firstname} {option?.person?.lastname}
                        </strong>
                        &nbsp;({option?.username})
                      </Grid>
                    )}
                    renderInput={(params: any) => <TextField {...params} fullWidth label={`${intl.formatMessage({ id: 'FormNewTournament_Organisators_Label' })}`} placeholder={`${intl.formatMessage({ id: 'FormNewTournament_Organisators_Placeholder' })}`} />}
                  />
                )}
              />

              <Typography id="max-width" variant="h3" sx={{ fontSize: '1.4em', fontWeight: '700', p: '40px 0 0px' }}>
                {`${intl.formatMessage({ id: 'FormNewTournament_Managers_Title' })}`}
              </Typography>
              <Stack
                direction={'row'}
                sx={{
                  display: 'inline-block',
                  textAlign: 'center',
                }}
              >
                {inputFields.selected_admins?.map((f: any, index: number) => (
                  <Chip
                    key={index}
                    variant="outlined"
                    label={
                      <strong>
                        {f?.person?.firstname} {f?.person?.lastname}
                      </strong>
                    }
                    avatar={<Avatar alt={f?.username} src={f?.avatar} />}
                    onDelete={() => handleDeleteAdmin(f?.id)}
                    sx={{ m: '5px' }}
                  />
                ))}
              </Stack>

              <Controller
                name="all_admins"
                control={control}
                render={({ field }: any) => (
                  <Autocomplete
                    {...field}
                    multiple
                    value={field.value}
                    autoHighlight
                    options={ALL_ORGANISATORS?.filter((cf: any) => inputFields?.selected_admins?.find((a: any) => a?.id === cf?.id) === undefined)}
                    getOptionLabel={(option: any) => option?.username}
                    defaultValue={ARRAY_AS_ANY}
                    filterSelectedOptions
                    onChange={(event: any, value: any) => handleAdmins(event, value)}
                    renderOption={(props: any, option: any) => (
                      <Grid
                        container
                        key={option?.id}
                        onClick={(event: any) => handleAdmins(event, option)}
                        sx={{
                          alignItems: 'center',
                          p: '10px 15px',
                        }}
                      >
                        <Avatar src={option?.avatar} />
                        &nbsp;&nbsp;
                        <strong>
                          {option?.person?.firstname} {option?.person?.lastname}
                        </strong>
                        &nbsp;({option?.username})
                      </Grid>
                    )}
                    renderInput={(params: any) => <TextField {...params} fullWidth label={`${intl.formatMessage({ id: 'FormNewTournament_Managers_Label' })}`} placeholder={`${intl.formatMessage({ id: 'FormNewTournament_Managers_Placeholder' })}`} />}
                  />
                )}
              />

              <Typography id="max-width" variant="h3" sx={{ fontSize: '1.4em', fontWeight: '700', p: '40px 0 20px' }}>
                {`${intl.formatMessage({ id: 'FormNewTournament_Settings_Title' })}`}
              </Typography>

              <Typography>{inputFields.as_phase_groups ? `${intl.formatMessage({ id: 'FormNewTournament_Settings_Groups_1' })}` : `${intl.formatMessage({ id: 'FormNewTournament_Settings_Groups_2' })}`}</Typography>
              <Controller
                name="as_phase_groups"
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field }: any) => <Switch {...field} checked={field.value} onChange={(event: any, value: any) => setValue('as_phase_groups', value)} inputProps={{ 'aria-label': 'controlled' }} className={'field-bottom-space'} />}
              />

              <Controller
                control={control}
                name="playoff_stage"
                render={({ field }: any) => (
                  <TextField {...field} value={field.value} select label={`${intl.formatMessage({ id: 'FormNewTournament_Settings_Playoff_Label' })}`} variant="outlined" error={errors?.playoff_stage !== undefined} required fullWidth className={'field-bottom-space'}>
                    {TOURNAMENT_PLAYOFF_STAGES.map((option: any) => (
                      <MenuItem key={option.value} value={option.value}>
                        {intl.formatMessage({ id: option?.label })}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />

              <Typography className={'field-bottom-space'}>
                {`${intl.formatMessage({ id: 'FormNewTournament_Settings_Playoff_Content_1' })}`} {TOTAL_NB_TEAMS} {`${intl.formatMessage({ id: 'FormNewTournament_Settings_Playoff_Content_2' })}`}
                {inputFields.as_phase_groups ? `, ${intl.formatMessage({ id: 'FormNewTournament_Settings_Playoff_Content_3' })} ${TOTAL_NB_TEAMS / 4} ${intl.formatMessage({ id: 'FormNewTournament_Settings_Playoff_Content_4' })}` : ''}
              </Typography>
            </Grid>

            <Grid container sx={{ overflow: 'auto', justifyContent: 'center', p: '0' }}>
              <Grid item xs={12} md={12} lg={12} sx={{ p: '0 10%', textAlign: 'center' }}>
                <OrganizationChart value={TOURNAMENT_PLAYOFF_STAGES?.find((s: any) => s.value === inputFields.playoff_stage)?.organigram} selectionMode="single" onSelectionChange={(e) => console.log(e.data)} nodeTemplate={nodeTemplate} />
              </Grid>
            </Grid>

            <Grid id="max-width" sx={{ p: '0px 10% 0' }}>
              <Typography
                id="max-width"
                variant="h3"
                sx={{
                  fontSize: '1.4em',
                  fontWeight: '700',
                  m: '40px 0 20px',
                }}
              >
                {intl.formatMessage({ id: 'FormNewTournament_Dates_Title' })}
              </Typography>
              <Controller
                name="start_date"
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field }: any) => (
                  <DateTimePicker
                    {...field}
                    value={field.value}
                    disablePast
                    views={['day', 'month', 'hours', 'minutes']}
                    openTo="day"
                    format="D MMM YYYY HH:mm"
                    renderInput={(props: any) => <TextField {...props} fullWidth autoComplete="off" />}
                    ampm={false}
                    sx={{ width: !inputFields.start_date ? '100%' : { xs: '100%', sm: '45%' }, m: !inputFields.start_date ? '0' : { xs: '0', sm: '0 2.5%' } }}
                    className={'field-bottom-space'}
                    onChange={handleStartDate}
                    label={`${intl.formatMessage({ id: 'Global_Dates_Start_Label' })}`}
                  />
                )}
              />

              {!inputFields.start_date ? (
                ''
              ) : (
                <>
                  <Controller
                    name="end_date"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field }: any) => (
                      <DateTimePicker
                        {...field}
                        value={field.value}
                        disablePast
                        views={['day', 'month', 'hours', 'minutes']}
                        minDate={inputFields.start_date}
                        maxDate={new Date(inputFields.start_date || Date.now()).setFullYear(new Date(inputFields.start_date || Date.now()).getFullYear() + 1)}
                        format="D MMM YYYY HH:mm"
                        renderInput={(props: any) => <TextField {...props} fullWidth autoComplete="off" />}
                        ampm={false}
                        sx={{ width: { xs: '100%', sm: '45%' }, m: { xs: '0', sm: '0 2.5%' } }}
                        className={'field-bottom-space'}
                        onChange={handleEndDate}
                        label={`${intl.formatMessage({ id: 'Global_Dates_End_Label' })}`}
                      />
                    )}
                  />
                </>
              )}

              <Typography
                id="max-width"
                variant="h3"
                sx={{
                  fontSize: '1.4em',
                  fontWeight: '700',
                  m: '40px 0 20px',
                }}
              >
                {intl.formatMessage({ id: 'FormNewTournament_Location_Title' })}
              </Typography>
              <Controller
                name="address_title"
                control={control}
                rules={{
                  required: true,
                  maxLength: 30,
                  minLength: 4,
                  pattern: /^[a-zA-Z0-9-\s]*$/,
                }}
                render={({ field }: any) => <TextField {...field} label={<FormattedMessage id="Global_Address_Title_1" />} placeholder={`${intl.formatMessage({ id: 'Global_Address_Title_2' })}`} variant="outlined" fullWidth required value={field.value} error={errors?.address_title !== undefined} helperText={errors?.address_title !== undefined ? `${errors?.address_title?.message}` : ''} className={'field-bottom-space'} />}
              />

              <AutofillAddressInput control={control} errors={errors} address_input={inputFields.street_name} setter={setValue} street_name="street_name" secondary_street_name={'secondary_street_name'} postcode={'postcode'} county={'county'} state={'state'} city={'city'} country={'country'} setterCountry={setCountry} />

              <Controller
                name="secondary_street_name"
                control={control}
                rules={{
                  required: false,
                  maxLength: 30,
                  minLength: 4,
                  pattern: /^[a-zA-Z0-9-\s]*$/,
                }}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    label={<FormattedMessage id="Global_Address_Complement_1" />}
                    autoComplete="address-line2"
                    placeholder={`${intl.formatMessage({ id: 'Global_Address_Complement_2' })}`}
                    variant="outlined"
                    fullWidth
                    value={field.value}
                    error={errors?.secondary_street_name !== undefined}
                    helperText={errors?.secondary_street_name !== undefined ? `${errors?.secondary_street_name?.message}` : `${intl.formatMessage({ id: 'Global_Address_Complement_3' })}`}
                    className={'field-bottom-space'}
                  />
                )}
              />

              <Controller
                name="postcode"
                control={control}
                rules={{
                  required: false,
                  maxLength: 30,
                  minLength: 4,
                  pattern: /^[a-zA-Z0-9-\s]*$/,
                }}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    label={<FormattedMessage id="Global_Address_Postcode_1" />}
                    placeholder={`${intl.formatMessage({ id: 'Global_Address_Postcode_2' })}`}
                    autoComplete="postal-code"
                    variant="outlined"
                    value={_.replace(field.value, /[^a-zA-Z0-9-\s]+/g, '')}
                    error={errors?.postcode !== undefined}
                    helperText={errors?.postcode !== undefined ? `${errors?.postcode?.message}` : ''}
                    className={'field-bottom-space'}
                    sx={{
                      mr: { xs: '0', sm: '3%', md: '5%' },
                      width: {
                        xs: '100%',
                        sm: '47%',
                        md: '45%',
                      },
                    }}
                  />
                )}
              />

              <Controller
                name="city"
                control={control}
                rules={{
                  required: false,
                  maxLength: 30,
                  minLength: 4,
                  pattern: /^[a-zA-Z0-9-\s]*$/,
                }}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    label={<FormattedMessage id="Global_Address_City_1" />}
                    placeholder={`${intl.formatMessage({ id: 'Global_Address_City_2' })}`}
                    autoComplete="address-level2"
                    variant="outlined"
                    value={field.value}
                    error={errors?.city !== undefined}
                    helperText={errors?.city !== undefined ? `${errors?.city?.message}` : ''}
                    className={'field-bottom-space'}
                    sx={{
                      ml: { xs: '0', sm: '3%', md: '5%' },
                      width: {
                        xs: '100%',
                        sm: '47%',
                        md: '45%',
                      },
                    }}
                  />
                )}
              />

              <Controller
                name="county"
                control={control}
                rules={{
                  required: false,
                  maxLength: 30,
                  minLength: 4,
                  pattern: /^[a-zA-Z0-9-\s]*$/,
                }}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    label={<FormattedMessage id="Global_Address_County_1" />}
                    placeholder={`${intl.formatMessage({ id: 'Global_Address_County_2' })}`}
                    variant="outlined"
                    value={field.value}
                    error={errors?.county !== undefined}
                    helperText={errors?.county !== undefined ? `${errors?.county?.message}` : ''}
                    className={'field-bottom-space'}
                    sx={{
                      mr: { xs: '0', sm: '3%', md: '5%' },
                      width: {
                        xs: '100%',
                        sm: '47%',
                        md: '45%',
                      },
                    }}
                  />
                )}
              />

              <Controller
                name="state"
                control={control}
                rules={{
                  required: false,
                  maxLength: 30,
                  minLength: 4,
                  pattern: /^[a-zA-Z0-9-\s]*$/,
                }}
                render={({ field }: any) => (
                  <TextField
                    {...field}
                    label={<FormattedMessage id="Global_Address_State_1" />}
                    placeholder={`${intl.formatMessage({ id: 'Global_Address_State_2' })}`}
                    autoComplete="address-level1"
                    variant="outlined"
                    value={field.value}
                    error={errors?.state !== undefined}
                    helperText={errors?.state !== undefined ? `${errors?.state?.message}` : ''}
                    className={'field-bottom-space'}
                    sx={{
                      ml: { xs: '0', sm: '3%', md: '5%' },
                      width: {
                        xs: '100%',
                        sm: '47%',
                        md: '45%',
                      },
                    }}
                  />
                )}
              />

              <Controller
                control={control}
                name="country"
                render={({ field }: any) => (
                  <Autocomplete
                    {...field}
                    options={COUNTRIES}
                    value={country}
                    className={'country-select'}
                    onChange={(event: any, value: any) => onChangeCountry(event, value)}
                    autoHighlight
                    getOptionLabel={(option: CountryType) => option.label}
                    isOptionEqualToValue={(option: any, value: any) => option.label === value.label}
                    renderOption={(props: any, option: CountryType) => (
                      <Grid
                        component="li"
                        sx={{
                          '& > img': {
                            mr: 2,
                            flexShrink: 0,
                          },
                        }}
                        {...props}
                      >
                        <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`} alt="" />
                        {option.label} ({option.value})
                      </Grid>
                    )}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        label={`${intl.formatMessage({ id: 'Global_Address_Country' })}`}
                        value={country}
                        required
                        variant="outlined"
                        className={'field-bottom-space'}
                        error={errors?.country !== undefined}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                )}
              />

              <GPSMapFeature position={inputFields.position} element={'position'} setValue={setValue} height="200px" />

              <Grid id="max-width" sx={{ p: '20px 10% 0' }}>
                <Button onClick={() => navigate(-1)} className={isSent.loading || newTournament.loading ? 'button-disabled' : 'button-cancel'} sx={{ mr: '10px' }}>
                  {`${intl.formatMessage({ id: 'Global_Button_Cancel' })}`}
                </Button>
                <LoadingButton
                  onClick={handleCreateTournament}
                  loading={isSent.loading || newTournament.loading}
                  loadingPosition="end"
                  endIcon={<Send />}
                  className={
                    inputFields.main_organisator_id === '' ||
                    inputFields.selected_admins?.length === 0 ||
                    inputFields.selected_organisators?.length === 0 ||
                    inputFields.descriptions?.length === 0 ||
                    inputFields.descriptions?.filter((d: any) => d?.content?.length > 0)?.length === 0 ||
                    inputFields.name === '' ||
                    !inputFields.position ||
                    inputFields.address_title === '' ||
                    inputFields.street_name === '' ||
                    inputFields.postcode === '' ||
                    inputFields.city === '' ||
                    !inputFields.start_date ||
                    !inputFields.end_date
                      ? 'button-disabled'
                      : 'button-green'
                  }
                  disabled={
                    inputFields.main_organisator_id === '' ||
                    inputFields.selected_admins?.length === 0 ||
                    inputFields.selected_organisators?.length === 0 ||
                    inputFields.descriptions?.length === 0 ||
                    inputFields.descriptions?.filter((d: any) => d?.content?.length > 0)?.length === 0 ||
                    inputFields.name === '' ||
                    !inputFields.position ||
                    inputFields.address_title === '' ||
                    inputFields.street_name === '' ||
                    inputFields.postcode === '' ||
                    inputFields.city === '' ||
                    !inputFields.start_date ||
                    !inputFields.end_date
                  }
                  sx={{ mr: '10px' }}
                >
                  {`${intl.formatMessage({ id: 'FormNewTournament_Button_New_Tournament' })}`}
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Grid>
    </Grid>
  );
};

export default FormNewTournament;

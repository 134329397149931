import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Dialog, DialogContent, DialogContentText, DialogActions, Button, Fab, Grid, Stack, CardMedia, MenuItem, TextField, Autocomplete, Avatar, useMediaQuery, Chip } from '@mui/material';
import { Send, Clear, Close } from '@mui/icons-material';
import { ARRAY_AS_ANY, CountryType, UNDEFINED_AS_ANY } from '../../utilities/CommonInterfaces';
import { gql, useQuery } from '@apollo/client';
import { COUNTRIES, FIELD_SIZES, FIELD_TYPES, SPORT_TYPES } from '../../utilities/utilities';
import { Controller, useForm } from 'react-hook-form';
import { City } from 'country-state-city';
import { DateTimePicker } from '@mui/x-date-pickers';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { selectSession } from '../../reducers/sessionSlice';
import { useAppSelector } from '../../redux/hooks';
import { REACT_APP_CUSTOM_MAP_URL } from '../../config';
import { useIntl } from 'react-intl';

export default function TournamentFiltersModal(props: { complex?: any; field?: any; start_date?: any; end_date?: any; country?: any; city?: any; setter: any; setter_prefix?: string; handleClose: any; open: any }) {
  const FIND_COMPLEXES_BY_OPTIONS_QUERY = gql`
    query findComplexesByOptions($options: inputFindComplexesByOptions!) {
      findComplexesByOptions(options: $options) {
        id
        name
        logo {
          path
        }
        cover {
          path
        }
        pictures {
          path
        }
        fields {
          id
          short_id
          name
          size
          sports
          type
          pictures {
            path
          }
          rental_asset {
            id
            price {
              value
              currency {
                currency_code
              }
            }
          }
        }
        location {
          id
          title
          coordinates
          country_code
          street_name
          secondary_street_name
          status
          postcode {
            name
          }
          city {
            name
          }
          county {
            name
          }
          state {
            name
          }
          country {
            name
          }
        }
      }
    }
  `;

  const session = useAppSelector(selectSession);
  const center_position: any = [48.8559202, 2.2910392];
  const isSmallMobile = useMediaQuery('(max-width:600px)');
  const intl = useIntl();

  const [openSearchComplex, setOpenSearchComplex] = React.useState(false);

  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      city: props.city,
      country: props.country,
      sport_types: ARRAY_AS_ANY,
      start_date: props.start_date,
      end_date: props.end_date,

      all_complexes: ARRAY_AS_ANY,
      complex: props.complex,
      complex_name: '',

      field: props.field,
      selected_field: '',
    },
  });
  const inputFields = watch();

  const loadedComplexes = useQuery(FIND_COMPLEXES_BY_OPTIONS_QUERY, {
    context: {
      headers: {
        'Authorization': `Bearer ${session?.token?.key}`,
        'X-Anonymous-Access': 'false',
      },
    },
    skip: !session?.token?.key,
    variables: {
      options: {
        name: inputFields.complex_name,
        city: inputFields.city?.name,
        country: inputFields.country?.label,
      },
    },
  });

  const CITIES: any = City.getCitiesOfCountry(inputFields?.country?.value);
  const ALL_COMPLEXES = loadedComplexes.data?.findComplexesByOptions;

  const DefaultIcon = L.icon({
    iconUrl: '../../img/marker-icon.png',
    shadowUrl: iconShadow,
  });
  const SelectedIcon = L.icon({
    iconUrl: '../../img/active-marker-icon.png',
    shadowUrl: iconShadow,
  });

  L.Marker.prototype.options.icon = DefaultIcon;

  const onChangeCountry = (event: any, country: CountryType) => {
    if (country) {
      setValue('country', country);
      props.setter(`${props.setter_prefix || ''}country`, country);
    }
  };

  const onChangeCity = (event: any, city: any) => {
    setValue('city', city);
    props.setter(`${props.setter_prefix || ''}city`, city);
  };

  const handleClose = () => {
    props.handleClose();
  };

  const handleStartDate = (date: any) => {
    setValue('start_date', date);
    props.setter(`${props.setter_prefix || ''}start_date`, date);
  };

  const handleEndDate = (date: any) => {
    setValue('end_date', date);
    props.setter(`${props.setter_prefix || ''}end_date`, date);
  };

  const handleComplex = (value: any) => {
    setValue('complex', value);
    props.setter(`${props.setter_prefix || ''}complex`, value);
    setOpenSearchComplex(false);
  };

  const handleSelectComplex = (value: any) => {
    setValue('complex', value);
    props.setter(`${props.setter_prefix || ''}complex`, value);
  };

  const handleRemoveComplex = () => {
    setValue('complex', UNDEFINED_AS_ANY);
    setValue('field', '');
  };

  const handleSelectField = (selected_field: any) => {
    setValue('field', selected_field?.id);
    props.setter(`${props.setter_prefix || ''}field`, selected_field?.id);
  };

  const handleApplyFilters = () => {
    props.setter(`${props.setter_prefix || ''}complex`, inputFields.complex);
    props.setter(`${props.setter_prefix || ''}field`, inputFields.field);
    props.setter(`${props.setter_prefix || ''}start_date`, inputFields.start_date);
    props.setter(`${props.setter_prefix || ''}end_date`, inputFields.end_date);
    props.setter(`${props.setter_prefix || ''}city`, inputFields.city);
    props.setter(`${props.setter_prefix || ''}country`, inputFields.country);
    handleClose();
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'component-modal modal-feature'}
      PaperProps={{
        sx: { minWidth: { xs: '70%', md: '40%' }, maxWidth: { xs: '90%', md: '50%' }, p: '20px 0' },
      }}
    >
      <Fab
        size="small"
        onClick={handleClose}
        className="button-cancel"
        sx={{
          boxShadow: 'none',
          bgcolor: 'transparent',
          ml: '2%',
        }}
      >
        <Close />
      </Fab>
      <DialogContent sx={{ pl: '0', pr: '0' }}>
        <DialogContentText id="alert-dialog-description" component="span">
          <Grid
            sx={{
              position: 'relative',
              p: '0',
            }}
          >
            <form>
              <Grid id="max-width" sx={{ width: '100%', p: '0 5%' }}>
                <Typography id="max-width" variant="h3" sx={{ color: 'rgba(247, 251, 250, 1)', fontSize: '1.4em', fontWeight: '700', p: '40px 0 20px' }}>
                  {`${intl.formatMessage({ id: 'ChallengeFiltersModal_When_Title' })}`}
                </Typography>

                <Controller
                  name="start_date"
                  control={control}
                  rules={{
                    required: false,
                  }}
                  render={({ field }: any) => (
                    <DateTimePicker
                      {...field}
                      value={field.value}
                      disablePast={props.setter_prefix === 'past_' ? false : true}
                      disableFuture={props.setter_prefix === 'past_' ? true : false}
                      views={['day', 'month', 'hours', 'minutes']}
                      format="D MMM YYYY HH:mm"
                      renderInput={(props: any) => <TextField {...props} fullWidth autoComplete="off" />}
                      ampm={false}
                      sx={{ width: '100%' }}
                      className={'field-bottom-space'}
                      onChange={handleStartDate}
                      label={`${intl.formatMessage({ id: 'Global_Dates_Start_Label' })}`}
                    />
                  )}
                />

                {!inputFields.start_date ? (
                  ''
                ) : (
                  <Controller
                    name="end_date"
                    control={control}
                    rules={{
                      required: false,
                    }}
                    render={({ field }: any) => (
                      <DateTimePicker
                        {...field}
                        value={field.value}
                        disablePast={props.setter_prefix === 'past_' ? false : true}
                        disableFuture={props.setter_prefix === 'past_' ? true : false}
                        views={['day', 'month', 'hours', 'minutes']}
                        minDate={props.setter_prefix === 'past_' ? undefined : inputFields.start_date}
                        format="D MMM YYYY HH:mm"
                        renderInput={(props: any) => <TextField {...props} fullWidth autoComplete="off" />}
                        ampm={false}
                        sx={{ width: '100%' }}
                        className={'field-bottom-space'}
                        onChange={handleEndDate}
                        label={`${intl.formatMessage({ id: 'Global_Dates_End_Label' })}`}
                      />
                    )}
                  />
                )}

                <Typography id="max-width" variant="h3" sx={{ color: 'rgba(247, 251, 250, 1)', fontSize: '1.4em', fontWeight: '700', p: '40px 0 20px' }}>
                  {`${intl.formatMessage({ id: 'ChallengeFiltersModal_Where_Title' })}`}
                </Typography>
                <Controller
                  control={control}
                  name="country"
                  render={({ field }: any) => (
                    <Autocomplete
                      {...field}
                      options={COUNTRIES}
                      value={inputFields.country}
                      className={'country-select'}
                      onChange={(event: any, value: any) => onChangeCountry(event, value)}
                      autoHighlight
                      getOptionLabel={(option: CountryType) => option.label}
                      isOptionEqualToValue={(option: any, value: any) => option.label === value.label}
                      renderOption={(props: any, option: CountryType) => (
                        <Grid
                          component="li"
                          sx={{
                            '& > img': {
                              mr: 2,
                              flexShrink: 0,
                            },
                          }}
                          {...props}
                        >
                          <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`} alt="" />
                          {option.label} ({option.value})
                        </Grid>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${intl.formatMessage({ id: 'Global_Address_Country' })}`}
                          value={inputFields.country}
                          required
                          variant="outlined"
                          className={'field-bottom-space'}
                          error={errors?.country !== undefined}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="city"
                  render={({ field }: any) => (
                    <Autocomplete
                      {...field}
                      options={CITIES}
                      value={inputFields.city}
                      className={'city-select'}
                      onChange={(event: any, value: any) => onChangeCity(event, value)}
                      autoHighlight
                      getOptionLabel={(option: any) => option.name}
                      isOptionEqualToValue={(option: any, value: any) => option.name === value.name}
                      renderOption={(props: any, option: any) => (
                        <Grid
                          component="li"
                          sx={{
                            '& > img': {
                              mr: 2,
                              flexShrink: 0,
                            },
                          }}
                          {...props}
                        >
                          {option?.name}
                        </Grid>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${intl.formatMessage({ id: 'Global_Address_City_1' })}`}
                          value={inputFields.city}
                          autoComplete="off"
                          variant="outlined"
                          className={'field-bottom-space'}
                          error={errors?.city !== undefined}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                  )}
                />

                {isSmallMobile ? (
                  <Grid container sx={{ justifyContent: 'center', mb: '20px' }}>
                    {inputFields.complex === undefined ? (
                      <Typography
                        variant="caption"
                        sx={{
                          m: '0px',
                          p: '5px 20px',
                          textAlign: 'center',
                          bgcolor: 'rgba(247, 251, 250, .04)',
                          borderRadius: '5px',
                          color: 'rgba(247, 251, 250, 1)',
                        }}
                      >
                        {`${intl.formatMessage({ id: 'ChallengeFiltersModal_No_Complex_Selected' })}`}
                      </Typography>
                    ) : (
                      <Chip
                        variant="outlined"
                        label={<strong>{inputFields?.complex?.name}</strong>}
                        avatar={
                          <Avatar
                            sx={{
                              height: {
                                xs: '40px !important',
                                md: '60px !important',
                                lg: '70px !important',
                              },
                              width: {
                                xs: '40px !important',
                                md: '60px !important',
                                lg: '70px !important',
                              },
                            }}
                            alt={inputFields?.complex?.name}
                            src={inputFields?.complex?.logo?.path}
                          />
                        }
                        onDelete={handleRemoveComplex}
                        sx={{
                          'm': '5px',
                          'height': 'auto',
                          'border': 'none',
                          'color': 'rgba(247, 251, 250, 1)',
                          '& svg': { color: 'rgba(247, 251, 250, .3) !important' },
                        }}
                      />
                    )}
                  </Grid>
                ) : (
                  ''
                )}
                <Controller
                  name="all_complexes"
                  control={control}
                  render={({ field }: any) => (
                    <Autocomplete
                      {...field}
                      open={openSearchComplex}
                      onOpen={() => setOpenSearchComplex(true)}
                      onClose={() => setOpenSearchComplex(false)}
                      multiple
                      autoHighlight
                      filterSelectedOptions
                      value={field.value}
                      loading={loadedComplexes.loading}
                      inputValue={inputFields.complex_name}
                      onInputChange={(event: any, newInputValue: any) => {
                        setValue('complex_name', newInputValue);
                      }}
                      options={ALL_COMPLEXES ? ALL_COMPLEXES : []}
                      getOptionLabel={(option: any) => option?.id}
                      defaultValue={ARRAY_AS_ANY}
                      renderOption={(props: any, option: any) => (
                        <Grid
                          container
                          key={option?.id}
                          onClick={() => handleComplex(option)}
                          sx={{
                            alignItems: 'center',
                            p: '10px 15px',
                          }}
                        >
                          <Avatar src={option?.logo?.path} alt={option?.name} />
                          &nbsp;&nbsp;
                          <strong>{option?.name}</strong>
                        </Grid>
                      )}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          fullWidth
                          label={`${intl.formatMessage({ id: 'ChallengeFiltersModal_Search_Complex_Label' })}`}
                          className={'field-bottom-space'}
                          placeholder={inputFields?.complex ? '' : `${intl.formatMessage({ id: 'ChallengeFiltersModal_Search_Complex_Placeholder' })}`}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <>
                                {isSmallMobile ? (
                                  ''
                                ) : (
                                  <Grid>
                                    {inputFields.complex === undefined ? (
                                      <Typography
                                        variant="caption"
                                        sx={{
                                          m: '0px',
                                          p: '5px 20px',
                                          textAlign: 'center',
                                          bgcolor: 'rgba(0, 0, 0, .1)',
                                          borderRadius: '5px',
                                        }}
                                      >
                                        {`${intl.formatMessage({ id: 'ChallengeFiltersModal_No_Complex_Selected' })}`}
                                      </Typography>
                                    ) : (
                                      <Chip
                                        variant="outlined"
                                        label={<strong>{inputFields?.complex?.name}</strong>}
                                        avatar={
                                          <Avatar
                                            sx={{
                                              height: {
                                                xs: '40px !important',
                                                md: '60px !important',
                                                lg: '70px !important',
                                              },
                                              width: {
                                                xs: '40px !important',
                                                md: '60px !important',
                                                lg: '70px !important',
                                              },
                                            }}
                                            alt={inputFields?.complex?.name}
                                            src={inputFields?.complex?.logo?.path}
                                          />
                                        }
                                        onDelete={handleRemoveComplex}
                                        sx={{
                                          'm': '5px',
                                          'height': 'auto',
                                          'border': 'none',
                                          'color': 'rgba(247, 251, 250, 1)',
                                          '& svg': { color: 'rgba(247, 251, 250, .3) !important' },
                                        }}
                                      />
                                    )}
                                  </Grid>
                                )}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="field"
                  render={({ field }: any) => (
                    <TextField {...field} value={field.value} name="field" select label={`${intl.formatMessage({ id: 'ChallengeFiltersModal_Field_Label' })}`} placeholder="Select a field" variant="outlined" error={errors?.field !== undefined} required fullWidth className={'field-bottom-space'}>
                      {inputFields?.complex?.fields?.map((option: any) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>

              <Grid sx={{ overflow: 'auto' }}>
                <MapContainer center={inputFields?.complex?.location?.coordinates ? [inputFields?.complex?.location?.coordinates?.latitude, inputFields?.complex?.location?.coordinates?.longitude] : center_position} zoom={10} scrollWheelZoom={false} style={{ height: '300px' }}>
                  <TileLayer attribution="" url={REACT_APP_CUSTOM_MAP_URL} />
                  {ALL_COMPLEXES?.map((c: any, index: number) => (
                    <Grid key={index}>
                      <Marker position={c?.location?.coordinates ? [c?.location?.coordinates?.latitude, c?.location?.coordinates?.longitude] : center_position} icon={inputFields?.complex?.id === c?.id ? SelectedIcon : DefaultIcon}>
                        <Popup minWidth={150} maxWidth={290}>
                          <Grid onClick={() => handleSelectComplex(c)} container sx={{ 'justifyContent': 'center', 'alignItems': 'center', '&:hover': { color: 'rgba(251, 88, 2, 1)' } }}>
                            <Avatar sx={{ height: '30px', width: '30px' }} src={c?.logo?.path} alt={c?.name} />
                            &nbsp;
                            <strong>{c?.name}</strong>
                          </Grid>
                        </Popup>
                      </Marker>
                    </Grid>
                  ))}
                </MapContainer>
                <Grid sx={{ mt: '-120px', position: 'relative', zIndex: '1000', width: '100%', height: '200px', backgroundImage: `linear-gradient(to bottom, rgba(33,36,42,0) 0%, rgba(33,36,42,1) 40%)` }}>
                  <Grid sx={{ overflow: 'auto', p: '0 10% 0', textAlign: 'center' }}>
                    <Stack
                      direction="row"
                      sx={{
                        m: '10px 0',
                        display: 'inline-flex',
                      }}
                    >
                      {inputFields?.complex?.fields?.map((f: any, index: number) => (
                        <CardMedia
                          key={index}
                          onClick={() => handleSelectField(f)}
                          sx={{
                            'justifyContent': 'center',
                            'alignItems': 'end',
                            'display': 'flex',
                            'borderRadius': '10px',
                            'border': f?.id === inputFields.field ? 'solid 3px rgba(168, 202, 65, 1)' : 'none',
                            'width': { xs: '200px', sm: '300px' },
                            'height': { xs: '150px', sm: '150px' },
                            'm': '0 30px 0 0',
                            'backgroundImage': `linear-gradient(to bottom, rgba(15,15,15,0) 30%, rgba(15,15,15,1) 70%), url(${f?.pictures ? f?.pictures[0]?.path : ''})`,
                            '& *': { color: 'rgba(247, 251, 250, 1) !important' },
                          }}
                        >
                          <Typography sx={{ 'textAlign': 'center', 'lineHeight': '15px', 'mb': '20px', 'fontWeight': '700', '&:hover': { color: 'rgba(251, 88, 2, 1)' } }}>
                            {f?.name}
                            <br />
                            <Typography variant="caption">
                              {`${intl.formatMessage({ id: FIELD_SIZES?.find((t: any) => t?.value === f?.size)?.label })}`}
                              &nbsp;
                              {`${intl.formatMessage({ id: SPORT_TYPES?.find((t: any) => f?.sports?.find((s: any) => s === t?.value))?.label })}`}
                              <br />
                              {`${intl.formatMessage({ id: FIELD_TYPES?.find((t: any) => t?.value === f?.type)?.label })}`}
                            </Typography>
                          </Typography>
                        </CardMedia>
                      ))}
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ p: '20px 5% 30px', justifyContent: 'center' }}>
        <Button onClick={handleClose} className={'button-cancel'} endIcon={<Clear />}>
          {`${intl.formatMessage({ id: 'Global_Button_Close' })}`}
        </Button>

        <Button onClick={handleApplyFilters} className={'button-green'} endIcon={<Send />}>
          {`${intl.formatMessage({ id: 'ChallengeFiltersModal_Button_Results' })}`}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

TournamentFiltersModal.propTypes = {
  field_id: PropTypes.string,
  handleClose: PropTypes.any,
  open: PropTypes.any,
};

TournamentFiltersModal.defaultProps = {};
